import JumboDdMenu from '@jumbo/components/JumboDdMenu'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputAdornment, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Axios } from 'index';
import AllApis from 'app/Apis/apis';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketExecutiveCommissionList } from 'app/redux/actions/MarketExecutiveCommissionMapping';
import { useLocation } from 'react-router-dom';
import CustomDialogBox from 'app/components/DialogBox';
import { Field, Formik, Form } from 'formik';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { debounce } from 'lodash';
import Div from '@jumbo/shared/Div';
import * as yup from "yup"

const textFieldSx = {
  width: "100%"
}

const tableCellSx = {
  textTransform: "capitalize",
  color: "white",
  textAlign: "left",
  minWidth: "175px",
  verticalAlign: "middle",
}

const tableCellSort = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white",
  },
}


const ListingMETCommission = () => {

  const [sortBy, setSortBy] = useState("created_at");
  const [searchTerm, setSearchTerm] = useState("")
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(1);
  const [commissionDialog, setCommissionDialog] = useState({
    open: false,
    commissionData: {}
  })
  const { marketExcutiveCommissionReducer } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleIsActive = async (commissionId, body) => {
    try {
      const { data } = await Axios.patch(AllApis?.marketExecutiveCommission?.update(commissionId), { ...body })
      if (data?.statusCode === 200) {
        dispatch(getMarketExecutiveCommissionList({
          companyId: state?.companyData?.companyId
        }));
        Swal.fire({
          icon: "success",
          title: data?.status,
          text: data?.message,
          timer: 1200,
          showConfirmButton: false,
        })
      }

    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      })
    }
  }

  const handleItemAction = function (menuItems) {
    switch (menuItems.action) {
      case "edit":
        setCommissionDialog({ ...commissionDialog, open: true, commissionData: menuItems?.commissionData })
        break;
      default:
        break;
    }
  }

  const handleClose = function () {
    setCommissionDialog({ ...commissionDialog, open: false })
  }

  const handleUpdateCommission = async function (values, action) {
    try {
      const { data } = await Axios.patch(AllApis?.marketExecutiveCommission?.update(commissionDialog?.commissionData?.commissionId), { ...values })
      if (data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data?.status,
          text: data?.message,
          timer: 1200,
          showConfirmButton: false,
        })
      }
      dispatch(getMarketExecutiveCommissionList({
        companyId: state?.companyData?.companyId
      }));
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      })
    } finally {
      setCommissionDialog({ ...commissionDialog, open: false })
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (searchTerm) => {
    setPage(1)
    dispatch(getMarketExecutiveCommissionList({
      sortBy: sortBy,
      search_value: searchTerm.trim(),
      sort: sort,
      page: page,
      companyId: state?.companyData?.companyId
    }));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);


  useEffect(() => {
    dispatch(getMarketExecutiveCommissionList({
      sortBy: sortBy,
      search_value: searchTerm.trim(),
      sort: sort,
      page: page,
      companyId: state?.companyData?.companyId
    }));
    // console.log(apiData)
  }, [sort, page])

  return (
    <>
      <Div sx={{ mt: 2 }}>
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === "") {
              dispatch(getMarketExecutiveCommissionList({
                sortBy: sortBy,
                search_value: "",
                sort: sort,
                page: page,
                companyId: state?.companyData?.companyId
              }));;
            }
          }}
          sx={{ width: 400 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <TableContainer component={Paper} sx={{ my: "2%" }}>
        <Table sx={{ minWidth: 650 }} size="medium" >
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7" }}>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.marketExecutiveId.current_data.contact_person_details.first_name`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >Market Executive Name</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.marketExecutiveId.current_data.contact_person_details.primary_email_id`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >primary Email Id</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.marketExecutiveId.current_data.contact_person_details.primary_mobile_no`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >primary contact</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.onBoardingDate`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >OnBoarding Date</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                Active
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.commissionPercentage`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >Commission %</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              marketExcutiveCommissionReducer?.marketExecutiveCommissionList?.map(({ _id, current_data: commission, proposed_changes }, index) => {
                return (
                  <TableRow key={_id}>
                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      textTransform: "capitalize"
                    }}>
                      {`${commission?.marketExecutiveId?.current_data?.contact_person_details?.first_name} ${commission?.marketExecutiveId?.current_data?.contact_person_details?.last_name}`}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {commission?.marketExecutiveId?.current_data?.contact_person_details?.primary_email_id}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {commission?.marketExecutiveId?.current_data?.contact_person_details?.primary_mobile_no}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {new Date(commission?.onBoardingDate || Date.now()).toISOString().split('T')[0]}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      <Switch
                        disabled={!proposed_changes?.status}
                        size='small'
                        checked={commission?.isActive}
                        onChange={(e) => handleIsActive(_id, { isActive: e.target.checked })}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {commission?.commissionPercentage} %
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <EditIcon />,
                            title: "Edit",
                            action: "edit",
                            commissionData: {
                              commissionId: _id,
                              onBoardingDate: commission.onBoardingDate,
                              commissionPercentage: commission.commissionPercentage
                            },
                            visibility: proposed_changes?.status
                          },
                        ].filter((e) => e?.visibility)}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Pagination
          count={marketExcutiveCommissionReducer?.totalPage || 0}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: "1%",
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
      <CustomDialogBox
        open={commissionDialog?.open}
        handleClose={handleClose}
        title={"Market Executive"}
        sx={{ width: 700 }}
      >
        <Formik
          initialValues={{
            onBoardingDate: new Date(commissionDialog?.commissionData?.onBoardingDate || Date.now()).toISOString().split("T")[0],
            commissionPercentage: commissionDialog?.commissionData?.commissionPercentage
          }}
          validationSchema={yup.object().shape({
            onBoardingDate: yup.date().required('Onboarding Date is required'),
            commissionPercentage: yup.number().min(1, 'Commission Percentage must be greater than 0').max(100, 'Commission Percentage must be less than or equal to 100').required("Commission Percentage is required"),
          })}
          onSubmit={handleUpdateCommission}
        >
          {({ values, setFieldValue }) => {
            return (
              <Box>
                <Form>
                  <Grid2 container spacing={4}>
                    <Grid2 xs={6}>
                      <Field name="onBoardingDate">
                        {({ field, meta }) => {
                          console.log(meta)
                          return <TextField
                            required
                            {...field}
                            type='date'
                            label={"OnBoarding Date"}
                            sx={{ ...textFieldSx }}
                            size='small'
                            error={meta?.error && meta.touched}
                            helperText={meta?.error && meta.touched ? meta?.error : ""}
                          />
                        }}
                      </Field>
                    </Grid2>
                    <Grid2 xs={6}>
                      <Field name="commissionPercentage">
                        {({ field, meta }) => {
                          return <TextField
                            required
                            {...field}
                            type='number'
                            label="Commission Percentage"
                            sx={{ ...textFieldSx }}
                            size='small'
                            error={meta?.error && meta.touched}
                            helperText={meta?.error && meta.touched ? meta?.error : ""}
                          />
                        }}
                      </Field>
                    </Grid2>
                  </Grid2>
                  <Box sx={{ textAlign: "end", mt: 3 }}>
                    <Button variant='outlined' sx={{ mx: 2 }} onClick={handleClose}>
                      cancel
                    </Button>
                    <Button variant='contained' type='submit'>
                      Update
                    </Button>
                  </Box>
                </Form>
              </Box>
            )
          }}
        </Formik>
      </CustomDialogBox>
    </>
  )
}

export default ListingMETCommission