import { MET_PAYOUT_FAILED, MET_PAYOUT_REQUEST, MET_PAYOUT_SUCCESSS } from "app/utils/constants/payoutContants"

const INIT_VALUE = {
    loading:false,
    marketExecutive:[],
    totalPage:0,
    error:false,
    errorMessage:null
}


const payoutReducer = (state = INIT_VALUE, action) => {
    switch (action.type) {
        case MET_PAYOUT_REQUEST:
            return { ...state, loading: true }
        case MET_PAYOUT_SUCCESSS:
            return {
                ...state,
                marketExecutive: action.payload.marketExecutive,
                totalPage: action.payload.totalPage,
                loading: false
            }
        case MET_PAYOUT_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                marketExecutive: [],
                totalPage: 0,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}




export default payoutReducer