import React from "react";
// import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import { usersRoute } from "./User";
import { rolesRoute } from "./Roles";
import { inventoryRoute } from "./Inventory";
import { categoryRoute } from "./Masters/Category";
import { unitRoute } from "./Masters/Units";
import { gstRoute } from "./Masters/Gst";
import { hsnCodeRoute } from "./Masters/Hsn Code";
import { productRoute } from "./Masters/Products";
import { tdsRoute } from "./Masters/Tds";
import { paymentTermDaysRoute } from "./Masters/PaymentTermDays";
import { sskCompanyRoute } from "./Masters/SskCompany";
import { suppliersRoute } from "./Masters/Suppliers";
import { retailerRoute } from "./Masters/Retailers";
import { offlineStoreRoute } from "./Masters/OfflineStores";
import { marketExecutiveTeamsRoute } from "./Masters/MarketExecutive";
import { purchaseOrderRoute } from "./PurchaseOrder";
import { sampleTrackingRoute } from "./SampleTracking";
import { ordersRoute } from "./OrderProcessing/Orders";
import { salesOrdersRoute } from "./OrderProcessing/SalesOrders";
import { dispatchRoute } from "./OrderProcessing/Dispatch";
import { paymentsRoute } from "./PaymentsAndPayouts/Payments";
import { payoutsRoute } from "./PaymentsAndPayouts/Payouts";
import Login from "app/pages/Login/Index";
import ListUsers from "app/pages/User/List";
import DashboardHome from "app/pages/DashboardHomepg";
import Error404 from "app/pages/404";
import Cookies from "js-cookie";
import { approvalRoute } from "./Approval";
import { faqRoute } from "./Faq";
import { ticketRoute } from "./Tickets";
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/

const routesForPublic = [
  {
    path: "/",
    element: <Page component={Login} layout={"solo-page"} />,
  },
  {
    path: "/login",
    element: <Page component={Login} layout={"solo-page"} />,
  },
  {
    path: "*",
    element: (
      <Page component={Error404} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  ...usersRoute,
  ...approvalRoute,
  ...rolesRoute,
  ...categoryRoute,
  ...unitRoute,
  ...gstRoute,
  ...hsnCodeRoute,
  ...productRoute,
  ...tdsRoute,
  ...paymentTermDaysRoute,
  ...suppliersRoute,
  ...sskCompanyRoute,
  ...retailerRoute,
  ...offlineStoreRoute,
  ...marketExecutiveTeamsRoute,
  ...inventoryRoute,
  ...purchaseOrderRoute,
  ...sampleTrackingRoute,
  ...ordersRoute,
  ...salesOrdersRoute,
  ...dispatchRoute,
  ...paymentsRoute,
  ...payoutsRoute,
  ...faqRoute,
  ...ticketRoute,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
