import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { formatDate } from "app/pages/Component/Function/formatDate";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./item.css";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import { getAllOrders } from "app/redux/actions/OrderProcessing/Orders";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewCreateSalesOrderModel({
  open,
  setOpen,
  selectedRow,
  type,
}) {
  const [data, setData] = React.useState({});
  const [rerender, setRerender] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => setOpen({ ...open, salesCreateModel: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const todaysDate = new Date(Date.now()).toISOString().split("T")[0];

  const sameState =
    data?.ssk_details?.address?.state ==
    data?.customer_details?.bill_to?.address.state;

  const handleDispatchQty = (event, index, row) => {
    let value = event.target.value;
    if (value < 1) {
      value = 1;
    } else if (value > row?.quantity) {
      // Assuming max value is 100, you can adjust this according to your requirement
      value = row.quantity;
    }
    const initialData = data?.Items;
    const gst_percentage = Object.entries(initialData[index].gst).reduce(
      (acc, ele, ind) => {
        return acc + ele[1];
      },
      0
    );
    initialData[index] = {
      ...initialData[index],
      dispatch_quantity: Number(value),
      balance_quantity: initialData[index].pending_quantity - Number(value),
      total_amount:
        initialData[index].rate_per_unit * Number(value) +
        Number(gst_percentage / 100),
    };
    const updated = { ...data, Items: initialData };
    setData(updated);
    setRerender(!rerender);
  };

  const handleCheck = (e, index) => {
    const isCheck = e.target.checked;
    if (isCheck) {
      const initialData = data?.Items;
      initialData[index] = {
        ...initialData[index],
        selected: isCheck,
      };
      const updated = { ...data, Items: initialData };
      setData(updated);
    } else {
      const initialData = selectedRow?.Items;
      const currentData = data?.Items;
      const gst_percentage = Object.entries(initialData[index].gst).reduce(
        (acc, ele, ind) => {
          return acc + ele[1];
        },
        0
      );
      currentData[index] = {
        ...currentData[index],
        dispatch_quantity: initialData[index].pending_quantity,
        balance_quantity: 0,
        total_amount:
          initialData[index].rate_per_unit * initialData[index].quantity +
          Number(gst_percentage / 100),
        selected: isCheck,
      };
      const updated = { ...data, Items: currentData };
      setData(updated);
    }
    setRerender(!rerender);
  };
  console.log(data, "dada");

  const handleSubmit = async () => {
    console.log("submittt");
    setIsSubmitting(true);
    try {
      const filteredArr = data.Items.filter((ele) => ele.selected == true);
      if (filteredArr.length === 0) {
        return Swal.fire({
          icon: "warning",
          title: "Please select a Product.",
          timer: 1000,
          showConfirmButton: false,
          customClass: {
            container: "popupImportant",
          },
        });
      }
      const body = {
        ...data,
        Items: filteredArr,
      };
      const response = await Axios.post(AllApis.salesOrder.add, body);
      if (response?.data?.statusCode) {
        Swal.fire({
          title: "Sales Order Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllOrders("", "desc", "created_at", 1, type, {}));
        navigate("/dashboards/orderProcessing/orders");
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    let gst;
    sameState
      ? (gst = {
          cgst: 0,
          sgst: 0,
        })
      : (gst = {
          igst: 0,
        });

    const initialData = {
      ...selectedRow,
      total_weight: 0,
      total_quantity: 0,
      total_item_amount: 0,
      total_gst: 0,
      total_igst: 0,
      total_cgst: 0,
      total_sgst: 0,
      ...gst,
      total_amount: 0,
      total_balance_quantity: 0,
      total_dispatch_quantity: 0,
      sales_order_date: todaysDate,
      Items: selectedRow?.Items.map((item) => {
        return {
          ...item,
          dispatch_quantity: item.pending_quantity,
          balance_quantity: 0,
          selected: false,
        };
      }),
    };
    setData(initialData);
  }, [selectedRow]);

  React.useEffect(() => {
    let gst;
    sameState
      ? (gst = {
          cgst: 0,
          sgst: 0,
        })
      : (gst = {
          igst: 0,
        });
    const initialData = {
      total_weight: 0,
      total_quantity: 0,
      total_item_amount: 0,
      total_gst: 0,
      total_igst: 0,
      total_cgst: 0,
      total_sgst: 0,
      total_balance_quantity: 0,
      total_dispatch_quantity: 0,
      ...gst,
      total_amount: 0,
    };
    if (data?.Items?.length > 0) {
      const filteredArr = data.Items.filter((ele) => ele.selected == true);
      const updatedState = filteredArr.reduce((acc, item) => {
        acc.total_weight += item.weight;
        acc.total_quantity += item.quantity;
        acc.total_dispatch_quantity += item.dispatch_quantity;
        acc.total_balance_quantity += item.balance_quantity;
        acc.total_item_amount += item.item_amount;
        acc.total_cgst += item.gst.cgst;
        acc.total_sgst += item.gst.sgst;
        acc.total_igst += item.gst.igst;
        acc.total_gst += sameState
          ? item.gst.cgst + item.gst.sgst
          : item.gst.igst;
        acc.total_amount += item.total_amount;
        return acc;
      }, initialData);
      const obj = {};
      Object.entries(updatedState).map((ele) => {
        const value = isNaN(ele[1]) ? 0 : ele[1];
        obj[ele[0]] = value;
      });
      setData({ ...data, ...updatedState });
    }
  }, [rerender]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ ml: 2, mt: 1.5, flex: 1, color: "white" }}
            variant="h4"
            component="div"
          >
            Create Sales Order
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ p: 5, width: "100%" }}>
        <Typography
          variant="h2"
          sx={{ fontWeight: 600, textTransform: "capitalize" }}
        >
          {data?.ssk_details?.company_name}
        </Typography>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.ssk_details?.first_name} ${data?.ssk_details?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.ssk_details?.primary_mobile_no} / ${
                data?.ssk_details?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.ssk_details?.primary_email_id}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.ssk_details?.secondary_email_id || "NA"}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GSTNo.</span> :{" "}
              {data?.ssk_details?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.ssk_details?.address?.address}, 
                            ${data?.ssk_details?.address?.area},
                            ${data?.ssk_details?.address?.location},
                            ${data?.ssk_details?.address?.district},
                            ${data?.ssk_details?.address?.taluka},
                            ${data?.ssk_details?.address?.city},
                            ${data?.ssk_details?.address?.state},
                            ${data?.ssk_details?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Order No.</span> :{" "}
              {data?.order_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Order Date</span> :{" "}
              {formatDate(data?.order_date)}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Est. Delivery Date</span> :{" "}
              {formatDate(data?.estimate_delivery_date)}
            </Typography>
          </Div>
        </Div>

        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 5,
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Bill To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.customer_details?.bill_to?.branch_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.customer_details?.bill_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.customer_details?.bill_to?.first_name} ${data?.customer_details?.bill_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.customer_details?.bill_to?.primary_mobile_no} / ${
                data?.customer_details?.bill_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.customer_details?.bill_to?.primary_email_id}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.customer_details?.bill_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.customer_details?.bill_to?.address?.address}, 
                            ${data?.customer_details?.bill_to?.address?.area},
                            ${data?.customer_details?.bill_to?.address?.location},
                            ${data?.customer_details?.bill_to?.address?.district},
                            ${data?.customer_details?.bill_to?.address?.taluka},
                            ${data?.customer_details?.bill_to?.address?.city},
                            ${data?.customer_details?.bill_to?.address?.state},
                            ${data?.customer_details?.bill_to?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Ship To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.customer_details?.ship_to?.branch_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.customer_details?.ship_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.customer_details?.ship_to?.first_name} ${data?.customer_details?.ship_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.customer_details?.ship_to?.primary_mobile_no} / ${
                data?.customer_details?.ship_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.customer_details?.ship_to?.primary_email_id}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.customer_details?.ship_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.customer_details?.ship_to?.address?.address}, 
                            ${data?.customer_details?.ship_to?.address?.area},
                            ${data?.customer_details?.ship_to?.address?.location},
                            ${data?.customer_details?.ship_to?.address?.district},
                            ${data?.customer_details?.ship_to?.address?.taluka},
                            ${data?.customer_details?.ship_to?.address?.city},
                            ${data?.customer_details?.ship_to?.address?.state},
                            ${data?.customer_details?.ship_to?.address?.pincode}`}
            </Typography>
          </Div>
        </Div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Sales Order Date"
            id="sales_order_date"
            format="DD-MM-YYYY"
            name="sales_order_date"
            sx={{ width: "15%", mt: 5 }}
            defaultValue={dayjs(data?.sales_order_date)}
            onChange={(newValue) => {
              setData({
                ...data,
                sales_order_date: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
              });
            }}
            slotProps={{ textField: { size: "small" } }}
          />
        </LocalizationProvider>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table style={{ border: "2px solid #7352C7" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "0px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Select
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  HSN Code
                </TableCell>
                {sameState ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      CGST
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "150px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      SGST
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    IGST
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Rate
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Weight
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Required Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Dispatch Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "130px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Balance Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  GST Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.Items?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      checked={row["selected"]}
                      disabled={row.dispatch_quantity==0 && row.pending_quantity==0}
                      onChange={(e) => handleCheck(e, i)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.item_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.category}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.sku}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.hsn_code}
                  </TableCell>
                  {sameState ? (
                    <>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.cgst}%
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.sgst}%
                      </TableCell>
                    </>
                  ) : (
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {row?.gst?.igst}%
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.rate_per_unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.weight}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.pending_quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "100%" }}
                      inputProps={{
                        min: 1,
                        max: row?.pending_quantity,
                      }}
                      disabled={!row?.selected}
                      onChange={(e) => handleDispatchQty(e, i, row)}
                      value={row?.dispatch_quantity}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.balance_quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.item_amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹{" "}
                    {sameState
                      ? row?.gst?.cgst / 100 + row?.gst?.cgst / 100
                      : row?.gst?.igst / 100}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.total_amount}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ borderTop: "2px solid #7352C7" }}>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                  colSpan={sameState ? 8 : 7}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                ></TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_weight} kg
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                ></TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_dispatch_quantity} qty
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_balance_quantity} qty
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  ₹ {data?.total_item_amount?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {(data?.total_gst / 100)?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {data?.total_amount?.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Div
          sx={{
            width: "93.5%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 3,
          }}
        >
          <Button
            sx={{ width: "100px" }}
            variant="outlined"
            onClick={handleClose}
          >
            Back
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ width: "100px" }}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            Create
          </LoadingButton>
        </Div>
      </List>
    </Dialog>
  );
}
