import React, { useEffect, useState } from "react";
import ListingOfflinePayment from "./ListingOfflinePayment";
import Div from "@jumbo/shared/Div";
import Heading from "app/components/Heading";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getOfflinePaymentList } from "app/redux/actions/PaymentsAndPayouts/Payments/OfflinePayment";

const datepickerStyling = {
  width: "100%",
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aeb7bd ", // or any other color you prefer
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#475259", // Change to the color you want on hover
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7352C7", // Change to the color you want when focused
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#7352C7", // Keep the same color as focused when hovered
    },
  "& .MuiInputLabel-outlined": {
    color: "#98a3aa", // Default label color
    "&.Mui-focused": {
      color: "#7352C7", // Label color on focus
    },
  },
};

const offlinePaymentFilters = function ({ range, ...filters }) {
  const filter = {};
  for (let i in filters) {
    if (filters[i]) {
      filter[`current_data.${i}`] = filters[i];
    }
  }
  for (let i in range) {
    filter["range"] = { ...filter?.["range"] };
    const fields = Object.keys(range[i]);
    for (let j of fields) {
      if (range[i][j]) {
        filter["range"][`current_data.${i}`] = {
          ...filter?.["range"]?.[`current_data.${i}`],
        };
        filter["range"][`current_data.${i}`][j] = range[i][j];
      }
    }
  }
  return filter;
};

const OfflinePayment = () => {
  
  const [filters, setFilters] = useState({
    salesOrderNo: "",
    "offlineStoreDetails.companyName": "",
    paymentStatus: "",
    range: {
      salesOrderDate: {
        from: "",
        to: "",
      },
      dueDate: {
        from: "",
        to: "",
      },
    },
  });
  const dispatch = useDispatch();

  const [filterDropdown, setFilterDropdown] = useState({
    salesOrderNo: [],
    offlineStoreCompanyName: [],
  });

  const handleFilter = function (name, value) {
    setFilters((pre) => ({ ...pre, [name]: value }));
  };

  const handleFiltersDate = function (name, field, value) {
    setFilters((pre) => ({
      ...pre,
      range: {
        ...pre.range,
        [name]: { ...pre.range[name], [field]: value },
      },
    }));
  };

  const handleFiltersApply = function () {
    dispatch(
      getOfflinePaymentList({
        filters: offlinePaymentFilters(filters),
      })
    );
  };
  const handleFilterClear = () => {
    setFilters({
      salesOrderNo: "",
      "offlineStoreDetails.companyName": "",
      paymentStatus: "",
      range: {
        salesOrderDate: {
          from: "",
          to: "",
        },
        dueDate: {
          from: "",
          to: "",
        },
      },
    });
    dispatch(getOfflinePaymentList({}));
  };

  const getDropdownData = async function () {
    try {
      const [salesOrderNO, offlineStoreCompanyName] = await Promise.all([
        Axios.get(AllApis?.salesOrder?.offlineSalesOrderNo),
        Axios.get(AllApis?.offlinestore?.dropdown),
      ]);
      if (
        salesOrderNO?.data?.statusCode === 200 &&
        offlineStoreCompanyName?.data?.statusCode === 200
      ) {
        setFilterDropdown({
          salesOrderNo: salesOrderNO?.data?.data,
          offlineStoreCompanyName:
            offlineStoreCompanyName?.data?.data?.offlinestore,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Heading Name={"Listing Offline Payments"} />
      </Div>
      <Box>
        <Typography variant="h1" sx={{ my: 3 }}>
          Filters
        </Typography>
        <Box>
          <Grid2 container columnSpacing={3} rowSpacing={5}>
            <Grid2 xs={3}>
              <Autocomplete
                sx={{ width: "100%" }}
                size="small"
                id="company-autocomplete"
                options={filterDropdown?.salesOrderNo}
                value={filters?.salesOrderNo || null}
                label="Sales Order No"
                name="salesOrderNo"
                getOptionLabel={(option) => option?.toString() || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.sales_order_no === value
                }
                onChange={(e, newValue) => {
                  handleFilter("salesOrderNo", newValue?.sales_order_no);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option?._id}
                    name="salesOrderNo"
                  >
                    {option?.sales_order_no}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sales Order No"
                  />
                )}
              />
            </Grid2>
            <Grid2 xs={3}>
              <Autocomplete
                sx={{ width: "100%" }}
                size="small"
                id="company-autocomplete"
                options={filterDropdown?.offlineStoreCompanyName}
                value={filters?.["offlineStoreDetails.companyName"] || null}
                label="Company Name"
                getOptionLabel={(option) => option.toString()}
                isOptionEqualToValue={(option, value) =>
                  option.company_name === value
                }
                onChange={(e, newValue) => {
                  handleFilter(
                    "offlineStoreDetails.companyName",
                    newValue?.company_name
                  );
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option?._id}
                  >
                    {option?.company_name} ({option?.pan_no})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Offline Store Name"
                  />
                )}
              />
            </Grid2>
            <Grid2 xs={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">
                  Payment Status
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={filters?.paymentStatus}
                  label="Payment Status"
                  onChange={(e) => {
                    handleFilter("paymentStatus", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"partailly paid"}>Partailly Paid</MenuItem>
                  <MenuItem value={"fully paid"}>Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={
                    filters?.range?.salesOrderDate?.from
                      ? "refresh1"
                      : "refresh2"
                  }
                  id="fromSaleOrderDate"
                  format="DD-MM-YYYY"
                  name="from"
                  label="From Sales Order Date"
                  sx={datepickerStyling}
                  defaultValue={
                    filters?.range?.salesOrderDate?.from != ""
                      ? dayjs(filters?.range?.salesOrderDate?.from)
                      : null
                  }
                  onChange={(newValue) => {
                    const newDate = newValue.startOf("day");
                    handleFiltersDate(
                      "salesOrderDate",
                      "from",
                      newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
                {/* {(!filters?.range?.salesOrderDate?.from && filters?.range?.salesOrderDate?.to) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select from sales order date </Typography>} */}
              </LocalizationProvider>
            </Grid2>
            <Grid2 xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={
                    filters?.range?.salesOrderDate?.to ? "refresh1" : "refresh2"
                  }
                  id="toSaleOrderDate"
                  format="DD-MM-YYYY"
                  name="from"
                  sx={datepickerStyling}
                  label="To Sales Order Date"
                  defaultValue={
                    filters?.range?.salesOrderDate?.to
                      ? dayjs(filters?.range?.salesOrderDate?.to)
                      : null
                  }
                  onChange={(newValue) => {
                    const newDate = newValue.startOf("day");
                    handleFiltersDate(
                      "salesOrderDate",
                      "to",
                      newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {/* {(!filters?.range?.salesOrderDate?.to && filters?.range?.salesOrderDate?.from) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select to sales order date </Typography>} */}
            </Grid2>
            <Grid2 xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={filters?.range?.dueDate?.from ? "refresh1" : "refresh2"}
                  id="fromDueDate"
                  format="DD-MM-YYYY"
                  name="fromDueDate"
                  sx={datepickerStyling}
                  label="From Due Date"
                  defaultValue={
                    filters?.range?.dueDate?.from != ""
                      ? dayjs(filters?.range?.dueDate?.from)
                      : null
                  }
                  onChange={(newValue) => {
                    const newDate = newValue.startOf("day");
                    handleFiltersDate(
                      "dueDate",
                      "from",
                      newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {/* {(!filters?.range?.dueDate?.from && filters?.range?.dueDate?.to) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select to sales order date </Typography>} */}
            </Grid2>
            <Grid2 xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={filters?.range?.dueDate?.to ? "refresh1" : "refresh2"}
                  id="toDueDate"
                  format="DD-MM-YYYY"
                  name="from"
                  sx={datepickerStyling}
                  label="To Due Date"
                  defaultValue={
                    filters?.range?.dueDate?.to != ""
                      ? dayjs(filters?.range?.dueDate?.to)
                      : null
                  }
                  onChange={(newValue) => {
                    const newDate = newValue.startOf("day");
                    handleFiltersDate(
                      "dueDate",
                      "to",
                      newDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {/* {(!filters?.range?.dueDate?.to && filters?.range?.dueDate?.from) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select to sales order date </Typography>} */}
            </Grid2>
          </Grid2>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            // disabled={
            //   ((!filters?.range?.salesOrderDate?.from && filters?.range?.salesOrderDate?.to) ||
            //     (!filters?.range?.salesOrderDate?.to && filters?.range?.salesOrderDate?.from) ||
            //     (!filters?.range?.dueDate?.from && filters?.range?.dueDate?.to) ||
            //     (!filters?.range?.dueDate?.to && filters?.range?.dueDate?.from)) ? true : false
            // }
            onClick={handleFiltersApply}
          >
            Apply
          </Button>
          <Button variant="outlined" onClick={handleFilterClear}>
            Clear
          </Button>
        </Box>
      </Box>
      <ListingOfflinePayment />
    </>
  );
};

export default OfflinePayment;
