import React, { useCallback, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoCompletewithDialog from "app/components/Autocomplete";
import { Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Heading from "app/pages/Component/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList } from "app/redux/actions/Roles";
import { getUsers } from "app/redux/actions/User";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import * as yup from "yup";
import AddStockForm from "./AddStockForm";
const AddInventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  // useSelector
  //  useState
  const [isSubmitting, setSubmitting] = useState(false);

  const [supplierList, SetsupplierList] = useState([]);
  const [purchaseOrderList, SetpurchaseOrderList] = useState([]);
  const handleImagesTab = async (value) => {
    if (value && typeof value === "string") {
      const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
      window.open(imageURL.href, "_blank");
    } else {
      const images = URL.createObjectURL(value);
      window.open(images, "__blank");
    }
  };
  const InitialValues = {
    supplier_id: "",
    PO_id: "",
  };
  const handleSubmit = async (values) => {};
  const validationSchema = yup.object().shape({});
  const handlePONo = useCallback(async (supplier_id) => {
    // Fetch purchase order list based on selected supplier
    let poList = await Axios.get(
      AllApis.inventory.supplierPO(supplier_id?._id)
    );
    SetpurchaseOrderList(poList?.data?.data);
  }, []);
  console.log(purchaseOrderList, "<------------------");
  // useEffect
  useEffect(async () => {
    let supplierlist = await Axios.get(AllApis.supplier.dropdown);
    SetsupplierList(supplierlist?.data?.data?.supplier);
  }, []);
  return (
    <>
      <Div sx={{ mt: "-4px" }}>
        <Heading Name={"Add Stock"} />
        <Box sx={{ marginTop: "2%" }}>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              handleSubmit,
              touched,
              errors,
            }) => {
              return (
                <>
                  {console.log(values)}
                  <Form>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "5% 0",
                      }}
                    >
                      {console.log(values)}
                      <Autocomplete
                        // disablePortal
                        id="combo-box-demo"
                        options={supplierList}
                        size="small"
                        sx={{ width: "25%" }}
                        // error={errors?.role_name && touched?.role_name}
                        // helperText={
                        //   errors?.role_name && touched?.role_name
                        //     ? errors?.role_name
                        //     : ""
                        // }
                        // value={
                        //   allroles?.filter(
                        //     (item) => item._id == values?.role_id
                        //   )?.[0] || null
                        // }
                        getOptionLabel={(option) => option?.company_name}
                        // isOptionEqualToValue={(option, value) => option._id === value._id}
                        onChange={(e, newValue) => {
                          setFieldValue("supplier_id", newValue);
                          handlePONo(newValue);
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option?.company_name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Supplier Name" />
                        )}
                      />
                      <Autocomplete
                        // disablePortal
                        id="combo-box-demo"
                        options={purchaseOrderList}
                        size="small"
                        sx={{ width: "25%" }}
                        // error={errors?.role_name && touched?.role_name}
                        // helperText={
                        //   errors?.role_name && touched?.role_name
                        //     ? errors?.role_name
                        //     : ""
                        // }
                        // value={
                        //   allroles?.filter(
                        //     (item) => item._id == values?.role_id
                        //   )?.[0] || null
                        // }
                        getOptionLabel={(option) =>
                          option?.current_data?.purchase_order_no
                        }
                        // isOptionEqualToValue={(option, value) => option._id === value._id}
                        onChange={(e, newValue) => {
                          console.log(newValue);
                          setFieldValue("PO_id", newValue);
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option?.current_data?.purchase_order_no}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Purchase Order Number"
                          />
                        )}
                      />
                    </Div>
                    {values?.PO_id && values?.PO_id ? (
                      <Div>
                        <AddStockForm POdata={values?.PO_id} />
                      </Div>
                    ) : (
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "1% 0",
                        }}
                      >
                        <Button
                          sx={{ width: "100px" }}
                          variant="contained"
                          onClick={() => navigate("/dashboards/inventory")}
                        >
                          Back
                        </Button>
                      </Div>
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Div>
    </>
  );
};

export default AddInventory;
