import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import EditIcon from "@mui/icons-material/Edit";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Swal from "sweetalert2";
import { formatDate } from "app/pages/Component/Function/formatDate";
import Div from "@jumbo/shared/Div";
import Heading from "app/pages/Component/Heading";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { getOfflinePaymentList } from "app/redux/actions/PaymentsAndPayouts/Payments/OfflinePayment";
import CustomDialogBox from "app/components/DialogBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Field, Form, Formik } from "formik";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { OverDueDays } from "../Functions";
import SearchIcon from "@mui/icons-material/Search";
import * as yup from "yup";
import { debounce } from "lodash";
import FullScreenModal from "app/components/FullScreenModal";
import TextFeildView from "app/components/TextFieldView";
import ViewOfflinePayment from "../ViewOfflinePayment";
import Loader from "app/components/Loader";

const TableHeadCell = {
  textAlign: "left",
  minWidth: "190px",
  verticalAlign: "middle",
  textTransform: "capitalize",
  color: "white",
};

const stickyTableHeadCellSx = {
  textTransform: "capitalize",
  color: "white",
  minWidth: "120px",
  verticalAlign: "middle",
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const TableSortLabelCell = {
  color: "white !important",
  "&:hover": { color: "white !important" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important", // Set the color for the active state
  },
};

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? "#C7D0DD" : "#1C2025"};
    background: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border: 1px solid ${
      theme.palette.mode === "dark" ? "#434D5B" : "#0000003b"
    };
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? "#1C2025" : "#F3F6F9"
    };

    &:hover {
      border-color: ${"black"};
    }

    &:focus {
      border-color: ${"#7352C7"};
      box-shadow: 0px 0px 0px 1px ${
        theme.palette.mode === "dark" ? "#7352C7" : "#7352C7"
      };
      outline: none;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const TableRowCell = { textAlign: "left", color: "black" };

const HeadData = [
  {
    title: "Sales Order Date",
    fieldname: "current_data.salesOrderDate",
  },
  {
    title: "Sales Order No",
    fieldname: "current_data.salesOrderNo",
  },
  {
    title: "Offline store name",
    fieldname: "current_data.offlineStoreDetails.companyName",
  },
  {
    title: "GST No",
    fieldname: "current_data.offlineStoreDetails.gstNo",
  },
  {
    title: "Email",
    fieldname: "current_data.offlineStoreDetails.email",
  },
  {
    title: "Mobile No",
    fieldname: "current_data.offlineStoreDetails.mobileNo",
  },
  {
    title: "Total Amount",
    fieldname: "current_data.totalSalesAmount",
  },
  {
    title: "Amount Received",
    fieldname: "current_data.recievedAmount",
  },
  {
    title: "Balance Amount",
    fieldname: "current_data.balanceAmount",
  },
  {
    title: "Payment Status",
    sort: false,
  },
  {
    title: " Due Date",
    fieldname: "current_data.dueDate",
  },
  {
    title: "Overdue Days",
    fieldname: "current_data.OverdueDays",
    sort: false,
  },
  {
    title: "Action",
    sort: false,
  },
];

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
};

export default function ListingOfflinePayment() {
  const [followUpRemarkModal, setFollowUpRemarkModal] = useState({
    open: false,
    offlinePaymentId: "",
  });
  const [offlinePaymentViewModal, setOfflinePaymentViewModal] = useState({
    open: false,
    offlinePaymentData: null,
  });
  const offlinePaymentList = useSelector(
    (state) => state?.offlinePaymentReducer
  );
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.offlinePaymentReducer);

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "updatePayment":
        navigate(`/dashboards/payments/updatePayment`, {
          state: { offlinePaymentData: { ...menuItem.data } },
        });
        break;
      case "view":
        setOfflinePaymentViewModal({
          open: true,
          offlinePaymentData: menuItem.data,
        });
        break;
      case "followUpAndRemarks":
        setFollowUpRemarkModal({
          open: true,
          offlinePaymentId: menuItem.offlinePaymentId,
        });
        break;
      default:
        navigate(`/dashboards/payments`);
        break;
    }
  };

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setFollowUpRemarkModal({
      open: false,
      offlinePaymentId: "",
    });
  };
  const handleCloseViewModal = function () {
    setOfflinePaymentViewModal({
      open: false,
      offlinePaymentData: null,
    });
  };

  const handlePaymentStatus = async (e, offlinePaymentId) => {
    const { value } = e.target;
    try {
      const { data } = await Axios.patch(
        AllApis?.payments?.offlinePayment?.updatePaymentStatus(
          offlinePaymentId
        ),
        {
          paymentStatus: value,
        }
      );
      if (data?.statusCode === 201) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          text: "",
          timer: 1200,
          showConfirmButton: false,
        });
      }
      dispatch(
        getOfflinePaymentList({
          page: page,
          sort: sort,
          sortBy: sortBy,
        })
      );
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      });
    }
  };

  const handleFollowUpAndRemarkUpdate = async (values, action) => {
    try {
      const { data } = await Axios.post(
        AllApis?.payments?.offlinePayment?.addFollowupAndRemark(
          followUpRemarkModal?.offlinePaymentId
        ),
        {
          ...values,
        }
      );
      if (data?.statusCode === 201) {
        Swal.fire({
          icon: "success",
          title: data?.message,
          text: "",
          timer: 1200,
          showConfirmButton: false,
        });
        action.resetForm();
        handleClose();
      }
      dispatch(
        getOfflinePaymentList({
          page: page,
          sort: sort,
          sortBy: sortBy,
        })
      );
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      });
    }
  };

  const handleSearch = (searchTerm) => {
    setPage(1);
    dispatch(
      getOfflinePaymentList({
        sortBy: sortBy,
        search_value: searchTerm?.trim(),
        sort: sort,
        page: page,
      })
    );
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getOfflinePaymentList({
        page: page,
        sort: sort,
        sortBy: sortBy,
      })
    );
  }, [page, sort, sortBy, dispatch]);

  return (
    <Box>
      {loading && <Loader />}

      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          my: 4,
        }}
      >
        <Div>
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === "") {
                dispatch(
                  getOfflinePaymentList({
                    page: 1,
                    sort: "desc",
                    sortBy: "created_at",
                    search_value: "",
                  })
                );
              }
            }}
            sx={{ width: 400 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Div>
      </Div>
      <Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                {HeadData.map((row, i) => (
                  <TableCell
                    key={i}
                    sx={
                      row?.title !== "Action"
                        ? { ...TableHeadCell }
                        : {
                            ...stickyTableHeadCellSx,
                            borderColor: "#7352C7",
                            textAlign: "center",
                          }
                    }
                  >
                    {row.sort !== false ? (
                      <TableSortLabel
                        onClick={() => handleSort(row.fieldname)}
                        direction={sort}
                        sx={{ ...TableSortLabelCell }}
                      >
                        {row.title}
                      </TableSortLabel>
                    ) : (
                      row.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {offlinePaymentList?.offlinePaymentList?.map(
                (
                  { _id, current_data: offlinePaymentData, proposed_changes },
                  i
                ) => {
                  return (
                    <TableRow key={_id}>
                      <TableCell sx={{ ...TableRowCell }}>
                        {formatDate(offlinePaymentData?.salesOrderDate)}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {offlinePaymentData?.salesOrderNo}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {offlinePaymentData?.offlineStoreDetails?.companyName}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {offlinePaymentData?.offlineStoreDetails?.gstNo}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {offlinePaymentData?.offlineStoreDetails?.email}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {offlinePaymentData?.offlineStoreDetails?.mobileNo}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        ₹ {offlinePaymentData?.totalSalesAmount}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        ₹ {offlinePaymentData?.recievedAmount}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        ₹ {offlinePaymentData?.balanceAmount}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        <Select
                          sx={{ width: "80%", ...disabledStyling }}
                          size="small"
                          disabled={
                            offlinePaymentData?.paymentStatus === "fully paid"
                          }
                          value={offlinePaymentData?.paymentStatus}
                          onChange={(e) => handlePaymentStatus(e, _id)}
                        >
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"partailly paid"}>
                            Partailly Paid
                          </MenuItem>
                          <MenuItem value={"fully paid"}>Paid</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {formatDate(offlinePaymentData?.dueDate)}
                      </TableCell>
                      <TableCell sx={{ ...TableRowCell }}>
                        {OverDueDays(offlinePaymentData?.dueDate)} days over
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          position: "sticky",
                          right: 0,
                          zIndex: 1,
                          bgcolor: "white",
                        }}
                      >
                        <JumboDdMenu
                          icon={<MoreHorizIcon />}
                          menuItems={[
                            {
                              icon: <VisibilityRoundedIcon />,
                              title: "View Payment Details",
                              action: "view",
                              data: {
                                offlinePaymentId: _id,
                                ...offlinePaymentData,
                              },
                              visibility: Permission?.["payments"]?.["view"],
                            },
                            {
                              icon: <EditIcon />,
                              title: "Update Payment",
                              action: "updatePayment",
                              data: {
                                offlinePaymentId: _id,
                                ...offlinePaymentData,
                              },
                              visibility:
                                (proposed_changes?.status &&
                                  offlinePaymentData.balanceAmount !== 0) ||
                                Permission?.["payments"]?.["edit"],
                            },
                            {
                              icon: <FeedbackIcon />,
                              title: "FollowUp & Remarks",
                              action: "followUpAndRemarks",
                              offlinePaymentId: _id,
                              data: offlinePaymentData,
                              visibility: Permission?.["payments"]?.["add"],
                            },
                          ].filter((e) => e.visibility == true)}
                          onClickCallback={handleItemAction}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
          <Pagination
            count={offlinePaymentList?.totalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              p: 1,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>

        <CustomDialogBox
          open={followUpRemarkModal?.open}
          handleClose={handleClose}
          title={"FollowUp & Remarks"}
          sx={{ width: 700 }}
        >
          <Formik
            initialValues={{
              followUpDate: new Date(Date.now()).toISOString().split("T")[0],
              remark: "",
            }}
            validationSchema={yup.object({
              followUpDate: yup.date().required("Follow-up date is required"),
              remark: yup
                .string()
                .required("Remark is required")
                .test(
                  "word-count",
                  "Remark must be at least 10 words",
                  (value) => {
                    if (!value) return false;
                    const wordCount = value.trim().split(/\s+/).length;
                    return wordCount >= 10;
                  }
                ),
            })}
            onSubmit={handleFollowUpAndRemarkUpdate}
          >
            {({ values }) => {
              return (
                <Form>
                  <Grid2 container spacing={3}>
                    <Grid2 xs={6}>
                      <Field name="followUpDate">
                        {({ field, meta }) => {
                          return (
                            <TextField
                              {...field}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              type="date"
                              sx={{
                                width: "100%",
                                textTransform: "capitalize",
                              }}
                              size="small"
                              label="Follow Up Date"
                              error={meta?.error && meta.touched}
                              helperText={
                                meta?.error && meta.touched ? meta?.error : ""
                              }
                            />
                          );
                        }}
                      </Field>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Field name="remark">
                        {({ field, meta }) => {
                          return (
                            <Box>
                              <Textarea
                                {...field}
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Remarks *"
                                style={{
                                  borderColor:
                                    meta?.error && meta?.touched && "red",
                                }}
                              />
                              <Typography
                                sx={{
                                  color: "red",
                                  ml: 2,
                                  fontSize: " 0.75rem",
                                }}
                              >
                                {meta?.error && meta.touched ? meta?.error : ""}
                              </Typography>
                            </Box>
                          );
                        }}
                      </Field>
                    </Grid2>
                  </Grid2>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Update
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </CustomDialogBox>

        {offlinePaymentViewModal?.offlinePaymentData && (
          <FullScreenModal
            open={offlinePaymentViewModal?.open}
            handleClose={handleCloseViewModal}
            title={"Offline Payment Details"}
          >
            <Box>
              <Grid2 container justifyContent={"space-between"}>
                <Grid2 xs={4} ml={2}>
                  <Box>
                    <Typography variant="h1">
                      {
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.companyName
                      }
                    </Typography>
                    <TextFeildView
                      label={"First Name"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.firstName
                      }
                    />
                    <TextFeildView
                      label={"Last Name"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.lastName
                      }
                    />
                    <TextFeildView
                      label={"Email Id"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.email
                      }
                    />
                    <TextFeildView
                      label={"Mobile No"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.mobileNo
                      }
                    />
                    <TextFeildView
                      label={"GST No"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.offlineStoreDetails?.gstNo
                      }
                    />
                    <TextFeildView
                      label={"Due Date"}
                      value={formatDate(
                        offlinePaymentViewModal?.offlinePaymentData?.dueDate
                      )}
                    />
                    <TextFeildView
                      label={"Over Due Days"}
                      value={`${OverDueDays(
                        offlinePaymentViewModal?.offlinePaymentData?.dueDate
                      )} Days`}
                    />
                  </Box>
                </Grid2>
                <Grid2 xs={3}>
                  <Box mt={3}>
                    <TextFeildView
                      label={"Sales Order No"}
                      value={
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.salesOrderNo
                      }
                    />
                    <TextFeildView
                      label={"Sales Order Date"}
                      value={formatDate(
                        offlinePaymentViewModal?.offlinePaymentData
                          ?.salesOrderDate
                      )}
                    />
                    <TextFeildView
                      label={"Sales Order Amount"}
                      value={`₹ ${offlinePaymentViewModal?.offlinePaymentData?.totalSalesAmount}`}
                    />
                    <TextFeildView
                      label={"Received Amount"}
                      value={`₹ ${offlinePaymentViewModal?.offlinePaymentData?.recievedAmount}`}
                    />
                    <TextFeildView
                      label={"Balance Amount"}
                      value={`₹ ${offlinePaymentViewModal?.offlinePaymentData?.balanceAmount}`}
                    />
                  </Box>
                </Grid2>
              </Grid2>
              <ViewOfflinePayment
                offlinePaymentData={offlinePaymentViewModal?.offlinePaymentData}
              />
            </Box>
          </FullScreenModal>
        )}
      </Div>
    </Box>
  );
}
