import AllApis from "app/Apis/apis";
import {
  ALL_ROLE_FAIL,
  ALL_ROLE_REQUEST,
  ALL_ROLE_SUCCESS,
  ROLE_LIST_FAIL,
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
} from "app/utils/constants/rolesConstant";
import axios from "axios";
import { Axios } from "index";

export const getAllRoles =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["current_data.role_name"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search:search_value.trim(),
        page:page,
        sort:sort,
        sortBy:sortBy
      })

      dispatch({ type: ALL_ROLE_REQUEST });

      const data = await Axios.post(
        `${AllApis.roles.list}?${urlParams.toString()}`,
        body
      );
      
      dispatch({
        type: ALL_ROLE_SUCCESS,
        payload: {
          data: data?.data?.data?.roles,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_ROLE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

  export const getRolesList = ()=> async (dispatch)=>{
    try {
      dispatch({ type: ROLE_LIST_REQUEST });
      const data = await Axios.get(
        `${AllApis.roles.rolelist}`
      );
      console.log(data)
      dispatch({
        type: ROLE_LIST_SUCCESS,
        payload: {
          data: data?.data?.data,
          totalPage: null,
        },
      });
    } catch (error) {
      dispatch({
        type: ROLE_LIST_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  }