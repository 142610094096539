import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS } from "app/utils/constants/InventoryConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  products: [],
  TotalPage: null,
};

export const InventoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.data,
        TotalPage: action.payload.totalPages,
      };

    case PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        products: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
