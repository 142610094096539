import { ALL_CATEGORY_FAIL, ALL_CATEGORY_REQUEST, ALL_CATEGORY_SUCCESS } from "app/utils/constants/categoryConstant";

  
  const INIT_STATE = {
    loading: false,
    error: null,
    allCategory: [],
    imagePath:null,
    TotalPage: null,
  };
  
  export const categoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ALL_CATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ALL_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          allCategory: action.payload.data,
          TotalPage: action.payload.totalPage,
          imagePath:action.payload.imagePath,
        };
  
      case ALL_CATEGORY_FAIL:
        return {
          ...state,
          loading: false,
          allCategory: [],
          imagePath:null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  