import AllApis from "app/Apis/apis";
import {
  ALL_CATEGORY_FAIL,
  ALL_CATEGORY_REQUEST,
  ALL_CATEGORY_SUCCESS,
} from "app/utils/constants/categoryConstant";
import { Axios } from "index";

export const getAllCategory =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "current_data.category_name",
            "current_data.created_by.name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      
      const urlParams = new URLSearchParams({
        search:search_value.trim(),
        page:page,
        sort:sort,
        sortBy:sortBy
      })

      dispatch({ type: ALL_CATEGORY_REQUEST });
      const data = await Axios.post(
        `${AllApis.category.list}?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: ALL_CATEGORY_SUCCESS,
        payload: {
          data: data?.data?.data?.category,
          totalPage: data?.data?.data?.totalPages,
          imagePath: data?.data?.data?.imagePath,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_CATEGORY_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
