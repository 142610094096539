import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { formatDate } from "app/pages/Component/Function/formatDate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewDispatchModel({
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
  deliveryStatus,
}) {
  const handleClose = () => {
    setOpen(false);
    setSelectedRow("");
  };

  const data = selectedRow;

  const sameState =
    data?.ssk_details?.address?.state ==
    data?.customer_details?.bill_to?.address.state;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ ml: 2, mt: 1.5, flex: 1, color: "white" }}
            variant="h4"
            component="div"
          >
            Dispatch Details
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ p: 5, width: "100%" }}>
        <Typography
          variant="h2"
          sx={{ fontWeight: 600, textTransform: "capitalize" }}
        >
          {data?.ssk_details?.company_name}
        </Typography>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.ssk_details?.first_name} ${data?.ssk_details?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.ssk_details?.primary_mobile_no} / ${
                data?.ssk_details?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.ssk_details?.primary_email_id}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.ssk_details?.secondary_email_id || "NA"}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GSTNo.</span> :{" "}
              {data?.ssk_details?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.ssk_details?.address?.address}, 
                            ${data?.ssk_details?.address?.area},
                            ${data?.ssk_details?.address?.location},
                            ${data?.ssk_details?.address?.district},
                            ${data?.ssk_details?.address?.taluka},
                            ${data?.ssk_details?.address?.city},
                            ${data?.ssk_details?.address?.state},
                            ${data?.ssk_details?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Dispatch No.</span> :{" "}
              {data?.dispatch_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                {deliveryStatus == "dispatched" || deliveryStatus == undefined
                  ? "Dispatch Generated Date"
                  : "Dispatch Date"}
              </span>{" "}
              :{" "}
              {deliveryStatus == "dispatched" || deliveryStatus == undefined
                ? formatDate(data?.tracking_date?.dispatch_generated_date)
                : formatDate(
                    data?.tracking_date?.out_for_delivery?.dispatch_date
                  )}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Sales Order No.</span> :{" "}
              {data?.sales_order_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Sales Order Date.</span> :{" "}
              {formatDate(data?.tracking_date?.sales_order_date)}
            </Typography>
            {deliveryStatus != "dispatched" && deliveryStatus != undefined && (
              <Typography
                sx={{ textTransform: "capitalize", fontSize: "15px" }}
              >
                <span style={{ fontWeight: 700 }}>Est. Delivery Date</span> :{" "}
                {formatDate(
                  data?.tracking_date?.out_for_delivery?.estimate_delivery_date
                )}
              </Typography>
            )}

            {deliveryStatus == "delivered" && deliveryStatus != undefined && (
              <Typography
                sx={{ textTransform: "capitalize", fontSize: "15px" }}
              >
                <span style={{ fontWeight: 700 }}>Delivered Date</span> :{" "}
                {formatDate(data?.tracking_date.delivered)}
              </Typography>
            )}
          </Div>
        </Div>

        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 5,
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Bill To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.customer_details?.bill_to?.branch_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.customer_details?.bill_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.customer_details?.bill_to?.first_name} ${data?.customer_details?.bill_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.customer_details?.bill_to?.primary_mobile_no} / ${
                data?.customer_details?.bill_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.customer_details?.bill_to?.primary_email_id}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.customer_details?.bill_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.customer_details?.bill_to?.address?.address}, 
                            ${data?.customer_details?.bill_to?.address?.area},
                            ${data?.customer_details?.bill_to?.address?.location},
                            ${data?.customer_details?.bill_to?.address?.district},
                            ${data?.customer_details?.bill_to?.address?.taluka},
                            ${data?.customer_details?.bill_to?.address?.city},
                            ${data?.customer_details?.bill_to?.address?.state},
                            ${data?.customer_details?.bill_to?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Ship To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.customer_details?.ship_to?.branch_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.customer_details?.ship_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.customer_details?.ship_to?.first_name} ${data?.customer_details?.ship_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.customer_details?.ship_to?.primary_mobile_no} / ${
                data?.customer_details?.ship_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.customer_details?.ship_to?.primary_email_id}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.customer_details?.ship_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.customer_details?.ship_to?.address?.address}, 
                            ${data?.customer_details?.ship_to?.address?.area},
                            ${data?.customer_details?.ship_to?.address?.location},
                            ${data?.customer_details?.ship_to?.address?.district},
                            ${data?.customer_details?.ship_to?.address?.taluka},
                            ${data?.customer_details?.ship_to?.address?.city},
                            ${data?.customer_details?.ship_to?.address?.state},
                            ${data?.customer_details?.ship_to?.address?.pincode}`}
            </Typography>
          </Div>
        </Div>

        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table style={{ border: "2px solid #7352C7" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  HSN Code
                </TableCell>
                {sameState ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      CGST
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "150px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      SGST
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    IGST
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Rate
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Weight
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  GST Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.Items?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.item_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.category}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.sku}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.hsn_code}
                  </TableCell>
                  {sameState ? (
                    <>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.cgst}%
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.sgst}%
                      </TableCell>
                    </>
                  ) : (
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {row?.gst?.igst}%
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.rate_per_unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.weight}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.item_amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹{" "}
                    {sameState
                      ? row.gst.cgst / 100 + row.gst.cgst / 100
                      : row.gst.igst / 100}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.total_amount}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ borderTop: "2px solid #7352C7" }}>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                  colSpan={sameState ? 8 : 7}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_weight}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_quantity} qty
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  ₹ {data?.total_item_amount?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {(data?.total_gst / 100)?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {data?.total_amount?.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {deliveryStatus != "dispatched" && deliveryStatus != undefined && (
          <Div sx={{ marginY: 2 }}>
            <Typography
              variant="h3"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Transport Details
            </Typography>

            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Transport Type</span> :{" "}
              {data?.transport_details?.transport_type?.split("_")?.join(" ")}
            </Typography>
            {Object.entries(
              data?.transport_details[
                `${data?.transport_details?.transport_type}`
              ]
            )?.map((ele) => {
              return (
                <Typography
                  sx={{ textTransform: "capitalize", fontSize: "15px" }}
                >
                  <span
                    style={{ fontWeight: 700, textTransform: "capitalize" }}
                  >
                    {ele[0]?.split("_")?.join(" ")}
                  </span>{" "}
                  : {ele[1]}
                </Typography>
              );
            })}
          </Div>
        )}
      </List>
    </Dialog>
  );
}
