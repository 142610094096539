import Div from '@jumbo/shared/Div';
import { Box, InputAdornment, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@mui/material'
import { formatDate } from 'app/pages/Component/Function/formatDate';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { getMETCompanyOrderCommissionHistory } from 'app/redux/actions/METCompanyOrder';



const TableHeadCell = {
  textAlign: "left",
  minWidth: "170px",
  verticalAlign: "middle",
  textTransform: "capitalize",
  color: "white",
  borderColor: "#7352C7",
}

const stickyTableHeadCellSx = {
  textTransform: "capitalize",
  color: "white",
  minWidth: "200px",
  verticalAlign: "middle",
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
}

const TableRowCell = { textAlign: "left", color: "black", borderColor: "black" }


const TableSortLabelCell = {
  color: "white !important",
  "&:hover": { color: "white !important" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important", // Set the color for the active state
  },
}

const tableHead = [
  {
    label: "Transaction Date",
    fieldName: "commission.salesOrderDate"
  },
  {
    label: "Company Name",
    fieldName: "commission.companyDetails.companyName"
  },
  {
    label: "First Name",
    fieldName: "commission.companyDetails.firstName"
  },
  {
    label: "Last Name",
    fieldName: "commission.companyDetails.lastName"
  },
  {
    label: "Email Id",
    fieldName: "commission.companyDetails.email"
  },
  {
    label: "Contact No",
    fieldName: "commission.companyDetails.mobileNo"
  },
  {
    label: "Sales Order Amt",
    fieldName: "commission.salesOrderAmount"
  },
  {
    label: "Commission %",
    fieldName: "commission.commissionPercentage"
  },
  {
    label: "Commission Amt",
    fieldName: "commission.commissionAmount",
    // sx: {
    //     textTransform: "capitalize",
    //     color: "white",
    //     minWidth: "200px",
    //     verticalAlign: "middle",
    //     position: "sticky",
    //     right: 200,
    //     zIndex: 1,
    //     bgcolor: "#7352C7",
    //     borderColor: "#7352C7",
    // }
  },
]

const ListingCompanyOrder = () => {
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const { metId, companyId } = useParams();
  const metCompnayOrderCommission = useSelector((state) => state?.metCompnayOrderCommissionReducer);
  const dispatch = useDispatch();


  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleSearch = (searchTerm) => {
    setPage(1);
    dispatch(getMETCompanyOrderCommissionHistory({
      sortBy: sortBy,
      search_value: searchTerm?.trim(),
      sort: sort,
      page: page,
      metId: metId,
      companyId: companyId,
    }));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);


  useEffect(() => {
    if (metId) {
      dispatch(getMETCompanyOrderCommissionHistory({
        sortBy: sortBy,
        sort: sort,
        page: page,
        metId: metId,
        companyId: companyId,
        search_value: searchTerm?.trim(),
      }))
    }
  }, [metId,companyId ,sort, sortBy, page])

  return (
    <Box sx={{ my: 4 }}>
      <Box sx={{ my: 4 }}>
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === "") {
              dispatch(getMETCompanyOrderCommissionHistory({
                metId: metId,
                companyId: companyId,
              }));
            }
          }}
          sx={{ width: 400 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Box>
      <TableContainer component={Paper} sx={{ border: "1px solid #7352C7" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              {
                tableHead.map((e, i) => {
                  return <TableCell key={i} sx={{ ...TableHeadCell }}>
                    <TableSortLabel
                      onClick={() => handleSort(e.fieldName)}
                      direction={sort}
                      sx={{ ...TableSortLabelCell }}
                    >
                      {e.label}
                    </TableSortLabel>
                  </TableCell>
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              metCompnayOrderCommission?.companyOrderHistory?.length <= 0 && <TableRow key={metCompnayOrderCommission?._id}>
                <TableCell sx={{ ...TableRowCell, textAlign: "center", border: 0 }} colSpan={9}>
                  {"No Transaction Found"}
                </TableCell>
              </TableRow>
            }
            {
              metCompnayOrderCommission?.companyOrderHistory?.map((metCompnayOrderCommission) => {
                return (
                  <TableRow key={metCompnayOrderCommission?._id}>
                    <TableCell sx={{ ...TableRowCell }}>
                      {formatDate(metCompnayOrderCommission?.commission?.salesOrderDate)}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.companyDetails?.companyName || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.companyDetails?.firstName || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.companyDetails?.lastName || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.companyDetails?.email || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.companyDetails?.mobileNo || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.salesOrderAmount || "-"}
                    </TableCell>
                    <TableCell sx={{ ...TableRowCell }}>
                      {metCompnayOrderCommission?.commission?.commissionPercentage || "-"}
                    </TableCell>
                    <TableCell sx={{
                      ...TableRowCell,
                      // position: "sticky",
                      // right: 200,
                      // zIndex: 1,
                      // bgcolor: "white",
                      // boxShadow: "inset 1px 0px 0px 0px black",
                      // borderColor: "black"
                    }}>
                      <Box>
                        {metCompnayOrderCommission?.commission?.commissionAmount ? `₹ ${metCompnayOrderCommission?.commission?.commissionAmount}` : "-"}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })
            }
            <TableRow>
              <TableCell colSpan={7} sx={{
                ...TableRowCell,
                // width: "100%",
                borderColor: "#7352C7",
                bgcolor: "#7352C7",
              }}></TableCell>
              <TableCell sx={{
                ...TableRowCell,
                // position: "sticky",
                // right: 400,
                // zIndex: 1,
                borderColor: "#7352C7",
                bgcolor: "#7352C7",
                color: "white",
                fontSize: "17px"
              }}>Total</TableCell>
              <TableCell sx={{
                ...TableRowCell,
                // position: "sticky",
                // right: 200,
                // zIndex: 1,
                borderColor: "#7352C7",
                bgcolor: "#7352C7",
                color: "white",
                fontSize: "17px"
              }}>₹ {Number(metCompnayOrderCommission?.totals?.[0]?.commission || 0).toFixed(2) || 0}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Pagination
          count={metCompnayOrderCommission?.totalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: 1,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </Box>
  )
}

export default ListingCompanyOrder