import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import { getAllFaq } from "app/redux/actions/FAQs/index";
import Heading from "app/pages/Component/Heading";
import Swal from "sweetalert2";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Loader from "app/components/Loader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddFaqModal from "../AddFaq";

export default function ListFAQ() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { allFaq, TotalPage } = useSelector((state) => state.faqReducer);
  const { userData, loading } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllFaq(value, sort, sortBy));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        setSelectedRow({ action: "edit", data: menuItem.data });
        setLogModalOpen(true);
        break;
      case "delete":
        setSelectedRow({ action: "delete", data: menuItem.data });
        setLogModalOpen(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getAllFaq(searchTerm, sort, sortBy, page));
  }, [sort, page]);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={"FAQs"} />
      {loading && <Loader />}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setSort("desc");
              setSortBy("created_at");
              dispatch(getAllFaq("", "desc", "created_at", 1));
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          {Permission?.["faq"]?.["add"] && (
            <Button
              variant="contained"
              size="small"
              sx={{
                mr: 2,
                p: 1,
                pl: 4,
                pr: 4,
              }}
              onClick={() => {
                setLogModalOpen(true);
                setSelectedRow({ action: "add" });
              }}
            >
              Add FAQs
            </Button>
          )}
        </Div>
      </Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "400px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("question")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Questions
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("answer")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Answers
                </TableSortLabel>
              </TableCell>
              {Permission?.["faq"]?.["edit"] && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allFaq?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.question}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.answer}
                </TableCell>
                {Permission?.["faq"]?.["edit"] && (
                  <TableCell sx={{ textAlign: "left", mr: 9 }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditIcon />,
                          title: "Edit Faq",
                          action: "edit",
                          data: row,
                        },
                        {
                          icon: <DeleteForeverIcon />,
                          title: "Delete Faq",
                          action: "delete",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <AddFaqModal
          open={isLogModalOpen}
          selectedRow={selectedRow}
          onClose={() => setLogModalOpen(false)}
        />
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: 1,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </Div>
  );
}
