import AllApis from "app/Apis/apis"
import { AREA, DISTRICT, LOADING, LOCATION, TALUKA } from "app/utils/constants/AddressDropdownConstant"
import { Axios } from "index"

const addressConstant = {
    district:DISTRICT,
    location:LOCATION,
    area:AREA,
    taluka:TALUKA
}
export const addressDropdownAction = function(type){
    return async (dispatch)=>{
        try {
            dispatch({type:LOADING});

            const {data} = await Axios.get(AllApis.addressDropdown.get(type));
            
            dispatch({type:addressConstant[type],payload:data.data?.[type]})
            
        } catch (error) {
            dispatch({type:addressConstant[type],payload:error})
        }
    }
}