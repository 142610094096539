import Page from "@jumbo/shared/Page";
import Suppliers from "app/pages/Masters/Suppliers";
import AddEditSuppliers from "app/pages/Masters/Suppliers/AddEditSuppliers";
import ListCompanyName from "app/pages/Masters/Suppliers/ListCompnayName";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/master/sskCompany";

// export const sskCompanyRoute = [
//   {
//     middleware: [
//       {
//         element: AuthenticateAndRolesMiddleware,
//         fallbackPath: "/dashboard",
//         module: modules
//       },
//     ],
//     routes: [
//       {
//         path: `${routesName}`,
//         element: <Page companyType={"sskCompany"} label={"SSK Company"} component={Suppliers} />
//       },
//       {
//         path: `${routesName}/list/Company`,
//         element: <Page companyType={"sskCompany"} label={"SSK Company"} component={ListCompanyName} />
//       },
//       {
//         path: `${routesName}/add`,
//         element: <Page companyType={"sskCompany"} label={"SSK Company"} component={AddEditSuppliers} />,
//       },
//       {
//         path: `${routesName}/edit/:id`,
//         element: <Page companyType={"sskCompany"} label={"SSK Company"} component={AddEditSuppliers} />,
//       },
//       {
//         path: `${routesName}/add-branch`,
//         element: <Page companyType={"sskCompany"} label={"SSK Company"} component={AddEditSuppliers} />,
//       },
//     ],
//   },]

export const sskCompanyRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "sskcompany",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: (
              <Page
                companyType={"sskCompany"}
                label={"SSK Company"}
                component={Suppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "sskcompany",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/list/Company`,
            element: (
              <Page
                companyType={"sskCompany"}
                label={"SSK Company"}
                component={ListCompanyName}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "sskcompany",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: (
              <Page
                companyType={"sskCompany"}
                label={"SSK Company"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "sskcompany",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit/:id`,
            element: (
              <Page
                companyType={"sskCompany"}
                label={"SSK Company"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "sskcompany",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add-branch`,
            element: (
              <Page
                companyType={"sskCompany"}
                label={"SSK Company"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
    ],
  },
];
