import { Avatar, Box, Button, IconButton, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { formatDate } from 'app/pages/Component/Function/formatDate'
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react'

const ApprovalListingUi = () => {
    return (
        <Box sx={{
            backgroundColor: "rgb(255, 255, 255)",
            minHeight: "100px",
            maxHeight: "100px",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
            mb: "8px",
            // pr:"20px"
          }}
          >
            <Grid2 container sx={{ minHeight: "100px", maxHeight: "100px" }} justifyContent={"center"} alignItems={"center"}>
              <Grid2 item xs={1} display={"flex"} justifyContent={"center"}>
                <Box>
                  <Avatar
                    alt="Abhishek Sharp"
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: 56, height: 56 }}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={2.5}>
                <Typography sx={{ fontSize: "20px",color:"#7352C7", mb: 0.5 }}>
                  Abhishek vishwakarma
                </Typography>
                <Typography sx={{color:"black"}}>
                  Abhi123456
                </Typography>
              </Grid2>
              <Grid2 xs={1.5}>
                <Box>
                  <Typography sx={{ fontSize: "16px", mb: 0.5 }}>
                    Sales Person
                  </Typography>
                  <Typography sx={{color:"black",}}>
                    Role
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 xs={3}>
                <Box>
                  <Typography sx={{ fontSize: "17px", mb: 0.5 }}>
                    Name
                  </Typography>
                  <Typography>
                    details
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 xs={1}>
                <Box>
                  <Typography sx={{ fontSize: "16px", mb: 0.5 }}>
                    {formatDate(Date.now())}
                  </Typography>
                  <Typography sx={{color:"black"}}>
                    {formatDate(Date.now()) === formatDate(new Date(1210596282755)) ? "Created" : "Updated"} At
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 xs={1.5}>
                <Box sx={{ textAlign: "center", }}>
                  <Button variant="contained" >View Details</Button>
                </Box>
              </Grid2>
              <Grid2 xs={1.5} sx={{ minHeight: "100px", maxHeight: "100px", alignSelf: "center", }}>
                {/* <Box sx={{minHeight: "100px",maxHeight: "100px",alignSelf:"center" }}>
                </Box> */}
                <Grid2 container justifyContent={"center"} gap={2} alignItems={"center"} sx={{ minHeight: "100px", maxHeight: "100px" }}>
                  <Grid2 xs={6} >
                    <Box sx={{ textAlign: "center" }}>
                      {/* <Typography mb={1}>Approve</Typography> */}
                      <IconButton aria-label="Approve" size="medium" sx={{ border: "1px solid green" }}>
                        <DoneIcon fontSize="inherit" sx={{ color: "green" }} />
                      </IconButton>
                    </Box>
                  </Grid2>
                  <Grid2 xs={6} >
                    <Box sx={{ textAlign: "center" }}>
                      {/* <Typography mb={1}>Reject</Typography> */}
                      <IconButton aria-label="Reject" size="medium" sx={{ border: "1px solid red" }}>
                        <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                      </IconButton>
                    </Box>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Box>
    )
}

export default ApprovalListingUi