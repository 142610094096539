import Div from '@jumbo/shared/Div'
import { Autocomplete, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import Heading from 'app/components/Heading'
import { FastField, FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import AddressTypeDialog from '../../Suppliers/AutoAddAddress'
import AddNomineeForm from '../Nominee/AddNomineeForm'
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { Axios } from 'index'
import AllApis from 'app/Apis/apis'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleMarketExecutive } from 'app/redux/actions/Masters/MarketExecutive';
import { State, City, Country } from "country-state-city";


const marketExecutive = {
    company_details: {
        companyName: "",
    },
    contact_person_details: {
        first_name: "",
        last_name: "",
        blood_group: "",
        primary_email_id: "",
        secondary_email_id: "",
        primary_mobile_no: "",
        secondary_mobile_no: "",
        //   password: "",
        onboarding_date: new Date(Date.now()).toISOString().split('T')[0],
        role_assign: "",
    },
    kyc: {
        pan: {
            pan_no: "",
            pan_image: "",
        },
        gst: {
            gst_no: "",
            gst_image: "",
        },
        aadhar: {
            aadhar_no: "",
            aadhar_image: "",
        },
        bank_details: {
            bank_name: "",
            account_no: "",
            confirm_account_no: "",
            ifsc_code: "",
            passbook_image: ""
        }
    },
    address: {
        address: "",
        location: "",
        area: "",
        district: "",
        taluka: "",
        state: "",
        city: "",
        country: "",
        pincode: "",
    },
    isActive: true,
    insurance: {
        policy_no: "",
        policy_image: "",
        policy_company_name: "",
        policy_amount: "",
        renewal_date: new Date(Date.now()).toISOString().split('T')[0],
    },
}

const AddEditMET = () => {
    const [marketExecutiveData, setMarketExecutiveData] = useState({
        company_details: {
            companyName: "",
        },
        contact_person_details: {
            first_name: "",
            last_name: "",
            blood_group: "",
            primary_email_id: "",
            secondary_email_id: "",
            primary_mobile_no: "",
            secondary_mobile_no: "",
            //   password: "",
            onboarding_date: new Date(Date.now()).toISOString().split('T')[0],
            role_assign: "",
        },
        kyc: {
            pan: {
                pan_no: "",
                pan_image: "",
            },
            gst: {
                gst_no: "",
                gst_image: "",
            },
            aadhar: {
                aadhar_no: "",
                aadhar_image: "",
            },
            bank_details: {
                bank_name: "",
                account_no: "",
                confirm_account_no: "",
                ifsc_code: "",
                passbook_image: ""
            }
        },
        address: {
            address: "",
            location: "",
            area: "",
            district: "",
            taluka: "",
            state: "",
            city: "",
            country: "",
            pincode: "",
        },
        isActive: true,
        insurance: {
            policy_no: "",
            policy_image: "",
            policy_company_name: "",
            policy_amount: "",
            renewal_date: new Date(Date.now()).toISOString().split('T')[0],
        },
    })
    const { addressDropdown, marketExecutiveReducer: { singleMarketExecutive, loading } } = useSelector((state) => state);
    const [stateCityList, setStateCityList] = useState({
        stateList: State.getStatesOfCountry("IN"),
        cityList: City.getCitiesOfState("IN", "MH"),
        countryList: Country.getCountryByCode("IN"),
    });
    const dispatch = useDispatch();
    const { metId } = useParams();
    const navigate = useNavigate();


    const handleImagesTab = async (value) => {
        if (value && typeof value === "string") {
            const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
            window.open(imageURL.href, '_blank');
        } else {
            const images = URL.createObjectURL(value);
            window.open(images, "__blank")
        }
    }

    const handleUploadFile = async function (field, value) {
        console.log(field, value)
        try {
            const formData = new FormData();
            formData.append(field, value)
            const { data } = await Axios.patch(AllApis?.marketExective?.uploadFile(metId), formData)
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
            }
            dispatch(getSingleMarketExecutive(metId))
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    const handleSubmitAddEdit = async function (values, action) {
        const formData = new FormData();
        formData.append("METdata", JSON.stringify({
            ...values,
            kyc: {
                pan: {
                    pan_no: values?.kyc?.pan?.pan_no,
                },
                gst: {
                    gst_no: values?.kyc?.gst?.gst_no,
                },
                aadhar: {
                    aadhar_no: values?.kyc?.aadhar?.aadhar_no,
                },
                bank_details: {
                    bank_name: values?.kyc?.bank_details?.bank_name,
                    account_no: values?.kyc?.bank_details?.account_no,
                    confirm_account_no: values?.kyc?.bank_details?.confirm_account_no,
                    ifsc_code: values?.kyc?.bank_details?.ifsc_code,
                }
            },
            insurance: {
                policy_no: values?.insurance?.policy_no,
                policy_company_name: values?.insurance?.policy_company_name,
                policy_amount: values?.insurance?.policy_amount,
                renewal_date: values?.insurance?.renewal_date,
            },
        }))
        formData.append("policy_image", values?.insurance?.policy_image)
        formData.append("pan_image", values?.kyc?.pan?.pan_image)
        formData.append("gst_image", values?.kyc?.gst?.gst_image)
        formData.append("aadhar_image", values?.kyc?.aadhar?.aadhar_image)
        formData.append("passbook_image", values?.kyc?.bank_details?.passbook_image)
        try {
            const { data } = !metId ? await Axios.post(AllApis?.marketExective?.add, formData) : await Axios.patch(AllApis?.marketExective?.update(metId), values)
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                action.resetForm();
            }
            if (metId && data?.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                dispatch(getSingleMarketExecutive(metId))
                navigate(`/dashboards/master/marketExecutiveTeams`)
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    useEffect(() => {
        if (metId) {
            dispatch(getSingleMarketExecutive(metId))
        }
    }, [metId, dispatch])

    useEffect(() => {
        const metData = singleMarketExecutive?.data?.MarketExecutive?.current_data
        if (metId && metData) {
            setMarketExecutiveData({
                ...metData,
                contact_person_details: {
                    ...metData?.contact_person_details,
                    onboarding_date: new Date(metData?.contact_person_details?.onboarding_date)?.toISOString()?.split('T')[0]
                },
                insurance: {
                    ...metData.insurance,
                    renewal_date: new Date(metData?.insurance?.renewal_date)?.toISOString()?.split('T')[0]
                }
            })
        }
    }, [singleMarketExecutive, metId])

    return (
        <Formik
            // key={JSON.stringify(marketExecutiveData)}
            enableReinitialize={true}
            initialValues={{ ...marketExecutiveData }}
            validationSchema={yup.object().shape({
                company_details: yup.object().shape({
                    companyName: yup.string(),
                }),
                contact_person_details: yup.object().shape({
                    first_name: yup.string().required("First name is required"),
                    last_name: yup.string().required("Last name is required"),
                    blood_group: yup.string(),
                    primary_email_id: yup.string().email("Invalid email format").required("Primary email is required"),
                    secondary_email_id: yup.string().email("Invalid email format"),
                    primary_mobile_no: yup.string().matches(/^[0-9]{10}$/, "Invalid mobile number").required("Primary mobile number is required"),
                    secondary_mobile_no: yup.string().matches(/^[0-9]{10}$/, "Invalid mobile number"),
                    onboarding_date: yup.date().required("Onboarding date is required"),
                    role_assign: yup.string().required("Role assign is required"),
                }),
                kyc: yup.object().shape({
                    pan: yup.object().shape({
                        pan_no: yup.string()
                            .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number')
                            .required('PAN number is required'),
                        pan_image: yup.mixed(),
                    }),
                    gst: yup.object().when('company_details', {
                        is: (company_details) => company_details && company_details.companyName,
                        then: yup.object().shape({
                            gst_no: yup.string()
                                .matches(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([1-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}?$/, 'Invalid GST number')
                                .required('GST number is required'),
                            gst_image: yup.mixed(),
                        }),
                        otherwise: yup.object().shape({
                            gst_no: yup.string(),
                            gst_image: yup.mixed(),
                        }),
                    }),

                    aadhar: yup.object().shape({
                        aadhar_no: yup.string()
                            .matches(/^\d{4}\d{4}\d{4}$/, 'Invalid Aadhar number')
                            .required('Aadhar number is required'),
                        aadhar_image: yup.mixed(),
                    }),
                    bank_details: yup.object().shape({
                        bank_name: yup.string().required('Bank name is required'),
                        account_no: yup.string().required('Account number is required'),
                        confirm_account_no: yup.string().oneOf([yup.ref('account_no')], 'Account number does not match').required('confirm account number is required'),
                        ifsc_code: yup.string()
                            .matches(/^([A-Z]){4}([0-9]){7}$/, 'Invalid IFSC code')
                            .required('IFSC code is required'),
                        passbook_image: yup.mixed(),
                    }),
                }),
                address: yup.object().shape({
                    address: yup.string().required("Address is required"),
                    location: yup.string().required("Address is required"),
                    area: yup.string().required("Address is required"),
                    district: yup.string().required("District is required"),
                    taluka: yup.string().required("Taluka is required"),
                    state: yup.string().required("State is required"),
                    city: yup.string().required("City is required"),
                    // country: yup.string().required("Country is required"),
                    pincode: yup.string().matches(/^[0-9]{6}$/, "Invalid pincode").required("Pincode is required"),
                }),
                isActive: yup.boolean().required(),
                insurance: yup.object().shape({
                    policy_no: yup.string().required("Policy number is required"),
                    policy_image: yup.mixed(),
                    policy_company_name: yup.string().required("Policy company name is required"),
                    policy_amount: yup.string().required("Policy amount is required"),
                    renewal_date: yup.date().required("Renewal date is required"),
                }),
            })}
            onSubmit={handleSubmitAddEdit}
        >
            {({ values, handleBlur, handleChange, errors, touched, initialValues, setFieldValue, isSubmitting }) => {
                return (
                    <>
                        <Div sx={{ mt: -4 }}>
                            <Heading Name={`${!metId ? "Add" : "Edit"} Market Executive`} />
                            <Form>
                                <Div>
                                    <Box id="companyDetails">
                                        <Typography variant='h3' my={3}>Company details</Typography>
                                        <Grid2 container>
                                            <Grid2 xs={4}>
                                                <FastField name='company_details.companyName'>
                                                    {({ field, meta }) => {
                                                        return <TextField
                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                            {...field}
                                                            size='small'
                                                            label="Company Name"
                                                            error={meta?.error && meta?.touched}
                                                            helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                        />
                                                    }}
                                                </FastField>
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    <Box id="personalInfo">
                                        <Typography variant='h3' my={3}>Personal Info</Typography>
                                        <Grid2 container spacing={3}>
                                            {
                                                Object.keys({ ...marketExecutive?.contact_person_details }).map((personDetails, index) => {
                                                    return (
                                                        <Grid2 xs={4} key={index}>
                                                            <Box>
                                                                <FastField name={`contact_person_details.${personDetails}`}>
                                                                    {({ field, form, meta }) => {
                                                                        return (
                                                                            <TextField
                                                                                required={!personDetails.toLowerCase().startsWith("secondary")}
                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                size='small'
                                                                                type={personDetails.toLowerCase().endsWith("date") ? "date" : "text"}
                                                                                {...field}
                                                                                error={meta?.error && meta?.touched}
                                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                                label={personDetails.split("_").join(" ")}
                                                                            />
                                                                        )
                                                                    }}
                                                                </FastField>
                                                            </Box>
                                                        </Grid2>
                                                    )
                                                })
                                            }
                                        </Grid2>
                                    </Box>
                                    <Box id="insurance">
                                        <Typography variant='h3' my={3}>Insurance</Typography>
                                        <Grid2 container spacing={3}>
                                            {
                                                Object.keys({ ...marketExecutive.insurance }).map((insurance, index) => {
                                                    return (
                                                        <Grid2 xs={4} key={index}>
                                                            {
                                                                insurance.toLowerCase().endsWith("image") ?
                                                                    <>
                                                                        <Div sx={{ display: "flex" }}>
                                                                            <FastField name={`insurance.${insurance}`}>
                                                                                {({ field, meta }) => {
                                                                                    const { value, ...rest } = field;
                                                                                    return <TextField
                                                                                        {...rest}
                                                                                        type='file'
                                                                                        InputLabelProps={{
                                                                                            shrink: true
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            const { name, files } = e.target
                                                                                            setFieldValue(name, files[0])
                                                                                        }}
                                                                                        sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                        size='small'
                                                                                        label={insurance.split("_").join(" ")}
                                                                                        error={meta?.error && meta?.touched}
                                                                                        helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                                    />
                                                                                }}
                                                                            </FastField>
                                                                            {values?.insurance?.[insurance] && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => handleImagesTab(values?.insurance?.[insurance])}>
                                                                                <PermMediaIcon sx={{ height: "100%" }} />
                                                                            </Button>}
                                                                            {metId && <Button variant='contained' size='small' onClick={() => handleUploadFile(insurance, values?.insurance?.[insurance])}>
                                                                                upload
                                                                            </Button>}
                                                                        </Div>
                                                                    </> :
                                                                    <FastField name={`insurance.${insurance}`}>
                                                                        {({ field, meta }) => {
                                                                            return <TextField
                                                                                required
                                                                                {...field}
                                                                                type={insurance.toLowerCase().endsWith("date") ? "date" : "text"}
                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                size='small'
                                                                                label={insurance.split("_").join(" ")}
                                                                                error={meta?.error && meta?.touched}
                                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                            />
                                                                        }}
                                                                    </FastField>
                                                            }
                                                        </Grid2>
                                                    )
                                                })
                                            }
                                        </Grid2>
                                    </Box>
                                    <Box id="kycDetails">
                                        <Typography variant='h3' my={3}>KYC Detail</Typography>
                                        {
                                            Object.entries({ ...marketExecutive.kyc }).map(([kycField, kycValues], index) => {
                                                return (
                                                    <Box key={index}>
                                                        <Typography variant='h6' sx={{ textTransform: "capitalize" }} my={2}>{kycField.split("_").join(" ")}</Typography>
                                                        <Grid2 container spacing={3}>
                                                            {
                                                                Object.keys({ ...kycValues }).map((kyc, index) => {
                                                                    return (
                                                                        <Grid2 xs={kycField === "bank_details" ? 4 : 6} key={index}>
                                                                            {
                                                                                kyc.toLowerCase().endsWith("image") ?
                                                                                    <>
                                                                                        <Div sx={{ display: "flex" }}>
                                                                                            <FastField name={`kyc.${kycField}.${kyc}`}>
                                                                                                {({ field, meta }) => {
                                                                                                    const { value, ...rest } = field;
                                                                                                    return <TextField
                                                                                                        {...rest}
                                                                                                        type='file'
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                        onChange={(e) => {
                                                                                                            const { name, files } = e.target
                                                                                                            setFieldValue(name, files[0])
                                                                                                        }}
                                                                                                        sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                                        size='small'
                                                                                                        label={kyc.split("_").join(" ")}
                                                                                                        error={meta?.error && meta?.touched}
                                                                                                        helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                                                    />
                                                                                                }}
                                                                                            </FastField>
                                                                                            {values?.kyc?.[kycField]?.[kyc] && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => handleImagesTab(values?.kyc?.[kycField]?.[kyc])}>
                                                                                                <PermMediaIcon sx={{ height: "100%" }} />
                                                                                            </Button>}
                                                                                            {metId && <Button variant='contained' size='small' onClick={() => handleUploadFile(kyc, values?.kyc?.[kycField]?.[kyc])}>
                                                                                                upload
                                                                                            </Button>}
                                                                                        </Div>
                                                                                    </> :
                                                                                    <FastField name={`kyc.${kycField}.${kyc}`} key={values?.company_details?.companyName}>
                                                                                        {({ field, meta }) => {
                                                                                            return <TextField
                                                                                                required={
                                                                                                    !values?.company_details?.companyName && kyc === "gst_no" ? false : true
                                                                                                }
                                                                                                {...field}
                                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                                size='small'
                                                                                                label={kyc.split("_").join(" ")}
                                                                                                error={meta?.error && meta?.touched}
                                                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                                            />
                                                                                        }}
                                                                                    </FastField>
                                                                            }
                                                                        </Grid2>
                                                                    )
                                                                })
                                                            }
                                                        </Grid2>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                    <Box id="Address">
                                        <Typography variant='h3' my={3}>Address</Typography>
                                        <Grid2 container spacing={3}>
                                            <Grid2 xs={4}>
                                                <FastField name={`address.address`}>
                                                    {({ field, meta }) => {
                                                        return <TextField
                                                            required
                                                            {...field}
                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                            size='small'
                                                            label={"address"}
                                                            error={meta?.error && meta?.touched}
                                                            helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                        />
                                                    }}
                                                </FastField>
                                            </Grid2>
                                            {
                                                ["location", "district", "area", "taluka"].map((e, i) => {
                                                    return (
                                                        <Grid2 xs={4} key={i}>
                                                            <FastField name={`address.${e}`} key={JSON.stringify(addressDropdown?.[e])}>
                                                                {({ field, meta }) => {
                                                                    return (
                                                                        <AddressTypeDialog
                                                                            type={e}
                                                                            {...field}
                                                                            setFieldValue={setFieldValue}
                                                                            name={`address.${e}`}
                                                                            list={addressDropdown?.[e] || []}
                                                                            label={e}
                                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                                            onBlur={field.onBlur}
                                                                            error={meta?.error && meta?.touched}
                                                                            helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                        />
                                                                    )
                                                                }}
                                                            </FastField>
                                                        </Grid2>
                                                    )
                                                })
                                            }
                                            {/* {
                                                ["state", "city"].map((e, i) => {
                                                    return (
                                                        <Grid2 xs={4} key={i}>
                                                            <FastField name={`address.${e}`}>
                                                                {({ field, meta }) => {
                                                                    return <Autocomplete
                                                                        sx={{ width: "100%", marginY: "10px", textTransform: "capitalize" }}
                                                                        size="small"
                                                                        id="company-autocomplete"
                                                                        options={stateCityList?.stateList || []}
                                                                        value={values?.state}
                                                                        getOptionLabel={(option) => option || ""}
                                                                        isOptionEqualToValue={
                                                                            (option, value) => option?.name === value
                                                                        }
                                                                        onChange={(e, newValue) => {
                                                                            setFieldValue("state", newValue?.name)
                                                                            if (newValue != null) {
                                                                                const cityNames = City.getCitiesOfState(
                                                                                    "IN",
                                                                                    newValue.isoCode
                                                                                );
                                                                                setStateCityList({ ...stateCityList, cityList: cityNames });
                                                                            } else {
                                                                                setStateCityList({
                                                                                    ...stateCityList,
                                                                                    cityList: City.getCitiesOfState("IN", "MH"),
                                                                                });
                                                                            }
                                                                        }}
                                                                        renderOption={(props, option) => (
                                                                            <Box
                                                                                component="li"
                                                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                                {...props}
                                                                            >
                                                                                {option?.name}
                                                                            </Box>
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} variant="outlined" label="State"
                                                                                required
                                                                                error={errors?.state && touched?.state}
                                                                                helperText={errors?.state && touched?.state ? errors?.state : ""}
                                                                            />
                                                                        )}
                                                                    />
                                                                }}
                                                            </FastField>
                                                        </Grid2>
                                                    )
                                                })
                                            } */}
                                            <Grid2 xs={4}>
                                                <FastField name={`address.state`} key={JSON.stringify(stateCityList?.stateList)}>
                                                    {({ field, meta }) => {
                                                        return <Autocomplete
                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                            size="small"
                                                            id="company-autocomplete"
                                                            options={stateCityList?.stateList || []}
                                                            {...field}
                                                            name="address.state"
                                                            value={field.value || ""}
                                                            freeSolo
                                                            getOptionLabel={(option = "") => {
                                                                if (typeof option === "object") {
                                                                    return option.name
                                                                }
                                                                return option
                                                            }}
                                                            isOptionEqualToValue={
                                                                (option, value) => option?.name === value
                                                            }
                                                            onBlur={field?.onBlur}
                                                            onChange={(e, newValue) => {
                                                                setFieldValue("address.state", newValue?.name)
                                                                if (newValue != null) {
                                                                    const cityNames = City.getCitiesOfState(
                                                                        "IN",
                                                                        newValue.isoCode
                                                                    );
                                                                    setStateCityList({ ...stateCityList, cityList: cityNames });
                                                                } else {
                                                                    setStateCityList({
                                                                        ...stateCityList,
                                                                        cityList: City.getCitiesOfState("IN", "MH"),
                                                                    });
                                                                }
                                                            }}
                                                            renderOption={(props, option) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                    {...props}
                                                                    key={option?.name}
                                                                >
                                                                    {option?.name}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="State"
                                                                    required
                                                                    name="address.state"
                                                                    error={meta?.error && meta?.touched}
                                                                    helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                />
                                                            )}
                                                        />
                                                    }}
                                                </FastField>
                                            </Grid2>
                                            <Grid2 xs={4}>
                                                <FastField name={`address.city`} key={JSON.stringify(stateCityList?.cityList)}>
                                                    {({ field, meta }) => {
                                                        return <Autocomplete
                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                            size="small"
                                                            id="company-autocomplete"
                                                            options={stateCityList?.cityList || []}
                                                            {...field}
                                                            name={`address.city`}
                                                            value={field.value || ""}
                                                            freeSolo
                                                            getOptionLabel={(option = "") => {
                                                                if (typeof option === "object") {
                                                                    return option.name
                                                                }
                                                                return option
                                                            }}
                                                            isOptionEqualToValue={
                                                                (option, value) => option?.name === value
                                                            }
                                                            onBlur={field?.onBlur}
                                                            onChange={(e, newValue) => {
                                                                setFieldValue("address.city", newValue?.name)
                                                            }}
                                                            renderOption={(props, option) => (
                                                                <Box
                                                                    component="li"
                                                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                    {...props}
                                                                    key={option?.name}
                                                                >
                                                                    {option?.name}
                                                                </Box>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="City"
                                                                    name={`address.city`}
                                                                    required
                                                                    error={meta?.error && meta?.touched}
                                                                    helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                />
                                                            )}
                                                        />
                                                    }}
                                                </FastField>
                                            </Grid2>
                                            <Grid2 xs={4}>
                                                <FastField name={`address.pincode`}>
                                                    {({ field, meta }) => {
                                                        return <TextField
                                                            required
                                                            {...field}
                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                            size='small'
                                                            label={"Pincode"}
                                                            error={meta?.error && meta?.touched}
                                                            helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                        />
                                                    }}
                                                </FastField>
                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                    {/* <Box id="nominee">
                                    <Typography variant='h1' my={5}>
                                        <Heading Name={"Nominee"} />
                                    </Typography>
                                    <FieldArray
                                        name='nominee'
                                        render={(arrayHelpers) => {
                                            return (
                                                <Box>
                                                    <Box id="NomineeForm">
                                                        <AddNomineeForm arrayHelper={arrayHelpers} />
                                                        {
                                                            values.nominee.map((e) => {
                                                                return (
                                                                    <TableContainer component={Paper} sx={{ my: "2%" }}>
                                                                        <Table  sx={{ minWidth: 650 }} size="medium" >
                                                                            <TableHead>
                                                                                <TableRow sx={{ bgcolor: "#7352C7" }}>
                                                                                    {

                                                                                    }
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody></TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }}
                                    />
                                </Box> */}
                                    <Div
                                        sx={{
                                            width: "92%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 3,
                                            mt: 4,
                                        }}
                                    >
                                        <Button
                                            sx={{ width: "100px" }}
                                            variant="outlined"
                                            onClick={() => navigate(`/dashboards/master/marketExecutiveTeams`)}
                                        >
                                            Back
                                        </Button>

                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            sx={{ width: "100px" }}
                                            loading={isSubmitting}
                                        >
                                            {!metId ? "Submit" : "Update"}
                                        </LoadingButton>
                                    </Div>
                                </Div>
                            </Form>
                        </Div>
                    </>
                )
            }}
        </Formik>
    )
}

export default AddEditMET