import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import deepDiff from "deep-diff";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Heading from "app/pages/Component/Heading";

export default function AddEditUnit() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New Unit" : "Edit Unit"
  );

  const initialValues = {
    unit_name: state?.current_data?.unit_name
      ? state?.current_data?.unit_name
      : "",
    unit_symbol: state?.current_data?.unit_symbol
      ? state?.current_data?.unit_symbol
      : "",
  };

  const validationSchema = yup.object({
    unit_name: yup.string().required("Unit Name is required"),
    unit_symbol: yup.string().required("Unit Symbol is required"),
  });

  //changed key values
  function convertToNestedObject(differences) {
    const result = {};

    differences.forEach((diff) => {
      const path = diff.path.join(".");
      const keys = path.split(".");

      let currentObj = result;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // Only set the value if it's not undefined
          if (diff.rhs !== undefined) {
            currentObj[key] = diff.rhs;
          }
        } else {
          currentObj[key] =
            currentObj[key] ||
            (Number.isNaN(parseInt(keys[index + 1])) ? {} : []); // If the next key is a number, initialize an array
          currentObj = currentObj[key];
        }
      });
    });

    return result;
  }

  function getUpdatedFields(oldObject, newObject) {
    const differences = deepDiff(oldObject, newObject);

    if (!differences) {
      // No differences found, objects are identical
      return null;
    }

    return convertToNestedObject(differences);
  }

  const onUserSave = async (values) => {
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        response = await Axios.post(AllApis.unit.add, values);
      } else {
        // Assuming you have a valid 'state' object
        const updatedFields = getUpdatedFields(state.current_data, values);
        console.log(updatedFields)
        response = await Axios.patch(
          AllApis.unit.edit(state._id),
          updatedFields
        );
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New Unit Created"
              : "Unit Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/master/unit");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(async () => {}, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 1,
                  }}
                >
                  <Div sx={{ width: "45%", mt: 2 }}>
                    <Typography variant="h5">Unit Name</Typography>
                    <TextField
                      name="unit_name"
                      size="small"
                      error={!!(touched.unit_name && errors.unit_name)}
                      value={values?.unit_name}
                      sx={{
                        width: "100%",
                        borderColor:
                          values.unit_name && errors.unit_name ? "red" : "",
                      }}
                      onChange={(e) =>
                        setFieldValue("unit_name", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="unit_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>

                  <Div sx={{ width: "45%", mt: 2 }}>
                    <Typography variant="h5">Unit Symbol</Typography>
                    <TextField
                      name="unit_symbol"
                      size="small"
                      error={!!(touched.unit_symbol && errors.unit_symbol)}
                      value={values?.unit_symbol}
                      sx={{
                        width: "100%",
                        borderColor:
                          values.unit_symbol && errors.unit_symbol ? "red" : "",
                      }}
                      onChange={(e) =>
                        setFieldValue("unit_symbol", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="unit_symbol"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>

                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={() => navigate("/dashboards/master/unit")}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
