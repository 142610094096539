import {ALL_SUPPLIER_COMPANY_FAIL, ALL_SUPPLIER_COMPANY_REQUEST, ALL_SUPPLIER_COMPANY_SUCCESS, ALL_SUPPLIER_FAIL, ALL_SUPPLIER_REQUEST, ALL_SUPPLIER_SUCCESS } from "app/utils/constants/supplierConstant";

const INIT_STATE = {
  branch: {
    loading: false,
    error: null,
    allBranch: [],
    TotalPage: null,
  },
  company: {
    loading: false,
    error: null,
    allCompany: [],
    TotalPage: null,
  }
};

export const supplierReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_SUPPLIER_REQUEST:
      return { ...state, branch: { ...state.branch, loading: true } };
    case ALL_SUPPLIER_SUCCESS:
      return {
        ...state,
        branch: {
          ...state.branch,
          loading: false,
          allBranch: action.payload.data,
          TotalPage: action.payload.totalPage,
        }
      };
    case ALL_SUPPLIER_FAIL:
      return {
        ...state, branch: {
          ...state.branch,
          loading: false,
          allBranch: [],
          error: action.payload
        }
      };
    case ALL_SUPPLIER_COMPANY_REQUEST:
      return {...state,company:{...state.company,loading:true}};
    case ALL_SUPPLIER_COMPANY_SUCCESS:
      return {...state,company:{
        ...state.company,
        loading:false,
        allCompany:action.payload.data,
        TotalPage:action.payload.totalPage
      }};
    case ALL_SUPPLIER_COMPANY_FAIL:
      return {...state,company:{
        ...state.company,
        loading:false,
        allCompany:[],
        error:action.payload
      }}
    default:
      return state;
  }
};



