import AllApis from "app/Apis/apis";
import {
  ALL_OFFLINESTORE_FAIL,
  ALL_OFFLINESTORE_REQUEST,
  ALL_OFFLINESTORE_SUCCESS,
  ALL_OFFLINESTORE_COMPANY_FAIL,
  ALL_OFFLINESTORE_COMPANY_REQUEST,
  ALL_OFFLINESTORE_COMPANY_SUCCESS,
} from "app/utils/constants/offlineStore";
import {
  ALL_RETAILER_FAIL,
  ALL_RETAILER_REQUEST,
  ALL_RETAILER_SUCCESS,
  ALL_RETAILER_COMPANY_FAIL,
  ALL_RETAILER_COMPANY_REQUEST,
  ALL_RETAILER_COMPANY_SUCCESS,
} from "app/utils/constants/retailerConstant";
import {
  ALL_SSKCOMPANY_FAIL,
  ALL_SSKCOMPANY_REQUEST,
  ALL_SSKCOMPANY_SUCCESS,
  ALL_SSKCOMPANY_COMPANY_FAIL,
  ALL_SSKCOMPANY_COMPANY_REQUEST,
  ALL_SSKCOMPANY_COMPANY_SUCCESS,
} from "app/utils/constants/sskCompnayConstant";
import {
  ALL_SUPPLIER_FAIL,
  ALL_SUPPLIER_REQUEST,
  ALL_SUPPLIER_SUCCESS,
  ALL_SUPPLIER_COMPANY_FAIL,
  ALL_SUPPLIER_COMPANY_REQUEST,
  ALL_SUPPLIER_COMPANY_SUCCESS,
} from "app/utils/constants/supplierConstant";

import { Axios } from "index";

const ActionType = {
  ALL_SUPPLIER_REQUEST,
  ALL_SUPPLIER_SUCCESS,
  ALL_SUPPLIER_FAIL,
  ALL_SUPPLIER_COMPANY_REQUEST,
  ALL_SUPPLIER_COMPANY_SUCCESS,
  ALL_SUPPLIER_COMPANY_FAIL,

  ALL_SSKCOMPANY_REQUEST,
  ALL_SSKCOMPANY_SUCCESS,
  ALL_SSKCOMPANY_FAIL,
  ALL_SSKCOMPANY_COMPANY_REQUEST,
  ALL_SSKCOMPANY_COMPANY_SUCCESS,
  ALL_SSKCOMPANY_COMPANY_FAIL,

  ALL_RETAILER_REQUEST,
  ALL_RETAILER_SUCCESS,
  ALL_RETAILER_FAIL,
  ALL_RETAILER_COMPANY_REQUEST,
  ALL_RETAILER_COMPANY_SUCCESS,
  ALL_RETAILER_COMPANY_FAIL,

  ALL_OFFLINESTORE_REQUEST,
  ALL_OFFLINESTORE_SUCCESS,
  ALL_OFFLINESTORE_FAIL,
  ALL_OFFLINESTORE_COMPANY_REQUEST,
  ALL_OFFLINESTORE_COMPANY_SUCCESS,
  ALL_OFFLINESTORE_COMPANY_FAIL,

}



export const getAllBranchList = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {}, companyType }) => async (dispatch) => {
  try {
    const payload = {
      filters: filters,
      searchFields: {
        string: [
          "current_data.branch_name",
          `current_data.${companyType}Id.current_data.company_name`,
          "current_data.kyc.gst.gst_no",
          "current_data.branch_address.district",
          "current_data.branch_address.taluka",
          "current_data.branch_address.state",
          "current_data.branch_address.city",
          "current_data.contact_person.primary_email",
          "current_data.contact_person.primary_mobile",
        ],
      },
    };
    if (!search_value) {
      search_value = "";
    }

    const urlParams = new URLSearchParams({
      search: search_value.trim(),
      page: page,
      sort: sort,
      sortBy: sortBy,
    });

    dispatch({ type: ActionType?.[`ALL_${companyType?.toUpperCase()}_REQUEST`] });
    const Branch = await Axios.post(
      `${AllApis?.[companyType].branch.list}?${urlParams.toString()}`, { ...payload }
    );
    dispatch({
      type: ActionType?.[`ALL_${companyType?.toUpperCase()}_SUCCESS`],
      payload: {
        data: Branch?.data?.data?.branch,
        totalPage: Branch?.data?.totalPages,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType?.[`ALL_${companyType?.toUpperCase()}_FAIL`],
      payload: error?.response?.data?.message,
    });
  }
};

export const getAllCompanyList = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {}, companyType }) => {
  return async (dispatch) => {
    try {
      const payload = {
        searchFields: {
          string: [
            "current_data.username",
            "current_data.company_name",
            "current_data.pan.pan_no",
          ]
        }
      }
      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ActionType[`ALL_${companyType.toUpperCase()}_COMPANY_REQUEST`] });

      const companyList = await Axios.post(`${AllApis?.[companyType].list}?${urlParams.toString()}`, { ...payload });

      dispatch({
        type: ActionType[`ALL_${companyType.toUpperCase()}_COMPANY_SUCCESS`], payload: {
          data: companyList?.data?.data?.[companyType],
          totalPage: companyList?.data?.totalPages
        }
      })

    } catch (error) {
      dispatch({ type: ActionType[`ALL_${companyType.toUpperCase()}_COMPANY_FAIL`], payload: error?.response?.data?.message })
    }
  }
}
