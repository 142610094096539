import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import Heading from 'app/components/Heading';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as yup from "yup"
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { updateCompanyList } from 'app/Apis/Masters/Suppliers';
import Swal from 'sweetalert2';
import { getAllCompanyList } from 'app/redux/actions/Masters/Suppliers';
import { useDispatch } from 'react-redux';
import AllApis from 'app/Apis/apis';
import { Axios } from 'index';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function EditCompanyModel({ open, setOpen, companyData, companyType }) {
    const dispatch = useDispatch();
    const companyNameDetails = useFormik({
        enableReinitialize: true,
        initialValues: { ...companyData, onboarding_date: new Date(companyData.onboarding_date || Date.now()).toISOString().split('T')[0] },
        validationSchema: yup.object({
            username: yup.string().when('company_type', {
                is: (val) => val === 'retailer' || val === "offlinestore",
                then: yup.string().min(2).required('Username is required for retailers'),
                otherwise: yup.string().min(2)
            }),
            company_name: yup.string().min(2).required("company name is required"),
            onboarding_date: yup.date(),
            pan: yup.object().shape({
                pan_no: yup.string()
                    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number')
                    .required('PAN number is required'),
                pan_image: yup.mixed(),
            }),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append("username", values.username)
            formData.append("company_name", values.company_name)
            formData.append("onboarding_date", values.onboarding_date)
            formData.append("pan_no", values.pan.pan_no)
            formData.append("pan_image", values.pan.pan_image)
            try {
                const { data } = await Axios.patch(AllApis?.[companyType].edit(values?._id), formData);
                if (data?.statusCode === 200) {
                    Swal.fire({
                        icon: "success",
                        title: data?.status,
                        text: data?.message,
                        timer: 1000,
                        showConfirmButton: false,
                    })
                    dispatch(getAllCompanyList({
                        companyType: companyType,
                    }))
                }
                setOpen(false)
            } catch (error) {
                Swal.fire({
                    icon: "success",
                    title: error?.response?.data?.message,
                    text: "",
                    timer: 1000,
                    showConfirmButton: false,
                })
            }

        }
    })

    const handleImagesTab = async (value) => {
        if (value && typeof value === "string") {
            const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
            window.open(imageURL.href, '_blank');
        } else {
            const images = URL.createObjectURL(value);
            window.open(images, "__blank")
        }
    }

    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography sx={{ my: 2 }}>
                            <Heading Name={"Edit Suppliers"} />
                        </Typography>
                        <Grid2 container spacing={3} sx={{ mt: 2 }}>
                            {(companyType === "retailer" || companyType === "offlinestore") && <Grid2 xs={6}>
                                <TextField name="username" value={companyNameDetails.values.username} label="User Name" size="small" sx={{ width: "100%" }} onChange={companyNameDetails.handleChange} onBlur={companyNameDetails.handleBlur} error={companyNameDetails.errors["username"] && companyNameDetails.touched["username"]} helperText={companyNameDetails.errors["username"] && companyNameDetails.touched["username"] ? companyNameDetails.errors["username"] : ""} />
                            </Grid2>}
                            <Grid2 xs={6}>
                                <TextField name="company_name" value={companyNameDetails.values.company_name} label="Company Name" size="small" sx={{ width: "100%" }} onChange={companyNameDetails.handleChange} onBlur={companyNameDetails.handleBlur} error={companyNameDetails.errors["company_name"] && companyNameDetails.touched["company_name"]} helperText={companyNameDetails.errors["company_name"] && companyNameDetails.touched["company_name"] ? companyNameDetails.errors["company_name"] : ""} />
                            </Grid2>
                            <Grid2 xs={6}>
                                <TextField name="onboarding_date" type="date" value={companyNameDetails.values.onboarding_date} label="OnBoarding Date" size="small" sx={{ width: "100%" }} onChange={companyNameDetails.handleChange} onBlur={companyNameDetails.handleBlur} error={companyNameDetails.errors["onboarding_date"] && companyNameDetails.touched["onboarding_date"]} helperText={companyNameDetails.errors["onboarding_date"] && companyNameDetails.touched["onboarding_date"] ? companyNameDetails.errors["onboarding_date"] : ""} />
                            </Grid2>
                            <Grid2 xs={6}>
                                <TextField
                                    required
                                    name={`pan.pan_no`}
                                    label="PAN No"
                                    size="small"
                                    sx={{ width: "100%" }}
                                    value={companyNameDetails.values?.pan?.pan_no}
                                    onChange={companyNameDetails.handleChange}
                                    onBlur={companyNameDetails.handleBlur}
                                    error={companyNameDetails.errors?.pan?.pan_no && companyNameDetails.touched?.pan?.pan_no}
                                    helperText={companyNameDetails.errors?.pan?.pan_no && companyNameDetails.touched?.pan?.pan_no ? companyNameDetails.errors?.pan?.pan_no : ""} />
                            </Grid2>
                            <Grid2 xs={6}>
                                <Div sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <TextField
                                        name={`pan.pan_image`}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        label="PAN Image"
                                        type='file'
                                        size="small"
                                        sx={{ width: "100%" }}
                                        onChange={(e) => {
                                            const { name, files } = e.target
                                            if (files && files.length > 0) {
                                                companyNameDetails.setFieldValue(name, files[0])
                                            }
                                        }}
                                        onBlur={companyNameDetails.handleBlur}
                                        error={companyNameDetails.errors?.pan?.pan_image && companyNameDetails.touched?.pan?.pan_image} helperText={companyNameDetails.errors?.pan?.pan_image && companyNameDetails.touched?.pan?.pan_image ? companyNameDetails.errors?.pan?.pan_image : ""} />
                                    {companyNameDetails.values?.pan?.pan_image && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => { handleImagesTab(companyNameDetails.values?.pan?.pan_image) }}>
                                        <PermMediaIcon sx={{ height: "100%" }} />
                                    </Button>}
                                </Div>
                            </Grid2>
                        </Grid2>
                        <Div
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                mt: 3,
                            }}
                        >

                            <Button
                                variant="outlined"
                                type="submit"
                                sx={{ width: "100px", mx: 3 }}
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>

                            <LoadingButton
                                variant="contained"
                                type="submit"
                                sx={{ width: "100px" }}
                                onClick={companyNameDetails.handleSubmit}
                            >
                                Update
                            </LoadingButton>
                        </Div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}