import AllApis from "app/Apis/apis";
import { ALL_PTD_FAIL, ALL_PTD_REQUEST, ALL_PTD_SUCCESS } from "app/utils/constants/paymentTermDaysConstant";
import { Axios } from "index";

export const getAllPtd =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "current_data.created_by.name",
          ],
          numbers:["current_data.payment_term_days"]
        },
      };
      if (!search_value) {
        search_value = "";
      }
      
      const urlParams = new URLSearchParams({
        search:search_value.trim(),
        page:page,
        sort:sort,
        sortBy:sortBy
      })

      dispatch({ type: ALL_PTD_REQUEST });
      const data = await Axios.post(
        `${AllApis.ptd.list}?${urlParams.toString()}`,
        body
      );
      console.log(data?.data?.data)
      dispatch({
        type: ALL_PTD_SUCCESS,
        payload: {
          data: data?.data?.data?.payment_term_days,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PTD_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
