import AllApis from "app/Apis/apis";
import {
  ALL_HSN_FAIL,
  ALL_HSN_REQUEST,
  ALL_HSN_SUCCESS,
} from "app/utils/constants/hsnConstant";
import { Axios } from "index";

export const getAllHsn =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["current_data.created_by.name"],
          numbers: [
            "current_data.hsn_code","current_data.gst_percentage.current_data.gst_percentage",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_HSN_REQUEST });
      const data = await Axios.post(
        `${AllApis.hsn.list}?${urlParams.toString()}`,
        body
      );
      console.log(data?.data?.data);
      dispatch({
        type: ALL_HSN_SUCCESS,
        payload: {
          data: data?.data?.data?.hsnCode,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_HSN_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
