const formatTime = (time) => {
    const timestamp = new Date(time);

    // Convert to Indian time zone
    const indianTime = timestamp.toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        hour12: true,
        hour: "numeric",
        minute: "numeric"
    });

    return indianTime
}
export default formatTime