import {
  ALL_PTD_FAIL,
  ALL_PTD_REQUEST,
  ALL_PTD_SUCCESS,
} from "app/utils/constants/paymentTermDaysConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allPtd: [],
  TotalPage: null,
};

export const ptdReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_PTD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PTD_SUCCESS:
      return {
        ...state,
        loading: false,
        allPtd: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PTD_FAIL:
      return {
        loading: false,
        ...state,
        allPtd: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
