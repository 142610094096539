const { TRANSACTION_REQUEST, TRANSACTION_SUCCESS, TRANSACTION_FAILED } = require("app/utils/constants/TransactionConstant")

const TRANSACTION_INIT = {
    loading:false,
    transactionHistory:[],
    totals:[],
    totalPage:0,
    error:false,
    errorMessage:null
}

const transactionReducer = (state = TRANSACTION_INIT, action) => {
    switch (action.type) {
        case TRANSACTION_REQUEST:
            return { ...state, loading: true }
        case TRANSACTION_SUCCESS:
            return {
                ...state,
                transactionHistory: action.payload.transactionHistory,
                totalPage: action.payload.totalPage,
                totals:action.payload.totals,
                loading: false
            }
        case TRANSACTION_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                transactionHistory: [],
                totalPage: 0,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default transactionReducer;