import {
  ALL_HSN_FAIL,
  ALL_HSN_REQUEST,
  ALL_HSN_SUCCESS,
} from "app/utils/constants/hsnConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allHsn: [],
  TotalPage: null,
};

export const hsnReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_HSN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_HSN_SUCCESS:
      return {
        ...state,
        loading: false,
        allHsn: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_HSN_FAIL:
      return {
        ...state,
        loading: false,
        allHsn: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
