import Page from "@jumbo/shared/Page";
import ListDispatchOrders from "app/pages/OrderProcessing/Dispatch/ListDispatch";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/orderProcessing/dispatch";

export const dispatchRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "dispatch",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListDispatchOrders} />,
          },
        ],
      },
    ],
  },
];
