import Page from "@jumbo/shared/Page";
import AddEditPtd from "app/pages/Masters/PaymentTermDays/AddEditPtd";
import ListPtd from "app/pages/Masters/PaymentTermDays/ListPtd";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/master/paymentTermDays";
const modules = "term_days";

export const paymentTermDaysRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/admin/dashboard",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "term_days",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListPtd} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "term_days",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddEditPtd} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "term_days",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit`,
            element: <Page component={AddEditPtd} />,
          },
        ],
      },
    ],
  },
];
