import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
} from "app/utils/constants/productConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allProduct: [],
  TotalPage: null,
  imagePath:null
};

export const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        allProduct: action.payload.data,
        imagePath:action.payload.imagePath,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        allProduct: [],
        imagePath:null,
        error: action.payload,
      };
    default:
      return state;
  }
};
