import AllApis from "app/Apis/apis";
import { Axios } from "index";

const { MARKET_EXECUTIVE_COMMISSION_REQUEST, MARKET_EXECUTIVE_COMMISSION_SUCCESS, MARKET_EXECUTIVE_COMMISSION_FAILED, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED, MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST, MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS, MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED } = require("app/utils/constants/MarketExecutiveCommission");

export const getMarketExecutiveCommissionList = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {}, companyId }) => {
    return async (dispatch) => {
        const payload = {
            searchFields: {
                string: [
                    "current_data.marketExecutiveId.current_data.contact_person_details.first_name",
                    "current_data.marketExecutiveId.current_data.contact_person_details.last_name",
                    "current_data.marketExecutiveId.current_data.contact_person_details.primary_email_id",
                    "current_data.marketExecutiveId.current_data.contact_person_details.primary_mobile_no",
                ],
                numbers:[
                    "current_data.commissionPercentage"
                ]
            }
        }

        if (!search_value) {
            search_value = "";
          }
      
          const urlParams = new URLSearchParams({
            search: search_value.trim(),
            page: page,
            sort: sort,
            sortBy: sortBy,
          });

        try {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_REQUEST });
            const { data } = await Axios.post(`${AllApis.marketExecutiveCommission.listing(companyId)}?${urlParams.toString()}`,{...payload});
            dispatch({
                type: MARKET_EXECUTIVE_COMMISSION_SUCCESS, payload: {
                    marketExecutiveCommissionList: data?.data?.MarketExecutiveCommission,
                    totalPage:data?.totalPage
                }
            })
        } catch (error) {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_FAILED, payload: error?.response?.data?.message })
        }
    }
}

export const getMarketExecutiveCommissionCompanyList = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {}, metId }) => {
    return async (dispatch) => {
        const payload = {
            searchFields: {
                string: [
                    "retailers.current_data.company_name",
                    "offlinestores.current_data.company_name",
                ],
                numbers:[
                    "current_data.commissionPercentage"
                ]
            }
        }

        if (!search_value) {
            search_value = "";
          }
      
          const urlParams = new URLSearchParams({
            search: search_value.trim(),
            page: page,
            sort: sort,
            sortBy: sortBy,
          });

        try {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST });
            const { data } = await Axios.post(`${AllApis.marketExecutiveCommission.metCompanyList(metId)}?${urlParams.toString()}`,{...payload});
            dispatch({
                type: MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS, payload: {
                    marketExecutiveCommissionCompanyList: data?.data?.MarketExectiveCompany,
                    totalPage:data?.totalPage
                }
            })
        } catch (error) {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED, payload: error?.response?.data?.message })
        }
    }
}

export const getMarketExecutiveDropdown = (companyId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST });
            const { data } = await Axios.get(AllApis.marketExecutiveCommission.dropdown(companyId));
            dispatch({
                type: MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS, payload: {
                    marketExecutiveList: data?.data?.marketExecutiveList
                }
            })
        } catch (error) {
            dispatch({ type: MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED, payload: error?.response?.data?.message })
        }
    }
}