import Div from '@jumbo/shared/Div';
import { Box, Button, InputAdornment, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Heading from 'app/components/Heading';
import React, { useEffect } from 'react'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from 'react-router-dom';
import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleMarketExecutive } from 'app/redux/actions/Masters/MarketExecutive';

const tableCellSx = {
    textTransform: "capitalize",
    color: "white",
    textAlign: "left",
    minWidth: "175px",
    verticalAlign: "middle",
}


const ListingNominee = () => {
    const { metId } = useParams();
    const {marketExecutiveReducer: { singleMarketExecutive, loading } } = useSelector((state) => state);
    const marketExecutiveData = singleMarketExecutive?.data?.MarketExecutive
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case "edit":
                navigate(`/dashboards/master/marketExecutiveTeams/nominee/edit/${metId}/${menuItem.nomineeId}`,{state:{nomineeData:menuItem.nomineeData}})
                break;
            default:
                navigate(`/dashboards/master/marketExecutiveTeams`)
        }
    }


    useEffect(()=>{
        dispatch(getSingleMarketExecutive(metId))
    },[])


    return (
        <Box sx={{ mt: -4 }}>
            <Typography as="div">
                <Heading Name={"Nominee"} />
            </Typography>
            <Div sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 4 }}>
                {/* <Div>
                    <TextField
                        id="search"
                        type="search"
                        label="Search"
                        value={searchTerm}
                        size="small"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            if (e.target.value === "") {
                                dispatch(getAllMarketExecutive({
                                    page: 1,
                                    sort: "desc",
                                    sortBy: "created_at",
                                    search_value: ""
                                }));
                            }
                        }}
                        sx={{ width: 400 }}
                        InputProps={{
                            endAdornment: (
                                <Div sx={{ cursor: "pointer" }}>
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </Div>
                            ),
                        }}
                    />
                </Div> */}
                <Button variant='outlined' sx={{mx:2}} onClick={() => navigate(`/dashboards/master/marketExecutiveTeams`)}>Back</Button>
                <Button variant='contained' onClick={() => navigate(`/dashboards/master/marketExecutiveTeams/nominee/add/${metId}`)}>Add Nominee</Button>
            </Div>
            <Box>
                <TableContainer component={Paper} sx={{ my: "2%" }}>
                    <Table sx={{ minWidth: 650 }} size="medium" >
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#7352C7" }}>
                                {
                                    ["Name","Age","DOB","PAN No","Aadhar No","City","State","Action"].map((head, index) => {
                                        return (
                                            <TableCell align={"left"} key={index} sx={{ ...tableCellSx }}>
                                                {head}
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                marketExecutiveData?.current_data.nominee?.map((nomineeElem, index) => {
                                    return (
                                        <TableRow key={nomineeElem?._id}>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.nominee_name || "NA"}
                                            </TableCell>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.nominee_age || "NA"}
                                            </TableCell>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {new Date(nomineeElem?.nominee_dob || Date.now()).toISOString().split('T')[0]}
                                            </TableCell>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.kyc?.pan?.pan_no || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.kyc?.aadhar?.aadhar_no || "NA"}
                                            </TableCell>
                                            
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.address?.city || "NA"}
                                            </TableCell>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {nomineeElem?.address?.state || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                <JumboDdMenu
                                                    icon={<MoreHorizIcon />}
                                                    menuItems={[
                                                        {
                                                            icon: <EditIcon />,
                                                            title: "Edit",
                                                            action: "edit",
                                                            nomineeId: nomineeElem?._id,
                                                            nomineeData:nomineeElem,
                                                            visibility: marketExecutiveData?.proposed_changes?.status
                                                        },
                                                    ].filter((e) => e?.visibility)}
                                                    onClickCallback={handleItemAction}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    {/* <Pagination
                        count={marketExecutiveList?.allMarketExecutive?.data?.totalPages || 0}
                        page={page}
                        onChange={handleChangePage}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            p: "1%",
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd",
                        }}
                    /> */}
                </TableContainer>
            </Box>
        </Box>
    )
}

export default ListingNominee