import AllApis from "app/Apis/apis";
import {
  ALL_GST_FAIL,
  ALL_GST_REQUEST,
  ALL_GST_SUCCESS,
} from "app/utils/constants/gstConstant";
import { Axios } from "index";

export const getAllGst =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["current_data.created_by.name"],
          numbers: ["current_data.gst_percentage"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_GST_REQUEST });
      const data = await Axios.post(
        `${AllApis.gst.list}?${urlParams.toString()}`,
        body
      );
      console.log(data?.data?.data);
      dispatch({
        type: ALL_GST_SUCCESS,
        payload: {
          data: data?.data?.data?.gst,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_GST_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
