import React, { useCallback, useEffect, useState } from 'react'
import Div from '@jumbo/shared/Div'
import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Heading from 'app/components/Heading'
import ListCompany from './ListCompany'
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux'
import { getAllBranchList } from 'app/redux/actions/Masters/Suppliers'
import { addressDropdownAction } from 'app/redux/actions/AddressDropdown'
import Loader from 'app/components/Loader'
import { State, City } from "country-state-city";


function Suppliers({ companyType, label }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const companyBranchList = useSelector((state) => state?.[`${companyType}Reducer`]?.branch);
  const [filters, setFilters] = useState({
    district: {
      _id: "",
      name: ""
    },
    location: {
      _id: "",
      name: ""
    },
    taluka: {
      _id: "",
      name: ""
    },
    area: {
      _id: "",
      name: ""
    },
    state: {
      name: ""
    },
    city: {
      name: ""
    }
  })
  const { district, location, taluka, area, loading: addressLoading } = useSelector((state) => state?.addressDropdown);
  const [stateCityList, setStateCityList] = useState({
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFilters = useCallback(function () {
    const filter = {};
    for (let i in filters) {
      if (filters?.[i]?.name) {
        filter[`current_data.branch_address.${i}`] = filters?.[i]?.name;
      }
    }
    dispatch(getAllBranchList({
      companyType: companyType,
      filters: filter
    }))
  }, [filters, companyType, dispatch])

  const handleClears = useCallback(function () {
    setFilters({
      district: {
        _id: "",
        name: ""
      },
      location: {
        _id: "",
        name: ""
      },
      taluka: {
        _id: "",
        name: ""
      },
      area: {
        _id: "",
        name: ""
      },
      state: {
        name: ""
      },
      city: {
        name: ""
      }
    }
    )
    dispatch(getAllBranchList({
      companyType: companyType,
    }))
  }, [companyType, dispatch])

  // useEffect(() => {
  //     Promise.allSettled([
  //         dispatch(addressDropdownAction("district")),
  //         dispatch(addressDropdownAction("location")),
  //         dispatch(addressDropdownAction("area")),
  //         dispatch(addressDropdownAction("taluka"))
  //     ]);
  // }, [companyType, dispatch])

  return (
    <>
      {companyBranchList?.loading && <Loader />}
      <Div sx={{ mt: -4 }}>
        <Heading Name={label} />
        <Div className='Filters' sx={{ width: "100%", mt: 4 }}>
          <Typography variant='h1' sx={{ mb: 3 }}>Filters</Typography>
          <Box sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}>
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={district}
              value={filters?.district}
              label="District"
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, district: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="District" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={location}
              value={filters?.location}
              label="location"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, location: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Location" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={taluka}
              value={filters?.taluka}
              label="taluka"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, taluka: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Taluka" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={area}
              value={filters?.area}
              label="area"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, area: newValue })
                console.log(newValue)
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Area" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={stateCityList?.stateList || []}
              value={filters?.state}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={
                (option, value) => option?.name === value?.name
              }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "state": {
                    name: newValue != null ? newValue?.name?.toLowerCase() : ""
                  },
                });
                if (newValue != null) {
                  const cityNames = City.getCitiesOfState(
                    "IN",
                    newValue.isoCode
                  );
                  setStateCityList({ ...stateCityList, cityList: cityNames });
                } else {
                  setStateCityList({
                    ...stateCityList,
                    cityList: City.getCitiesOfState("IN", "MH"),
                  });
                }
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="State" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={stateCityList?.cityList || []}
              label="city"
              value={filters?.city}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={
                (option, value) => option?.name === value?.name
              }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "city": {
                    name: newValue != null ? newValue?.name?.toLowerCase() : ""
                  },
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="City" />
              )}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button variant='contained' sx={{ marginRight: 1 }} onClick={handleFilters}>Apply</Button>
            <Button variant='outlined' onClick={handleClears}>Clear</Button>
          </Box>
        </Div>

        <Div sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 4 }}>
          <Div>
            <TextField
              id="search"
              type="search"
              label="Search"
              value={searchTerm}
              size="small"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (e.target.value === "") {
                  const filter = {}
                  for (let i in filters) {
                    if (filters[i]?.name) {
                      filter[`current_data.branch_address.${i}`] = filters[i]?.name
                    }
                  }
                  dispatch(getAllBranchList({
                    companyType: companyType,
                    page: 1,
                    sort: "desc",
                    sortBy: "created_at",
                    search_value: "",
                    filters: filter
                  }));
                }
              }}
              sx={{ width: 400 }}
              InputProps={{
                endAdornment: (
                  <Div sx={{ cursor: "pointer" }}>
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  </Div>
                ),
              }}
            />
          </Div>
          <Div>
            <Button variant='contained' onClick={() => {
              navigate(`/dashboards/master/${companyType}/list/Company`)
            }}>{companyType}</Button>
            <Button variant='contained' sx={{ marginLeft: 2 }} onClick={() => {
              navigate(`/dashboards/master/${companyType}/add-branch`, { state: { addBranch: true } })
            }}>Add New Branch</Button>
          </Div>
        </Div>

        <ListCompany
          searchTerm={searchTerm}
          filters={filters}
          companyType={companyType}
          setIsSubmitting={setIsSubmitting}
        />
      </Div>
    </>
  );
}

export default Suppliers;
