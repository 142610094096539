import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import Heading from "app/components/Heading";
import { useNavigate, useParams } from "react-router-dom";
import {
  ImageListItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import { useEffect } from "react";
import { useState } from "react";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ViewModel from "../viewModel";
let imagepath = process.env.REACT_APP_IMAGE_URL;
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TextFeild({ label, value, sx }) {
  return (
    <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
      <Typography as="span" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>{" "}
      :- {value}
    </Typography>
  );
}

export default function ProductHistory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productHistory, setProductHistory] = useState();
  const [transportHistory, setTransportHistory] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [page, setPage] = useState(1);
  const [sortOrder, setsortOrder] = useState("desc");
  const [sortField, setsortField] = useState("created_at");
  const handleSort = (property) => {
    setsortOrder(sortOrder == "asc" ? "desc" : "asc");
    setsortField(property);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate(`/dashboards/users/edit`, { state: { ...menuItem.data } });
        break;
      case "view":
        setOpenModel(true);
        setTransportHistory({ state: { ...menuItem.data } });
        break;
      default:
        navigate(`/dashboard/inventory`);
    }
  };
  useEffect(async () => {
    let response = await Axios.post(AllApis.inventory.productHistory(id));
    setProductHistory(response?.data?.data);
  }, []);
  console.log(productHistory, "------------------------dsfsdfsdfdfsdff");
  return (
    <div>
      <Box>
        <Typography sx={{ my: 2 }}>
          <Heading Name={"View Product Details"} />
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.employee_id")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Supplier Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.profile_pic")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Invoice No
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.first_name")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Invoice Date
                  </TableSortLabel>
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.last_name")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Received Date
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.primary_email_id")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Received Quantity
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    // onClick={() => handleSort("current_data.primary_mobile_no")}
                    direction={sortOrder}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Balance Quantity
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productHistory &&
                productHistory?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {`${row?.current_data?.supplierDetails?.firstName} 
                      ${row?.current_data?.supplierDetails?.lastName}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {row?.current_data?.invoiceDetails?.invoiceNo}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {row?.current_data?.invoiceDetails?.invoiceDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row?.current_data?.receivedDate}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row?.current_data?.itemsDetails?.receivedQuantity}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row?.current_data?.itemsDetails?.balanceQuantity}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <VisibilityRoundedIcon />,
                            title: "View Transport Details",
                            action: "view",
                            data: row,
                            visibility: true,
                          },
                          {
                            icon: <VisibilityRoundedIcon />,
                            title: "Download invoice",
                            action: "download",
                            visibility: true,
                          },
                        ].filter((e) => e?.visibility)}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
        {openModel && (
          <ViewModel
            open={openModel}
            setOpen={setOpenModel}
            transportDetails={transportHistory}
          />
        )}
      </Box>
    </div>
  );
}
