import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import Heading from "app/components/Heading";
import {
    ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
let imagepath = process.env.REACT_APP_IMAGE_URL;
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TextFeild({ label, value, sx }) {
  return (
    <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
      <Typography as="span" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>{" "}
      :- {value}
    </Typography>
  );
}

export default function ViewModel({ open, setOpen, userDetails }) {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ my: 2 }}>
              <Heading Name={"View User Details"} />
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              sx={{ my: 4 }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography id="spring-modal-title" variant="h1" component="h1">
                  {userDetails?.current_data?.first_name}
                  {userDetails?.current_data?.last_name}
                </Typography>
                <TextFeild
                  label={`Employee Name`}
                  value={`${userDetails?.state?.current_data?.first_name} ${userDetails?.state?.current_data?.last_name}`}
                />
                <TextFeild
                  label={"Primary Mobile Number"}
                  value={`${userDetails?.state?.current_data?.primary_mobile_no}`}
                />
                <TextFeild
                  label={"Secondary Mobile Number"}
                  value={`${userDetails?.state?.current_data?.secondary_mobile_no}`}
                />
                <TextFeild
                  label={"Primary Email Id"}
                  value={`${userDetails?.state?.current_data?.primary_email_id}`}
                />
                <TextFeild
                  label={"Secondary Email Id"}
                  value={`${userDetails?.state?.current_data?.secondary_email_id}`}
                />
                <TextFeild
                  label={"Role"}
                  value={`${userDetails?.state?.current_data?.role_id?.current_data?.role_name}`}
                />
              </Box>
              <Box sx={{ width: "10%" }}>
                <ImageListItem>
                  <img
                    src={`${imagepath}/${userDetails?.state?.current_data?.profile_pic}?w=100%&h=100%&fit=crop&auto=format`}
                    loading="lazy"
                  />
                </ImageListItem>
              </Box>
              <Box sx={{ width: "20%" }}>
                <TextFeild
                  label={"Address"}
                  value={` ${userDetails?.state?.current_data?.address?.address} `}
                />
                <TextFeild
                  label={"Area"}
                  value={`${userDetails?.state?.current_data?.address?.area}`}
                />
                <TextFeild
                  label={"Location"}
                  value={`${userDetails?.state?.current_data?.address?.location}`}
                />
                <TextFeild
                  label={"District"}
                  value={`${userDetails?.state?.current_data?.address?.district}`}
                />
                <TextFeild
                  label={"Taluka"}
                  value={`${userDetails?.state?.current_data?.address?.taluka} `}
                />
                <TextFeild
                  label={"City"}
                  value={`${userDetails?.state?.current_data?.address?.city}`}
                />
                <TextFeild
                  label={"State"}
                  value={`${userDetails?.state?.current_data?.address?.state}`}
                />
                <TextFeild
                  label={"Pincode"}
                  value={`${userDetails?.state?.current_data?.address?.pincode} `}
                />
              </Box>
            </Box>
            <Box sx={{ my: 4 }}>
              <Typography
                id="spring-modal-title"
                variant="h1"
                component="h2"
                sx={{ my: 2 }}
              >
                Bank Details
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                      {[
                        "Bank Name",
                        "Account No",
                        "Confirm Account No",
                        "IFSC Code",
                      ].map((ele, i) => {
                        return (
                          <>
                            <TableCell
                              align="left"
                              sx={{
                                textTransform: "capitalize",
                                color: "white",
                                textAlign: "left",
                                minWidth: "150px",
                                verticalAlign: "middle",
                              }}
                            >
                              {ele}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        {userDetails?.state?.current_data?.kyc?.bank_details
                          ?.bank_name || "NA"}
                      </TableCell>
                      <TableCell align="left">
                        {userDetails?.state?.current_data?.kyc?.bank_details
                          ?.account_no || "NA"}
                      </TableCell>
                      <TableCell align="left">
                        {userDetails?.state?.current_data?.kyc?.bank_details
                          ?.confirm_account_no || "NA"}
                      </TableCell>
                      <TableCell align="left">
                        {userDetails?.state?.current_data?.kyc?.bank_details
                          ?.ifsc_code || "NA"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ my: 4 }}>
              <Typography
                id="spring-modal-title"
                variant="h1"
                component="h2"
                sx={{ my: 2 }}
              >
                Uploaded Documents
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="medium">
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                      {["ID Name", "ID Number"].map((ele, i) => {
                        return (
                          <>
                            <TableCell
                              align="left"
                              sx={{
                                textTransform: "capitalize",
                                color: "white",
                                textAlign: "left",
                                minWidth: "180px",
                                verticalAlign: "middle",
                              }}
                            >
                              {ele}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        PAN Card
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {userDetails?.state?.current_data?.kyc?.pan_card_detail
                          ?.pan_no || "NA"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        Aadhar Card
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {userDetails?.state?.current_data?.kyc
                          ?.aadhar_card_detail?.aadhar_no || "NA"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
