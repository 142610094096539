import Div from '@jumbo/shared/Div'
import { LoadingButton } from '@mui/lab'
import { Box, Button, TextField } from '@mui/material'
import { addSuppliers } from 'app/Apis/Masters/Suppliers'
import AllApis from 'app/Apis/apis'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { ErrorMessage, Form, Formik } from 'formik'
import { Axios } from 'index'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from "yup"

function AddCompany({ setCompanyId, companyId, companyType }) {
    console.log(companyType)
    const navigate = useNavigate();


    const handleImagesTab = async (value) => {
        const images = URL.createObjectURL(value);
        window.open(images, "__blank")
    }

    return (
        <>
            <Box sx={{ marginTop: "2%" }}>
                <Formik
                    initialValues={{
                        company_name: "",
                        company_type: companyType,
                        pan: {
                            pan_no: "",
                            pan_image: ""
                        },
                        onboarding_date: new Date(Date.now()).toISOString().split('T')[0]
                    }}
                    validationSchema={
                        yup.object({
                            username: yup.string().when('company_type', {
                                is: (val) => val === 'retailer' || val === "offlinestore",
                                then: yup.string().min(2).required('Username is required for retailers'),
                                otherwise: yup.string().min(2)
                            }),
                            company_name: yup.string().min(2).required("company name is required"),
                            onboarding_date: yup.date(),
                            pan: yup.object().shape({
                                pan_no: yup.string()
                                    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number')
                                    .required('PAN number is required'),
                                pan_image: yup.mixed(),
                            }),
                        })
                    }
                    onSubmit={async (values, action) => {
                        const formData = new FormData();
                        formData.append("username",values.username)
                        formData.append("company_name",values.company_name)
                        formData.append("onboarding_date",values.onboarding_date)
                        formData.append("pan_no",values.pan.pan_no)
                        formData.append("pan_image",values.pan.pan_image)
                        try {
                            const { data } = await Axios.post(AllApis?.[companyType].add, formData);
                            if (data?.statusCode === 201) {
                                const companyData = data?.data?.[companyType]
                                setCompanyId({ _id: companyData?._id, company_name: companyData?.current_data?.company_name })
                                Swal.fire({
                                    icon: "success",
                                    title: data?.message,
                                    text: "",
                                    timer: 1000,
                                    showConfirmButton: false,
                                })
                            }
                        } catch (error) {
                            Swal.fire({
                                icon: "error",
                                title: error?.response?.data?.message,
                                text: "",
                                timer: 1000,
                                showConfirmButton: false,
                            })
                        }
                    }}
                >
                    {
                        ({ values, setFieldValue, errors, touched, handleChange, handleBlur, isSubmitting, handleSubmit }) => {
                            return (
                                <>
                                    <Form>
                                        <Div sx={{
                                            display: "flex",
                                            width: "100%",
                                            flexWrap: "wrap",
                                            columnGap: 4,
                                            rowGap: 3,
                                        }}>
                                            {(companyType === "retailer" || companyType === "offlinestore") && <TextField name="username" required disabled={companyId?._id} value={values.username} label="User Name" size="small" sx={{ width: "45%", }} onChange={handleChange} onBlur={handleBlur} error={errors["username"] && touched["username"]} helperText={errors["username"] && touched["username"] ? errors["username"] : ""} />}

                                            <TextField name="company_name" required disabled={companyId?._id} value={values.company_name} label="Company Name" size="small" sx={{ width: "45%", }} onChange={handleChange} onBlur={handleBlur} error={errors["company_name"] && touched["company_name"]} helperText={errors["company_name"] && touched["company_name"] ? errors["company_name"] : ""} />

                                            <TextField name="onboarding_date" disabled={companyId?._id} type="date" value={values.onboarding_date} label="OnBoarding Date" size="small" sx={{ width: "45%" }} onChange={handleChange} onBlur={handleBlur} error={errors["onboarding_date"] && touched["onboarding_date"]} helperText={errors["onboarding_date"] && touched["onboarding_date"] ? errors["onboarding_date"] : ""} />

                                            <TextField
                                                required disabled={companyId?._id}
                                                name={`pan.pan_no`}
                                                label="PAN No"
                                                size="small"
                                                sx={{ width: "45%" }}
                                                value={values?.pan?.pan_no}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors?.pan?.pan_no && touched?.pan?.pan_no}
                                                helperText={errors?.pan?.pan_no && touched?.pan?.pan_no ? errors?.pan?.pan_no : ""} />
                                            <Div sx={{ width: "45%", display: "flex", justifyContent: "space-between" }}>
                                                <TextField disabled={companyId?._id}
                                                    name={`pan.pan_image`}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    label="PAN Image"
                                                    type='file'
                                                    size="small"
                                                    sx={{ width: "100%" }}
                                                    onChange={(e) => {
                                                        const { name, files } = e.target
                                                        if (files && files.length > 0) {
                                                            setFieldValue(name, files[0])
                                                        }
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={errors?.pan?.pan_image && touched?.pan?.pan_image} helperText={errors?.pan?.pan_image && touched?.pan?.pan_image ? errors?.pan?.pan_image : ""} />
                                                {values?.pan?.pan_image && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => { handleImagesTab(values?.pan?.pan_image) }}>
                                                    <PermMediaIcon sx={{ height: "100%" }} />
                                                </Button>}
                                            </Div>
                                        </Div>
                                        {!companyId?._id && <Div
                                            sx={{
                                                width: "92%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                gap: 3,
                                                mt: 3,
                                            }}
                                        >
                                            <Button
                                                sx={{ width: "100px" }}
                                                variant="outlined"
                                                onClick={() => navigate(`/dashboards/master/${companyType}`)}
                                            >
                                                Back
                                            </Button>

                                            <LoadingButton
                                                variant="contained"
                                                type="submit"
                                                sx={{ width: "100px" }}
                                            >
                                                Submit
                                            </LoadingButton>
                                        </Div>}

                                    </Form>
                                </>
                            )
                        }
                    }
                </Formik>
            </Box>
        </>
    )
}

export default AddCompany