import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { debounce } from "lodash";
import {
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
// import LogsModal from "../Logs";
import Heading from "app/pages/Component/Heading";
import { getUsers } from "app/redux/actions/User";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { addressDropdownAction } from "app/redux/actions/AddressDropdown";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import { getSamples } from "app/redux/actions/SampleTracking";
import ViewModel from "../viewModel";
export default function ListSamples() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sortOrder, setsortOrder] = useState("desc");
  const [sortField, setsortField] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [sampleDetails, setSampleDetails] = useState({});
  const { TotalPage, loading, samples } = useSelector(
    (state) => state?.sampleReducer
  );
  const [filters, setFilters] = useState({
    personName: "",
    location: {
      _id: "",
      name: "",
    },
    taluka: {
      _id: "",
      name: "",
    },
    area: {
      _id: "",
      name: "",
    },
    state: {
      _id: "",
      name: "",
    },
    city: {
      _id: "",
      name: "",
    },
  });

  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const {
    district,
    location,
    taluka,
    area,
    loading: addressLoading,
  } = useSelector((state) => state?.addressDropdown);
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  }));

  const handleFilters = useCallback(
    function () {
      const filter = {};
      for (let i in filters) {
        if (filters?.[i]?.name) {
          filter[`current_data.branch_address.${i}`] = filters?.[i]?.name;
        }
      }
      dispatch(
        getUsers({
          filters: filter,
        })
      );
    },
    [filters, dispatch]
  );

  const handleClears = useCallback(
    function () {
      setFilters({
        district: {
          _id: "",
          name: "",
        },
        location: {
          _id: "",
          name: "",
        },
        taluka: {
          _id: "",
          name: "",
        },
        area: {
          _id: "",
          name: "",
        },
        state: {
          _id: "",
          name: "",
        },
        city: {
          _id: "",
          name: "",
        },
      });
      dispatch(getUsers({}));
    },
    [dispatch]
  );
  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getUsers(value, sortOrder, sortField));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setsortOrder(sortOrder == "asc" ? "desc" : "asc");
    setsortField(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "view":
        setOpenModel(true);
        setSampleDetails({ state: { ...menuItem.data } });
        break;
      default:
        navigate(`/dashboard/sampleTracking`);
    }
  };
  const changeStatus = async (e, row) => {
    try {
      const response = await Axios.patch(AllApis.users.edit(row._id), {
        isActive: e.target.checked,
      });
      if (response) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getUsers("", sortOrder, sortField, page));
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };
  // useEffect(() => {
  //   dispatch(getUsers(searchTerm, sortOrder, sortField, page));
  // }, [sortOrder, page]);
  useEffect(() => {
    dispatch(getSamples());
  }, []);
  useEffect(() => {
    Promise.allSettled([
      dispatch(addressDropdownAction("district")),
      dispatch(addressDropdownAction("location")),
      dispatch(addressDropdownAction("area")),
      dispatch(addressDropdownAction("taluka")),
    ]);
  }, [dispatch]);
  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={"Samples"} />

      {/* <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      > */}
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <TextField
            name="Person Name"
            label="Person Name"
            size="small"
            sx={{ width: "20%", marginY: "10px" }}
          />
          <TextField
            name="Person Name"
            label="Company Name"
            size="small"
            sx={{ width: "20%", marginY: "10px" }}
          />
          <TextField
            name="Person Name"
            label="Category"
            size="small"
            sx={{ width: "20%", marginY: "10px" }}
          />
          <TextField
            name="Person Name"
            label="Item Name"
            size="small"
            sx={{ width: "20%", marginY: "10px" }}
          />
          {/* <Autocomplete
            sx={{ width: "23%", textTransform: "capitalize" }}
            size="small"
            id="company-autocomplete"
            options={area}
            value={filters?.area}
            label="area"
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(e, newValue) => {
              setFilters({ ...filters, area: newValue });
              console.log(newValue);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="area" />
            )}
          /> */}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            sx={{ marginRight: 1 }}
            onClick={handleFilters}
          >
            Apply
          </Button>
          <Button variant="outlined" onClick={handleClears}>
            Clear
          </Button>
        </Box>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setsortOrder("desc");
              setsortField("created_at");
              dispatch(getUsers("", "desc", "created_at", 1));
            }
          }}
          sx={{ width: 300, mb: 2, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Button
          variant="contained"
          size="small"
          sx={{
            mr: 2,
            p: 1,
            pl: 4,
            pr: 4,
          }}
          onClick={() => navigate("/dashboards/sampleTracking/add")}
        >
          Add Sample
        </Button>
      </Div>

      {/* </Div> */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.employee_id")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Items Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.profile_pic")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Category
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.first_name")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  SKU
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.last_name")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  HSN Code
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.primary_email_id")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Item Weight
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.primary_mobile_no")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data?.created_by.name")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.address.city")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Person Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.address.state")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Company name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.approver_one.name")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  GST No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.approver_two.name")}
                  direction={sortOrder}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Delivery Challan No
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {samples &&
              samples?.map((row, i) => (
                <TableRow key={i}>
                  {console.log(row?.items[0]?.sku)}
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {row?.items[0]?.product_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {row?.items[0]?.category}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.items[0]?.sku}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.items[0]?.hsn_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.items[0]?.item_weight}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.items[0]?.quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.dateofsample}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.contactDetails?.personName}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.contactDetails?.companyName}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.contactDetails?.gstNo}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row?.deliveryChallanNo}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <VisibilityRoundedIcon />,
                          title: "View",
                          action: "view",
                          data: row,
                          visibility: true,
                        },
                      ].filter((e) => e?.visibility)}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
      {/* <LogsModal open={isLogModalOpen} onClose={() => setLogModalOpen(false)} /> */}
      {openModel && (
        <ViewModel
          open={openModel}
          setOpen={setOpenModel}
          sampleDetails={sampleDetails}
        />
      )}
    </Div>
  );
}
