import React, { useState } from "react";
import { Button, Modal, Box } from "@mui/material";

const ImageViewer = ({ imageURL,open,setOpen }) => {
  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="aadhar-card-modal"
        aria-describedby="view-aadhar-card"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/${imageURL}`}
            alt="Aadhar Card"
            style={{ maxWidth: "100%", maxHeight: "400px" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageViewer;
