import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { socket } from "index";

const AuthUserDropdown = () => {
  const navigate = useNavigate();
  const { theme } = useJumboTheme();

  const { userData } = useSelector((state) => state?.userReducer);
  const user = userData?.current_data;

  const onLogout = () => {
    Cookies.remove("isAuthenticate");
    Cookies.remove("user_id");
    Cookies.remove("token");
    socket.emit("logoutactiveUser", {
      userID:user?._id
    }); 
    navigate("/");
    window.location.reload();
  };

  // useEffect(() => {
  //   const handleWindowClose = (event) => {
  //     // Handle the window close event here
  //     socket.emit("logoutactiveUser", user?._id);
  //     console.log("Window is closing...");
  //     // You can perform any cleanup or additional tasks here before the window closes
  //   };

  //   window.addEventListener("beforeunload", handleWindowClose);

  //   return () => {
  //     // Clean up the event listener when the component unmounts
  //     window.removeEventListener("beforeunload", handleWindowClose);
  //   };
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={authUser.profile_pic}
            sizes={"small"}
            sx={{ boxShadow: 25, cursor: "pointer" }}
          />
        }
      >
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={authUser.profile_pic}
            alt={user?.first_name?.split(" ")?.[0]}
            sx={{
              width: 60,
              height: 60,
              mb: 2,
              textTransform: "capitalize",
              bgcolor: "#7352C7",
            }}
          />
          <Typography
            variant={"h5"}
            sx={{ textTransform: "capitalize" }}
          >{`${user?.first_name} ${user?.last_name}`}</Typography>
          <Typography variant={"body1"} color="text.secondary">
            {user?.primary_email_id}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <PersonOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                sx={{ my: 0 }}
                onClick={() => navigate("/dashboards/users/profile")}
              />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
