import Page from "@jumbo/shared/Page";
import AuthenticateAndRolesMiddleware from "../Middleware";
import ListRole from "app/pages/Roles/ListRole";
import ConfigureRole from "app/pages/Roles/AddEditRoles";
import AuthenticateMiddleware from "../Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/roles";

export const rolesRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "roles",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListRole} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "roles",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={ConfigureRole} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/admin/dashboard",
              module: "roles",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit`,
            element: <Page component={ConfigureRole} />,
          },
        ],
      },
    ],
  },
];
