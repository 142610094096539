import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import Heading from "app/components/Heading";
import {
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
let imagepath = process.env.REACT_APP_IMAGE_URL;
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TextFeild({ label, value, sx }) {
  return (
    <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
      <Typography as="span" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>{" "}
      :- {value}
    </Typography>
  );
}

export default function ViewModel({ open, setOpen, transportDetails }) {
  const handleClose = () => setOpen(false);
  console.log("transportDetails--------------------", transportDetails);
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ my: 2 }}>
              <Heading Name={"View Transport Details"} />
            </Typography>
            <Box display={"flex"} justifyContent={"left"} sx={{ my: 4 }}>
              <Box sx={{ width: "50%" }}>
                <Typography id="spring-modal-title" variant="h1" component="h1">
                  {transportDetails?.current_data?.first_name}
                  {transportDetails?.current_data?.last_name}
                </Typography>
                <TextFeild
                  label={`Supplier Name`}
                  value={`${transportDetails?.state?.current_data?.supplierDetails?.firstName} ${transportDetails?.state?.current_data?.supplierDetails?.lastName}`}
                />
                <TextFeild
                  label={"Received Date"}
                  value={`${transportDetails?.state?.current_data?.receivedDate.split(
                    "T"
                  )}`}
                />
                <TextFeild
                  label={"Delivery Challan Number"}
                  value={`${transportDetails?.state?.current_data?.transportDetails?.deliveryChallanNo}`}
                />
              </Box>
            </Box>
            <Box sx={{ my: 4 }}>
              <Typography
                id="spring-modal-title"
                variant="h1"
                component="h2"
                sx={{ my: 2 }}
              >
                Transport Details
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                      {["Transport Type", "", "", "", ""].map((ele, i) => {
                        return (
                          <>
                            <TableCell
                              align="left"
                              sx={{
                                textTransform: "capitalize",
                                color: "white",
                                textAlign: "left",
                                minWidth: "150px",
                                verticalAlign: "middle",
                              }}
                            >
                              {ele}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        Hand Delivery
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.handDelivery
                            ?.personName
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.handDelivery
                            ?.personNumber
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        courier Delivery
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.courier
                            ?.companyName
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.courier
                            ?.docketNumber
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        Air/Rail Delivery
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.airRail
                            ?.transporterName
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.airRail?.airRail
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.airRail
                            ?.awbNumber
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        Road Delivery
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.road
                            ?.transporterName
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.road
                            ?.vehicleNumber
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.road?.driverName
                        }
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {
                          transportDetails?.state?.current_data
                            ?.transportDetails?.transportType?.road
                            ?.driverNumber
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
