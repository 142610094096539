import JumboDdMenu from '@jumbo/components/JumboDdMenu';
import { Box, CircularProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@mui/material';
import { getAllBranchList } from 'app/redux/actions/Masters/Suppliers';
import React, { useEffect, useState } from 'react'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarsIcon from '@mui/icons-material/Stars';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import ViewModel from '../viewModel';
import Div from '@jumbo/shared/Div';
import AllApis from 'app/Apis/apis';
import { Axios } from 'index';
import Swal from 'sweetalert2';
import { formatDate } from 'app/pages/Component/Function/formatDate';


//companyType means singular for eg:-supplier
//companyTypeOption means plural for eg:-suppliers

const stickyTableRow = {
    textTransform: "capitalize",
    color: "white",
    minWidth: "175px",
    verticalAlign: "middle",
    position: "sticky",
    right: 0,
    zIndex: 1, // Ensures
    bgcolor: "white",
}

const TabelRowStyle = {
    textTransform: "capitalize",
    color: "white",
    minWidth: "175px",
    verticalAlign: "middle",
}

const ListCompany = ({ searchTerm, filters, companyType, setIsSubmitting }) => {
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");
    const [openModel, setOpenModel] = useState(false)
    const [branchData, setBranchData] = useState({})
    const companyBranchList = useSelector((state) => state?.[`${companyType}Reducer`]?.branch);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Head = [
        { name: 'OnBoarding Date', field: 'branch_onboarding_date' },
        { name: 'Company Name', field: `${companyType}Id.current_data.company_name` },
        { name: 'Branch Name', field: 'branch_name' },
        { name: 'PAN No', field: `${companyType}Id.current_data.pan.pan_no` },
        { name: 'GST No', field: 'kyc.gst.gst_no' },
        { name: 'KYC Status', field: 'kyc_status', sort: false, align: "center" },
        { name: 'Active Status', field: 'isActive', sort: false, align: "center" },
        { name: 'Email ID', field: 'contact_person.primary_email' },
        { name: 'Mobile No', field: 'contact_person.primary_mobile' },
        { name: 'City', field: 'branch_address.city' },
        { name: 'State', field: 'branch_address.state' },
        { name: 'District', field: 'branch_address.district' },
        { name: 'Taluka', field: 'branch_address.taluka' },
        { name: 'Action', field: 'action',sort: false, align: "center" }
    ]
    const handleSearch = (searchTerm) => {
        const filter = {}
        for (let i in filters) {
            if (filters[i]?.name) {
                filter[`current_data.branch_address.${i}`] = filters[i]?.name
            }
        }
        setPage(1);
        dispatch(getAllBranchList({
            companyType: companyType,
            page: page,
            search_value: searchTerm?.trim(),
            filters: filter
        }));
    };

    const debouncedHandleSearch = debounce(handleSearch, 500);

    useEffect(() => {
        if (searchTerm !== "") {
            debouncedHandleSearch(searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [searchTerm]);

    const handleSort = (property) => {
        setSort(sort === "asc" ? "desc" : "asc");
        setSortBy(property);
        setPage(1);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handlePrimaryBranch = async (companyId, branchId) => {
        try {
            setIsSubmitting(true);
            const primaryBranch = await Axios.patch(AllApis?.[companyType]?.setPrimaryBranch(companyId, branchId))
            if (primaryBranch?.data?.statusCode === 201 || primaryBranch?.data?.statusCode === 200) {
                const filter = {}
                for (let i in filters) {
                    if (filters[i]?.name) {
                        filter[`current_data.branch_address.${i}`] = filters[i]?.name
                    }
                }
                Swal.fire({
                    icon: "success",
                    title: primaryBranch?.data?.status,
                    text: primaryBranch?.data?.message,
                    timer: 1000,
                    showConfirmButton: false,
                })
                dispatch(getAllBranchList({
                    companyType: companyType,
                    sortBy: sortBy,
                    sort: sort,
                    page: page,
                    search_value: searchTerm?.trim(),
                    filters: filter
                }))
            }
            setIsSubmitting(false)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
            setIsSubmitting(false)
        }
    }

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case "edit":
                navigate(`/dashboards/master/${companyType}/edit/${menuItem.branchId}`, { state: { branchData: menuItem.branchData } });
                break;
            case "primaryBranch":
                const companyId = menuItem?.branchData?.[`${companyType}Id`]?._id
                const branchId = menuItem?.branchId
                handlePrimaryBranch(companyId, branchId);
                break;
            case "view":
                setOpenModel(true)
                setBranchData({ ...menuItem?.branchData })
                break;
            // case "manageMarketExecutive":
            //     navigate(`/dashboards/master/${companyType}/market-executive/${menuItem?.companyId}`)
            //     break;
            default:
                navigate(`/dashboard/master/${companyType}`)
        }
    }

    const handleActiveAndKycStatus = async ({ companyId, branchId, body }) => {
        try {
            setIsSubmitting(true);
            const AddBranch = await Axios.patch(AllApis?.[companyType]?.branch.edit(companyId, branchId), body)
            if (AddBranch?.data?.statusCode === 201 || AddBranch?.data?.statusCode === 200) {
                const filter = {}
                for (let i in filters) {
                    if (filters[i]?.name) {
                        filter[`current_data.branch_address.${i}`] = filters[i]?.name
                    }
                }
                Swal.fire({
                    icon: "success",
                    title: AddBranch?.data?.status,
                    text: AddBranch?.data?.message,
                    timer: 1000,
                    showConfirmButton: false,
                })
                dispatch(getAllBranchList({
                    companyType: companyType,
                    sortBy: sortBy,
                    sort: sort,
                    page: page,
                    search_value: searchTerm?.trim(),
                    filters: filter
                }))
            }
            setIsSubmitting(false)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error?.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        const filter = {}
        for (let i in filters) {
            if (filters[i]?.name) {
                filter[`current_data.branch_address.${i}`] = filters[i]?.name
            }
        }
        dispatch(getAllBranchList({
            companyType: companyType,
            sortBy: sortBy,
            sort: sort,
            page: page,
            search_value: searchTerm?.trim(),
            filters: filter
        }))
    }, [page, sort, companyType, sortBy])


    return (
        <>
            <TableContainer component={Paper} sx={{ my: "2%" }}>
                <Table sx={{ minWidth: 650 }} size="medium" >
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#7352C7" }}>
                            {Head.map((heading, i) => {
                                return (
                                    <TableCell key={i} align={heading.align ? heading.align : "left"} sx={heading?.field !== "action" ? {
                                        textTransform: "capitalize",
                                        color: "white",
                                        textAlign: heading.align ? heading.align : "left",
                                        minWidth: "175px",
                                        verticalAlign: "middle",
                                    } : {
                                        textTransform: "capitalize",
                                        color: "white",
                                        textAlign: heading.align ? heading.align : "left",
                                        minWidth: "175px",
                                        verticalAlign: "middle",
                                        position: "sticky",
                                        right: 0,
                                        zIndex: 1, // Ensures
                                        bgcolor: "#7352C7",
                                        borderColor:"#7352C7"
                                    }}>{
                                            heading.sort === false ? heading.name : <TableSortLabel
                                                onClick={() => handleSort(`current_data.${heading?.field}`)}
                                                direction={sort}
                                                sx={{
                                                    color: "white",
                                                    "&:hover": { color: "white" },
                                                    "&.MuiTableSortLabel-root.Mui-active": {
                                                        color: "white",
                                                    },
                                                }}
                                            >{heading?.name}</TableSortLabel>
                                        }</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            companyBranchList?.allBranch?.map(({ _id, current_data: branch, proposed_changes }, index) => {
                                //get the primary contact
                                const contact_details = branch?.contact_person?.filter((e) => e?.isPrimary === true)[0];
                                //get the primary branch which is store in company
                                const primaryBranch = branch?.[`${companyType}Id`]?.current_data?.primaryBranch
                                return (
                                    <TableRow key={_id} >
                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            verticalAlign: "middle",
                                        }}>{formatDate(branch?.branch_onboarding_date) || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.[`${companyType}Id`]?.current_data?.company_name || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.branch_name || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            verticalAlign: "middle",
                                        }}>{branch?.[`${companyType}Id`]?.current_data?.pan?.pan_no || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            verticalAlign: "middle",
                                        }}>{branch?.kyc?.gst?.gst_no || "NA"}</TableCell>

                                        <TableCell align="center" sx={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}>
                                            {/* {
                                                branch?.kyc?.kyc_status ?
                                                <Div sx={{ display: "flex", alignItems: "center",justifyContent:"center", width: "100%" }}>
                                                    <Typography sx={{textAlign:"center" }}>
                                                        Verified
                                                    </Typography>
                                                    <VerifiedIcon style={{ color: "blue", fontSize: "20px" }} />
                                                </Div> :
                                                <Div sx={{ display: "flex", alignItems: "center",justifyContent:"center", width: "100%" }}>
                                                    <Typography sx={{textAlign:"center" }}>
                                                        Pending
                                                    </Typography>
                                                    <NewReleasesIcon style={{ color: "orange", fontSize: "20px" }} />
                                                </Div>
                                                } */}

                                            <Switch
                                                disabled={!proposed_changes?.status}
                                                size='small'
                                                checked={branch?.kyc?.kyc_status}
                                                onChange={(e) => {
                                                    const { checked } = e.target;
                                                    handleActiveAndKycStatus({
                                                        companyId: branch?.[`${companyType}Id`]?._id,
                                                        branchId: _id,
                                                        body: {
                                                            kyc: {
                                                                kyc_status: checked
                                                            }
                                                        }
                                                    });
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />

                                        </TableCell>

                                        <TableCell align="center" sx={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                        }}>
                                            <Switch
                                                disabled={!proposed_changes?.status || _id === primaryBranch}
                                                size='small'
                                                checked={branch?.isActive}
                                                onChange={(e) => {
                                                    const { checked } = e.target;
                                                    handleActiveAndKycStatus({
                                                        companyId: branch?.[`${companyType}Id`]?._id,
                                                        branchId: _id,
                                                        body: {
                                                            isActive: checked
                                                        }
                                                    });
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            verticalAlign: "middle",
                                        }}>{contact_details?.primary_email || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            verticalAlign: "middle",
                                        }}>{contact_details?.primary_mobile || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.branch_address?.city || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.branch_address?.state || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.branch_address?.district || "NA"}</TableCell>

                                        <TableCell align="left" sx={{
                                            textAlign: "left",
                                            verticalAlign: "middle",
                                            color: _id === primaryBranch ? "rgb(243, 151, 17)" : "black",
                                            textTransform: "capitalize"
                                        }}>{branch?.branch_address?.taluka || "NA"}</TableCell>


                                        <TableCell align="center" sx={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                            position: "sticky",
                                            right: 0,
                                            zIndex: 1, // Ensures
                                            bgcolor: "white",
                                        }}>
                                            <JumboDdMenu
                                                icon={<MoreHorizIcon />}
                                                menuItems={[
                                                    {
                                                        icon: <EditIcon />,
                                                        title: "Edit",
                                                        action: "edit",
                                                        branchId: _id,
                                                        branchData: branch,
                                                        visibility: proposed_changes?.status
                                                    },
                                                    {
                                                        icon: <StarsIcon sx={{ color: "rgb(243, 151, 17)" }} />,
                                                        title: "Set Primary",
                                                        action: "primaryBranch",
                                                        branchId: _id,
                                                        branchData: branch,
                                                        visibility: proposed_changes?.status && _id !== primaryBranch
                                                    },
                                                    {
                                                        icon: <VisibilityRoundedIcon />,
                                                        title: "View",
                                                        action: "view",
                                                        branchId: _id,
                                                        branchData: branch,
                                                        visibility: true
                                                    },
                                                    // {
                                                    //     icon: <ManageAccountsIcon />,
                                                    //     title: "Manage Market Executive",
                                                    //     action: "manageMarketExecutive",
                                                    //     companyId:branch?.[`${companyType}Id`],
                                                    //     visibility: companyType === ("retailer" || "offlineStore")
                                                    // },
                                                ].filter((e) => e?.visibility)}
                                                onClickCallback={handleItemAction}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
                <Pagination
                    count={companyBranchList?.TotalPage || 0}
                    page={page}
                    onChange={handleChangePage}
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        p: "1%",
                        backgroundColor: "white",
                        borderTop: "1px solid #ddd",
                    }}
                />
            </TableContainer>
            {openModel && <ViewModel open={openModel} setOpen={setOpenModel} branchData={branchData} companyType={companyType} />}
        </>
    )
}

export default React.memo(ListCompany)