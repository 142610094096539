import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { formatDate } from "app/pages/Component/Function/formatDate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewPOModel({ open, setOpen, selectedRow }) {
  const handleClose = () => setOpen(false);
  const data = selectedRow?.current_data;
  const sameState =
    data?.supplier_details.address.state &&
    data?.supplier_details.address.state ==
      data?.ssk_details?.bill_to?.address.state;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ ml: 2, mt: 1.5, flex: 1, color: "white" }}
            variant="h4"
            component="div"
          >
            Purchase Order Details
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ p: 5, width: "100%" }}>
        <Typography
          variant="h2"
          sx={{ fontWeight: 600, textTransform: "capitalize" }}
        >
          {data?.supplier_details?.company_name}
        </Typography>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.supplier_details?.first_name} ${data?.supplier_details?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.supplier_details?.primary_mobile_no} / ${
                data?.supplier_details?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.supplier_details?.primary_email_id}
            </Typography>
            <Typography sx={{fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.supplier_details?.primary_email_id || "NA"}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GSTNo.</span> :{" "}
              {data?.supplier_details?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.supplier_details?.address?.address}, 
                            ${data?.supplier_details?.address?.area},
                            ${data?.supplier_details?.address?.location},
                            ${data?.supplier_details?.address?.district},
                            ${data?.supplier_details?.address?.taluka},
                            ${data?.supplier_details?.address?.city},
                            ${data?.supplier_details?.address?.state},
                            ${data?.supplier_details?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Purchase Order No.</span> :{" "}
              {data?.purchase_order_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Purchase Order Date</span> :{" "}
              {formatDate(data?.purchase_order_date)}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Est. Delivery Date</span> :{" "}
              {formatDate(data?.estimate_delivery_date)}
            </Typography>
          </Div>
        </Div>

        {/*  */}
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 5,
          }}
        >
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Bill To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.ssk_details?.bill_to?.company_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.ssk_details?.bill_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.ssk_details?.bill_to?.first_name} ${data?.ssk_details?.bill_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.ssk_details?.bill_to?.primary_mobile_no} / ${
                data?.ssk_details?.bill_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{  fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.ssk_details?.bill_to?.primary_email_id}
            </Typography>
            <Typography sx={{  fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.ssk_details?.bill_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.ssk_details?.bill_to?.address?.address}, 
                            ${data?.ssk_details?.bill_to?.address?.area},
                            ${data?.ssk_details?.bill_to?.address?.location},
                            ${data?.ssk_details?.bill_to?.address?.district},
                            ${data?.ssk_details?.bill_to?.address?.taluka},
                            ${data?.ssk_details?.bill_to?.address?.city},
                            ${data?.ssk_details?.bill_to?.address?.state},
                            ${data?.ssk_details?.bill_to?.address?.pincode}`}
            </Typography>
          </Div>
          <Div sx={{ width: "30%" }}>
            <Typography
              variant="h2"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
            >
              Ship To
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Branch Name</span> :{" "}
              {data?.ssk_details?.ship_to?.company_name}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>GST No.</span> :{" "}
              {data?.ssk_details?.ship_to?.gst_no}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>First Name/Last Name</span> :{" "}
              {`${data?.ssk_details?.ship_to?.first_name} ${data?.ssk_details?.ship_to?.last_name}`}
            </Typography>
            <Typography sx={{ textTransform: "capitalize", fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>
                Primary Contact No. /Secondary Contact No.
              </span>{" "}
              :{" "}
              {`${data?.ssk_details?.ship_to?.primary_mobile_no} / ${
                data?.ssk_details?.ship_to?.secondary_mobile_no || "NA"
              }`}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Primary Email ID</span> :{" "}
              {data?.ssk_details?.ship_to?.primary_email_id}
            </Typography>
            <Typography sx={{ fontSize: "15px" }}>
              <span style={{ fontWeight: 700 }}>Secondary Email ID</span> :{" "}
              {data?.ssk_details?.ship_to?.primary_email_id || "NA"}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "15px",
                maxWidth: "600px",
              }}
            >
              <span style={{ fontWeight: 700 }}>Address</span> :{" "}
              {`
                            ${data?.ssk_details?.ship_to?.address?.address}, 
                            ${data?.ssk_details?.ship_to?.address?.area},
                            ${data?.ssk_details?.ship_to?.address?.location},
                            ${data?.ssk_details?.ship_to?.address?.district},
                            ${data?.ssk_details?.ship_to?.address?.taluka},
                            ${data?.ssk_details?.ship_to?.address?.city},
                            ${data?.ssk_details?.ship_to?.address?.state},
                            ${data?.ssk_details?.ship_to?.address?.pincode}`}
            </Typography>
          </Div>
        </Div>

        {/* product table */}
        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table style={{ border: "2px solid #7352C7" }}>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "120px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  HSN Code
                </TableCell>
                {sameState ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      CGST
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "150px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      SGST
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    IGST
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Rate
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Weight
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Item Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  GST Amount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.Items?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.item_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.category}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.sku}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.hsn_code}
                  </TableCell>
                  {sameState ? (
                    <>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.cgst}%
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {row?.gst?.sgst}%
                      </TableCell>
                    </>
                  ) : (
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {row?.gst?.igst}%
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.rate_per_unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.unit}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.weight}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {row?.quantity}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.item_amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹{" "}
                    {sameState
                      ? row.gst.cgst / 100 + row.gst.cgst / 100
                      : row.gst.igst / 100}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    ₹ {row?.total_amount}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ borderTop: "2px solid #7352C7" }}>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                  colSpan={sameState ? 8 : 7}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_weight}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {data?.total_quantity} qty
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  ₹ {data?.total_item_amount}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {data?.total_gst / 100}
                </TableCell>
                <TableCell
                  sx={{ textAlign: "left", fontSize: "15px", fontWeight: 600 }}
                >
                  {" "}
                  ₹ {data?.total_amount.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </List>
    </Dialog>
  );
}
