import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoCompletewithDialog from "app/components/Autocomplete";
import { Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Heading from "app/pages/Component/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList } from "app/redux/actions/Roles";
import { getUsers } from "app/redux/actions/User";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import * as yup from "yup";
const AddEditUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  console.log(state);
  // useSelector
  const { allroles } = useSelector((state) => state.roleReducer);
  const { user } = useSelector((state) => state?.userReducer);
  //  useState
  const [isSubmitting, setSubmitting] = useState(false);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New User" : "Edit User"
  );
  const [imageURL, setImageURL] = useState({
    profile_pic: "",
    aadhar_image: "",
    pan_image: "",
    passbook_image: "",
  });
  const handleImagesTab = async (value) => {
    if (value && typeof value === "string") {
      const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
      window.open(imageURL.href, "_blank");
    } else {
      const images = URL.createObjectURL(value);
      window.open(images, "__blank");
    }
  };
  const InitialValues = {
    employee_id: state?.current_data?.employee_id
      ? state?.current_data?.employee_id
      : "",
    first_name: state?.current_data?.first_name
      ? state?.current_data?.first_name
      : "",
    last_name: state?.current_data?.last_name
      ? state?.current_data?.last_name
      : "",
    primary_email_id: state?.current_data?.primary_email_id
      ? state?.current_data?.primary_email_id
      : "",
    secondary_email_id: state?.current_data?.secondary_email_id
      ? state?.current_data?.secondary_email_id
      : "",
    primary_mobile_no: state?.current_data?.primary_mobile_no
      ? state?.current_data?.primary_mobile_no
      : "",
    secondary_mobile_no: state?.current_data?.secondary_mobile_no
      ? state?.current_data?.secondary_mobile_no
      : "",
    profile_pic: state?.current_data?.profile_pic
      ? state?.current_data?.profile_pic
      : "",
    role_id: state?.current_data?.role_id?._id
      ? state?.current_data?.role_id?._id
      : "",
    status: state?.current_data?.status ? state?.current_data?.status : "",
    isActive: state?.current_data?.isActive
      ? state?.current_data?.isActive
      : "",
    password: state?.current_data?.password
      ? state?.current_data?.password
      : "",
    address: {
      address: state?.current_data?.address.address
        ? state?.current_data?.address.address
        : "",
      location: state?.current_data?.address.location
        ? state?.current_data?.address.location
        : "",
      area: state?.current_data?.address.area
        ? state?.current_data?.address.area
        : "",
      district: state?.current_data?.address.district
        ? state?.current_data?.address.district
        : "",
      taluka: state?.current_data?.address.taluka
        ? state?.current_data?.address.taluka
        : "",
      state: state?.current_data?.address.state
        ? state?.current_data?.address?.state
        : "",
      city: state?.current_data?.address?.city
        ? state?.current_data?.address?.city
        : "",
      country: state?.current_data?.address?.country
        ? state?.current_data?.address?.country
        : "",
      pincode: state?.current_data?.address?.pincode
        ? state?.current_data?.address?.pincode
        : "",
    },
    kyc: {
      pan_card_detail: {
        pan_no: state?.current_data?.kyc?.pan_card_detail.pan_no
          ? state?.current_data?.kyc?.pan_card_detail.pan_no
          : "",
        pan_image: state?.current_data?.kyc?.pan_card_detail.pan_image
          ? state?.current_data?.kyc?.pan_card_detail.pan_image
          : "",
      },
      aadhar_card_detail: {
        aadhar_no: state?.current_data?.kyc?.aadhar_card_detail?.aadhar_no
          ? state?.current_data?.kyc?.aadhar_card_detail?.aadhar_no
          : "",
        aadhar_image: state?.current_data?.kyc?.aadhar_card_detail?.aadhar_image
          ? state?.current_data?.kyc?.aadhar_card_detail?.aadhar_image
          : "",
      },
      bank_details: {
        bank_name: state?.current_data?.kyc?.bank_details?.bank_name
          ? state?.current_data?.kyc?.bank_details?.bank_name
          : "",
        account_no: state?.current_data?.kyc?.bank_details?.account_no
          ? state?.current_data?.kyc?.bank_details?.account_no
          : "",
        confirm_account_no: state?.current_data?.kyc?.bank_details
          ?.confirm_account_no
          ? state?.current_data?.kyc?.bank_details?.confirm_account_no
          : "",
        ifsc_code: state?.current_data?.kyc?.bank_details?.ifsc_code
          ? state?.current_data?.kyc?.bank_details?.ifsc_code
          : "",
        passbook_image: state?.current_data?.kyc?.bank_details?.passbook_image
          ? state?.current_data?.kyc?.bank_details?.passbook_image
          : "",
      },
    },
    approvers: {
      approver1: {
        name: state?.current_data?.approver_one?.name
          ? state?.current_data?.approver_one?.name
          : "",
        email_id: state?.current_data?.approver_one?.email_id
          ? state?.current_data?.approver_one?.email_id
          : "",
        user_id: state?.current_data?.approver_one?.user_id
          ? state?.current_data?.approver_one?.user_id
          : "",
        employee_id: state?.current_data?.approver_one?.employee_id
          ? state?.current_data?.approver_one?.employee_id
          : "",
      },
      approver2: {
        name: state?.current_data?.approver_two?.name
          ? state?.current_data?.approver_two?.name
          : "",
        email_id: state?.current_data?.approver_two?.email_id
          ? state?.current_data?.approver_two?.email_id
          : "",
        user_id: state?.current_data?.approver_two?.user_id
          ? state?.current_data?.approver_two?.user_id
          : "",
        employee_id: state?.current_data?.approver_two?.employee_id
          ? state?.current_data?.approver_one?.employee_id
          : "",
      },
    },
    created_by: {
      user_id: "",
      name: "",
      email_id: "",
      employee_id: "",
    },
  };
  const handleSubmit = async (values) => {
    let formdata = new FormData();
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        formdata.append("employee_id", values.employee_id);
        formdata.append("first_name", values.first_name);
        formdata.append("last_name", values.last_name);
        formdata.append("primary_email_id", values.primary_email_id);
        formdata.append("secondary_email_id", values.secondary_email_id);
        formdata.append("password", values.password);
        formdata.append("primary_mobile_no", values.primary_mobile_no);
        formdata.append("secondary_mobile_no", values.secondary_mobile_no);
        formdata.append("profile_pic", values.profile_pic);
        formdata.append("role_id", values.role_id);
        formdata.append(
          "aadhar_image",
          values.kyc.aadhar_card_detail.aadhar_image
        );
        formdata.append("pan_image", values.kyc.pan_card_detail.pan_image);
        formdata.append(
          "passbook_image",
          values.kyc.bank_details.passbook_image
        );
        formdata.append("address", JSON.stringify(values.address));
        formdata.append("kyc", JSON.stringify(values.kyc));
        formdata.append(
          "approver_one",
          JSON.stringify(values?.approvers?.approver1)
        );
        if (values?.approvers?.approver2?.name != "") {
          formdata.append(
            "approver_two",
            JSON.stringify(values?.approvers?.approver2)
          );
        }
        response = await Axios.post(AllApis.users.add, formdata);
      } else {
        // Assuming you have a valid 'state' object
        // console.log(values,"sdfsdfsfsfsf")
        // const updatedFields = getUpdatedFields(state.current_data, values);
        // console.log(updatedFields);
        // Object.entries(updatedFields).map((item) =>
        //   formdata.append(item[0], item[1])
        // );
        formdata.append("employee_id", values.employee_id);
        formdata.append("first_name", values.first_name);
        formdata.append("last_name", values.last_name);
        formdata.append("primary_email_id", values.primary_email_id);
        formdata.append("secondary_email_id", values.secondary_email_id);
        formdata.append("password", values.password);
        formdata.append("primary_mobile_no", values.primary_mobile_no);
        formdata.append("secondary_mobile_no", values.secondary_mobile_no);
        formdata.append("profile_pic", values.profile_pic);
        formdata.append("role_id", values.role_id);
        formdata.append(
          "aadhar_image",
          values.kyc.aadhar_card_detail.aadhar_image
        );
        formdata.append("pan_image", values.kyc.pan_card_detail.pan_image);
        formdata.append(
          "passbook_image",
          values.kyc.bank_details.passbook_image
        );
        formdata.append("address", JSON.stringify(values.address));
        formdata.append("kyc", JSON.stringify(values.kyc));
        formdata.append(
          "approver_one",
          JSON.stringify(values?.approvers?.approver1)
        );
        if (values?.approvers?.approver2?.name != "") {
          formdata.append(
            "approver_two",
            JSON.stringify(values?.approvers?.approver2)
          );
        }
        console.log(formdata);
        response = await Axios.patch(AllApis.users.edit(state._id), formdata);
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New User Added"
              : "User Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/users");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object().shape({
    employee_id: yup
      .string("Enter your Employee ID")
      .required("Employee ID is required"),
    first_name: yup.string().required("Enter First Name"),
    last_name: yup.string().required("Enter Last Name"),
    primary_email_id: yup
      .string()
      .email("Enter a valid email address")
      .required("Enter Primary EmailId"),
    secondary_email_id: yup.string().email("Enter a valid email address"),
    primary_mobile_no: yup
      .number()
      .typeError("Primary Mobile Number must be a number")
      .required("Enter Primary Mobile Number"),
    secondary_mobile_no: yup
      .number()
      .typeError("Secondary Mobile Number must be a number"),
    role_id: yup.string("Enter your Role ID").required("Role ID is required"),
    password: yup.string().required("Enter password"),
    address: yup.object().shape({
      address: yup.string().required("Enter Address"),
      location: yup.string().required("Enter Location"),
      area: yup.string().required("Enter Area"),
      district: yup.string().required("Enter District"),
      taluka: yup.string().required("Enter Taluka"),
      state: yup.string().required("Enter State"),
      city: yup.string().required("Enter City"),
      country: yup.string().required("Enter Country"),
      pincode: yup.string().required("Enter Pincode"),
    }),
    kyc: yup.object().shape({
      pan_card_detail: yup.object().shape({
        pan_no: yup.string().required("Enter PAN Number"),
      }),
      aadhar_card_detail: yup.object().shape({
        aadhar_no: yup.string().required("Enter Aadhar Number"),
      }),
      bank_details: yup.object().shape({
        bank_name: yup.string().required("Enter Bank Name"),
        account_no: yup.string().required("Enter Account Number"),
        confirm_account_no: yup
          .string()
          .oneOf([yup.ref("account_no"), null], "Account Numbers must match"),
        ifsc_code: yup.string().required("Enter IFSC Code"),
      }),
    }),
  });

  // console.log(user)
  // useEffect
  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getUsers());
  }, []);

  return (
    <>
      <Div sx={{ mt: "-4px" }}>
        <Heading Name={headingName} />
        <Box sx={{ marginTop: "2%" }}>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              handleSubmit,
              touched,
              errors,
            }) => {
              return (
                <>
                  <Form>
                    <Div>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "2% 0",
                        }}
                      >
                        <TextField
                          name="employee_id"
                          type="text"
                          value={values.employee_id}
                          label="Employee ID"
                          error={errors?.employee_id && touched?.employee_id}
                          helperText={
                            errors?.employee_id && touched?.employee_id
                              ? errors?.employee_id
                              : ""
                          }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                        <TextField
                          name="first_name"
                          type="text"
                          value={values.first_name}
                          label="First Name"
                          size="small"
                          sx={{ width: "25%" }}
                          required="true"
                          error={errors?.first_name && touched?.first_name}
                          helperText={
                            errors?.first_name && touched?.first_name
                              ? errors?.first_name
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="last_name"
                          type="text"
                          value={values.last_name}
                          label="Last Name"
                          required="true"
                          size="small"
                          sx={{ width: "25%" }}
                          error={errors?.last_name && touched?.last_name}
                          helperText={
                            errors?.last_name && touched?.last_name
                              ? errors?.last_name
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="primary_email_id"
                          type="email"
                          value={values.primary_email_id}
                          label="Primary Email"
                          required="true"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.primary_email_id &&
                            touched?.primary_email_id
                          }
                          helperText={
                            errors?.primary_email_id &&
                            touched?.primary_email_id
                              ? errors?.primary_email_id
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="secondary_email_id"
                          type="email"
                          value={values.secondary_email_id}
                          label="Secondary Email"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.secondary_email_id &&
                            touched?.secondary_email_id
                          }
                          helperText={
                            errors?.secondary_email_id &&
                            touched?.secondary_email_id
                              ? errors?.secondary_email_id
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="primary_mobile_no"
                          type="Number"
                          value={values.primary_mobile_no}
                          label="Primary Mobile"
                          size="small"
                          required="true"
                          sx={{ width: "25%" }}
                          error={
                            errors?.primary_mobile_no &&
                            touched?.primary_mobile_no
                          }
                          helperText={
                            errors?.primary_mobile_no &&
                            touched?.primary_mobile_no
                              ? errors?.primary_mobile_no
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="secondary_mobile_no"
                          type="Number"
                          value={values.secondary_mobile_no}
                          label="Secondary Mobile"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.secondary_mobile_no &&
                            touched?.secondary_mobile_no
                          }
                          helperText={
                            errors?.secondary_mobile_no &&
                            touched?.secondary_mobile_no
                              ? errors?.secondary_mobile_no
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="profile_pic"
                          type="file"
                          size="small"
                          sx={{ width: "20%" }}
                          // value={values.profile_pic}
                          label="Profile Pic"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onChange={(e) => handleFileChange(e, "profile_pic")}
                          onChange={(e) =>
                            setFieldValue("profile_pic", e.target.files[0])
                          }
                          variant="outlined"
                        />
                        {values?.profile_pic && (
                          <Button
                            sx={{ "&:hover": { bgcolor: "transparent" } }}
                            onClick={() => {
                              handleImagesTab(values?.profile_pic);
                            }}
                          >
                            <PermMediaIcon sx={{ height: "100%" }} />
                          </Button>
                        )}
                        {!state?.current_data?.password && (
                          <TextField
                            name="password"
                            type="text"
                            value={values.password}
                            label="Password"
                            size="small"
                            required="true"
                            sx={{ width: "25%" }}
                            error={errors?.password && touched?.password}
                            helperText={
                              errors?.password && touched?.password
                                ? errors?.password
                                : ""
                            }
                            onChange={handleChange}
                          />
                        )}

                        <Autocomplete
                          // disablePortal
                          id="combo-box-demo"
                          options={allroles}
                          size="small"
                          sx={{ width: "25%" }}
                          error={errors?.role_name && touched?.role_name}
                          helperText={
                            errors?.role_name && touched?.role_name
                              ? errors?.role_name
                              : ""
                          }
                          value={
                            allroles?.filter(
                              (item) => item._id == values?.role_id
                            )?.[0] || null
                          }
                          getOptionLabel={(option) => option?.role_name}
                          // isOptionEqualToValue={(option, value) => option._id === value._id}
                          onChange={(e, newValue) => {
                            setFieldValue("role_id", newValue?._id);
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option?.role_name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Roles" />
                          )}
                        />
                        {/* <FormControlLabel
                          control={<Switch onChange={handleChange} />}
                          value={values.isActive}
                          name="isActive"
                          label="Status"
                        /> */}
                      </Div>
                      {/* Bank Details */}

                      {/* Address */}
                      <Typography variant="h1">Address Details</Typography>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "1% 0",
                        }}
                      >
                        <TextField
                          name="address.address"
                          type="text"
                          value={values.address.address}
                          label="Address"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.address &&
                            touched?.address?.address
                          }
                          helperText={
                            errors?.address?.address &&
                            touched?.address?.address
                              ? errors?.address?.address
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.location"
                          type="text"
                          value={values.address.location}
                          label="Location"
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                          error={
                            errors?.address?.location &&
                            touched?.address?.location
                          }
                          helperText={
                            errors?.address?.location &&
                            touched?.address?.location
                              ? errors?.address?.location
                              : ""
                          }
                        />
                        {console.log(values)}
                        <TextField
                          name="address.area"
                          type="text"
                          value={values.address.area}
                          label="Area"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.area && touched?.address?.area
                          }
                          helperText={
                            errors?.address?.area && touched?.address?.area
                              ? errors?.address?.area
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.district"
                          type="text"
                          value={values.address.district}
                          label="District"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.district &&
                            touched?.address?.district
                          }
                          helperText={
                            errors?.address?.district &&
                            touched?.address?.district
                              ? errors?.address?.district
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.taluka"
                          type="text"
                          value={values.address.taluka}
                          label="Taluka"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.taluka && touched?.address?.taluka
                          }
                          helperText={
                            errors?.address?.taluka && touched?.address?.taluka
                              ? errors?.address?.taluka
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.state"
                          type="text"
                          value={values.address.state}
                          label="State"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.state && touched?.address?.state
                          }
                          helperText={
                            errors?.address?.state && touched?.address?.state
                              ? errors?.address?.state
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.city"
                          type="text"
                          value={values.address.city}
                          label="City"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.city && touched?.address?.city
                          }
                          helperText={
                            errors?.address?.city && touched?.address?.city
                              ? errors?.address?.city
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.country"
                          type="text"
                          value={values.address.country}
                          label="Country"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.country &&
                            touched?.address?.country
                          }
                          helperText={
                            errors?.address?.country &&
                            touched?.address?.country
                              ? errors?.address?.country
                              : ""
                          }
                          onChange={handleChange}
                        />
                        <TextField
                          name="address.pincode"
                          type="text"
                          value={values.address.pincode}
                          label="Pincode"
                          size="small"
                          sx={{ width: "25%" }}
                          error={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                          }
                          helperText={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                              ? errors?.address?.pincode
                              : ""
                          }
                          onChange={handleChange}
                        />
                        {/* <AutoCompletewithDialog
                          data={["test", "test1", "test2"]}
                          type={"city"}
                        /> */}
                      </Div>
                      <Div>
                        <Typography variant="h1">Bank Details</Typography>
                        <Div
                          sx={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            columnGap: 4,
                            gap: 5,
                            margin: "1% 0",
                          }}
                        >
                          <TextField
                            name="kyc.bank_details.bank_name"
                            type="text"
                            value={values.kyc.bank_details.bank_name}
                            label="Bank Name"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.bank_details?.bank_name &&
                              touched?.kyc?.bank_details?.bank_name
                            }
                            helperText={
                              errors?.kyc?.bank_details?.bank_name &&
                              touched?.kyc?.bank_details?.bank_name
                                ? errors?.kyc?.bank_details?.bank_name
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="kyc.bank_details.account_no"
                            type="number"
                            value={values.kyc.bank_details.account_no}
                            label="Account Number"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.bank_details?.account_no &&
                              touched?.kyc?.bank_details?.account_no
                            }
                            helperText={
                              errors?.kyc?.bank_details?.account_no &&
                              touched?.kyc?.bank_details?.account_no
                                ? errors?.kyc?.bank_details?.account_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="kyc.bank_details.confirm_account_no"
                            type="number"
                            value={values.kyc.bank_details.confirm_account_no}
                            label="Confirm Account Number"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.bank_details?.confirm_account_no &&
                              touched?.kyc?.bank_details?.confirm_account_no
                            }
                            helperText={
                              errors?.kyc?.bank_details?.confirm_account_no &&
                              touched?.kyc?.bank_details?.confirm_account_no
                                ? errors?.kyc?.bank_details?.confirm_account_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="kyc.bank_details.ifsc_code"
                            type="text"
                            value={values.kyc.bank_details.ifsc_code}
                            label="IFSC"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.bank_details?.ifsc_code &&
                              touched?.kyc?.bank_details?.ifsc_code
                            }
                            helperText={
                              errors?.kyc?.bank_details?.ifsc_code &&
                              touched?.kyc?.bank_details?.ifsc_code
                                ? errors?.kyc?.bank_details?.ifsc_code
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="kyc.bank_details.passbook_image"
                            type="file"
                            // value={values.kyc.bank_details.passbook_image}
                            size="small"
                            sx={{ width: "25%" }}
                            label="Passbook Image"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              errors?.kyc?.bank_details?.passbook_image &&
                              touched?.kyc?.bank_details?.passbook_image
                            }
                            helperText={
                              errors?.kyc?.bank_details?.passbook_image &&
                              touched?.kyc?.bank_details?.passbook_image
                                ? errors?.kyc?.bank_details?.passbook_image
                                : ""
                            }
                            onChange={(e) =>
                              setFieldValue(
                                "kyc.bank_details.passbook_image",
                                e.target.files[0]
                              )
                            }
                          />
                          {values?.kyc?.bank_details?.passbook_image && (
                            <Button
                              sx={{ "&:hover": { bgcolor: "transparent" } }}
                              onClick={() => {
                                handleImagesTab(
                                  values?.kyc?.bank_details?.passbook_image
                                );
                              }}
                            >
                              <PermMediaIcon sx={{ height: "100%" }} />
                            </Button>
                          )}
                        </Div>
                        <Typography variant="h1">KYC Details</Typography>
                        <Div
                          sx={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            columnGap: 4,
                            gap: 5,
                            margin: "1% 0",
                          }}
                        >
                          <TextField
                            name="kyc.pan_card_detail.pan_no"
                            type="text"
                            value={values.kyc.pan_card_detail.pan_no}
                            label="Pan Number"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.pan_card_detail?.pan_no &&
                              touched?.kyc?.pan_card_detail?.pan_no
                            }
                            helperText={
                              errors?.kyc?.pan_card_detail?.pan_no &&
                              touched?.kyc?.pan_card_detail?.pan_no
                                ? errors?.kyc?.pan_card_detail?.pan_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="kyc.pan_card_detail.pan_image"
                            type="file"
                            // value={values.kyc.pan_card_detail.pan_image}
                            size="small"
                            sx={{ width: "25%" }}
                            label="Pan Card Image"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              setFieldValue(
                                "kyc.pan_card_detail.pan_image",
                                e.target.files[0]
                              )
                            }
                          />
                          {values?.kyc?.pan_card_detail?.pan_image && (
                            <Button
                              sx={{
                                "&:hover": { bgcolor: "transparent" },
                                width: "20%",
                              }}
                              onClick={() => {
                                handleImagesTab(
                                  values?.kyc?.pan_card_detail?.pan_image
                                );
                              }}
                            >
                              <PermMediaIcon sx={{ height: "100%" }} />
                            </Button>
                          )}
                          <TextField
                            name="kyc.aadhar_card_detail.aadhar_no"
                            type="number"
                            value={values.kyc.aadhar_card_detail.aadhar_no}
                            label="Aadhar Number"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.kyc?.aadhar_card_detail?.aadhar_no &&
                              touched?.kyc?.aadhar_card_detail?.aadhar_no
                            }
                            helperText={
                              errors?.kyc?.aadhar_card_detail?.aadhar_no &&
                              touched?.kyc?.aadhar_card_detail?.aadhar_no
                                ? errors?.kyc?.aadhar_card_detail?.aadhar_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                          {/* <FormControlLabel label="Aadhar Image" /> */}
                          <TextField
                            name="kyc.aadhar_card_detail.aadhar_image"
                            type="file"
                            // value={values.kyc.aadhar_card_detail.aadhar_image}
                            size="small"
                            sx={{ width: "25%" }}
                            label="Aadhar Card Image"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              setFieldValue(
                                "kyc.aadhar_card_detail.aadhar_image",
                                e.target.files[0]
                              )
                            }
                          />
                          {values?.kyc?.aadhar_card_detail?.aadhar_image && (
                            <Button
                              sx={{
                                "&:hover": { bgcolor: "transparent" },
                                width: "20%",
                              }}
                              onClick={() => {
                                handleImagesTab(
                                  values?.kyc?.aadhar_card_detail?.aadhar_image
                                );
                              }}
                            >
                              <PermMediaIcon sx={{ height: "100%" }} />
                            </Button>
                          )}
                        </Div>
                      </Div>
                      <Typography variant="h1">Approvers</Typography>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "1% 0",
                        }}
                      >
                        <Autocomplete
                          // disablePortal
                          id="combo-box-demo"
                          options={user}
                          sx={{ width: 300 }}
                          value={
                            user?.filter(
                              (item) =>
                                item?._id ==
                                values?.approvers?.approver1?.user_id
                            )?.[0]?.current_data?.first_name || null
                          }
                          onChange={(e, newValue) => {
                            setFieldValue("approvers.approver1", {
                              name: newValue?.current_data?.first_name,
                              email_id:
                                newValue?.current_data?.primary_email_id,
                              user_id: newValue?._id,
                              employee_id: newValue?.current_data?.employee_id,
                            });
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option?.current_data?.first_name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Approver 1" />
                          )}
                        />

                        <Autocomplete
                          // disablePortal
                          id="combo-box-demo"
                          options={user}
                          sx={{ width: 300 }}
                          value={
                            user?.filter(
                              (item) =>
                                item?._id ==
                                values?.approvers?.approver2?.user_id
                            )?.[0]?.current_data?.first_name || null
                          }
                          onChange={(e, newValue) => {
                            setFieldValue("approvers.approver2", {
                              name: newValue?.current_data?.first_name,
                              email_id:
                                newValue?.current_data?.primary_email_id,
                              user_id: newValue?._id,
                              employee_id: newValue?.current_data?.employee_id,
                            });
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option?.current_data?.first_name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Approver 2" />
                          )}
                        />
                      </Div>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "1% 0",
                        }}
                      >
                        <Button
                          sx={{ width: "100px" }}
                          variant="contained"
                          onClick={() => navigate("/dashboards/users")}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Div>
                    </Div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Div>
    </>
  );
};

export default AddEditUsers;
