import Div from "@jumbo/shared/Div";
import * as React from "react";
import UnstyledSelectGrouping from "./Dropdown";
import Heading from "../Component/Heading";
import { Avatar, Box, Button, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextareaAutosize, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate } from "../Component/Function/formatDate";
import ApprovalListingUi from "./ListingUi";
import CustomDialogBox from "app/components/DialogBox";
import { Field, Form, Formik } from "formik";
import styled from "@emotion/styled";
import * as yup from "yup";


const sideBars = [
  {
    name: "User"
  },
  {
    name: "Roles & Permissions"
  },
  {
    name: "Master",
    nested: true,
    nestedData: [
      {
        name: "Category"
      },
      {
        name: "Unit"
      },
      {
        name: "Gst"
      },
      {
        name: "Hsn code"
      },
      {
        name: "Products"
      },
      {
        name: "Tds"
      },
      {
        name: "Payment terms days"
      },
      {
        name: "Suppliers"
      },
      {
        name: "Retailers"
      },
      {
        name: "Market Exectives"
      },
      {
        name: "Offline Store"
      },
    ]
  },
  {
    name: "Purchase Order"
  },
  {
    name: "Orders"
  },
  {
    name: "Sales Order"
  },
  {
    name: "Dispatch"
  },
]

const tableCellSx = {
  textTransform: "capitalize",
  color: "white",
  textAlign: "left",
  verticalAlign: "middle",
}
const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? "#C7D0DD" : "#1C2025"};
    background: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border: 1px solid ${
      theme.palette.mode === "dark" ? "#434D5B" : "#0000003b"
    };
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? "#1C2025" : "#F3F6F9"
    };

    &:hover {
      border-color: ${"black"};
    }

    &:focus {
      border-color: ${"#7352C7"};
      box-shadow: 0px 0px 0px 1px ${
        theme.palette.mode === "dark" ? "#7352C7" : "#7352C7"
      };
      outline: none;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);


export default function Approvals() {
  const [rejectModal, setRejectModal] = React.useState({
    open: false,
    documentId: null
  })

  const handleClose = ()=>{
    setRejectModal({
      open: false,
      documentId: null
    })
  }

  return (
    <Box sx={{ mt: -4 }}>
      <Heading Name={"Approvals"} />
      <Div sx={{ my: 4 }}>
        <UnstyledSelectGrouping data={sideBars} />
      </Div>
      <TableContainer component={Paper} sx={{ my: "2%" }}>
        <Table sx={{ minWidth: 600 }} size="medium" >
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7" }}>
              <TableCell align={"center"} sx={{ ...tableCellSx, minWidth: "120px", textAlign: "center" }}>
                Profile Pic
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx, minWidth: "280px" }}>
                Employee Name & Id
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx, minWidth: "170px" }}>
                Role
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx, minWidth: "150px" }}>
                Document
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx, minWidth: "150px" }}>
                Date
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx, minWidth: "170px" }}>
                View Details
              </TableCell>
              <TableCell align={"center"} sx={{ ...tableCellSx, minWidth: "170px", textAlign: "center" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Array(10).fill(4).map(() => {
                return <TableRow sx={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0.5rem 1.25rem",
                  height: "100px",
                  gap: 5,
                  "& td": { // Corrected selector
                    marginBottom: "10px",
                    color: "black" // Changed mb to marginBottom for consistency
                  }
                }}>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Avatar
                        alt="Abhishek Sharp"
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography sx={{ fontSize: "20px", color: "#7352C7", mb: 0.5 }}>
                        Abhishek vishwakarma
                      </Typography>
                      <Typography sx={{ color: "black" }}>
                        Abhi123456
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography sx={{ fontSize: "16px", mb: 0.5 }}>
                        Sales Person
                      </Typography>
                      {/* <Typography sx={{ color: "black", }}>
                        Role
                      </Typography> */}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography sx={{ fontSize: "17px", mb: 0.5 }}>
                        Name
                      </Typography>
                      <Typography>
                        details
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography sx={{ fontSize: "16px", mb: 0.5 }}>
                        {formatDate(Date.now())}
                      </Typography>
                      <Typography sx={{ color: "black" }}>
                        {formatDate(Date.now()) === formatDate(new Date(1210596282755)) ? "Created" : "Updated"} On
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box >
                      <Button variant="contained" >View Details</Button>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <Box display={"flex"} justifyContent={"space-evenly"}>
                      <IconButton aria-label="Approve" size="large" sx={{ border: "1px solid green" }}>
                        <DoneIcon fontSize="inherit" sx={{ color: "green" }} />
                      </IconButton>
                      <IconButton aria-label="Reject" size="large" sx={{ border: "1px solid red" }} onClick={()=>{
                        setRejectModal({
                          open:true,
                          documentId:1
                        })
                      }}>
                        <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              })
            }
          </TableBody>
        </Table>
        <Pagination
          count={0}
          page={1}
          // onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: "1%",
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>

      <CustomDialogBox
        open={rejectModal?.open && rejectModal?.documentId}
        handleClose={handleClose}
        title={"Reason for rejection"}
        sx={{ width: 700 }}
      >
        <Formik
          initialValues={{
            remark: "",
          }}
          validationSchema={yup.object({
            remark: yup
              .string()
              .required("Remark is required")
              .test(
                "word-count",
                "Remark must be at least 10 words",
                (value) => {
                  if (!value) return false;
                  const wordCount = value.trim().split(/\s+/).length;
                  return wordCount >= 10;
                }
              ),
          })}
          onSubmit={()=>{}}
        >
          {({ values }) => {
            return (
              <Form>
                <Grid2 container spacing={3}>
                  <Grid2 xs={12}>
                    <Field name="remark">
                      {({ field, meta }) => {
                        return (
                          <Box>
                            <Textarea
                              {...field}
                              aria-label="minimum height"
                              minRows={5}
                              placeholder="Remarks *"
                              style={{
                                borderColor:
                                  meta?.error && meta?.touched && "red",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "red",
                                ml: 2,
                                fontSize: " 0.75rem",
                              }}
                            >
                              {meta?.error && meta.touched ? meta?.error : ""}
                            </Typography>
                          </Box>
                        );
                      }}
                    </Field>
                  </Grid2>
                </Grid2>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </CustomDialogBox>
      {/* {
          Array(10).fill(4).map(() => {
            return <ApprovalListingUi/>
          })
      } */}

    </Box >
  );
}
