// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupImportant{
  z-index: 300000;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/OrderProcessing/Orders/ViewCreateSalesOrder/item.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".popupImportant{\n  z-index: 300000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
