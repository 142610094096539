import Div from "@jumbo/shared/Div/Div";
import React, { useEffect } from "react";
import { Typography, Container, Grid, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "app/redux/actions/User";

export default function DashboardHome() {
  return (
    <Div sx={{ mt: -4 }}>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              flexDirection: "column",
            }}
          >
            <Typography variant="h1" sx={{ textAlign: "center" }} gutterBottom>
              SSK BHARAT
            </Typography>
            <Typography variant="h4">
              Welcome to your admin dashboard. Here you can manage and monitor
              your application.
            </Typography>
          </Grid>
          {/* Add more Grid items for additional content or widgets */}
        </Grid>
      </Container>
    </Div>
  );
}
