import {
  ALL_UNIT_FAIL,
  ALL_UNIT_REQUEST,
  ALL_UNIT_SUCCESS,
} from "app/utils/constants/unitsConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allUnit: [],
  TotalPage: null,
};

export const unitReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_UNIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        allUnit: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        allUnit: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
