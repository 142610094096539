import React, { useEffect } from "react";

import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import { addressDropdownAction } from "./redux/actions/AddressDropdown";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const AppRoutes = () => {
  const appRoutes = useJumboRoutes(routes);
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  useEffect(() => {
    if (pathname != "/login" && pathname != "/") {
      Promise.allSettled([
        dispatch(addressDropdownAction("district")),
        dispatch(addressDropdownAction("location")),
        dispatch(addressDropdownAction("area")),
        dispatch(addressDropdownAction("taluka")),
      ]);
    }
  }, [dispatch]);

  return <React.Fragment>{appRoutes}</React.Fragment>;
};
export default AppRoutes;
