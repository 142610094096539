import Page from "@jumbo/shared/Page";
import OfflinePayment from "app/pages/PaymentsAndPayouts/Payments/OfflinePayment";
import UpdateOfflinePayment from "app/pages/PaymentsAndPayouts/Payments/OfflinePayment/UpdateOfflinePayment"
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";


const routesName = "/dashboards/payments";
const modules = "payments";

export const paymentsRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: "/admin/dashboard",
        module: modules
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: (
              <Page component={OfflinePayment} />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/updatePayment`,
            element: (
              <Page component={UpdateOfflinePayment} />
            ),
          },
        ],
      },
      {
        path: `${routesName}/edit`,
        // element: <Page component={EditCloudstratClient} />,
      },
    ],
  },]
