import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import Heading from 'app/components/Heading';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Div from '@jumbo/shared/Div';

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    height:"70vh",
    overflowY:"scroll",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function TextFeild({ label, value, sx }) {
    return <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
        <Typography as="span" sx={{ fontWeight: 600 }}>{label}</Typography> :- {value}
    </Typography>
}

export default function ViewModel({ open, setOpen, branchData,companyType }) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography sx={{my:2}}>
                            <Heading Name={"View Branch Details"} />
                        </Typography>
                        <Box display={"flex"} justifyContent={"space-between"} sx={{ my: 4,textTransform:"capitalize" }}>
                            <Box sx={{ width: "50%" }}>
                                <Typography id="spring-modal-title" variant="h1" component="h1">
                                    {branchData?.branch_name}
                                </Typography>
                                <TextFeild label={`${companyType} Name`} value={branchData?.[`${companyType}Id`]?.current_data?.company_name} />
                                <TextFeild label={'Onboarding Date'} value={new Date(branchData?.branch_onboarding_date).toISOString().split('T')[0]} />
                                <TextFeild label={'GST NO'} value={branchData?.kyc?.gst?.gst_no} />
                                <TextFeild label={'PAN NO'} value={branchData?.[`${companyType}Id`]?.current_data?.pan?.pan_no || "N/A"} />
                            </Box>
                            <Box sx={{ width: "30%" }}>
                                <TextFeild label={"Address"} value={`
                            ${branchData?.branch_address?.address} 
                            ${branchData?.branch_address?.area}
                            ${branchData?.branch_address?.location}
                            ${branchData?.branch_address?.district}
                            ${branchData?.branch_address?.taluka}
                            ${branchData?.branch_address?.city}
                            ${branchData?.branch_address?.state}
                            ${branchData?.branch_address?.pincode}`} />
                            </Box>
                        </Box>
                        <Box sx={{ my: 4 }}>
                            <Typography id="spring-modal-title" variant="h1" component="h2" sx={{ my: 2 }}>
                                Bank Details
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" >
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                                            {["Bank Name", "Account No", "Confirm Account No", "IFSC Code"].map((ele, i) => {
                                                return (
                                                    <>
                                                        <TableCell align='left'
                                                            sx={{
                                                                textTransform: "capitalize",
                                                                color: "white",
                                                                textAlign: "left",
                                                                minWidth: "150px",
                                                                verticalAlign: "middle",
                                                            }}
                                                        >{ele}</TableCell>
                                                    </>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left">{branchData?.kyc?.bank_details?.bank_name || "NA"}</TableCell>
                                            <TableCell align="left">{branchData?.kyc?.bank_details?.account_no || "NA"}</TableCell>
                                            <TableCell align="left">{branchData?.kyc?.bank_details?.confirm_account_no || "NA"}</TableCell>
                                            <TableCell align="left">{branchData?.kyc?.bank_details?.ifsc_code || "NA"}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box sx={{ my: 4 }}>
                            <Typography id="spring-modal-title" variant="h1" component="h2" sx={{ my: 2 }}>
                                Contact Person Details
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="medium" >
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                                            {["First Name", "Last Name", "Role", "Primary Email Id", "Secondary Email Id", "Primary Mobile No", "Secondary Mobile No"].map((ele, i) => {
                                                return (
                                                    <>
                                                        <TableCell align='left'
                                                            sx={{
                                                                textTransform: "capitalize",
                                                                color: "white",
                                                                textAlign: "left",
                                                                minWidth: "180px",
                                                                verticalAlign: "middle",
                                                            }}
                                                        >{ele}</TableCell>
                                                    </>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            branchData?.contact_person?.map((ele, i) => {
                                                return (
                                                    <TableRow key={`${i}.${ele.role}`}>
                                                        <TableCell align="left">{ele?.first_name || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.last_name || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.role || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.primary_email || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.secondary_email || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.primary_mobile || "NA"}</TableCell>
                                                        <TableCell align="left">{ele?.secondary_mobile || "NA"}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}