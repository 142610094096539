import React, { Suspense, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from "./redux/store";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import { Toaster } from "react-hot-toast";
import { func } from "prop-types";
import { Axios, socket } from "index";
import Cookies from "js-cookie";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const store = configureStore();


function App() {
  const isAuthenticated = Cookies.get("isAuthenticate");


  useEffect(() => {
    const userID = Cookies.get("user_id");
    const token = Cookies.get("token");
    if (isAuthenticated) {
      socket.emit("activeUser", {
        userID: userID,
        token: token
      });
    }
  }, [isAuthenticated])

  return (
    <QueryClientProvider client={queryClient}>
      {/* <Toaster position="top-center" /> */}
      <Provider store={store}>
        <BrowserRouter history={history}>
          <AppProvider>
            <JumboApp activeLayout={config.defaultLayout}>
              <JumboTheme init={config.theme}>
                <JumboRTL>
                  <JumboDialogProvider>
                    <JumboDialog />
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      maxSnack={3}
                    >
                      <AppLayout>
                        <Suspense
                          fallback={
                            <Div
                              sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                alignContent: "center",
                                height: "100%",
                              }}
                            >
                              <CircularProgress sx={{ m: "-40px auto 0" }} />
                            </Div>
                          }
                        >
                          <AppRoutes />
                        </Suspense>
                      </AppLayout>
                    </SnackbarProvider>
                  </JumboDialogProvider>
                </JumboRTL>
              </JumboTheme>
            </JumboApp>
          </AppProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
