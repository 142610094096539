import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Button,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import Heading from "app/pages/Component/Heading";
import Swal from "sweetalert2";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getAllTicket } from "app/redux/actions/Tickets";
import Loader from "app/components/Loader";
import ReplyTicketModal from "../ChatModal";

export default function ListTicket() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [isChatModalOpen, setChatModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const { allTicket, TotalPage, loading } = useSelector(
    (state) => state.ticketReducer
  );

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllTicket(value, sort, sortBy));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateStatus = async (value, id) => {
    try {
      const response = await Axios.patch(AllApis.ticket.updateStatus(id), {
        ticket_status: value,
      });
      if (response) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllTicket("", sort, sortBy, page));
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    dispatch(getAllTicket(searchTerm, sort, sortBy, page));
  }, [sort, page]);

  useEffect(() => {
    const updatedTickets = allTicket.filter(
      (ticket, index) => index == selectedRowIndex
    );
    setSelectedRow(updatedTickets?.[0] || {});
  }, [allTicket]);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={"Tickets"} />
      {loading && <Loader />}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setSort("desc");
              setSortBy("created_at");
              dispatch(getAllTicket("", "desc", "created_at", 1));
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
      </Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "400px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("complaint")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Complaint
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() =>
                    handleSort("user_id.current_data.company_name")
                  }
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Raised By
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("ticket_status")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allTicket?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.complaint}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.user_id?.current_data?.company_name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  <Select
                    value={row?.ticket_status}
                    size="small"
                    onChange={(e) => updateStatus(e.target.value, row._id)}
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="resolved">Resolved</MenuItem>
                  </Select>
                </TableCell>
                <TableCell sx={{ textAlign: "left", mr: 9 }}>
                  <Button
                    onClick={() => {
                      setChatModalOpen(true);
                      setSelectedRow(row);
                      setSelectedRowIndex(i);
                    }}
                  >
                    Chat
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isChatModalOpen && (
          <ReplyTicketModal
            open={isChatModalOpen}
            selectedRow={selectedRow}
            onClose={() => setChatModalOpen(false)}
          />
        )}
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: 1,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </Div>
  );
}
