import JumboDdMenu from '@jumbo/components/JumboDdMenu'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, InputAdornment, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Axios } from 'index';
import AllApis from 'app/Apis/apis';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketExecutiveCommissionCompanyList } from 'app/redux/actions/MarketExecutiveCommissionMapping';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PaidIcon from '@mui/icons-material/Paid';
import { debounce } from 'lodash';
import Div from '@jumbo/shared/Div';
import Heading from 'app/components/Heading';


const tableCellSx = {
  textTransform: "capitalize",
  color: "white",
  textAlign: "left",
  minWidth: "150px",
  verticalAlign: "middle",
}

const tableCellSort = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white",
  },
}


const ListingMETCommissionCompany = () => {

  const [sortBy, setSortBy] = useState("created_at");
  const [searchTerm, setSearchTerm] = useState("")
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(1);

  const { marketExcutiveCommissionCompanyReducer } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { metId } = useParams();
  const navigate = useNavigate();

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleIsActive = async (commissionId, body) => {
    try {
      const { data } = await Axios.patch(AllApis?.marketExecutiveCommission?.update(commissionId), { ...body })
      if (data?.statusCode === 200) {
        dispatch(getMarketExecutiveCommissionCompanyList({
          metId: metId
        }));
        Swal.fire({
          icon: "success",
          title: data?.status,
          text: data?.message,
          timer: 1200,
          showConfirmButton: false,
        })
      }

    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: error?.response?.data?.status,
        text: error?.response?.data?.message,
        timer: 1200,
        showConfirmButton: false,
      })
    }
  }

  // const handleItemAction = function (menuItems) {
  //   switch (menuItems.action) {
  //     case "edit":
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (searchTerm) => {
    setPage(1)
    dispatch(getMarketExecutiveCommissionCompanyList({
      sortBy: sortBy,
      search_value: searchTerm.trim(),
      sort: sort,
      page: page,
      metId: metId
    }));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);


  useEffect(() => {
    dispatch(getMarketExecutiveCommissionCompanyList({
      sortBy: sortBy,
      search_value: searchTerm.trim(),
      sort: sort,
      page: page,
      metId: metId
    }));
    // console.log(apiData)
  }, [sort, page,metId])

  return (
    <>
      <Box sx={{ mt: -4 }}>
        <Typography>
          <Heading Name={"My Company List"} />
        </Typography>

      </Box>
      <Div sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value === "") {
              dispatch(getMarketExecutiveCommissionCompanyList({
                sortBy: sortBy,
                search_value: "",
                sort: sort,
                page: page,
                metId: metId
              }));;
            }
          }}
          sx={{ width: 400 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Button variant='outlined' onClick={()=> navigate('/dashboards/master/marketExecutiveTeams')}>Back</Button>
      </Div>
      <TableContainer component={Paper} sx={{ my: "2%" }}>
        <Table sx={{ minWidth: 650 }} size="medium" >
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7" }}>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.marketExecutiveId.current_data.contact_person_details.first_name`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >Company Name</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.companyType`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >Company Type</TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.onBoardingDate`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >OnBoarding Date</TableSortLabel>
              </TableCell>
              <TableCell align={"center"} sx={{ ...tableCellSx,textAlign: "center" }}>
                Active Status
              </TableCell>
              <TableCell align={"center"} sx={{ ...tableCellSx,textAlign: "center" }}>
                Order History
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.commissionPercentage`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >Commission %</TableSortLabel>
              </TableCell>
              {/* <TableCell align={"left"} sx={{ ...tableCellSx }}>
                Action
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              marketExcutiveCommissionCompanyReducer?.marketExecutiveCommissionCompanyList?.map(({ _id, current_data: commission, proposed_changes, ...rest }, index) => {
                console.log(rest?.[commission?.companyType]?.current_data?.company_name)
                return (
                  <TableRow key={_id}>
                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      textTransform: "capitalize"
                    }}>
                      {rest?.[commission?.companyType]?.[0]?.current_data?.company_name}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                      textTransform: "capitalize"
                    }}>
                      {commission?.companyType}
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {new Date(commission?.onBoardingDate || Date.now()).toISOString().split('T')[0]}
                    </TableCell>

                    <TableCell align="center" sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}>
                      <Switch
                        disabled={!proposed_changes?.status}
                        size='small'
                        checked={commission?.isActive}
                        onChange={(e) => handleIsActive(_id, { isActive: e.target.checked })}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>

                    <TableCell align="center" sx={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}>
                      <IconButton onClick={()=>{
                        navigate(`/dashboards/master/marketExecutiveTeams/mapped-company-order/${metId}/${commission?.companyId}`)
                      }}>
                        <PaidIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      {commission?.commissionPercentage} %
                    </TableCell>

                    {/* <TableCell align="left" sx={{
                      textAlign: "left",
                      verticalAlign: "middle",
                    }}>
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <EditIcon />,
                            title: "Transaction History",
                            action: "transactionHistory",
                            commissionData: {
                              commissionId: _id,
                              onBoardingDate: commission.onBoardingDate,
                              commissionPercentage: commission.commissionPercentage
                            },
                            visibility: proposed_changes?.status
                          },
                        ].filter((e) => e?.visibility)}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell> */}
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Pagination
          count={marketExcutiveCommissionCompanyReducer?.totalPage || 0}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: "1%",
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  )
}

export default ListingMETCommissionCompany