import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { addressDropdownAction } from "app/redux/actions/AddressDropdown";

const filter = createFilterOptions();

export default function AddressTypeDialog({
  type,
  name,
  sx,
  value,
  setFieldValue,
  list,
  label,
  error,
  helperText,
  onBlur,
  isValidating,
}) {
  // const [value, setValue] = React.useState({
  //     name: ""
  // });
  const [open, toggleOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setFieldValue(name, "");
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setFieldValue(name, dialogValue.name);
      const { data } = await Axios.post(AllApis.addressDropdown.add(type), {
        name: dialogValue.name,
      });
      if (data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data?.status,
          text: data?.message,
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(addressDropdownAction(type));
      }
      setDialogValue({
        name: "",
      });
      toggleOpen(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error?.message || "Something went wrong",
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        name={name}
        size="small"
        value={value || ""}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setFieldValue(name, newValue?.name);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setFieldValue(name, newValue?.name);
            setDialogValue({
              name: newValue.inputValue,
            });
          } else {
            setFieldValue(name, newValue?.name);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const isExisting = options.some(
            (option) => params.inputValue === option.name
          );

          if (params.inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={list || []}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => option?.name === value}
        selectOnFocus
        onBlur={onBlur}
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        sx={{
          ...sx,
          "& .MuiInputBase-input": {
            textTransform: "capitalize", // Capitalize first letter
          },
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            name={name}
            error={error}
            helperText={helperText}
            required
          />
        )}
      />
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Add a new {type}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any {type} in our list? Please, add it!
            </DialogContentText>
            <TextField
              sx={{ width: "100%", mt: 2 }}
              autoFocus
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label={`${label} name`}
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
