import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";
import axios from "axios";
import Cookies from "js-cookie";
import io from "socket.io-client";

export const socket = io.connect(`${process.env.REACT_APP_SOCKET_URL}`);

socket.on("connect",()=>{
  console.log(socket.id)
})

socket.on("disconnect", (reason) => {
  console.log("Disconnected:", reason);
  if (reason === "io server disconnect") {
    // Automatically reconnect if disconnected by the server
    socket.connect();
  } else {
    // Emit logoutactiveUser event when disconnected by other reasons
    socket.emit("logoutactiveUser", {
      socketID: socket.id
    });
  }
});

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,  
});

Axios.interceptors.request.use(function (request) {
  // const token = localStorage.getItem("token");
  const token = Cookies.get("token");

  if (token) {
    request.headers.Authorization = token;
  }
  return request;
});

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
