import AllApis from "app/Apis/apis";
import { OFFLINE_PAYMENT_FAILED, OFFLINE_PAYMENT_REQUEST, OFFLINE_PAYMENT_SUCCESSS } from "app/utils/constants/paymentContanst";
import { Axios } from "index";

export const getOfflinePaymentList = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {}, metId }) => {
    return async (dispatch) => {
        const payload = {
            filters:filters,
            searchFields: {
                string: [
                    "current_data.offlineStoreDetails.companyName",
                    "current_data.offlineStoreDetails.gstNo",
                    "current_data.offlineStoreDetails.firstName",
                    "current_data.offlineStoreDetails.lastName",
                    "current_data.offlineStoreDetails.email",
                ],
                numbers: [
                    "current_data.salesOrderNo",
                    "current_data.totalSalesAmount",
                    "current_data.receviedAmount",
                    "current_data.balanceAmount"
                ]
            }
        }

        if (!search_value) {
            search_value = "";
        }

        const urlParams = new URLSearchParams({
            search: search_value.trim(),
            page: page,
            sort: sort,
            sortBy: sortBy,
        });

        try {
            dispatch({ type: OFFLINE_PAYMENT_REQUEST });
            const { data } = await Axios.post(`${AllApis?.payments?.offlinePayment?.listing}?${urlParams.toString()}`, { ...payload });
            dispatch({
                type: OFFLINE_PAYMENT_SUCCESSS, payload: {
                    offlinePaymentList: data?.data?.offlinePaymentList,
                    totalPage: data?.totalPages
                }
            })
        } catch (error) {
            dispatch({ type: OFFLINE_PAYMENT_FAILED, payload: error?.response?.data?.message })
        }
    }
}