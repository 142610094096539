export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";


//login
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";


//clear
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";


//Active Users
export const ACTIVE_USER_LIST_REQUEST = "ACTIVE_USER_LIST_REQUEST";
export const ACTIVE_USER_LIST_SUCCESS = "ACTIVE_USER_LIST_SUCCESS";
export const ACTIVE_USER_LIST_FAIL = "ACTIVE_USER_LIST_FAIL";