import AllApis from "app/Apis/apis";
import {
  ALL_FAQ_FAIL,
  ALL_FAQ_REQUEST,
  ALL_FAQ_SUCCESS,
} from "app/utils/constants/faqConstant";
import { Axios } from "index";

export const getAllFaq =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["question", "answer"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_FAQ_REQUEST });
      const data = await Axios.post(
        `${AllApis.faq.list}?${urlParams.toString()}`,
        body
      );
      console.log(data.data.data);
      dispatch({
        type: ALL_FAQ_SUCCESS,
        payload: {
          data: data?.data?.data?.faqs,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_FAQ_FAIL,
        payload: error.response.data.message,
      });
    }
  };
