import React from "react";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import ScaleIcon from "@mui/icons-material/Scale";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PeopleIcon from "@mui/icons-material/People";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import ApprovalIcon from "@mui/icons-material/Approval";
import QuizIcon from '@mui/icons-material/Quiz';
import BugReportIcon from '@mui/icons-material/BugReport';

const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/dashboards/users/active-users",
        label: "sidebar.menuItem.active_users",
        type: "nav-item",
        permissionModule: "user",
        icon: <OnlinePredictionIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/dashboards/users",
        label: "sidebar.menuItem.users",
        type: "nav-item",
        permissionModule: "user",
        icon: <PeopleIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/dashboards/roles",
        isActiveUri: ["/dashboards/roles/add", "/dashboards/roles/edit"],
        label: "sidebar.menuItem.roles",
        type: "nav-item",
        icon: <LockPersonOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.approvals",
    type: "section",
    children: [
      {
        uri: "/dashboards/approvals",
        label: "sidebar.menuItem.approvals",
        type: "nav-item",
        permissionModule: "approvals",
        icon: <ApprovalIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.masters",
    type: "section",
    children: [
      {
        label: "sidebar.menu.masters",
        type: "collapsible",
        children: [
          {
            uri: "/dashboards/master/category",
            label: "sidebar.menuItem.master.category",
            isActiveUri: [
              "/dashboards/master/category/add",
              "/dashboards/master/category/edit",
            ],
            type: "nav-item",
            icon: <CategoryIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/unit",
            label: "sidebar.menuItem.master.unit",
            isActiveUri: [
              "/dashboards/master/unit/add",
              "/dashboards/master/unit/edit",
            ],
            type: "nav-item",
            icon: <ScaleIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/gst",
            label: "sidebar.menuItem.master.gst",
            isActiveUri: [
              "/dashboards/master/gst/add",
              "/dashboards/master/gst/edit",
            ],
            type: "nav-item",
            icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/hsnCode",
            label: "sidebar.menuItem.master.hsnCode",
            isActiveUri: [
              "/dashboards/master/hsnCode/add",
              "/dashboards/master/hsnCode/edit",
            ],
            type: "nav-item",
            icon: <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/product",
            label: "sidebar.menuItem.master.product",
            isActiveUri: [
              "/dashboards/master/product/add",
              "/dashboards/master/product/edit",
            ],
            type: "nav-item",
            icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/tds",
            label: "sidebar.menuItem.master.tds",
            isActiveUri: [
              "/dashboards/master/tds/add",
              "/dashboards/master/tds/edit",
            ],
            type: "nav-item",
            icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/paymentTermDays",
            label: "sidebar.menuItem.master.paymentTermDays",
            isActiveUri: [
              "/dashboards/master/paymentTermDays/add",
              "/dashboards/master/paymentTermDays/edit",
            ],
            type: "nav-item",
            icon: <LocalOfferOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/supplier",
            isActiveUri: [
              "/dashboards/master/supplier/list/Company",
              "/dashboards/master/supplier/add",
              "/dashboards/master/supplier/add-branch",
              "/dashboards/master/supplier/edit/:branchId",
            ],
            label: "sidebar.menuItem.master.supplier",
            type: "nav-item",
            icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/sskCompany",
            isActiveUri: [
              "/dashboards/master/sskCompany/list/Company",
              "/dashboards/master/sskCompany/add",
              "/dashboards/master/sskCompany/add-branch",
              "/dashboards/master/sskCompany/edit/:branchId",
            ],
            label: "sidebar.menuItem.master.sskCompany",
            type: "nav-item",
            icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/marketExecutiveTeams",
            isActiveUri: [
              "/dashboards/master/marketExecutiveTeams/add",
              "/dashboards/master/marketExecutiveTeams/edit/:metId",
              "/dashboards/master/marketExecutiveTeams/nominee/:metId",
              "/dashboards/master/marketExecutiveTeams/nominee/add/:metId",
              "/dashboards/master/marketExecutiveTeams/nominee/edit/:metId/:nomineeId",
              "/dashboards/master/marketExecutiveTeams/mapped-company/:metId",
              "/dashboards/master/marketExecutiveTeams/mapped-company-order/:metId/:companyId"
            ],
            label: "sidebar.menuItem.master.marketExecutiveTeams",
            type: "nav-item",
            icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/retailer",
            label: "sidebar.menuItem.master.retailer",
            isActiveUri: [
              "/dashboards/master/retailer/list/Company",
              "/dashboards/master/retailer/add",
              "/dashboards/master/retailer/add-branch",
              "/dashboards/master/retailer/edit/:branchId",
              "/dashboards/master/retailer/map-market-executive",
            ],
            type: "nav-item",
            icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/dashboards/master/offlinestore",
            isActiveUri: [
              "/dashboards/master/offlinestore/list/Company",
              "/dashboards/master/offlinestore/add",
              "/dashboards/master/offlinestore/add-branch",
              "/dashboards/master/offlinestore/edit/:branchId",
              "/dashboards/master/offlinestore/map-market-executive",
            ],
            label: "sidebar.menuItem.master.offlineStore",
            type: "nav-item",
            icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
    ],
  },
  {
    label: "sidebar.menu.purchaseOrder",
    type: "section",
    children: [
      {
        uri: "/dashboards/purchase-order",
        label: "sidebar.menuItem.purchaseOrder",
        isActiveUri: ["/dashboards/purchase-order/add"],
        isActiveUri: ["/dashboards/purchase-order/add"],
        type: "nav-item",
        icon: <LocalMallOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.inventory",
    type: "section",
    children: [
      {
        uri: "/dashboards/inventory",
        label: "sidebar.menuItem.inventory",
        type: "nav-item",
        icon: <Inventory2OutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.sampleTracking",
    type: "section",
    children: [
      {
        uri: "/dashboards/sampleTracking",
        label: "sidebar.menuItem.sampleTracking",
        type: "nav-item",
        icon: <PostAddIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.orderProcessing",
    type: "section",
    children: [
      {
        uri: "/dashboards/orderProcessing/orders",
        label: "sidebar.menuItem.orders",
        isActiveUri: ["/dashboards/orderProcessing/orders/add"],
        type: "nav-item",
        icon: <AssignmentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/dashboards/orderProcessing/salesOrder",
        label: "sidebar.menuItem.salesOrder",
        type: "nav-item",
        icon: <AssignmentTurnedInOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/dashboards/orderProcessing/dispatch",
        label: "sidebar.menuItem.dispatch",
        type: "nav-item",
        icon: <LocalShippingOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.payments",
    type: "section",
    children: [
      {
        uri: "/dashboards/payments",
        label: "sidebar.menuItem.payments",
        isActiveUri: [
          "/dashboards/payments/updatePayment",
          "/dashboards/payments/updatePayment/updatePayment",
        ],
        type: "nav-item",
        icon: <AccountBalanceWalletOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/dashboards/payouts",
        label: "sidebar.menuItem.payouts",
        isActiveUri: ["/dashboards/payouts/add-payout", "/dashboards/payouts"],
        type: "nav-item",
        icon: <AddCardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.faqs",
    type: "section",
    children: [
      {
        uri: "/dashboards/faqs",
        label: "sidebar.menuItem.faqs",
        type: "nav-item",
        permissionModule: "faq",
        icon: <QuizIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.tickets",
    type: "section",
    children: [
      {
        uri: "/dashboards/tickets",
        label: "sidebar.menuItem.tickets",
        type: "nav-item",
        permissionModule: "ticket",
        icon: <BugReportIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
