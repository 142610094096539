import AllApis from "app/Apis/apis";
import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
} from "app/utils/constants/productConstant";
import { Axios } from "index";

export const getAllProduct =
  (search_value, sort, sortBy, page,filter) => async (dispatch) => {
    try {
      const body = {
        filters: { "current_data.category._id":filter},
        searchFields: {
          string: [
            "current_data.created_by.name",
            "current_data.category.category_name",
            "current_data.product_name",
            "current_data.sku",
            ,
          ],
          numbers: [
            "current_data.tds_percentage",
            "current_data.hsn_code.hsn_code",
            "current_data.hsn_code.gst_percentage",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_PRODUCT_REQUEST });
      const data = await Axios.post(
        `${AllApis.product.list}?${urlParams.toString()}`,
        body
      );
      
      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: {
          data: data?.data?.data?.product,
          totalPage: data?.data?.data?.totalPages,
          imagePath: data?.data?.data?.imagePath,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
