import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Card, Switch, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import deepDiff from "deep-diff";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Heading from "app/pages/Component/Heading";

export default function AddEditCategory() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New Category" : "Edit Category"
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [imageURL, setImageURL] = useState(
    state?.current_data?.category_image
      ? `${process.env.REACT_APP_IMAGE_URL}/${state?.current_data?.category_image}`
      : ""
  );

  const initialValues = {
    category_name: state?.current_data?.category_name
      ? state?.current_data?.category_name
      : "",
    category_image: state?.current_data?.category_image
      ? state?.current_data?.category_image
      : "",
    show_in_website: state?.current_data?.show_in_website
      ? state?.current_data?.show_in_website
      : false,
    show_in_retailer: state?.current_data?.show_in_retailer
      ? state?.current_data?.show_in_retailer
      : false,
    show_in_offline_store: state?.current_data?.show_in_offline_store
      ? state?.current_data?.show_in_offline_store
      : false,
  };

  const validationSchema = yup.object({
    category_name: yup.string().required("Category Name is required"),
    category_image: yup.string("Upload Image").required("Upload Image"),
  });

  //changed key values
  function convertToNestedObject(differences) {
    const result = {};

    differences.forEach((diff) => {
      const path = diff.path.join(".");
      const keys = path.split(".");

      let currentObj = result;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // Only set the value if it's not undefined
          if (diff.rhs !== undefined) {
            currentObj[key] = diff.rhs;
          }
        } else {
          currentObj[key] =
            currentObj[key] ||
            (Number.isNaN(parseInt(keys[index + 1])) ? {} : []); // If the next key is a number, initialize an array
          currentObj = currentObj[key];
        }
      });
    });

    return result;
  }

  function getUpdatedFields(oldObject, newObject) {
    const differences = deepDiff(oldObject, newObject);

    if (!differences) {
      // No differences found, objects are identical
      return null;
    }

    return convertToNestedObject(differences);
  }

  const onUserSave = async (values) => {
    let formdata = new FormData();
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        formdata.append("category_name", values.category_name);
        formdata.append("category_image", values.category_image);
        formdata.append("show_in_website", values.show_in_website);
        formdata.append("show_in_retailer", values.show_in_retailer);
        formdata.append("show_in_offline_store", values.show_in_offline_store);
        response = await Axios.post(AllApis.category.add, formdata);
      } else {
        // Assuming you have a valid 'state' object
        const updatedFields = getUpdatedFields(state.current_data, values);
        Object.entries(updatedFields).map((item) =>
          formdata.append(item[0], item[1])
        );
        console.log(formdata);
        response = await Axios.patch(
          AllApis.category.edit(state._id),
          formdata
        );
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New Category Created"
              : "Category Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/master/category");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (event, setFieldValue) => {
    const selectedFile = event.target.files[0];
    setFieldValue("category_image", selectedFile);
    if (selectedFile) {
      const imageURL = URL.createObjectURL(selectedFile);
      setImageURL(imageURL);
    }
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 1,
                  }}
                >
                  <Div
                    sx={{
                      mt: 2,
                      position: "relative",
                      textAlign: "center",
                      width: 450,
                      "&:hover": {
                        "& label": {
                          opacity: 1,
                        },
                      },
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Card
                      sx={{
                        maxWidth: 450,

                        border: "1px solid",
                        height: "280px",
                        position: "relative", // Added position relative
                      }}
                    >
                      <Div
                        sx={
                          isHovered
                            ? {
                                height: 280,
                                width: 450,
                                backgroundColor: "black",
                                opacity: isHovered ? 0.5 : 0.1, // Set opacity to 0.2
                                position: "absolute", // Set position to absolute
                                top: 0,
                                left: 0,
                              }
                            : {}
                        }
                      ></Div>
                      <img
                        style={{ cursor: "pointer" }}
                        width={"100%"}
                        height={"100%"}
                        src={imageURL}
                      />
                    </Card>

                    <input
                      type="file"
                      accept="image/*"
                      id="imageInput"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                    <label
                      htmlFor="imageInput"
                      style={{
                        position: "absolute", // Position absolute for centering
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)", // Centering trick
                        opacity: isHovered ? 1 : 0,
                        color: isHovered ? "white" : "black",
                        cursor: "pointer",
                      }}
                    >
                      <Typography>Select File</Typography>
                      <Typography>
                        click{" "}
                        <span
                          style={{
                            color: "#fee440",
                            textDecoration: "underline",
                          }}
                        >
                          browse
                        </span>{" "}
                        through your machine
                      </Typography>
                    </label>
                    <ErrorMessage
                      name="category_image"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </Div>

                  <Div sx={{ width: "45%", mt: 2 }}>
                    <Div sx={{ width: "50%" }}>
                      <Typography variant="h5">Category Name</Typography>
                      <TextField
                        name="category_name"
                        size="small"
                        error={
                          !!(touched.category_name && errors.category_name)
                        }
                        value={values?.category_name}
                        sx={{
                          width: "100%",
                          borderColor:
                            values.category_name && errors.category_name
                              ? "red"
                              : "",
                        }}
                        onChange={(e) =>
                          setFieldValue("category_name", e.target.value)
                        }
                      />
                      <ErrorMessage
                        name="category_name"
                        component={"div"}
                        style={{ color: "red" }}
                      ></ErrorMessage>
                    </Div>

                    <Div sx={{ width: "100%", mt: 2, mb: 2 }}>
                      <Typography variant="h5">
                        Active/Inactive Category (Portal)
                      </Typography>
                      {[
                        {
                          name: "show_in_website",
                          heading: "Website",
                        },
                        {
                          name: "show_in_retailer",
                          heading: "Retailer",
                        },
                        {
                          name: "show_in_offline_store",
                          heading: "Offline Store",
                        },
                      ].map((item, i) => {
                        return (
                          <Div key={i}>
                            <Div sx={{ display: "flex", alignItems: "center" }}>
                              <Switch
                                checked={values[item.name]}
                                size="small"
                                onChange={(e) =>
                                  setFieldValue(
                                    `${item.name}`,
                                    e.target.checked
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography variant="h6" sx={{ mt: 1 }}>
                                {item.heading}
                              </Typography>
                              {/* <Checkbox
                              name={item.name}
                              checked={values[item.name]}
                              onChange={(e) =>
                                setFieldValue(`${item.name}`, e.target.checked)
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            /> */}
                            </Div>
                            {/* <ErrorMessage
                            name={item.name}
                            component={"div"}
                            style={{ color: "red" }}
                          ></ErrorMessage> */}
                          </Div>
                        );
                      })}
                    </Div>
                  </Div>
                </Div>

                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    sx={{ width: "100px" }}
                    variant="outlined"
                    onClick={() => navigate("/dashboards/master/category")}
                  >
                    Back
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
