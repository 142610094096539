import Page from "@jumbo/shared/Page";
import Suppliers, { CompanyTypeComponent } from "app/pages/Masters/Suppliers";
import AddEditSuppliers from "app/pages/Masters/Suppliers/AddEditSuppliers";
import ListCompanyName from "app/pages/Masters/Suppliers/ListCompnayName";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/master/supplier";

export const suppliersRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "suppliers",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: (
              <Page
                companyType={"supplier"}
                label={"Supplier"}
                component={Suppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "suppliers",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/list/Company`,
            element: (
              <Page
                companyType={"supplier"}
                label={"Supplier"}
                component={ListCompanyName}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "suppliers",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: (
              <Page
                companyType={"supplier"}
                label={"Supplier"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "suppliers",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit/:id`,
            element: (
              <Page
                companyType={"supplier"}
                label={"Supplier"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "suppliers",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add-branch`,
            element: (
              <Page
                companyType={"supplier"}
                label={"Supplier"}
                component={AddEditSuppliers}
              />
            ),
          },
        ],
      },
    ],
  },
];
