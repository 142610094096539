import AllApis from "app/Apis/apis";
import { MET_PAYOUT_FAILED, MET_PAYOUT_REQUEST, MET_PAYOUT_SUCCESSS } from "app/utils/constants/payoutContants";
import { Axios } from "index";

export const getAllMarketExecutivePayout = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {} }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: MET_PAYOUT_REQUEST });
            const payload = {
                filters: filters,
                searchFields: {
                    string: [
                        "current_data.contact_person_details.first_name",
                        "current_data.contact_person_details.last_name",
                        "current_data.contact_person_details.primary_email_id",
                        "current_data.kyc.pan.pan_no",
                        "current_data.kyc.aadhar.aadhar_no",
                    ],
                    numbers:[
                        "account_balance"
                    ]
                },
            };
            if (!search_value) {
                search_value = "";
            }

            const urlParams = new URLSearchParams({
                search: search_value.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
            });

            const { data } = await Axios.post(`${AllApis.marketExective.list}?${urlParams.toString()}`, { ...payload });

            dispatch({
                type: MET_PAYOUT_SUCCESSS, payload: {
                    marketExecutive: data?.data?.MarketExecutive,
                    totalPage: data?.data?.totalPages
                }
            })
        } catch (error) {
            dispatch({ type: MET_PAYOUT_FAILED, payload: error?.response?.data?.message });
        }
    }
}