import { Box } from "@mui/material";
import React from "react";
import ListingTransaction from "./ListingTransaction";
import Heading from "app/components/Heading";
import FilterList from "./FilterList";
import { useSelector } from "react-redux";
import Loader from "app/components/Loader";

const TransactionHistory = () => {
  const { loading } = useSelector((state) => state?.transactionReducer);

  return (
    <Box sx={{ mt: -4 }}>
      {loading && <Loader />}
      <Box>
        <Heading Name={"Transaction History"}></Heading>
      </Box>
      <Box>
        <FilterList />
        <ListingTransaction />
      </Box>
    </Box>
  );
};

export default TransactionHistory;
