import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getTransactionHistory } from "app/redux/actions/PaymentsAndPayouts/Transaction";
import { useParams } from "react-router-dom";

const datepickerStyling = {
  width: "100%",
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#aeb7bd ", // or any other color you prefer
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#475259", // Change to the color you want on hover
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7352C7", // Change to the color you want when focused
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#7352C7", // Keep the same color as focused when hovered
    },
  "& .MuiInputLabel-outlined": {
    color: "#98a3aa", // Default label color
    "&.Mui-focused": {
      color: "#7352C7", // Label color on focus
    },
  },
};

export const filterFunction = (filter = {}) => {
  if (!filter.range) return {};
  const filterData = {
    range: {},
  };
  for (let i in filter.range) {
    for (let j of Object.keys(filter.range[i])) {
      if (filter.range[i][j]) {
        filterData.range[i] = {
          ...filterData.range[i],
        };
        filterData.range[i][j] = filter.range[i][j];
      }
    }
  }
  return filterData;
};

const FilterList = () => {
  const [filters, setFilters] = useState({
    range: {
      "commission.commissionDate": {
        from: "",
        to: "",
      },
      "payouts.payoutDate": {
        from: "",
        to: "",
      },
      "commission.commissionPercentage": {
        from: "",
        to: "",
      },
      "commission.commissionAmount": {
        from: "",
        to: "",
      },
      "payouts.amountPaid": {
        from: "",
        to: "",
      },
    },
  });
  const { metId } = useParams();
  const dispatch = useDispatch();

  const handleFilters = function (name, field, value) {
    setFilters((pre) => ({
      ...pre,
      range: {
        ...pre.range,
        [name]: {
          ...pre.range[name],
          [field]: value,
        },
      },
    }));
  };

  const handleApply = function () {
    dispatch(
      getTransactionHistory({
        filters: filterFunction(filters),
        metId: metId,
      })
    );
  };

  const handleClear = function () {
    setFilters({
      range: {
        "commission.commissionDate": {
          from: "",
          to: "",
        },
        "payouts.payoutDate": {
          from: "",
          to: "",
        },
        "commission.commissionPercentage": {
          from: "",
          to: "",
        },
        "commission.commissionAmount": {
          from: "",
          to: "",
        },
        "payouts.amountPaid": {
          from: "",
          to: "",
        },
      },
    });
    dispatch(
      getTransactionHistory({
        metId: metId,
      })
    );
  };

  return (
    <Box>
      <Box sx={{ my: 4 }}>
        <Typography variant="h1">Filters</Typography>
      </Box>
      <Grid2 container rowSpacing={4} columnSpacing={3}>
        <Grid2 xs={2}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={
                  filters?.range?.["commission.commissionDate"]?.from
                    ? "refresh1"
                    : "refresh2"
                }
                id="fromSaleOrderDate"
                format="DD-MM-YYYY"
                name="from"
                label="From Commission Date"
                sx={datepickerStyling}
                defaultValue={
                  filters?.range?.["commission.commissionDate"]?.from != ""
                    ? dayjs(filters?.range?.["commission.commissionDate"]?.from)
                    : null
                }
                onChange={(newValue) => {
                  handleFilters(
                    "commission.commissionDate",
                    "from",
                    newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              {/* {(!filters?.range?.["commission.commissionDate"]?.from && filters?.range?.["commission.commissionDate"]?.to) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select from commission date </Typography>} */}
            </LocalizationProvider>
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={
                  filters?.range?.["commission.commissionDate"]?.to
                    ? "refresh1"
                    : "refresh2"
                }
                id="fromSaleOrderDate"
                format="DD-MM-YYYY"
                name="to"
                label="To Commission Date"
                sx={datepickerStyling}
                defaultValue={
                  filters?.range?.["commission.commissionDate"]?.to != ""
                    ? dayjs(filters?.range?.["commission.commissionDate"]?.to)
                    : null
                }
                onChange={(newValue) => {
                  handleFilters(
                    "commission.commissionDate",
                    "to",
                    newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              {/* {(!filters?.range?.["commission.commissionDate"]?.to && filters?.range?.["commission.commissionDate"]?.from) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select to commission date </Typography>} */}
            </LocalizationProvider>
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="From Commission percentage"
              value={filters?.range?.["commission.commissionPercentage"]?.from}
              onChange={(e) =>
                handleFilters(
                  "commission.commissionPercentage",
                  "from",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="To Commission percentage"
              value={filters?.range?.["commission.commissionPercentage"]?.to}
              onChange={(e) =>
                handleFilters(
                  "commission.commissionPercentage",
                  "to",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="From Commission Amount"
              value={filters?.range?.["commission.commissionAmount"]?.from}
              onChange={(e) =>
                handleFilters(
                  "commission.commissionAmount",
                  "from",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="To Commission Amount"
              value={filters?.range?.["commission.commissionAmount"]?.to}
              onChange={(e) =>
                handleFilters(
                  "commission.commissionAmount",
                  "to",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={
                  filters?.range?.["payouts.payoutDate"]?.from
                    ? "refresh1"
                    : "refresh2"
                }
                id="fromSaleOrderDate"
                format="DD-MM-YYYY"
                name="Payoutfrom"
                label="From Payout Date"
                sx={datepickerStyling}
                defaultValue={
                  filters?.range?.["payouts.payoutDate"]?.from != ""
                    ? dayjs(filters?.range?.["payouts.payoutDate"]?.from)
                    : null
                }
                onChange={(newValue) => {
                  handleFilters(
                    "payouts.payoutDate",
                    "from",
                    newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              {/* {(!filters?.range?.["payouts.payoutDate"]?.from && filters?.range?.["payouts.payoutDate"]?.to) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select from payout date </Typography>} */}
            </LocalizationProvider>
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={
                  filters?.range?.["payouts.payoutDate"]?.to
                    ? "refresh1"
                    : "refresh2"
                }
                id="fromSaleOrderDate"
                format="DD-MM-YYYY"
                name="Payoutto"
                label="To Payout Date"
                sx={datepickerStyling}
                defaultValue={
                  filters?.range?.["payouts.payoutDate"]?.to
                    ? dayjs(filters?.range?.["payouts.payoutDate"]?.to)
                    : null
                }
                onChange={(newValue) => {
                  handleFilters(
                    "payouts.payoutDate",
                    "to",
                    newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                  );
                }}
                slotProps={{ textField: { size: "small" } }}
              />
              {/* {(!filters?.range?.["payouts.payoutDate"]?.to && filters?.range?.["payouts.payoutDate"]?.from) && <Typography sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}>Select to payout date </Typography>} */}
            </LocalizationProvider>
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="From Payout Amount"
              value={filters?.range?.["payouts.amountPaid"]?.from}
              onChange={(e) =>
                handleFilters(
                  "payouts.amountPaid",
                  "from",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              label="To Payout Amount"
              value={filters?.range?.["payouts.amountPaid"]?.to}
              onChange={(e) =>
                handleFilters(
                  "payouts.amountPaid",
                  "to",
                  Number(e.target.value)
                )
              }
            />
          </Box>
        </Grid2>
      </Grid2>
      <Box sx={{ my: 4 }}>
        <Button variant="contained" sx={{ mr: 1 }} onClick={handleApply}>
          Apply
        </Button>
        <Button variant="outlined" onClick={handleClear}>
          Clear
        </Button>
      </Box>
    </Box>
  );
};

export default FilterList;
