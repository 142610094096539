import Div from '@jumbo/shared/Div'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { getAllMarketExecutive } from 'app/redux/actions/Masters/MarketExecutive'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListingMET from './ListingMET'
import Heading from 'app/components/Heading'
import { State, City } from "country-state-city";
import Loader from 'app/components/Loader';

export const metFilterFunction = (filters) => {
  const filter = {}
  for (let i in filters) {
    if (filters?.[i]?.name) {
      filter[`current_data.address.${i}`] = filters?.[i]?.name
    }
  }
  return filter
}

const MarketExecutiveTeam = () => {
  const [filters, setFilters] = useState({
    district: {
      _id: "",
      name: ""
    },
    location: {
      _id: "",
      name: ""
    },
    taluka: {
      _id: "",
      name: ""
    },
    area: {
      _id: "",
      name: ""
    },
    state: {
      name: ""
    },
    city: {
      name: ""
    }
  })
  const { district, location, taluka, area, loading: addressLoading } = useSelector((state) => state?.addressDropdown);
  const marketExecutiveList = useSelector((state) => state?.marketExecutiveReducer);
  const [stateCityList, setStateCityList] = useState({
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
  });
  const dispatch = useDispatch();


  const handleFilters = useCallback(function () {
    dispatch(getAllMarketExecutive({
      filters: metFilterFunction(filters)
    }))
  }, [filters, dispatch])

  const handleClears = useCallback(function () {
    setFilters({
      district: {
        _id: "",
        name: ""
      },
      location: {
        _id: "",
        name: ""
      },
      taluka: {
        _id: "",
        name: ""
      },
      area: {
        _id: "",
        name: ""
      },
      state: {
        name: ""
      },
      city: {
        name: ""
      }
    }
    )
    dispatch(getAllMarketExecutive({}))
  }, [dispatch])
  return (
    <>
      {marketExecutiveList?.loading && <Loader />}
      <Box sx={{ mt: -4 }}>
        <Typography as="div">
          <Heading Name={"Market Executive Teams"} />
        </Typography>
        <Div className='Filters' sx={{ width: "100%", mt: 4 }}>
          <Typography variant='h1' sx={{ mb: 3 }}>Filters</Typography>
          <Box sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}>
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={district}
              value={filters?.district}
              defaultValue={""}
              label="District"
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, district: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="District" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={location}
              value={filters?.location}
              label="location"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, location: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Location" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={taluka}
              value={filters?.taluka}
              label="taluka"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, taluka: newValue })
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Taluka" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={area}
              value={filters?.area}
              label="area"
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, newValue) => {
                setFilters({ ...filters, area: newValue })
                console.log(newValue)
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Area" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={stateCityList?.stateList || []}
              value={filters?.state}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={
                (option, value) => option?.name === value?.name
              }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "state": {
                    name: newValue != null ? newValue?.name?.toLowerCase() : ""
                  },
                });
                if (newValue != null) {
                  const cityNames = City.getCitiesOfState(
                    "IN",
                    newValue.isoCode
                  );
                  setStateCityList({ ...stateCityList, cityList: cityNames });
                } else {
                  setStateCityList({
                    ...stateCityList,
                    cityList: City.getCitiesOfState("IN", "MH"),
                  });
                }
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="State" />
              )}
            />
            <Autocomplete
              freeSolo
              sx={{ width: "23%" }}
              size="small"
              id="company-autocomplete"
              options={stateCityList?.cityList || []}
              label="city"
              value={filters?.city}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={
                (option, value) => option?.name === value?.name
              }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "city": {
                    name: newValue != null ? newValue?.name?.toLowerCase() : ""
                  },
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="City" />
              )}
            />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button variant='contained' sx={{ marginRight: 1 }} onClick={handleFilters}>Apply</Button>
            <Button variant='outlined' onClick={handleClears}>Clear</Button>
          </Box>

          <ListingMET filter={metFilterFunction(filters)}/>
        </Div>
      </Box>
    </>
  );
};

export default MarketExecutiveTeam;
