import AllApis from "app/Apis/apis"
import { Axios } from "index"
import Swal from "sweetalert2";

export const addSuppliers = async (data,companyType)=>{
    try {
        const {data} = await Axios.post(AllApis?.[companyType].add,data);
        return data?.data?.[companyType]
    } catch (error) { 
        return error
    }
}

export const getCompanyDropdown = async (companyType)=>{
    try {
        const {data} = await Axios.get(AllApis?.[companyType].dropdown);
        return data?.data?.[companyType]
    } catch (error) {
        return new Error(error)
    }
}

export const updateCompanyList = async (companyId,body,companyType)=>{
    try {
        const {data} = await Axios.patch(AllApis?.[companyType].edit(companyId),{...body});
        return data
    } catch (error) {
       return new Error(error)
    }
}