import AllApis from "app/Apis/apis";
import {
  ALL_PURCHASE_ORDER_FAIL,
  ALL_PURCHASE_ORDER_REQUEST,
  ALL_PURCHASE_ORDER_SUCCESS,
} from "app/utils/constants/purchaseOrderConstant";
import { Axios } from "index";

export const getAllPurchaseOrders =
  (search_value, sort, sortBy, page, filters) => async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filters,
        },
        searchFields: {
          string: [
            "current_data.supplier_details.company_name",
            "current_data.supplier_details.gst_no",
            "current_data.supplier_details.address.city",
            "current_data.supplier_details.address.state",
            "current_data.order_status",
          ],
          numbers: ["current_data.purchase_order_no"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_PURCHASE_ORDER_REQUEST });

      const data = await Axios.post(
        `${AllApis.purchaseOrder.list}?${urlParams.toString()}`,
        body
      );

      dispatch({
        type: ALL_PURCHASE_ORDER_SUCCESS,
        payload: {
          data: data?.data?.data?.purchaseOrder,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_PURCHASE_ORDER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
