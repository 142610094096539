import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import Heading from "app/components/Heading";
import {
  FormHelperText,
  ImageListItem,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import { getAllSalesOrders } from "app/redux/actions/OrderProcessing/SalesOrders";
import { useDispatch } from "react-redux";
import { formatDate } from "app/pages/Component/Function/formatDate";

let imagepath = process.env.REACT_APP_IMAGE_URL;
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TextFeild({ label, value, sx }) {
  return (
    <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
      <Typography as="span" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>{" "}
      :- {value}
    </Typography>
  );
}

export default function RefundModal({
  open,
  setOpen,
  selectedRow,
  type,
  view,
}) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { current_data } = selectedRow;
  const todaysDate = new Date(Date.now()).toISOString().split("T")[0];
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };

  const initialValues = {
    total_amount: current_data?.total_amount,
    refund_amount: view ? current_data?.refund_amount : "",
    transaction_id: view ? current_data?.transaction_id : "",
    sales_order_id: selectedRow?._id,
    sales_order_no: current_data?.sales_order_no,
    order_no: current_data?.order_no,
    refund_date: view ? current_data?.refund_date : todaysDate,
    transaction_type: view ? current_data?.transaction_type : "",
    customer_name: current_data?.customer_details?.customer_name,
  };

  const validationSchema = yup.object({
    total_amount: yup.string().required("Total Amount is required"),
    refund_amount: yup.string().required("Refund Amount is required"),
    transaction_type: yup.string().required("Transaction Type is required"),
  });

  const onUserSave = async (values) => {
    try {
      setSubmitting(true);

      let response = await Axios.post(AllApis.refund.add, values);

      if (response?.data?.statusCode) {
        setOpen(false);
        dispatch(getAllSalesOrders("", "desc", "created_at", 1, type, {}));
        Swal.fire({
          title: "Saved",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ my: 2 }}>
              <Heading Name={view ? "Refund Details" : "Refund"} />
            </Typography>
            <Div>
              <Formik
                validateOnChange={true}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onUserSave}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form noValidate autoComplete="off">
                    <Div sx={{ mt: 4 }}>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 5,
                          rowGap: 1,
                        }}
                      >
                        <Div
                          sx={{
                            width: "45%",
                            display: "flex",
                            columnGap: 4,
                          }}
                        >
                          <Div sx={{ width: "45%" }}>
                            <Typography variant="h5">Sales Order No</Typography>

                            <TextField
                              name="sales_order_no"
                              size="small"
                              disabled
                              value={values?.sales_order_no}
                              sx={{
                                width: "100%",
                                ...disabledStyling,
                              }}
                            />
                          </Div>
                          <Div sx={{ width: "45%" }}>
                            <Typography variant="h5">Order No</Typography>

                            <TextField
                              name="order_no"
                              size="small"
                              disabled
                              value={values?.order_no}
                              sx={{
                                width: "100%",
                                ...disabledStyling,
                              }}
                            />
                          </Div>
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Refund Date</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              id="to"
                              format="DD-MM-YYYY"
                              disabled={view}
                              sx={{
                                width: "100%",
                                ...(view && disabledStyling),
                              }}
                              defaultValue={dayjs(values?.refund_date)}
                              onChange={(newValue) => {
                                setFieldValue("refund_date", newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") );
                              }}
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </LocalizationProvider>
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Transaction ID</Typography>

                          <TextField
                            name="transaction_id"
                            size="small"
                            error={
                              !!(
                                touched.transaction_id && errors.transaction_id
                              )
                            }
                            helperText={
                              touched.transaction_id && errors.transaction_id
                                ? errors.transaction_id
                                : ""
                            }
                            value={values?.transaction_id}
                            disabled={view}
                            sx={{
                              width: "100%",
                              borderColor:
                                values.transaction_id && errors.transaction_id
                                  ? "red"
                                  : "",
                              ...(view && disabledStyling),
                            }}
                            onChange={(e) =>
                              setFieldValue("transaction_id", e.target.value)
                            }
                          />
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Transaction Type</Typography>
                          <Select
                            value={values?.transaction_type}
                            disabled={view}
                            sx={{ width: "100%", ...(view && disabledStyling) }}
                            size="small"
                            error={
                              !!(
                                touched.transaction_type &&
                                errors.transaction_type
                              )
                            }
                            helperText={
                              touched.transaction_type &&
                              errors.transaction_type
                                ? errors.transaction_type
                                : ""
                            }
                            onChange={(e) =>
                              setFieldValue("transaction_type", e.target.value)
                            }
                          >
                            <MenuItem value="neft">NEFT</MenuItem>
                            <MenuItem value="cheque">Cheque</MenuItem>
                            <MenuItem value="rtgs">RTGS</MenuItem>
                          </Select>
                          {touched.transaction_type &&
                            errors.transaction_type && (
                              <FormHelperText
                                sx={{ color: "#E73145", pl: 1.6 }}
                              >
                                {errors.transaction_type}
                              </FormHelperText>
                            )}
                        </Div>

                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Total Amount</Typography>

                          <TextField
                            name="total_amount"
                            size="small"
                            disabled
                            value={values?.total_amount}
                            sx={{
                              width: "100%",
                              ...disabledStyling,
                            }}
                            onChange={(e) =>
                              setFieldValue("total_amount", e.target.value)
                            }
                          />
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Refund Amount</Typography>
                          <TextField
                            type="number"
                            name="refund_amount"
                            size="small"
                            inputProps={{ min: 0, max: values?.total_amount }}
                            onKeyDown={(e) => {
                              const inputValue = parseInt(
                                e.target.value + e.key,
                                10
                              );

                              if (
                                isNaN(inputValue) ||
                                inputValue < 0 ||
                                inputValue > values?.total_amount
                              ) {
                                e.preventDefault();
                              }
                            }}
                            error={
                              !!(touched.refund_amount && errors.refund_amount)
                            }
                            helperText={
                              touched.refund_amount && errors.refund_amount
                                ? errors.refund_amount
                                : ""
                            }
                            value={values?.refund_amount}
                            disabled={view}
                            sx={{
                              width: "100%",
                              borderColor:
                                values.refund_amount && errors.refund_amount
                                  ? "red"
                                  : "",
                              ...(view && disabledStyling),
                            }}
                            onChange={(e) =>
                              setFieldValue("refund_amount", e.target.value)
                            }
                          />
                        </Div>

                        <Div
                          sx={{
                            width: "93.5%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 3,
                            mt: 3,
                          }}
                        >
                          {!view && (
                            <LoadingButton
                              variant="contained"
                              type="submit"
                              sx={{ width: "100px" }}
                              // loading={isSubmitting}
                            >
                              Save
                            </LoadingButton>
                          )}
                        </Div>
                      </Div>
                    </Div>
                  </Form>
                )}
              </Formik>
            </Div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
