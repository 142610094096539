import Page from "@jumbo/shared/Page";
import MarketExecutiveTeam from "app/pages/Masters/MarketExecutive";
import AddEditMET from "app/pages/Masters/MarketExecutive/AddEditMET";
import AddNomineeForm from "app/pages/Masters/MarketExecutive/Nominee/AddNomineeForm";
import ListingMET from "app/pages/Masters/MarketExecutive/ListingMET";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";
import ListingNominee from "app/pages/Masters/MarketExecutive/Nominee/ListingNominee/ListingNominee";
import MyCompany from "app/pages/Masters/MarketExecutive/MyCompany";
import MyCompanyOrder from "app/pages/Masters/MarketExecutive/MyCompany/MyCompanyOrder";


const routesName = "/dashboards/master/marketExecutiveTeams";
const modules = "marketExecutive";

export const marketExecutiveTeamsRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={MarketExecutiveTeam} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddEditMET} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit/:metId`,
            element: <Page component={AddEditMET} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/nominee/:metId`,
            element: <Page component={ListingNominee} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/nominee/add/:metId`,
            element: <Page component={AddNomineeForm} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/nominee/edit/:metId/:nomineeId`,
            element: <Page component={AddNomineeForm} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/mapped-company/:metId`,
            element: <Page component={MyCompany} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/mapped-company-order/:metId/:companyId`,
            element: <Page component={MyCompanyOrder} />,
          },
        ],
      },
    ],
  },
];


