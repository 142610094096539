import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Swal from "sweetalert2";
import { formatDate } from "app/pages/Component/Function/formatDate";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getAllDispatchOrders } from "app/redux/actions/OrderProcessing/Dispatch";
import ViewDispatchModel from "../../ViewDispatchModel";
import OutForDeliveryModal from "../../OutForDeliveryModal/OutForDeliveryModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function OutForDeliveryTable({
  orderType,
  deliveryStatus,
  page,
  setPage,
  search,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const dispathOrderData = useSelector(
    (state) => state?.dispatchReducer?.[orderType]
  );
  const { TotalPage } = useSelector((state) => state?.dispatchReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const [orderDetails, setOrderDetails] = useState({});
  const [showmodel, setshowmodel] = useState(false);

  const dispatch = useDispatch();
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "view_dispatch":
        setOrderDetails(menuItem?.data?.current_data);
        setshowmodel(true);
        break;
      default:
    }
  };

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelivered = async (newValue, row) => {
    try {
      let response = await Axios.patch(AllApis.dispatch.delivered(row?._id), {
        tracking_date: {
          delivered: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        },
      });
      if (response?.data?.statusCode) {
        dispatch(
          getAllDispatchOrders(
            "",
            "desc",
            "created_at",
            1,
            orderType,
            deliveryStatus,
            {}
          )
        );
        Swal.fire({
          title: "Delivered",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "140px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.dispatch_no")}
                direction={sort}
                sx={{
                  color: "white",
                  width: "100%",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Dispatch No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "130px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Dispatch Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "160px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.sales_order_no")}
                direction={sort}
                sx={{
                  color: "white",
                  width: "100%",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Sales Order No
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Sales Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "170px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort("current_data.customer_details.customer_name")
                }
                direction={sort}
                sx={{
                  color: "white",
                  width: "100%",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Customer Name
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "120px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort(
                    "current_data.customer_details.bill_to.address.city"
                  )
                }
                direction={sort}
                sx={{
                  color: "white",
                  width: "100%",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                City
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "140px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort(
                    "current_data.customer_details.bill_to.address.state"
                  )
                }
                direction={sort}
                sx={{
                  color: "white",
                  width: "100%",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                State
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.total_weight")}
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Total Weight
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.total_amount")}
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Totol Amount
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "190px",
                verticalAlign: "middle",
                color: "white",
                position: "sticky",
                right: 150,
                zIndex: 1,
                bgcolor: "#7352C7",
              }}
            >
              Delivered
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
                color: "white",
                position: "sticky",
                right: 0,
                zIndex: 1,
                bgcolor: "#7352C7",
                height: "58px",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dispathOrderData?.map((row, i) => {
            return (
              <TableRow key={row._id}>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.dispatch_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {formatDate(
                    row?.current_data?.tracking_date?.out_for_delivery
                      ?.dispatch_date
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.sales_order_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {formatDate(
                    row?.current_data?.tracking_date?.sales_order_date
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.customer_name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.bill_to?.address?.city}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.bill_to?.address?.state}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.total_weight}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  ₹ {row?.current_data?.total_amount?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    right: 150,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="DD-MM-YYYY"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#475259",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#475259", // Change label color to blue
                        },
                      }}
                      disabled={
                        !Permission?.["dispatch"]?.["add"] ||
                        row?.current_data?.tracking_date?.delivered != null
                      }
                      defaultValue={dayjs(
                        new Date(Date.now()).toISOString().split("T")[0]
                      )}
                      onChange={(newValue) => {
                        handleDelivered(newValue, row);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <InfoIcon />,
                        title: "View Dispatch Details",
                        action: "view_dispatch",
                        data: row,
                        visibility: true,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {showmodel && (
        <ViewDispatchModel
          open={showmodel}
          setOpen={setshowmodel}
          selectedRow={orderDetails}
          setSelectedRow={setOrderDetails}
          deliveryStatus={deliveryStatus}
        />
      )}

      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          p: 1,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
  );
}
