import {
  ALL_TDS_FAIL,
  ALL_TDS_REQUEST,
  ALL_TDS_SUCCESS,
} from "app/utils/constants/tdsConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allTds: [],
  TotalPage: null,
};

export const tdsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_TDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_TDS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTds: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_TDS_FAIL:
      return {
        ...state,
        loading: false,
        allTds: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
