import { Box } from "@mui/material";
import React from "react";
import Heading from "app/components/Heading";
import FilterList from "./FilterList";
import { useSelector } from "react-redux";
import Loader from "app/components/Loader";
import ListingCompanyOrder from "./ListingCompanyOrder";

const MyCompanyOrder = () => {
  const { loading } = useSelector((state) => state?.metCompnayOrderCommissionReducer);

  return (
    <Box sx={{ mt: -4 }}>
      {loading && <Loader />}
      <Box>
        <Heading Name={"Order History"}></Heading>
      </Box>
      <Box>
        <FilterList />
        <ListingCompanyOrder />
      </Box>
    </Box>
  );
};

export default MyCompanyOrder;
