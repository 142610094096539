import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import { getAllCategory } from "app/redux/actions/Masters/Category";
import Heading from "app/pages/Component/Heading";
import CategoryLogsModal from "../Logs";
import Swal from "sweetalert2";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Loader from "app/components/Loader";

export default function ListCategory() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);

  const { allCategory, TotalPage } = useSelector(
    (state) => state.categoryReducer
  );
  const { userData, loading } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllCategory(value, sort, sortBy));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeStatus = async (e, row) => {
    try {
      let formdata = new FormData();
      formdata.append("isActive", e.target.checked);
      const response = await Axios.patch(
        AllApis.category.edit(row._id),
        formdata
      );
      if (response) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllCategory("", sort, sortBy, page));
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/dashboards/master/category/edit", {
          state: { ...menuItem.data },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getAllCategory(searchTerm, sort, sortBy, page));
  }, [sort, page]);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={"Category Master"} />
      {loading && <Loader />}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setSort("desc");
              setSortBy("created_at");
              dispatch(getAllCategory("", "desc", "created_at", 1));
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          {/* <Button
            variant="contained"
            sx={{
              mr: 2,
              p: 1,
              pl: 4,
              pr: 4,
            }}
            onClick={() => setLogModalOpen(true)}
          >
            Logs
          </Button> */}
          {Permission?.["category"]?.["add"] && (
            <Button
              variant="contained"
              size="small"
              sx={{
                mr: 2,
                p: 1,
                pl: 4,
                pr: 4,
              }}
              onClick={() => navigate("/dashboards/master/category/add")}
            >
              Add Category
            </Button>
          )}
        </Div>
      </Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.category_name")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Category Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Category Image
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.created_by.name")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Created By
                </TableSortLabel>
              </TableCell>

              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Status
              </TableCell>
              {Permission?.["category"]?.["edit"] && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {allCategory?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform:"capitalize"
                  }}
                >
                  {row?.current_data?.category_name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    width={"100px"}
                    height={"100px"}
                    src={`${process.env.REACT_APP_IMAGE_URL}/${row?.current_data?.category_image}`}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform:"capitalize"
                  }}
                >
                  {row?.current_data?.created_by?.name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Switch
                    checked={row?.current_data?.isActive}
                    size="small"
                    disabled={!Permission?.["category"]?.["edit"]}
                    onChange={(e) => changeStatus(e, row)}
                    style={{
                      color: row?.current_data?.isActive
                        ? "#7352C7"
                        : "lightgrey",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                {Permission?.["category"]?.["edit"] && (
                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditIcon />,
                          title: "Edit Category",
                          action: "edit",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: 1,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
      <CategoryLogsModal
        open={isLogModalOpen}
        onClose={() => setLogModalOpen(false)}
      />
    </Div>
  );
}
