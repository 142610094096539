import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";

import Div from "@jumbo/shared/Div";
import { Axios } from "index";
import AllApis from "app/Apis/apis";

const LogsModal = ({ open, onClose }) => {
  const [logs, setLogs] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(async () => {
    try {
      const response = await Axios.get(AllApis.roles.logs(page));
      if (response?.data?.statusCode) {
        setLogs(response.data.data);
        setTotalPage(response.data.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  return (
  <>
    {/* <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Logs</DialogTitle>
      </Div>
      <DialogContent>
        <TableContainer
          component={Paper}
          sx={{ position: "relative", height: "615px" }}
        >
          <Table border={2}>
            <TableHead sx={{ position: "sticky", top: 0, left: 0 }}>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell sx={{ color: "white" }}>User</TableCell>
                <TableCell sx={{ color: "white" }}>Type</TableCell>
                <TableCell sx={{ color: "white" }}>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs?.map((log, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {log?.userData?.name}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {log.data.operationType}
                  </TableCell>

                  <TableCell>
                    {log.data.operationType === "insert" ? (
                      // If operationType is insert, show current_data
                      <pre>
                        {Object.entries(
                          log.data.fullDocument.current_data.permissions
                        ).map((item, i) => {
                          return (
                            <>
                              <Table border={2}>
                                {i == 0 && (
                                  <TableHead>
                                    <TableRow
                                      sx={{
                                        bgcolor: "#202020",
                                        color: "white",
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          textTransform: "capitalize",
                                          fontWeight: 500,
                                          color: "white",
                                        }}
                                      >
                                        Permissions
                                      </TableCell>
                                      {Object.entries(item[1]).map((item) => {
                                        return (
                                          <TableCell
                                            sx={{
                                              textTransform: "capitalize",
                                              fontWeight: 500,
                                              color: "white",
                                              width: "100%",
                                            }}
                                          >
                                            {item[0]}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  </TableHead>
                                )}
                                <TableBody>
                                  <TableCell
                                    sx={{
                                      textTransform: "capitalize",
                                      fontWeight: 500,
                                      width: "100%",
                                    }}
                                  >
                                    {item[0]}
                                  </TableCell>
                                  {Object.entries(item[1]).map((item) => {
                                    return (
                                      <TableCell
                                        sx={{
                                          textTransform: "capitalize",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {item[1] == true
                                          ? "Active"
                                          : "Inactive"}
                                      </TableCell>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </>
                          );
                        })}
                      </pre>
                    ) : (
                      // If operationType is not insert, show updateDescription.updatedFields

                      <pre>
                        {log.data.updateDescription?.updatedFields?.[
                          "proposed_changes.permissions"
                        ] != undefined &&
                          Object.entries(
                            log.data.updateDescription?.updatedFields?.[
                              "proposed_changes.permissions"
                            ]
                          ).map((item, i) => {
                            return (
                              <>
                                <Table border={2}>
                                  {i == 0 && (
                                    <TableHead>
                                      <TableRow
                                        sx={{
                                          bgcolor: "#202020",
                                          color: "white",
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            textTransform: "capitalize",
                                            fontWeight: 500,
                                            color: "white",
                                          }}
                                        >
                                          Permissions
                                        </TableCell>
                                        {Object.entries(item[1]).map((item) => {
                                          return (
                                            <TableCell
                                              sx={{
                                                textTransform: "capitalize",
                                                fontWeight: 500,
                                                color: "white",
                                                width: "100%",
                                              }}
                                            >
                                              {item[0]}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    </TableHead>
                                  )}
                                  <TableBody>
                                    <TableCell
                                      sx={{
                                        textTransform: "capitalize",
                                        fontWeight: 500,
                                        width: "100%",
                                      }}
                                    >
                                      {item[0]}
                                    </TableCell>
                                    {Object.entries(item[1]).map((item) => {
                                      return (
                                        <TableCell
                                          sx={{
                                            textTransform: "capitalize",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {item[1] == true
                                            ? "Active"
                                            : "Inactive"}
                                        </TableCell>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </>
                            );
                          })}
                      </pre>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </>
      </DialogActions>
    </Dialog> */}
  </>
  );
};

export default LogsModal;
