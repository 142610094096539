import {
  ACTIVE_USER_LIST_FAIL,
  ACTIVE_USER_LIST_REQUEST,
  ACTIVE_USER_LIST_SUCCESS,
  CLEAR_USER_ERROR,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
} from "app/utils/constants/Users/usersConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  user: [],
  activeUser: [],
  userData: null,
  TotalPage: null,
};

export const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        user: [],
        error: action.payload,
      };
    //active user
    case ACTIVE_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIVE_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        activeUser: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ACTIVE_USER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        activeUser: [],
        error: action.payload,
      };

    //load user
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };

    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        userData: [],
        error: action.payload,
      };
    case CLEAR_USER_ERROR:
      return {
        ...state,
        loading: false,
        userData: [],
        error: null,
      };
    default:
      return state;
  }
};
