import Div from '@jumbo/shared/Div';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { BranchFormContext } from 'app/pages/Masters/Suppliers/AddEditSuppliers';
import AddressTypeDialog from 'app/pages/Masters/Suppliers/AutoAddAddress';
import { addressDropdownAction } from 'app/redux/actions/AddressDropdown';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { State, City, Country } from "country-state-city";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";

function Address({ formikRef }) {
    const { district, location, area, taluka } = useSelector((state) => state?.addressDropdown);
    const [stateCityList, setStateCityList] = useState({
        stateList: State.getStatesOfCountry("IN"),
        cityList: City.getCitiesOfState("IN", "MH"),
        countryList: Country.getCountryByCode("IN"),
    });
    const { branchProps: { data, setData } } = useContext(BranchFormContext);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     Promise.allSettled([dispatch(addressDropdownAction("district")), dispatch(addressDropdownAction("location")), dispatch(addressDropdownAction("area")), dispatch(addressDropdownAction("taluka"))]);
    // }, [])

    return (
        <>
            <Formik
                initialValues={{ ...data.branch_address }}
                validationSchema={yup.object().shape({
                    address: yup.string().required('Address is required'),
                    location: yup.string().required('Location is required'),
                    area: yup.string().required('Area is required'),
                    district: yup.string().required('District is required'),
                    taluka: yup.string().required('Taluka is required'),
                    state: yup.string().required('State is required'),
                    city: yup.string().required('City is required'),
                    // country: yup.string().required('Country is required'),
                    pincode: yup.number().required('Pincode is required'),
                })}
                onSubmit={(values, action) => {
                    setData((pre) => ({ ...pre, branch_address: { ...values } }))
                }}
                innerRef={formikRef}
            >
                {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => {
                    return (
                        <Form>
                            <Typography mt="1%" variant="h4">Address</Typography>
                            <Div sx={{
                                // marginLeft: "2%",
                                display: "flex",
                                width: "100%",
                                flexWrap: "wrap",
                                columnGap: 2,
                            }}>
                                <TextField
                                    required
                                    name="address"
                                    label="Address"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.address && touched?.address}
                                    helperText={errors?.address && touched?.address ? errors?.address : ""}
                                    onChange={handleChange}
                                    value={values?.address}
                                    onBlur={handleBlur}
                                />
                                <AddressTypeDialog
                                    type={"district"}
                                    name={"district"}
                                    label={"District"}
                                    list={district}
                                    sx={{ width: "30%", marginY: "10px" }}
                                    setFieldValue={setFieldValue}
                                    value={values.district}
                                    error={errors?.district && touched?.district}
                                    onBlur={handleBlur}
                                    helperText={errors?.district && touched?.district ? errors?.district : ""}
                                />
                                <AddressTypeDialog
                                    type={"location"}
                                    name={"location"}
                                    list={location}
                                    sx={{ width: "30%", marginY: "10px" }}
                                    label="Location"
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    error={errors?.location && touched?.location}
                                    helperText={errors?.location && touched?.location ? errors?.location : ""}
                                    onChange={handleChange}
                                    value={values?.location}
                                />
                                <AddressTypeDialog
                                    type={"area"}
                                    name={"area"}
                                    label={"Area"}
                                    list={area}
                                    sx={{ width: "30%", marginY: "10px" }}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    value={values.area}
                                    error={errors?.area && touched?.area}
                                    helperText={errors?.area && touched?.area ? errors?.area : ""}
                                />
                                {console.log(values.taluka)}
                                <AddressTypeDialog
                                    type={"taluka"}
                                    name={"taluka"}
                                    label={"Taluka"}
                                    list={taluka}
                                    sx={{ width: "30%", marginY: "10px" }}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    value={values.taluka}
                                    error={errors?.taluka && touched?.taluka}
                                    helperText={errors?.taluka && touched?.taluka ? errors?.taluka : ""}
                                />
                                <Autocomplete
                                    sx={{ width: "30%", marginY: "10px", textTransform: "capitalize" }}
                                    size="small"
                                    id="company-autocomplete"
                                    name="state"
                                    options={stateCityList?.stateList || []}
                                    value={values?.state || null}
                                    getOptionLabel={(option = "") => {
                                        if(typeof option === "object"){
                                            return option.name
                                        }
                                        return option
                                    }}
                                    isOptionEqualToValue={
                                        (option, value) => option?.name === value
                                    }
                                    freeSolo 
                                    autoHighlight
                                    onBlur={handleBlur}
                                    onChange={(e, newValue) => {
                                        setFieldValue("state", newValue?.name)
                                        if (newValue != null) {
                                            const cityNames = City.getCitiesOfState(
                                                "IN",
                                                newValue.isoCode
                                            );
                                            setStateCityList({ ...stateCityList, cityList: cityNames });
                                        } else {
                                            setStateCityList({
                                                ...stateCityList,
                                                cityList: City.getCitiesOfState("IN", "MH"),
                                            });
                                        }
                                    }}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                            key={option?.name}
                                        >
                                            {option?.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" name="state" label="State"
                                            required
                                            error={errors?.state && touched?.state}
                                            helperText={errors?.state && touched?.state ? errors?.state : ""}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    sx={{ width: "30%", marginY: "10px" }}
                                    size="small"
                                    id="company-autocomplete"
                                    options={stateCityList?.cityList || []}
                                    label="city"
                                    name="city"
                                    value={values?.city || null}
                                    getOptionLabel={(option = "") => {
                                        if(typeof option === "object"){
                                            return option.name
                                        }
                                        return option
                                    }}
                                    isOptionEqualToValue={
                                        (option, value) => option?.name === value
                                    }
                                    freeSolo 
                                    autoHighlight
                                    onBlur={handleBlur}
                                    onChange={(e, newValue) => {
                                        setFieldValue("city", newValue?.name)
                                    }}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                            {...props}
                                            key={option?.name}
                                        >
                                            {option?.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" name="city" label="City"
                                            required
                                            error={errors?.city && touched?.city}
                                            helperText={errors?.city && touched?.city ? errors?.city : ""}
                                        />
                                    )}
                                />
                                {/* <TextField
                                    name="location"
                                    label="Location"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.location && touched?.location}
                                    helperText={errors?.location && touched?.location ? errors?.location : ""}
                                    onChange={handleChange}
                                    value={values?.location}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="area"
                                    label="Area"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.area && touched?.area}
                                    helperText={errors?.area && touched?.area ? errors?.area : ""}
                                    onChange={handleChange}
                                    value={values?.area}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="district"
                                    label="District"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.district && touched?.district}
                                    helperText={errors?.district && touched?.district ? errors?.district : ""}
                                    onChange={handleChange}
                                    value={values?.district}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="taluka"
                                    label="Taluka"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.taluka && touched?.taluka}
                                    helperText={errors?.taluka && touched?.taluka ? errors?.taluka : ""}
                                    onChange={handleChange}
                                    value={values?.taluka}
                                    onBlur={handleBlur} /> */}
                                {/* <TextField
                                    required
                                    name="state"
                                    label="State"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.state && touched?.state}
                                    helperText={errors?.state && touched?.state ? errors?.state : ""}
                                    onChange={handleChange}
                                    value={values?.state}
                                    onBlur={handleBlur} />
                                <TextField
                                    required
                                    name="city"
                                    label="City"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.city && touched?.city}
                                    helperText={errors?.city && touched?.city ? errors?.city : ""}
                                    onChange={handleChange}
                                    value={values?.city}
                                    onBlur={handleBlur} />
                                <TextField
                                    required
                                    name="country"
                                    label="Country"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.country && touched?.country}
                                    helperText={errors?.country && touched?.country ? errors?.country : ""}
                                    onChange={handleChange}
                                    value={values?.country}
                                    onBlur={handleBlur} /> */}
                                <TextField
                                    required
                                    name="pincode"
                                    label="Pincode"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.pincode && touched?.pincode}
                                    helperText={errors?.pincode && touched?.pincode ? errors?.pincode : ""}
                                    onChange={handleChange}
                                    value={values?.pincode}
                                    onBlur={handleBlur} />
                            </Div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default Address