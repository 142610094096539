import Page from "@jumbo/shared/Page";
import ListTicket from "app/pages/Tickets/ListTickets";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/tickets";
const module = "ticket";

export const ticketRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: module,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListTicket} />,
          },
        ],
      },
    ],
  },
];
