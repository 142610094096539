import styled from '@emotion/styled'
import { Autocomplete, Box, TextField, TextareaAutosize as BaseTextareaAutosize, Typography, TextareaAutosize, Button } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import AllApis from 'app/Apis/apis'
import Heading from 'app/components/Heading'
import { FastField, Form, Formik } from 'formik'
import { Axios } from 'index'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import * as yup from "yup"

const initialValues = {
    salesOrderNo: "",
    salesOrderDate: new Date(Date.now()).toISOString().split('T')[0],
    offlineStoreDetails: {
        companyId: "",
        companyName: "",
        gstNo: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNo: "",
    },
    totalSalesAmount: "",
    recievedAmount: "",
    balanceAmount: "",
    dueDate: new Date(Date.now()).toISOString().split('T')[0],
    OverdueDays: new Date(Date.now()).toISOString().split('T')[0],
    paymentStatus: "",
    payments: {
        paymentDate: new Date(Date.now()).toISOString().split('T')[0],
        paymentType: "",
        transactionId: "",
        upiId: "",
        followUpDate: "",
        paymentAmount: "",
        remark: "",
    },
}

const dates = [
    {
        fieldName: "salesOrderDate",
        name: "Sales Order No"
    },
    {
        fieldName: "dueDate",
        name: "Sales Order No"
    },
    {
        fieldName: "salesOrderDate",
        name: "Sales Order No"
    },
]

const totals = [
    {
        fieldName: "totalSalesAmount",
        name: "Total Sales Order Amount"
    },
    {
        fieldName: "recievedAmount",
        name: "Received Amount"
    },
    {
        fieldName: "balanceAmount",
        name: "Balance Amount"
    }
]

const payments = [
    {
        fieldName: "paymentDate",
        name: "Payment Date",
        type: "Date"
    },
    {
        fieldName: "paymentType",
        name: "Payment Type"
    },
    {
        fieldName: "transactionId",
        name: "Transaction Id"
    },
    {
        fieldName: "upiId",
        name: "UPI Id"
    },
    {
        fieldName: "paymentAmount",
        name: "Payment Amount"
    },
]

const followUpAndRemark = [
    {
        fieldName: "followUpDate",
        name: "Follow Up Date"
    },
    {
        fieldName: "remark",
        name: "Remark"
    }
]

const Textarea = styled(TextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? '#C7D0DD' : "#1C2025"};
    background: ${theme.palette.mode === 'dark' ? "#1C2025" : '#f5f7fa'};
    border: 1px solid ${theme.palette.mode === 'dark' ? '#434D5B' : '#0000003b'};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? "#1C2025" : '#F3F6F9'};

    &:hover {
      border-color: ${'black'};
    }

    &:focus {
      border-color: ${'#7352C7'};
      box-shadow: 0px 0px 0px 1px ${theme.palette.mode === 'dark' ? "#7352C7" : '#7352C7'};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
        color: "#475259", // Change label color to blue
    },
};

const UpdateOfflinePayment = () => {
    const [offlinePaymentValue, setOfflinePaymentValue] = useState({
        salesOrderNo: "",
        salesOrderDate: new Date(Date.now()).toISOString().split('T')[0],
        offlineStoreDetails: {
            companyId: "",
            companyName: "",
            gstNo: "",
            firstName: "",
            lastName: "",
            email: "",
            mobileNo: "",
        },
        totalSalesAmount: "",
        recievedAmount: "",
        balanceAmount: "",
        dueDate: new Date(Date.now()).toISOString().split('T')[0],
        OverdueDays: new Date(Date.now()).toISOString().split('T')[0],
        paymentStatus: "",
        payments: {
            paymentDate: new Date(Date.now()).toISOString().split('T')[0],
            paymentType: "",
            transactionId: "",
            upiId: "",
            followUpDate: "",
            paymentAmount: "",
            remark: "",
        },
    })
    const [salesOrderNoList,setSalesOrderNoList] = useState([])
    const { state } = useLocation();
    const navigate = useNavigate()



    const handleAddPayment = async function (values, action) {
        try {
            const { data } = await Axios.post(AllApis?.payments?.offlinePayment?.addPayment(offlinePaymentValue?.offlinePaymentId), {
                ...values.payments
            })
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.message,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                action.resetForm();
                navigate("/dashboards/payments")
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }


    const getSalesOrderNoList = async function(){
        try {
            const { data } = await Axios.get(AllApis?.salesOrder?.offlineSalesOrderNo)
            if (data?.statusCode === 200) {
                setSalesOrderNoList(data?.data)
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    useEffect(() => {
        const offlinePaymentData = state?.offlinePaymentData
        if (offlinePaymentData) {
            setOfflinePaymentValue({
                ...offlinePaymentValue,
                offlinePaymentId: offlinePaymentData.offlinePaymentId,
                salesOrderNo: offlinePaymentData.salesOrderNo,
                salesOrderDate: new Date(offlinePaymentData?.salesOrderDate).toISOString().split('T')[0],
                offlineStoreDetails: {
                    companyId: offlinePaymentData?.offlineStoreDetails?.companyId,
                    companyName: offlinePaymentData?.offlineStoreDetails?.companyName,
                    gstNo: offlinePaymentData?.offlineStoreDetails?.gstNo,
                    firstName: offlinePaymentData?.offlineStoreDetails?.firstName,
                    lastName: offlinePaymentData?.offlineStoreDetails?.lastName,
                    email: offlinePaymentData?.offlineStoreDetails?.email,
                    mobileNo: offlinePaymentData?.offlineStoreDetails?.mobileNo,
                },
                totalSalesAmount: offlinePaymentData?.totalSalesAmount,
                recievedAmount: offlinePaymentData?.recievedAmount,
                balanceAmount: offlinePaymentData?.balanceAmount,
                dueDate: new Date(offlinePaymentData?.dueDate).toISOString().split('T')[0],
                OverdueDays: new Date(offlinePaymentData?.OverdueDays).toISOString().split('T')[0],
                paymentStatus: offlinePaymentData?.paymentStatus,
            })
        }
        getSalesOrderNoList()
    }, [state?.offlinePaymentData])
    return (
        <>
            {
                !state?.offlinePaymentData ? <Navigate to={"/dashboards/payments"} /> : <>
                    <Box sx={{ mt: -4 }}>
                        <Heading Name={"Add Payment"} />
                    </Box>
                    <Box sx={{ my: 4 }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ ...offlinePaymentValue }}
                            validationSchema={yup.object().shape({
                                payments: yup.object().shape({
                                    paymentDate: yup.date().required(),
                                    paymentType: yup.string().required("Payment Type is required"),
                                    transactionId: yup.string().matches(/^[a-zA-Z0-9]*$/, 'Invalid transaction ID').required("Transaction Id is required"),
                                    upiId: yup.string().matches(/^[a-zA-Z0-9]*$/, 'Invalid UPI ID'),
                                    followUpDate: yup.date().nullable(),
                                    paymentAmount: yup.number().required("Amount is required"),
                                    remark: yup.string(),
                                })
                            })}
                            onSubmit={handleAddPayment}
                        >
                            {({ values }) => {
                                return <Form>
                                    <Grid2 container spacing={3}>
                                        <Grid2 xs={4}>
                                            <FastField name={"salesOrderNo"} key={JSON.stringify(salesOrderNoList)}>
                                                {({ field, meta }) => {
                                                    return <Autocomplete
                                                        sx={{ width: "100%" }}
                                                        size="small"
                                                        id="company-autocomplete"
                                                        options={salesOrderNoList}
                                                        disabled
                                                        value={values?.salesOrderNo}
                                                        label="Sales Order No"
                                                        getOptionLabel={(option) => option.toString()}
                                                        isOptionEqualToValue={(option, value) => option.sales_order_no == value}
                                                        // onChange={(e, newValue) => {
                                                        //     // setSupplierId(newValue)
                                                        //     // console.log(newValue)
                                                        // }}
                                                        renderOption={(props, option) => (
                                                            <Box
                                                                component="li"
                                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                {...props}
                                                                key={option?._id}
                                                            >
                                                                {option?.sales_order_no}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} sx={{...disabledStyling}} variant="outlined" label="Sales Order No" />
                                                        )}
                                                    />
                                                }}
                                            </FastField>
                                        </Grid2>
                                    </Grid2>
                                    <Box>
                                        <Typography sx={{ my: 3 }} variant='h3'>Offline Store Details</Typography>
                                        <Grid2 container spacing={3}>
                                            {
                                                dates.map((e, i) => {
                                                    return <Grid2 xs={4} key={i}>
                                                        <FastField name={e.fieldName}>
                                                            {({ field, meta }) => {
                                                                return <TextField
                                                                    {...field}
                                                                    disabled
                                                                    type='date'
                                                                    sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                                    size='small'
                                                                    label={e.name}
                                                                />
                                                            }}
                                                        </FastField>
                                                    </Grid2>
                                                })
                                            }
                                            {
                                                Object.keys(initialValues.offlineStoreDetails).filter((e) => e !== "companyId").map((e, i) => {
                                                    return <Grid2 xs={4} key={i}>
                                                        <FastField name={`offlineStoreDetails.${e}`}>
                                                            {({ field, meta }) => {
                                                                return <TextField
                                                                    {...field}
                                                                    disabled
                                                                    sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                                    size='small'
                                                                    label={e.split(/(?=[A-Z])/).join(" ")}
                                                                />
                                                            }}
                                                        </FastField>
                                                    </Grid2>
                                                })
                                            }
                                        </Grid2>
                                        <Grid2 container spacing={3} sx={{ mt: 2 }}>
                                            {
                                                totals.map((e, i) => {
                                                    return <Grid2 xs={4} key={i}>
                                                        <FastField name={e.fieldName}>
                                                            {({ field, meta }) => {
                                                                return <TextField
                                                                    {...field}
                                                                    disabled
                                                                    sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                                    size='small'
                                                                    label={e.name}
                                                                />
                                                            }}
                                                        </FastField>
                                                    </Grid2>
                                                })
                                            }
                                        </Grid2>
                                        <Box>
                                            <Typography sx={{ my: 3 }} variant='h3'>Add Payment</Typography>
                                            <Grid2 container spacing={3}>
                                                {
                                                    payments.map((e, i) => {
                                                        return <Grid2 xs={4} key={i}>
                                                            <FastField name={`payments.${e.fieldName}`}>
                                                                {({ field, meta }) => {
                                                                    return <TextField
                                                                        {...field}
                                                                        type={e.type || "text"}
                                                                        sx={{ width: "100%", textTransform: "capitalize" }}
                                                                        size='small'
                                                                        label={e.name}
                                                                        error={meta?.error && meta?.touched}
                                                                        helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                                    />
                                                                }}
                                                            </FastField>
                                                        </Grid2>
                                                    })
                                                }
                                            </Grid2>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ my: 3 }} variant='h3'>Add FollowUp & Remarks</Typography>
                                            <Grid2 container spacing={3}>
                                                {
                                                    followUpAndRemark.map((e, i) => {
                                                        return <Grid2 xs={e.fieldName === "remark" ? 12 : 4} key={i}>
                                                            <FastField name={`payments.${e.fieldName}`}>
                                                                {({ field, meta }) => {
                                                                    return e.fieldName === "followUpDate" ? <TextField
                                                                        {...field}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        type="date"
                                                                        sx={{ width: "100%", textTransform: "capitalize" }}
                                                                        size='small'
                                                                        label={e.name}
                                                                    /> : <Textarea {...field} aria-label="minimum height" minRows={5} placeholder="Remarks" />
                                                                }}
                                                            </FastField>
                                                        </Grid2>
                                                    })
                                                }
                                            </Grid2>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: "center", gap: 3, mt: 3 }}>
                                        <Button variant='outlined' onClick={() => navigate("/dashboards/payments")}>Back</Button>
                                        <Button variant='contained' type='submit'>Update</Button>
                                    </Box>
                                </Form>
                            }}
                        </Formik>
                    </Box>
                </>
            }
        </>
    )
}

export default UpdateOfflinePayment