import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { Form, Formik } from "formik";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Heading from "app/pages/Component/Heading";
import { State, City, Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList } from "app/redux/actions/Roles";
import { getUsers } from "app/redux/actions/User";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import AddressTypeDialog from "../Masters/Suppliers/AutoAddAddress";
import ImageViewer from "./imageModal";

const disabledStyling = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#475259",
  },
  "& .MuiInputLabel-root": {
    color: "#475259", // Change label color to blue
  },
  "& .MuiInputBase-input": {
    textTransform: "capitalize", // Capitalize first letter
  },
};

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useSelector
  const { district, location, area, taluka } = useSelector(
    (state) => state?.addressDropdown
  );
  const state = useSelector((state) => state?.userReducer?.userData);
  //  useState
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedImg, setSelectedImg] = useState(false);
  const [imageURL, setImageURL] = useState({
    profile_pic: "",
    aadhar_image: "",
    pan_image: "",
    passbook_image: "",
  });
  const [stateCityList, setStateCityList] = useState({
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
    countryList: Country.getCountryByCode("IN"),
  });

  const InitialValues = {
    employee_id: state?.current_data?.employee_id
      ? state?.current_data?.employee_id
      : "",
    first_name: state?.current_data?.first_name
      ? state?.current_data?.first_name
      : "",
    last_name: state?.current_data?.last_name
      ? state?.current_data?.last_name
      : "",
    primary_email_id: state?.current_data?.primary_email_id
      ? state?.current_data?.primary_email_id
      : "",
    secondary_email_id: state?.current_data?.secondary_email_id
      ? state?.current_data?.secondary_email_id
      : "",
    primary_mobile_no: state?.current_data?.primary_mobile_no
      ? state?.current_data?.primary_mobile_no
      : "",
    secondary_mobile_no: state?.current_data?.secondary_mobile_no
      ? state?.current_data?.secondary_mobile_no
      : "",
    profile_pic: state?.current_data?.profile_pic
      ? state?.current_data?.profile_pic
      : "",
    role_id: state?.current_data?.role_id?._id
      ? state?.current_data?.role_id?._id
      : "",
    status: state?.current_data?.status ? state?.current_data?.status : "",
    isActive: state?.current_data?.isActive
      ? state?.current_data?.isActive
      : "",
    password: state?.current_data?.password
      ? state?.current_data?.password
      : "",
    address: {
      address: state?.current_data?.address.address
        ? state?.current_data?.address.address
        : "",
      location: state?.current_data?.address.location
        ? state?.current_data?.address.location
        : "",
      area: state?.current_data?.address.area
        ? state?.current_data?.address.area
        : "",
      district: state?.current_data?.address.district
        ? state?.current_data?.address.district
        : "",
      taluka: state?.current_data?.address.taluka
        ? state?.current_data?.address.taluka
        : "",
      state: state?.current_data?.address.state
        ? state?.current_data?.address?.state
        : "",
      city: state?.current_data?.address?.city
        ? state?.current_data?.address?.city
        : "",
      country: state?.current_data?.address?.country
        ? state?.current_data?.address?.country
        : "",
      pincode: state?.current_data?.address?.pincode
        ? state?.current_data?.address?.pincode
        : "",
    },
    kyc: {
      pan_card_detail: {
        pan_no: state?.current_data?.kyc?.pan_card_detail.pan_no
          ? state?.current_data?.kyc?.pan_card_detail.pan_no
          : "",
        pan_image: state?.current_data?.kyc?.pan_card_detail.pan_image
          ? state?.current_data?.kyc?.pan_card_detail.pan_image
          : "",
      },
      aadhar_card_detail: {
        aadhar_no: state?.current_data?.kyc?.aadhar_card_detail?.aadhar_no
          ? state?.current_data?.kyc?.aadhar_card_detail?.aadhar_no
          : "",
        aadhar_image: state?.current_data?.kyc?.aadhar_card_detail?.aadhar_image
          ? state?.current_data?.kyc?.aadhar_card_detail?.aadhar_image
          : "",
      },
      bank_details: {
        bank_name: state?.current_data?.kyc?.bank_details?.bank_name
          ? state?.current_data?.kyc?.bank_details?.bank_name
          : "",
        account_no: state?.current_data?.kyc?.bank_details?.account_no
          ? state?.current_data?.kyc?.bank_details?.account_no
          : "",
        confirm_account_no: state?.current_data?.kyc?.bank_details
          ?.confirm_account_no
          ? state?.current_data?.kyc?.bank_details?.confirm_account_no
          : "",
        ifsc_code: state?.current_data?.kyc?.bank_details?.ifsc_code
          ? state?.current_data?.kyc?.bank_details?.ifsc_code
          : "",
        passbook_image: state?.current_data?.kyc?.bank_details?.passbook_image
          ? state?.current_data?.kyc?.bank_details?.passbook_image
          : "",
      },
    },
  };

  const handleSubmit = async (values) => {
    let formdata = new FormData();
    let response;
    try {
      setSubmitting(true);
      formdata.append("primary_email_id", values.primary_email_id);
      formdata.append("secondary_email_id", values.secondary_email_id);
      formdata.append("primary_mobile_no", values.primary_mobile_no);
      formdata.append("secondary_mobile_no", values.secondary_mobile_no);
      formdata.append("address", JSON.stringify(values.address));
      console.log(values, "formmm");
      response = await Axios.patch(AllApis.users.profile(state._id), formdata);
      if (response?.data?.statusCode) {
        Swal.fire({
          title: "Profile Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object().shape({
    primary_email_id: yup
      .string()
      .email("Enter a valid email address")
      .required("Enter Primary EmailId"),
    secondary_email_id: yup.string().email("Enter a valid email address"),
    primary_mobile_no: yup
      .number()
      .typeError("Primary Mobile Number must be a number")
      .required("Enter Primary Mobile Number"),
    secondary_mobile_no: yup
      .number()
      .typeError("Secondary Mobile Number must be a number"),
    address: yup.object().shape({
      address: yup.string().required("Enter Address"),
      location: yup.string().required("Enter Location"),
      area: yup.string().required("Enter Area"),
      district: yup.string().required("Enter District"),
      taluka: yup.string().required("Enter Taluka"),
      state: yup.string().required("Enter State"),
      city: yup.string().required("Enter City"),
      country: yup.string().required("Enter Country"),
      pincode: yup.string().required("Enter Pincode"),
    }),
  });

  const handleImagesTab = async (value) => {
    if (value && typeof value === "string") {
      const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
      window.open(imageURL.href, "_blank");
    } else {
      const images = URL.createObjectURL(value);
      window.open(images, "__blank");
    }
  };

  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Heading Name={"User Profile"} />
        <Box sx={{ marginTop: "2%" }}>
          <Formik
            enableReinitialize={true}
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              handleBlur,
              touched,
              errors,
            }) => {
              return (
                <>
                  <Form>
                    <Div>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 2,
                          // gap: 5,
                          //   margin: "2% 0",
                        }}
                      >
                        <Div
                          sx={{
                            width: "15%",
                          }}
                        >
                          <Card
                            sx={{
                              maxWidth: 200,
                              minHeight: 200,
                              marginTop: 3,
                              marginBottom: 3,
                            }}
                          >
                            <CardContent>
                              <Div container>
                                <Div sx={{ textAlign: "center" }}>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${values?.profile_pic}`}
                                    alt="Passport Photo"
                                    style={{ width: "100%", height: "180px" }}
                                  />
                                </Div>
                                <Div>
                                  <Typography
                                    variant="body2"
                                    color="black"
                                    align="center"
                                  >
                                    Profile Photo
                                  </Typography>
                                </Div>
                              </Div>
                            </CardContent>
                          </Card>
                        </Div>
                        <Div
                          sx={{
                            display: "flex",
                            width: "80%",
                            flexWrap: "wrap",
                            columnGap: 4,
                            gap: 5,
                            margin: "2% 0",
                          }}
                        >
                          <TextField
                            name="employee_id"
                            type="text"
                            value={values.employee_id}
                            disabled
                            label="Employee ID"
                            error={errors?.employee_id && touched?.employee_id}
                            helperText={
                              errors?.employee_id && touched?.employee_id
                                ? errors?.employee_id
                                : ""
                            }
                            size="small"
                            sx={{ width: "25%", ...disabledStyling }}
                          />
                          <TextField
                            name="first_name"
                            type="text"
                            value={values.first_name}
                            sx={{
                              textTransform: "capitalize",
                              width: "25%",
                              ...disabledStyling,
                            }}
                            label="First Name"
                            disabled
                            size="small"
                          />
                          <TextField
                            name="last_name"
                            type="text"
                            value={values.last_name}
                            label="Last Name"
                            size="small"
                            disabled
                            sx={{ width: "25%", ...disabledStyling }}
                          />
                          <TextField
                            name="primary_email_id"
                            type="email"
                            value={values.primary_email_id}
                            label="Primary Email"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.primary_email_id &&
                              touched?.primary_email_id
                            }
                            helperText={
                              errors?.primary_email_id &&
                              touched?.primary_email_id
                                ? errors?.primary_email_id
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="secondary_email_id"
                            type="email"
                            value={values.secondary_email_id}
                            label="Secondary Email"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.secondary_email_id &&
                              touched?.secondary_email_id
                            }
                            helperText={
                              errors?.secondary_email_id &&
                              touched?.secondary_email_id
                                ? errors?.secondary_email_id
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="primary_mobile_no"
                            type="Number"
                            value={values.primary_mobile_no}
                            label="Primary Mobile"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.primary_mobile_no &&
                              touched?.primary_mobile_no
                            }
                            helperText={
                              errors?.primary_mobile_no &&
                              touched?.primary_mobile_no
                                ? errors?.primary_mobile_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                          <TextField
                            name="secondary_mobile_no"
                            type="Number"
                            value={values.secondary_mobile_no}
                            label="Secondary Mobile"
                            size="small"
                            sx={{ width: "25%" }}
                            error={
                              errors?.secondary_mobile_no &&
                              touched?.secondary_mobile_no
                            }
                            helperText={
                              errors?.secondary_mobile_no &&
                              touched?.secondary_mobile_no
                                ? errors?.secondary_mobile_no
                                : ""
                            }
                            onChange={handleChange}
                          />
                        </Div>
                      </Div>

                      {/* Address */}
                      <Typography mt="1%" variant="h4">
                        Address
                      </Typography>
                      <Div
                        sx={{
                          // marginLeft: "2%",
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 2,
                        }}
                      >
                        <Autocomplete
                          sx={{
                            width: "30%",
                            marginY: "10px",
                            textTransform: "capitalize",
                          }}
                          size="small"
                          id="company-autocomplete"
                          name="address.state"
                          options={stateCityList?.stateList || []}
                          value={values?.address?.state || null}
                          getOptionLabel={(option = "") => {
                            if (typeof option === "object") {
                              return option.name;
                            }
                            return option;
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value
                          }
                          freeSolo
                          autoHighlight
                          onBlur={handleBlur}
                          onChange={(e, newValue) => {
                            setFieldValue("address.state", newValue?.name);
                            if (newValue != null) {
                              const cityNames = City.getCitiesOfState(
                                "IN",
                                newValue.isoCode
                              );
                              setStateCityList({
                                ...stateCityList,
                                cityList: cityNames,
                              });
                            } else {
                              setStateCityList({
                                ...stateCityList,
                                cityList: City.getCitiesOfState("IN", "MH"),
                              });
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={option?.name}
                            >
                              {option?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="address.state"
                              label="State"
                              required
                              error={
                                errors?.address?.state &&
                                touched?.address?.state
                              }
                              helperText={
                                errors?.address?.state &&
                                touched?.address?.state
                                  ? errors?.address?.state
                                  : ""
                              }
                            />
                          )}
                        />
                        <AddressTypeDialog
                          type={"district"}
                          name={"address.district"}
                          label={"District"}
                          list={district}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          value={values.address?.district}
                          error={
                            errors?.address?.district &&
                            touched?.address?.district
                          }
                          onBlur={handleBlur}
                          helperText={
                            errors?.address?.district &&
                            touched?.address?.district
                              ? errors?.address?.district
                              : ""
                          }
                        />
                        <AddressTypeDialog
                          type={"taluka"}
                          name={"address.taluka"}
                          label={"Taluka"}
                          list={taluka}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                          value={values.address?.taluka}
                          error={
                            errors?.address?.taluka && touched?.address?.taluka
                          }
                          helperText={
                            errors?.address?.taluka && touched?.address?.taluka
                              ? errors?.address?.taluka
                              : ""
                          }
                        />

                        <AddressTypeDialog
                          type={"location"}
                          name={"address.location"}
                          list={location}
                          sx={{ width: "30%", marginY: "10px" }}
                          label="Location"
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                          error={
                            errors?.address?.location &&
                            touched?.address?.location
                          }
                          helperText={
                            errors?.address?.location &&
                            touched?.address?.location
                              ? errors?.address?.location
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.location}
                        />
                        <AddressTypeDialog
                          type={"area"}
                          name={"address.area"}
                          label={"Area"}
                          list={area}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                          value={values.address?.area}
                          error={
                            errors?.address?.area && touched?.address?.area
                          }
                          helperText={
                            errors?.address?.area && touched?.address?.area
                              ? errors?.address?.area
                              : ""
                          }
                        />

                        <Autocomplete
                          sx={{ width: "30%", marginY: "10px" }}
                          size="small"
                          id="company-autocomplete"
                          options={stateCityList?.cityList || []}
                          label="city"
                          name="address.city"
                          value={values?.address?.city || null}
                          getOptionLabel={(option = "") => {
                            if (typeof option === "object") {
                              return option.name;
                            }
                            return option;
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value
                          }
                          freeSolo
                          autoHighlight
                          onBlur={handleBlur}
                          onChange={(e, newValue) => {
                            setFieldValue("address.city", newValue?.name);
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              key={option?.name}
                            >
                              {option?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              name="city"
                              label="City"
                              required
                              error={
                                errors?.address?.city && touched?.address?.city
                              }
                              helperText={
                                errors?.address?.city && touched?.address?.city
                                  ? errors?.address?.city
                                  : ""
                              }
                            />
                          )}
                        />
                        <TextField
                          required
                          name="address.address"
                          label="Address"
                          size="small"
                          sx={{
                            width: "30%",
                            marginY: "10px",
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          error={
                            errors?.address?.address &&
                            touched?.address?.address
                          }
                          helperText={
                            errors?.address?.address &&
                            touched?.address?.address
                              ? errors?.address?.address
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.address}
                          onBlur={handleBlur}
                        />
                        {/* <TextField
                                    name="location"
                                    label="Location"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.location && touched?.location}
                                    helperText={errors?.location && touched?.location ? errors?.location : ""}
                                    onChange={handleChange}
                                    value={values?.location}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="area"
                                    label="Area"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.area && touched?.area}
                                    helperText={errors?.area && touched?.area ? errors?.area : ""}
                                    onChange={handleChange}
                                    value={values?.area}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="district"
                                    label="District"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.district && touched?.district}
                                    helperText={errors?.district && touched?.district ? errors?.district : ""}
                                    onChange={handleChange}
                                    value={values?.district}
                                    onBlur={handleBlur} />
                                <TextField
                                    name="taluka"
                                    label="Taluka"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.taluka && touched?.taluka}
                                    helperText={errors?.taluka && touched?.taluka ? errors?.taluka : ""}
                                    onChange={handleChange}
                                    value={values?.taluka}
                                    onBlur={handleBlur} /> */}
                        {/* <TextField
                                    required
                                    name="state"
                                    label="State"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.state && touched?.state}
                                    helperText={errors?.state && touched?.state ? errors?.state : ""}
                                    onChange={handleChange}
                                    value={values?.state}
                                    onBlur={handleBlur} />
                                <TextField
                                    required
                                    name="city"
                                    label="City"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.city && touched?.city}
                                    helperText={errors?.city && touched?.city ? errors?.city : ""}
                                    onChange={handleChange}
                                    value={values?.city}
                                    onBlur={handleBlur} />
                                <TextField
                                    required
                                    name="country"
                                    label="Country"
                                    size="small"
                                    sx={{ width: "30%", marginY: "10px" }}
                                    error={errors?.country && touched?.country}
                                    helperText={errors?.country && touched?.country ? errors?.country : ""}
                                    onChange={handleChange}
                                    value={values?.country}
                                    onBlur={handleBlur} /> */}
                        <TextField
                          required
                          name="address.pincode"
                          label="Pincode"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                          }
                          helperText={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                              ? errors?.address?.pincode
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.pincode}
                          onBlur={handleBlur}
                        />
                      </Div>
                      <Div>
                        <Typography variant="h1">Bank Details</Typography>
                        <Div
                          sx={{
                            gap: 5,
                            margin: "1% 0",
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            columnGap: 2,
                          }}
                        >
                          <TextField
                            name="kyc.bank_details.bank_name"
                            type="text"
                            value={values.kyc.bank_details.bank_name}
                            label="Bank Name"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />
                          <TextField
                            name="kyc.bank_details.account_no"
                            type="number"
                            value={values.kyc.bank_details.account_no}
                            label="Account Number"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />
                          <TextField
                            name="kyc.bank_details.confirm_account_no"
                            type="number"
                            value={values.kyc.bank_details.confirm_account_no}
                            label="Confirm Account Number"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />
                          <TextField
                            name="kyc.bank_details.ifsc_code"
                            type="text"
                            value={values.kyc.bank_details.ifsc_code}
                            label="IFSC"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />
                          {values?.kyc?.bank_details?.passbook_image && (
                            <Div
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                p: 0.3,
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ mt: 1, fontWeight: 500 }}
                              >
                                Passbook Image:
                              </Typography>
                              <Button
                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                onClick={() =>
                                  handleImagesTab(
                                    values?.kyc?.bank_details?.passbook_image
                                  )
                                }
                              >
                                <PermMediaIcon sx={{ height: "100%" }} />
                              </Button>
                            </Div>
                          )}
                        </Div>
                        <Typography variant="h1">KYC Details</Typography>
                        <Div
                          sx={{
                            gap: 5,
                            margin: "1% 0",
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            columnGap: 2,
                          }}
                        >
                          <TextField
                            name="kyc.pan_card_detail.pan_no"
                            type="text"
                            value={values.kyc.pan_card_detail.pan_no}
                            label="Pan Number"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />
                          {values.kyc.pan_card_detail.pan_image && (
                            <Div
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                p: 0.3,
                                width: "45%",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ mt: 1, fontWeight: 500 }}
                              >
                                Pan Card Image:
                              </Typography>
                              <Button
                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                onClick={() =>
                                  handleImagesTab(
                                    values.kyc.pan_card_detail.pan_image
                                  )
                                }
                              >
                                <PermMediaIcon sx={{ height: "100%" }} />
                              </Button>
                            </Div>
                          )}
                          <TextField
                            name="kyc.aadhar_card_detail.aadhar_no"
                            type="number"
                            value={values.kyc.aadhar_card_detail.aadhar_no}
                            label="Aadhar Number"
                            size="small"
                            disabled
                            sx={{ width: "30%", ...disabledStyling }}
                          />

                          {values.kyc.aadhar_card_detail.aadhar_image && (
                            <Div
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                p: 0.3,
                                width: "45%",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ mt: 1, fontWeight: 500 }}
                              >
                                Aadhar Card Image:
                              </Typography>
                              <Button
                                sx={{ "&:hover": { bgcolor: "transparent" } }}
                                onClick={() =>
                                  handleImagesTab(
                                    values.kyc.aadhar_card_detail.aadhar_image
                                  )
                                }
                              >
                                <PermMediaIcon sx={{ height: "100%" }} />
                              </Button>
                            </Div>
                          )}
                        </Div>
                        {/* <ImageViewer
                          imageURL={selectedImg}
                          setOpen={setOpen}
                          open={open}
                        /> */}
                      </Div>
                      <Div
                        sx={{
                          width: "93.5%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 3,
                          mt: 3,
                        }}
                      >
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          sx={{ width: "100px" }}
                          loading={isSubmitting}
                        >
                          Update
                        </LoadingButton>
                      </Div>
                    </Div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Div>
    </>
  );
};

export default UserProfile;
