import Page from "@jumbo/shared/Page";
import AddEditProduct from "app/pages/Masters/Products/AddEditProduct.js";
import ListProduct from "app/pages/Masters/Products/ListProduct";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/master/product";

export const productRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "product",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListProduct} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "product",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddEditProduct} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "product",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit`,
            element: <Page component={AddEditProduct} />,
          },
        ],
      },
    ],
  },
];
