import Page from "@jumbo/shared/Page";
import AuthenticateAndRolesMiddleware from "../Middleware";
import AddInventory from "app/pages/Inventory/AddInventory";
import AuthenticateMiddleware from "../Middleware/AuthenticateMiddleware";
import ListItems from "app/pages/Inventory/List";
import ProductHistory from "app/pages/Inventory/ProductHistory";

const routesName = "/dashboards/inventory";
const modules = "inventory";

export const inventoryRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListItems} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard/inventory",
              module: modules,
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddInventory} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard/inventory",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/:id`,
            element: <Page component={ProductHistory} />,
          },
        ],
      },
    ],
  },
];
