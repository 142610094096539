import Div from '@jumbo/shared/Div';
import { Box, InputAdornment, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@mui/material'
import { formatDate } from 'app/pages/Component/Function/formatDate';
import { getTransactionHistory } from 'app/redux/actions/PaymentsAndPayouts/Transaction';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';



const TableHeadCell = {
    textAlign: "left",
    minWidth: "200px",
    verticalAlign: "middle",
    textTransform: "capitalize",
    color: "white",
    borderColor: "#7352C7",
}

const stickyTableHeadCellSx = {
    textTransform: "capitalize",
    color: "white",
    minWidth: "200px",
    verticalAlign: "middle",
    position: "sticky",
    right: 0,
    zIndex: 1,
    bgcolor: "#7352C7",
}

const TableRowCell = { textAlign: "left", color: "black", borderColor: "black" }


const TableSortLabelCell = {
    color: "white !important",
    "&:hover": { color: "white !important" },
    "&.MuiTableSortLabel-root.Mui-active": {
        color: "white !important", // Set the color for the active state
    },
}

const tableHead = [
    {
        label: "Transaction Date",
        fieldName: ""
    },
    {
        label: "Transaction Type",
        fieldName: ""
    },
    {
        label: "Company Name",
        fieldName: "commission.companyDetails.companyName"
    },
    {
        label: "Sales Order Amt",
        fieldName: "commission.salesOrderAmount"
    },
    {
        label: "Commission %",
        fieldName: "commission.commissionPercentage"
    },
    {
        label: "Transaction ID",
        fieldName: "payouts.transactionId"
    },
    {
        label: "TDS %",
        fieldName: "payouts.tdsPercentage"
    },
    {
        label: "TDS Amount",
        fieldName: "payouts.tdsAmount"
    },
    {
        label: "Commission received",
        fieldName: "commission.commissionAmount",
        sx: {
            textTransform: "capitalize",
            color: "white",
            minWidth: "200px",
            verticalAlign: "middle",
            position: "sticky",
            right: 200,
            zIndex: 1,
            bgcolor: "#7352C7",
            borderColor: "#7352C7",
        }
    },
    {
        label: "Amount Paid",
        fieldName: "payouts.amountPaid",
        sx: {
            textTransform: "capitalize",
            color: "white",
            minWidth: "200px",
            verticalAlign: "middle",
            position: "sticky",
            right: 0,
            zIndex: 1,
            bgcolor: "#7352C7",
            borderColor: "#7352C7",
        }
    }
]

const ListingTransaction = () => {
    const [sort, setSort] = useState("desc");
    const [sortBy, setSortBy] = useState("created_at");
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const { metId } = useParams();
    const transactionData = useSelector((state) => state?.transactionReducer);
    const dispatch = useDispatch();


    const handleSort = (property) => {
        setSort(sort === "asc" ? "desc" : "asc");
        setSortBy(property);
        setPage(1);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleSearch = (searchTerm) => {
        setPage(1);
        dispatch(getTransactionHistory({
            sortBy: sortBy,
            search_value: searchTerm?.trim(),
            sort: sort,
            page: page,
            metId: metId,
        }));
    };

    const debouncedHandleSearch = debounce(handleSearch, 500);

    useEffect(() => {
        if (searchTerm !== "") {
            debouncedHandleSearch(searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [searchTerm]);


    useEffect(() => {
        if (metId) {
            dispatch(getTransactionHistory({
                sortBy: sortBy,
                sort: sort,
                page: page,
                metId: metId,
                search_value: searchTerm?.trim(),
            }))
        }
    }, [metId, sort, sortBy, page])

    return (
        <Box sx={{ my: 4 }}>
            <Box sx={{ my: 4 }}>
                <TextField
                    id="search"
                    type="search"
                    label="Search"
                    value={searchTerm}
                    size="small"
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        if (e.target.value === "") {
                            dispatch(getTransactionHistory({
                                metId: metId,
                            }));
                        }
                    }}
                    sx={{ width: 400 }}
                    InputProps={{
                        endAdornment: (
                            <Div sx={{ cursor: "pointer" }}>
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            </Div>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ border: "1px solid #7352C7" }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                            {
                                tableHead.map((e, i) => {
                                    return <TableCell key={i} sx={e.sx ? e.sx : { ...TableHeadCell }}>
                                        <TableSortLabel
                                            onClick={() => handleSort(e.fieldName)}
                                            direction={sort}
                                            sx={{ ...TableSortLabelCell }}
                                        >
                                            {e.label}
                                        </TableSortLabel>
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            transactionData?.transactionHistory?.length <= 0 && <TableRow key={transactionData?._id}>
                                <TableCell sx={{ ...TableRowCell,textAlign:"center",border:0 }} colSpan={8}>
                                    {"No Transaction Found"}
                                </TableCell>
                            </TableRow>
                        }
                        {
                            transactionData?.transactionHistory?.map((transactionData) => {
                                return (
                                    <TableRow key={transactionData?._id}>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {formatDate(transactionData?.commission?.salesOrderDate) || formatDate(transactionData?.payouts?.payoutDate)}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.commission ? "Commission" : "Payout"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.commission?.companyDetails?.companyName || "-"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.commission?.salesOrderAmount || "-"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.commission?.commissionPercentage || "-"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.payouts?.transactionId || "-"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.payouts?.tdsPercentage || "-"}
                                        </TableCell>
                                        <TableCell sx={{ ...TableRowCell }}>
                                            {transactionData?.payouts?.tdsAmount || "-"}
                                        </TableCell>
                                        <TableCell sx={{
                                            ...TableRowCell, position: "sticky",
                                            right: 200,
                                            zIndex: 1,
                                            bgcolor: "white",
                                            boxShadow: "inset 1px 0px 0px 0px black",
                                            borderColor: "black"
                                        }}>
                                            <Box>
                                                {transactionData?.commission?.commissionAmount ? `₹ ${transactionData?.commission?.commissionAmount}` : "-"}
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={{
                                            ...TableRowCell, position: "sticky",
                                            right: 0,
                                            zIndex: 1,
                                            bgcolor: "white",
                                            boxShadow: "inset 1px 0px 0px 0px black",
                                            borderColor: "black"
                                        }}>
                                            {transactionData?.payouts?.amountPaid ? `₹ ${transactionData?.payouts?.amountPaid}` : "-"}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell colSpan={7} sx={{
                                width: "100%",
                                borderColor: "#7352C7",
                                bgcolor: "#7352C7",
                            }}></TableCell>
                            <TableCell sx={{
                                ...TableRowCell,
                                position: "sticky",
                                right: 400,
                                zIndex: 1,
                                borderColor: "#7352C7",
                                bgcolor: "#7352C7",
                                color: "white",
                                fontSize: "17px"
                            }}>Total</TableCell>
                            <TableCell sx={{
                                ...TableRowCell,
                                position: "sticky",
                                right: 200,
                                zIndex: 1,
                                borderColor: "#7352C7",
                                bgcolor: "#7352C7",
                                color: "white",
                                fontSize: "17px"
                            }}>₹ {Number(transactionData?.totals?.[0]?.commission || 0).toFixed(2) || 0}</TableCell>
                            <TableCell sx={{
                                ...TableRowCell,
                                position: "sticky",
                                right: 0,
                                zIndex: 1,
                                borderColor: "#7352C7",
                                bgcolor: "#7352C7",
                                color: "white",
                                fontSize: "17px"
                            }}>₹ {Number(transactionData?.totals?.[0]?.payouts || 0).toFixed(2) || 0}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Pagination
                    count={transactionData?.totalPage || 1}
                    page={page}
                    onChange={handleChangePage}
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        p: 1,
                        backgroundColor: "white",
                        borderTop: "1px solid #ddd",
                    }}
                />
            </TableContainer>
        </Box>
    )
}

export default ListingTransaction