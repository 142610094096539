import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import Heading from "app/components/Heading";
import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getAllDispatchOrders } from "app/redux/actions/OrderProcessing/Dispatch";

let imagepath = process.env.REACT_APP_IMAGE_URL;
const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TextFeild({ label, value, sx }) {
  return (
    <Typography id="SupplierName" sx={{ mt: 0, letterSpacing: 0.5, ...sx }}>
      <Typography as="span" sx={{ fontWeight: 600 }}>
        {label}
      </Typography>{" "}
      :- {value}
    </Typography>
  );
}

export default function OutForDeliveryModal({
  open,
  setOpen,
  selectedRow,
  orderType,
  deliveryStatus,
}) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const todaysDate = new Date(Date.now()).toISOString().split("T")[0];
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);

  const initialValues = {
    transport_details: {
      transport_type: "",
      delivery_challan_no: "",
      hand_delivery: {
        person_name: "",
        person_phone_no: "",
      },
      courier: {
        company_name: "",
        docket_no: "",
      },
      road: {
        transporter_name: "",
        vehicle_no: "",
        driver_name: "",
        driver_phone_no: "",
      },
      rail: {
        transporter_name: "",
        awb_no: "",
      },
      air: {
        transporter_name: "",
        awb_no: "",
      },
    },
    tracking_date: {
      out_for_delivery: {
        dispatch_date: todaysDate,
        estimate_delivery_date: todaysDate,
      },
    },
  };

  const validationSchema = yup.object().shape({
    transport_details: yup.object().shape({
      transport_type: yup.string().required("Transportation Type is required"),
      delivery_challan_no: yup.string().required("Challan Number is required"),
      hand_delivery: yup.object().when("transport_type", {
        is: "hand_delivery",
        then: yup.object().shape({
          person_name: yup.string().required("Person Name is required"),
          person_phone_no: yup
            .number()
            .required("Person Phone Number is required"),
        }),
        otherwise: yup.object().nullable(),
      }),
      courier: yup.object().when("transport_type", {
        is: "courier",
        then: yup.object().shape({
          company_name: yup.string().required("Company Name is required"),
          docket_no: yup.string().required("Docket Number is required"),
        }),
        otherwise: yup.object().nullable(),
      }),
      road: yup.object().when("transport_type", {
        is: "road",
        then: yup.object().shape({
          transporter_name: yup
            .string()
            .required("Transporter Name is required"),
          vehicle_no: yup.string().required("Vehicle Number is required"),
          driver_name: yup.string().required("Driver Name is required"),
          driver_phone_no: yup
            .number()
            .required("Driver Phone Number is required"),
        }),
        otherwise: yup.object().nullable(),
      }),
      rail: yup.object().when("transport_type", {
        is: "rail",
        then: yup.object().shape({
          transporter_name: yup
            .string()
            .required("Transporter Name is required"),
          awb_no: yup.string().required("AWB Number is required"),
        }),
        otherwise: yup.object().nullable(),
      }),
      air: yup.object().when("transport_type", {
        is: "air",
        then: yup.object().shape({
          transporter_name: yup
            .string()
            .required("Transporter Name is required"),
          awb_no: yup.string().required("AWB Number is required"),
        }),
        otherwise: yup.object().nullable(),
      }),
    }),
    tracking_date: yup.object().shape({
      out_for_delivery: yup
        .object()
        .shape({
          dispatch_date: yup.date().required("Dispatch Date is required"),
          estimate_delivery_date: yup
            .date()
            .required("Estimate Delivery Date is required"),
        })
        .required("Out for Delivery details are required"),
    }),
  });

  const onUserSave = async (values) => {
    try {
      setSubmitting(true);

      let response = await Axios.patch(
        AllApis.dispatch.outForDelivery(selectedRow?._id),
        values
      );

      if (response?.data?.statusCode) {
        setOpen(false);
        dispatch(
          getAllDispatchOrders(
            "",
            "desc",
            "created_at",
            1,
            orderType,
            deliveryStatus,
            {}
          )
        );
        Swal.fire({
          title: "Saved",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography sx={{ my: 2 }}>
              <Heading Name={"Generate Out For Delivery"} />
            </Typography>
            <Div>
              <Formik
                validateOnChange={true}
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onUserSave}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form noValidate autoComplete="off">
                    <Div sx={{ mt: 4 }}>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 5,
                          rowGap: 1,
                        }}
                      >
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Dispatch Date</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format="DD-MM-YYYY"
                              sx={{
                                width: "100%",
                              }}
                              defaultValue={dayjs(
                                values?.tracking_date.out_for_delivery
                                  ?.dispatch_date
                              )}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "tracking_date.out_for_delivery.dispatch_date",
                                  newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                                );
                              }}
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </LocalizationProvider>
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">
                            Est. Delivery Date
                          </Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              id="to"
                              format="DD-MM-YYYY"
                              sx={{
                                width: "100%",
                              }}
                              defaultValue={dayjs(
                                values?.tracking_date?.out_for_delivery
                                  ?.estimate_delivery_date
                              )}
                              onChange={(newValue) => {
                                setFieldValue(
                                  "tracking_date.out_for_delivery.estimate_delivery_date",
                                  newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                                );
                              }}
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </LocalizationProvider>
                        </Div>
                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Challan No.</Typography>
                          <TextField
                            name="delivery_challan_no"
                            size="small"
                            error={
                              !!(
                                touched.transport_details
                                  ?.delivery_challan_no &&
                                errors.transport_details?.delivery_challan_no
                              )
                            }
                            helperText={
                              touched.transport_details?.delivery_challan_no &&
                              errors.transport_details?.delivery_challan_no
                                ? errors.transport_details?.delivery_challan_no
                                : ""
                            }
                            value={
                              values?.transport_details?.delivery_challan_no
                            }
                            sx={{
                              width: "100%",
                              borderColor:
                                values.transport_details?.delivery_challan_no &&
                                errors.transport_details?.delivery_challan_no
                                  ? "red"
                                  : "",
                            }}
                            onChange={(e) =>
                              setFieldValue(
                                "transport_details.delivery_challan_no",
                                e.target.value
                              )
                            }
                          />
                        </Div>

                        <Div sx={{ width: "45%" }}>
                          <Typography variant="h5">Transport Type</Typography>
                          <Select
                            value={values?.transport_details?.transport_type}
                            sx={{ width: "100%" }}
                            size="small"
                            error={
                              !!(
                                touched.transport_details?.transport_type &&
                                errors.transport_details?.transport_type
                              )
                            }
                            helperText={
                              touched.transport_details?.transport_type &&
                              errors.transport_details?.transport_type
                                ? errors.transport_details?.transport_type
                                : ""
                            }
                            onChange={(e) => {
                              const { delivery_challan_no, ...data } =
                                initialValues.transport_details;
                              setFieldValue("transport_details", {
                                ...data,
                                delivery_challan_no:
                                  values.transport_details.delivery_challan_no,
                              });
                              setFieldValue(
                                "transport_details.transport_type",
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value="hand_delivery">Hand</MenuItem>
                            <MenuItem value="courier">Courier</MenuItem>
                            <MenuItem value="road">Road</MenuItem>
                            <MenuItem value="rail">Rail</MenuItem>
                            <MenuItem value="air">Air</MenuItem>
                          </Select>
                          {touched.transport_details?.transport_type &&
                            errors.transport_details?.transport_type && (
                              <FormHelperText
                                sx={{ color: "#E73145", pl: 1.6 }}
                              >
                                {errors?.transport_details?.transport_type}
                              </FormHelperText>
                            )}
                        </Div>
                        {console.log(values, "error")}
                        {/* Hand Delivery */}
                        {values?.transport_details?.transport_type ==
                          "hand_delivery" && (
                          <>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">Person Name</Typography>
                              <TextField
                                name="person_name"
                                size="small"
                                onKeyDown={(e) => {
                                  const inputValue = parseInt(
                                    e.target.value + e.key,
                                    10
                                  );
                                }}
                                error={
                                  !!(
                                    touched?.transport_details?.hand_delivery
                                      ?.person_name &&
                                    errors?.transport_details?.hand_delivery
                                      ?.person_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.hand_delivery
                                    ?.person_name &&
                                  errors?.transport_details?.hand_delivery
                                    ?.person_name
                                    ? errors?.transport_details?.hand_delivery
                                        ?.person_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.hand_delivery
                                    ?.person_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.hand_delivery
                                      ?.person_name &&
                                    errors?.transport_details?.hand_delivery
                                      ?.person_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.hand_delivery.person_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">
                                Person Phone No.
                              </Typography>
                              <TextField
                                type="number"
                                name="person_phone_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.hand_delivery
                                      ?.person_phone_no &&
                                    errors?.transport_details?.hand_delivery
                                      ?.person_phone_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.hand_delivery
                                    ?.person_phone_no &&
                                  errors?.transport_details?.hand_delivery
                                    ?.person_phone_no
                                    ? errors?.transport_details?.hand_delivery
                                        ?.person_phone_no
                                    : ""
                                }
                                value={
                                  values?.transport_details?.hand_delivery
                                    ?.person_phone_no
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.hand_delivery
                                      ?.person_phone_no &&
                                    errors?.transport_details?.hand_delivery
                                      ?.person_phone_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.hand_delivery.person_phone_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                          </>
                        )}

                        {/* Courier */}
                        {values?.transport_details?.transport_type ==
                          "courier" && (
                          <>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">Company Name</Typography>
                              <TextField
                                name="company_name"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.courier
                                      ?.company_name &&
                                    errors?.transport_details?.courier
                                      ?.company_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.courier
                                    ?.company_name &&
                                  errors?.transport_details?.courier
                                    ?.company_name
                                    ? errors?.transport_details?.courier
                                        ?.company_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.courier
                                    ?.company_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.courier
                                      ?.company_name &&
                                    errors?.transport_details?.courier
                                      ?.company_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.courier.company_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">Docket No.</Typography>
                              <TextField
                                name="docket_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.courier
                                      ?.docket_no &&
                                    errors?.transport_details?.courier
                                      ?.docket_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.courier
                                    ?.docket_no &&
                                  errors?.transport_details?.courier?.docket_no
                                    ? errors?.transport_details?.courier
                                        ?.docket_no
                                    : ""
                                }
                                value={
                                  values?.transport_details?.courier?.docket_no
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.courier
                                      ?.docket_no &&
                                    errors?.transport_details?.courier
                                      ?.docket_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.courier.docket_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                          </>
                        )}

                        {/* Road */}
                        {values?.transport_details?.transport_type ==
                          "road" && (
                          <>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">
                                Transporter Name
                              </Typography>
                              <TextField
                                name="transporter_name"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.road
                                      ?.transporter_name &&
                                    errors?.transport_details?.road
                                      ?.transporter_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.road
                                    ?.transporter_name &&
                                  errors?.transport_details?.road
                                    ?.transporter_name
                                    ? errors?.transport_details?.road
                                        ?.transporter_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.road
                                    ?.transporter_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.road
                                      ?.transporter_name &&
                                    errors?.transport_details?.road
                                      ?.transporter_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.road.transporter_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">Vehicle No.</Typography>
                              <TextField
                                name="vehicle_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.road
                                      ?.vehicle_no &&
                                    errors?.transport_details?.road?.vehicle_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.road
                                    ?.vehicle_no &&
                                  errors?.transport_details?.road?.vehicle_no
                                    ? errors?.transport_details?.road
                                        ?.vehicle_no
                                    : ""
                                }
                                value={
                                  values?.transport_details?.road?.vehicle_no
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.road
                                      ?.vehicle_no &&
                                    errors?.transport_details?.road?.vehicle_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.road.vehicle_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">Driver Name</Typography>
                              <TextField
                                name="driver_name"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.road
                                      ?.driver_name &&
                                    errors?.transport_details?.road?.driver_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.road
                                    ?.driver_name &&
                                  errors?.transport_details?.road?.driver_name
                                    ? errors?.transport_details?.road
                                        ?.driver_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.road?.driver_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.road
                                      ?.driver_name &&
                                    errors?.transport_details?.road?.driver_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.road.driver_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">
                                Driver Phone No.
                              </Typography>
                              <TextField
                                name="driver_phone_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.road
                                      ?.driver_phone_no &&
                                    errors?.transport_details?.road
                                      ?.driver_phone_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.road
                                    ?.driver_phone_no &&
                                  errors?.transport_details?.road
                                    ?.driver_phone_no
                                    ? errors?.transport_details?.road
                                        ?.driver_phone_no
                                    : ""
                                }
                                value={
                                  values?.transport_details?.road
                                    ?.driver_phone_no
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.road
                                      ?.driver_phone_no &&
                                    errors?.transport_details?.road
                                      ?.driver_phone_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.road.driver_phone_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                          </>
                        )}

                        {/* Rail */}
                        {values?.transport_details?.transport_type ==
                          "rail" && (
                          <>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">
                                Transporter Name
                              </Typography>
                              <TextField
                                name="transporter_name"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.rail
                                      ?.transporter_name &&
                                    errors?.transport_details?.rail
                                      ?.transporter_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.rail
                                    ?.transporter_name &&
                                  errors?.transport_details?.rail
                                    ?.transporter_name
                                    ? errors?.transport_details?.rail
                                        ?.transporter_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.rail
                                    ?.transporter_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.rail
                                      ?.transporter_name &&
                                    errors?.transport_details?.rail
                                      ?.transporter_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.rail.transporter_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">AWB No.</Typography>
                              <TextField
                                name="awb_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.rail?.awb_no &&
                                    errors?.transport_details?.rail?.awb_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.rail?.awb_no &&
                                  errors?.transport_details?.rail?.awb_no
                                    ? errors?.transport_details?.rail?.awb_no
                                    : ""
                                }
                                value={values?.transport_details?.rail?.awb_no}
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.rail?.awb_no &&
                                    errors?.transport_details?.rail?.awb_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.rail.awb_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                          </>
                        )}

                        {/* Air */}
                        {values?.transport_details?.transport_type == "air" && (
                          <>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">
                                Transporter Name
                              </Typography>
                              <TextField
                                name="transporter_name"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.air
                                      ?.transporter_name &&
                                    errors?.transport_details?.air
                                      ?.transporter_name
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.air
                                    ?.transporter_name &&
                                  errors?.transport_details?.air
                                    ?.transporter_name
                                    ? errors?.transport_details?.air
                                        ?.transporter_name
                                    : ""
                                }
                                value={
                                  values?.transport_details?.air
                                    ?.transporter_name
                                }
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.air
                                      ?.transporter_name &&
                                    errors?.transport_details?.air
                                      ?.transporter_name
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.air.transporter_name",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                            <Div sx={{ width: "45%" }}>
                              <Typography variant="h5">AWB No.</Typography>
                              <TextField
                                name="awb_no"
                                size="small"
                                error={
                                  !!(
                                    touched?.transport_details?.air?.awb_no &&
                                    errors?.transport_details?.air?.awb_no
                                  )
                                }
                                helperText={
                                  touched?.transport_details?.air?.awb_no &&
                                  errors?.transport_details?.air?.awb_no
                                    ? errors?.transport_details?.air?.awb_no
                                    : ""
                                }
                                value={values?.transport_details?.air?.awb_no}
                                sx={{
                                  width: "100%",
                                  borderColor:
                                    values?.transport_details?.air?.awb_no &&
                                    errors?.transport_details?.air?.awb_no
                                      ? "red"
                                      : "",
                                }}
                                onChange={(e) =>
                                  setFieldValue(
                                    "transport_details.air.awb_no",
                                    e.target.value
                                  )
                                }
                              />
                            </Div>
                          </>
                        )}

                        <Div
                          sx={{
                            width: "93.5%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 3,
                            mt: 3,
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            type="submit"
                            sx={{ width: "100px" }}
                            // loading={isSubmitting}
                          >
                            Save
                          </LoadingButton>
                        </Div>
                      </Div>
                    </Div>
                  </Form>
                )}
              </Formik>
            </Div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
