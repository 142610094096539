export const OverDueDays = function (targetDateStr) {
    let targetDate = new Date(targetDateStr);
    let currentDate = new Date();

    // Check if the current date is after the target date
    if (currentDate > targetDate) {
        let difference = currentDate.getTime() - targetDate.getTime();

        var absoluteDifference = Math.abs(difference);

        // Convert milliseconds to days
        var daysPassed = Math.floor(absoluteDifference / (1000 * 60 * 60 * 24));

        return daysPassed;
    } else {
        return 0; // If the current date is not after the target date, return 0 days
    }
}
