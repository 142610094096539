import {
  ALL_ROLE_FAIL,
  ALL_ROLE_REQUEST,
  ALL_ROLE_SUCCESS,
  ROLE_LIST_SUCCESS,
} from "app/utils/constants/rolesConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allRoles: [],
  TotalPage: null,
};

export const roleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        allRoles: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        allRoles: [],
        error: action.payload,
      };
    case ROLE_LIST_SUCCESS:return{
      ...state,
      loading:false,
      allroles:action?.payload?.data,
      TotalPage: null,
    }
    default:
      return state;
  }
};
