import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import { LoadingButton } from "@mui/lab";

export default function ProductTable({
  values,
  setFieldValue,
  error,
  initialValues,
  setValues,
}) {
  const [productList, setProductList] = useState([]);
  const [loader, setLoader] = useState({
    addProduct: false,
    removeProduct: false,
  });
  const sameState =
    values?.supplier_details.address.state ==
    values?.ssk_details?.bill_to?.address.state;

  //disabled textfield styling
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const handleChange = (newValues, values, setFieldValue, index, e, type) => {
    if (type == "product") {
      const productData = newValues?.current_data;
      //gst
      let gst;
      const gst_percentage = productData?.hsn_code?.gst_percentage;
      sameState
        ? (gst = {
            cgst: gst_percentage / 2,
            sgst: gst_percentage / 2,
          })
        : (gst = {
            igst: gst_percentage,
          });
      const data = {
        product_id: newValues?._id,
        item_name: productData?.product_name,
        category: productData?.category?.category_name,
        sku: productData?.sku,
        hsn_code: productData?.hsn_code?.hsn_code,
        weight: productData?.item_weight,
        unit: productData?.unit?.unit_symbol,
        rate_per_unit: productData?.prices?.offline_store_sales_price,
        quantity: 1,
        item_amount: productData?.prices?.offline_store_sales_price * 1,
        total_amount:
          productData?.prices?.offline_store_sales_price * 1 +
          gst_percentage / 100,
        gst,
        totalGst: gst_percentage,
      };

      //changeing data
      const updatedData = [...values.Items];
      updatedData[index] = data;
      setFieldValue("Items", updatedData);
    }
    if (type == "quantity") {
      const updateData = [...values["Items"]];
      updateData[index] = {
        ...updateData[index],
        [e.target.name]: Number(e.target.value),
        item_amount: updateData[index].rate_per_unit * Number(e.target.value),
        total_amount:
          updateData[index].rate_per_unit * Number(e.target.value) +
          updateData[index].totalGst / 100,
      };
      setFieldValue("Items", updateData);
    }
  };

  const removeRow = (values, setFieldValue, index) => {
    setLoader({ ...loader, removeProduct: true });
    setTimeout(() => {
      const updateData = [...values["Items"]];
      updateData.splice(index, 1);
      setFieldValue("Items", updateData);
      setLoader({ ...loader, removeProduct: false });
    }, 200);
  };

  useEffect(() => {
    let gst;
    sameState
      ? (gst = {
          cgst: 0,
          sgst: 0,
        })
      : (gst = {
          igst: 0,
        });
    const initialData = {
      total_weight: 0,
      total_quantity: 0,
      total_item_amount: 0,
      total_gst: 0,
      total_igst: 0,
      total_cgst: 0,
      total_sgst: 0,
      ...gst,
      total_amount: 0,
    };
    const updatedState = [...values["Items"]].reduce((acc, item) => {
      acc.total_weight += item.weight;
      acc.total_quantity += item.quantity;
      acc.total_item_amount += item.item_amount;
      acc.total_cgst += item.gst.cgst;
      acc.total_sgst += item.gst.sgst;
      acc.total_igst += item.gst.igst;
      acc.total_gst += sameState
        ? item.gst.cgst + item.gst.sgst
        : item.gst.igst;
      acc.total_amount += item.total_amount;
      return acc;
    }, initialData);
    const obj = {};
    Object.entries(updatedState).map((ele) => {
      const value = isNaN(ele[1]) ? 0 : ele[1];
      obj[ele[0]] = value;
    });
    setValues({ ...values, ...obj });
  }, [values["Items"]]);

  useEffect(async () => {
    try {
      const productData = await Axios.get(`${AllApis.product.dropdown}`);
      setProductList(productData?.data?.data);
    } catch (error) {}
  }, []);

  return (
    <Div sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Product
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                SKU
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                HSN Code
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Weight
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Rate
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Item Amount
              </TableCell>
              {sameState ? (
                <>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    CGST
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    SGST
                  </TableCell>
                </>
              ) : (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  IGST
                </TableCell>
              )}
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.Items?.map((row, i,arr) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "250px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <Autocomplete
                    size="small"
                    id="tags-standard"
                    options={productList || []}
                    value={
                      productList.filter(
                        (item) => item._id == values?.Items?.[i]?.product_id
                      )?.[0] || null
                    }
                    getOptionLabel={(option) =>
                      option?.current_data?.product_name
                    }
                    onChange={(e, newValue) => {
                      if (newValue == null) {
                        const updateData = [...values["Items"]];
                        updateData[i] = initialValues.Items[0];
                        setFieldValue("Items", updateData);
                      } else {
                        handleChange(
                          newValue,
                          values,
                          setFieldValue,
                          i,
                          e,
                          "product"
                        );
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.current_data?.product_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={!!error?.Items?.[i]?.product_id}
                        helperText={error?.Items?.[i]?.product_id || ""}
                        {...params}
                        variant="outlined"
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "200px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    name="category"
                    value={row?.category}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    value={row?.sku}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    value={row?.hsn_code}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    value={`${row?.weight} ${row?.unit}`}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    value={`₹ ${row?.rate_per_unit}`}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    type="number"
                    size="small"
                    name="quantity"
                    disabled={row?.category == ""}
                    value={row?.quantity}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                        e.preventDefault(); // Prevent the default behavior of arrow keys
                        const step = e.key === "ArrowDown" ? -1 : 1;
                        const newValue = Math.max(
                          1,
                          (row?.quantity || 0) + step
                        );
                        handleChange(
                          "",
                          values,
                          setFieldValue,
                          i,
                          { target: { value: newValue } },
                          "quantity"
                        );
                      }
                    }}
                    onChange={(e) =>
                      handleChange("", values, setFieldValue, i, e, "quantity")
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    name="item_amount"
                    value={`₹ ${row?.item_amount}`}
                    disabled
                    sx={{
                      width: "100%",
                      ...disabledStyling,
                    }}
                  />
                </TableCell>
                {sameState ? (
                  <>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100%",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      <TextField
                        size="small"
                        value={`${row?.gst?.cgst}%`}
                        disabled
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100%",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      <TextField
                        size="small"
                        value={`${row?.gst?.sgst}%`}
                        disabled
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                        }}
                      />
                    </TableCell>
                  </>
                ) : (
                  <TableCell
                    sx={{
                      textAlign: "left",
                      minWidth: "100%",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    <TextField
                      size="small"
                      value={`${row?.gst?.igst}%`}
                      disabled
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                    />
                  </TableCell>
                )}

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <TextField
                    size="small"
                    disabled={row?.category == ""}
                    value={`₹ ${row?.total_amount}`}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100%",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  <LoadingButton
                    loading={loader.removeProduct}
                    variant="outlined"
                    color="error"
                    disabled={arr.length == 1}
                    onClick={() => removeRow(values, setFieldValue, i)}
                  >
                    Remove
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
          <LoadingButton
            loading={loader.addProduct}
            variant="outlined"
            sx={{ marginX: 2, marginY: 1 }}
            onClick={() => {
              setLoader({ ...loader, addProduct: true });
              setTimeout(() => {
                const updateData = [...values["Items"]];
                updateData.push(initialValues.Items[0]);
                setFieldValue("Items", updateData);

                setLoader({ ...loader, addProduct: false });
              }, 300);
            }}
          >
            Add Product
          </LoadingButton>
      </TableContainer>
      <Div sx={{ mt: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Total weight : {values?.total_weight}
        </Typography>
        {sameState ? (
          <>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              Total CGST : ₹ {values?.total_cgst / 100}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "500" }}>
              Total SGST : ₹ {values?.total_sgst / 100}
            </Typography>
          </>
        ) : (
          <Typography variant="h5" sx={{ fontWeight: "500" }}>
            Total IGST : ₹ {values?.total_igst / 100}
          </Typography>
        )}
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Total Item Amount : ₹ {values?.total_item_amount}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Total GST : ₹ {values?.total_gst / 100}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "500" }}>
          Final Amount : ₹ {values?.total_amount}
        </Typography>
      </Div>
    </Div>
  );
}
