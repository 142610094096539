import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import deepDiff from "deep-diff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Heading from "app/pages/Component/Heading";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";

export default function AddEditProduct() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [imageURL, setImageURL] = useState([]);
  const [apiRun, setApiRun] = useState(false);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New Product" : "Edit Product"
  );
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const initialValues = {
    category: state?.current_data?.category?._id
      ? state?.current_data?.category?._id
      : "",
    product_images: state?.current_data?.product_images
      ? state?.current_data?.product_images
      : [],
    product_name: state?.current_data?.product_name
      ? state?.current_data?.product_name
      : "",
    sku: state?.current_data?.sku ? state?.current_data?.sku : "",
    hsn_code: state?.current_data?.hsn_code?._id
      ? state?.current_data?.hsn_code?._id
      : "",
    gst_percentage: state?.current_data?.hsn_code?.gst_percentage
      ? state?.current_data?.hsn_code?.gst_percentage
      : "",
    unit: state?.current_data?.unit?._id ? state?.current_data?.unit?._id : "",
    unit_symbol: state?.current_data?.unit?.unit_symbol
      ? state?.current_data?.unit?.unit_symbol
      : "",
    retailer_sales_price: state?.current_data?.prices?.retailer_sales_price
      ? state?.current_data?.prices?.retailer_sales_price
      : "",
    website_sales_price: state?.current_data?.prices?.website_sales_price
      ? state?.current_data?.prices?.website_sales_price
      : "",
    offline_store_sales_price: state?.current_data?.prices
      ?.offline_store_sales_price
      ? state?.current_data?.prices?.offline_store_sales_price
      : "",
    show_in_website: state?.current_data?.show_in_website ? true : false,
    show_in_retailer: state?.current_data?.show_in_retailer ? true : false,
    show_in_offline_store: state?.current_data?.show_in_offline_store
      ? true
      : false,
    mrp: state?.current_data?.mrp ? state?.current_data?.mrp : "",
    item_weight: state?.current_data?.item_weight
      ? state?.current_data?.item_weight
      : "",
    short_description: state?.current_data?.short_description
      ? state?.current_data?.short_description
      : "",
    long_description: state?.current_data?.long_description
      ? state?.current_data?.long_description
      : "",
  };
  const validationSchema = yup.object({
    category: yup.string().required("Category is required"),
    show_in_website: yup.boolean(),
    website_sales_price: yup.string().when("show_in_website", {
      is: true,
      then: yup
        .string()
        .required(
          "Website sales price is required when show_in_website is true"
        ),
      otherwise: yup.string(),
    }),
    show_in_offline_store: yup.boolean(),
    offline_store_sales_price: yup.string().when("show_in_offline_store", {
      is: true,
      then: yup
        .string()
        .required(
          "Offline sales price is required when show_in_offline_store is true"
        ),
      otherwise: yup.string(),
    }),
    show_in_retailer: yup.boolean(),
    retailer_sales_price: yup.string().when("show_in_retailer", {
      is: true,
      then: yup
        .string()
        .required(
          "Retailer sales price is required when show_in_retailer is true"
        ),
      otherwise: yup.string(),
    }),
    product_name: yup.string().required("Product Name is required"),
    sku: yup.string().required("SKU is required"),
    hsn_code: yup.string().required("HSN Code is required"),
    unit: yup.string().required("Unit is required"),
    short_description: yup.string().required("Short Description is required"),
    long_description: yup.string().required("Long Description is required"),
    product_images: yup
      .array()
      .min(1, "Product Images is required")
      .max(8, "Maximum of 8 images allowed"),
    mrp: yup
      .number()
      .typeError("Price Must be a Number")
      .required("Price is required"),
    item_weight: yup
      .number()
      .typeError("Product Weight Must be a Number")
      .required("Product Weight is required"),
  });

  const onUserSave = async (values) => {
    let formData = new FormData();
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        formData.append("category", values?.category);
        formData.append("product_name", values?.product_name);
        formData.append("sku", values?.sku);
        formData.append("short_description", values?.short_description);
        formData.append("long_description", values?.long_description);
        formData.append("hsn_code", values?.hsn_code);
        formData.append("show_in_website", values?.show_in_website);
        formData.append("show_in_retailer", values?.show_in_retailer);
        formData.append("show_in_offline_store", values?.show_in_offline_store);
        formData.append("retailer_sales_price", values?.retailer_sales_price);
        formData.append("website_sales_price", values?.website_sales_price);
        formData.append(
          "offline_store_sales_price",
          values?.offline_store_sales_price
        );
        formData.append("mrp", values?.mrp);
        formData.append("item_weight", values?.item_weight);
        formData.append("unit", values?.unit);
        values.product_images.forEach((file, index) => {
          formData.append(`product_images`, file);
        });
        response = await Axios.post(AllApis.product.add, formData);
      } else {
        // Assuming you have a valid 'state' object
        const { product_images, ...rest } = values;

        response = await Axios.patch(AllApis.product.edit(state._id), rest);
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New Product Created"
              : "Product Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/master/product");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = async (event, setFieldValue, values) => {
    const selectedFiles = event.target.files;
    const allImages = [...imageURL, ...selectedFiles];
    if (allImages.length > 8) {
      return Swal.fire({
        title: "Maximum of 8 images allowed",
        icon: "warning",
        timer: 1000,
        showConfirmButton: false,
      });
    } else {
      let newImageURLs = [];
      let newImgFile = [...values?.product_images];

      if (pathname.split("/").pop() != "add") {
        let formData = new FormData();
        Array.from(selectedFiles).forEach((file, index) => {
          formData.append("product_images", file);
        });
        const response = await Axios.post(
          AllApis.product.uploadImg(state._id),
          formData
        );
        if (response?.data?.statusCode) {
          setApiRun(!apiRun);
          Swal.fire({
            title: "Image Uploaded",
            text: "Your images has been Uploaded.",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      } else {
        Array.from(selectedFiles).forEach((file, index) => {
          const url = URL.createObjectURL(file);
          newImageURLs.push(url);
          newImgFile.push(file);
        });
        setImageURL([...imageURL, ...newImageURLs]);
        setFieldValue("product_images", [...newImgFile]);
      }
    }
  };

  const handleDelete = (index, values, setFieldValue) => {
    let newImageURLs = [...imageURL];
    let newImgFile = [...values?.product_images];
    if (pathname.split("/").pop() === "add") {
      newImageURLs.splice(index, 1);
      newImgFile.splice(index, 1);
      // Update the state and form field with the updated arrays
      setImageURL(newImageURLs);
      setFieldValue("product_images", newImgFile);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await Axios.delete(
            AllApis.product.deleteImg(state._id, newImageURLs[index])
          );
          if (response?.data?.statusCode) {
            newImageURLs.splice(index, 1);
            newImgFile.splice(index, 1);
            setImageURL(newImageURLs);
            setFieldValue("product_images", newImgFile);
            Swal.fire({
              title: "Deleted!",
              text: "Your image has been deleted.",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
          }
        }
      });
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(async () => {
    try {
      const categoryData = await Axios.get(`${AllApis.category.dropdown}`);
      const hsnData = await Axios.get(`${AllApis.hsn.dropdown}`);
      const unitData = await Axios.get(`${AllApis.unit.dropdown}`);
      setCategoryList(categoryData?.data?.data);
      setHsnList(hsnData?.data?.data);
      setUnitList(unitData?.data?.data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (pathname.split("/").pop() != "add") {
      (async function () {
        const response = await Axios.get(
          AllApis.product.getProductById(state._id)
        );

        setImageURL(response.data.data.current_data.product_images);
      })();
    }
  }, [apiRun]);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 2,
                  }}
                >
                  <Div sx={{ border: "1px solid", p: 2, borderRadius: "8px" }}>
                    <Div
                      sx={{
                        mt: 2,
                        position: "relative",
                        textAlign: "center",
                        width: 450,
                        "&:hover": {
                          "& label": {
                            opacity: 1,
                          },
                        },
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Card
                        sx={{
                          maxWidth: 450,
                          border: "2px solid",
                          height: "220px",
                          position: "relative", // Added position relative
                        }}
                      >
                        <Div
                          sx={
                            isHovered
                              ? {
                                  height: 300,
                                  width: 450,
                                  backgroundColor: "black",
                                  opacity: 0.1, // Set opacity to 0.2
                                  position: "absolute", // Set position to absolute
                                  top: 0,
                                  left: 0,
                                }
                              : {}
                          }
                        ></Div>
                      </Card>

                      <input
                        type="file"
                        accept="image/*"
                        id="imageInput"
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileChange(e, setFieldValue, values)
                        }
                      />
                      <label
                        htmlFor="imageInput"
                        style={{
                          position: "absolute", // Position absolute for centering
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)", // Centering trick
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        <Typography>Select File</Typography>
                        <Typography>
                          click{" "}
                          <span
                            style={{
                              color: "#7352C7",
                              textDecoration: isHovered && "underline",
                            }}
                          >
                            browse
                          </span>{" "}
                          through your machine
                        </Typography>
                      </label>
                      <ErrorMessage
                        name="product_images"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Div>
                    <Div sx={{ mt: 2, width: 450 }}>
                      {imageURL?.length > 0 && (
                        <Div
                          sx={{
                            width: "100%",
                            rowGap: 1,
                            display: "flex",
                            columnGap: 1,
                            flexWrap: "wrap",
                          }}
                        >
                          {imageURL?.map((element, index) => (
                            <Div
                              key={index}
                              sx={{
                                height: "105px",
                                width: "105px",
                                border: "1px solid",
                                borderRadius: "10px",
                                padding: 0.5,
                                position: "relative",
                              }}
                            >
                              <Typography
                                sx={{
                                  float: "right",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                }}
                              >
                                <CancelIcon
                                  fontSize="small"
                                  onClick={() =>
                                    handleDelete(index, values, setFieldValue)
                                  }
                                  sx={{ color: "red" }}
                                />
                              </Typography>

                              <Box sx={{ height: "100%", width: "100%" }}>
                                <img
                                  style={{ height: "100%", width: "100%" }}
                                  src={
                                    state
                                      ? `${process.env.REACT_APP_IMAGE_URL}/${element}`
                                      : element
                                  }
                                  alt={`Product Image ${index + 1}`}
                                />
                              </Box>
                            </Div>
                          ))}
                        </Div>
                      )}
                    </Div>
                  </Div>

                  <Div sx={{ width: "45%" }}>
                    <Div sx={{ width: "100%", mt: 2, mb: 2 }}>
                      <Typography variant="h5">
                        Active/Inactive Product (Portal)
                      </Typography>
                      {[
                        {
                          name: "show_in_website",
                          price: "website_sales_price",
                          heading: "Website",
                        },
                        {
                          name: "show_in_retailer",
                          price: "retailer_sales_price",
                          heading: "Retailer",
                        },
                        {
                          name: "show_in_offline_store",
                          price: "offline_store_sales_price",
                          heading: "Offline Store",
                        },
                      ].map((item, i) => {
                        return (
                          <Div key={i} sx={{ mb: 1 }}>
                            <Div>
                              <TextField
                                sx={{ width: "70%" }}
                                type="number"
                                error={
                                  !!(touched[item.price] && errors[item.price])
                                }
                                label={`${item.heading} Price`}
                                value={values[item.price]}
                                size="small"
                                onChange={(e) =>
                                  setFieldValue(`${item.price}`, e.target.value)
                                }
                              />
                            </Div>
                            <Div sx={{ display: "flex", alignItems: "center" }}>
                              <Switch
                                checked={values[item.name]}
                                size="small"
                                onChange={(e) =>
                                  setFieldValue(
                                    `${item.name}`,
                                    e.target.checked
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography variant="h6" sx={{ mt: 1 }}>
                                {item.heading}
                              </Typography>
                            </Div>
                          </Div>
                        );
                      })}
                    </Div>
                  </Div>

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Product Name</Typography>

                    <TextField
                      name="product_name"
                      size="small"
                      error={!!(touched.product_name && errors.product_name)}
                      value={values?.product_name}
                      sx={{
                        width: "100%",
                        borderColor:
                          values.product_name && errors.product_name
                            ? "red"
                            : "",
                      }}
                      onChange={(e) =>
                        setFieldValue("product_name", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="product_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Category Name</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={categoryList || []}
                      value={
                        categoryList.filter(
                          (item) => item._id == values?.category
                        )?.[0] || null
                      }
                      getOptionLabel={(option) => option?.category_name}
                      onChange={(e, newValue) =>
                        setFieldValue(
                          "category",
                          newValue == null ? "" : newValue?._id
                        )
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.category_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.category && errors.category}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="category"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">HSN Code</Typography>

                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={hsnList || []}
                      value={
                        hsnList.filter(
                          (item) => item._id == values?.hsn_code
                        )?.[0] || null
                      }
                      getOptionLabel={(option) => option?.hsn_code}
                      onChange={(e, newValue) => {
                        setFieldValue(
                          "hsn_code",
                          newValue == null ? "" : newValue?._id
                        );
                        setFieldValue(
                          "gst_percentage",
                          newValue == null ? "" : newValue.gst.gst_percentage
                        );
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.hsn_code}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.hsn_code && errors.hsn_code}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />

                    <ErrorMessage
                      name="hsn_code"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">GST %</Typography>
                    <TextField
                      name="gst_percentage"
                      size="small"
                      error={
                        !!(touched.gst_percentage && errors.gst_percentage)
                      }
                      value={values?.gst_percentage}
                      disabled
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                        borderColor:
                          values.gst_percentage && errors.gst_percentage
                            ? "red"
                            : "",
                      }}
                    />
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Product Weight</Typography>
                    <TextField
                      name="item_weight"
                      type="number"
                      size="small"
                      error={!!(touched.item_weight && errors.item_weight)}
                      value={values?.item_weight}
                      sx={{
                        width: "100%",
                        borderColor:
                          values.item_weight && errors.item_weight ? "red" : "",
                      }}
                      onChange={(e) =>
                        setFieldValue("item_weight", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="item_weight"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div
                    sx={{
                      width: "45%",
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Div sx={{ width: "47%" }}>
                      <Typography variant="h5">Unit</Typography>
                      <Autocomplete
                        size="small"
                        id="tags-standard"
                        options={unitList || []}
                        value={
                          unitList.filter(
                            (item) => item._id == values?.unit
                          )?.[0] || null
                        }
                        getOptionLabel={(option) => option?.unit_name}
                        onChange={(e, newValue) => {
                          setFieldValue(
                            "unit",
                            newValue == null ? "" : newValue?._id
                          );
                          setFieldValue(
                            "unit_symbol",
                            newValue == null ? "" : newValue.unit_symbol
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option?.unit_name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={touched.unit && errors.unit}
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />

                      <ErrorMessage
                        name="unit"
                        component={"div"}
                        style={{ color: "red" }}
                      ></ErrorMessage>
                    </Div>
                    <Div sx={{ width: "47%" }}>
                      <Typography variant="h5">Symbol</Typography>
                      <TextField
                        name="unit_symbol"
                        size="small"
                        disabled
                        error={!!(touched.unit_symbol && errors.unit_symbol)}
                        value={values?.unit_symbol}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          borderColor:
                            values.unit_symbol && errors.unit_symbol
                              ? "red"
                              : "",
                        }}
                        onChange={(e) =>
                          setFieldValue("unit_symbol", e.target.value)
                        }
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">SKU</Typography>
                    <TextField
                      name="sku"
                      size="small"
                      error={!!(touched.sku && errors.sku)}
                      value={values?.sku}
                      sx={{
                        width: "100%",
                        borderColor: values.sku && errors.sku ? "red" : "",
                      }}
                      onChange={(e) => setFieldValue("sku", e.target.value)}
                    />
                    <ErrorMessage
                      name="sku"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Price</Typography>
                    <TextField
                      name="mrp"
                      type="number"
                      size="small"
                      error={!!(touched.mrp && errors.mrp)}
                      value={values?.mrp}
                      sx={{
                        width: "100%",
                        borderColor: values.mrp && errors.mrp ? "red" : "",
                      }}
                      onChange={(e) => setFieldValue("mrp", e.target.value)}
                    />
                    <ErrorMessage
                      name="mrp"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Short Description</Typography>
                    <ReactQuill
                      theme="snow"
                      value={values?.short_description}
                      onChange={(content, delta, source, editor) => {
                        console.log(content); // This will log the updated value
                        // You can also update your state or perform other actions here
                        setFieldValue("short_description", content);
                      }}
                    />
                    <ErrorMessage
                      name="short_description"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Long Description</Typography>
                    <ReactQuill
                      theme="snow"
                      value={values?.long_description}
                      onChange={(content, delta, source, editor) => {
                        console.log(content); // This will log the updated value
                        setFieldValue("long_description", content);

                        // You can also update your state or perform other actions here
                      }}
                    />
                    <ErrorMessage
                      name="long_description"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={() => navigate("/dashboards/master/product")}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
