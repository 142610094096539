import { ALL_GST_FAIL, ALL_GST_REQUEST, ALL_GST_SUCCESS } from "app/utils/constants/gstConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allGst: [],
  TotalPage: null,
};

export const gstReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_GST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_GST_SUCCESS:
      return {
        ...state,
        loading: false,
        allGst: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_GST_FAIL:
      return {
        ...state,
        loading: false,
        allGst: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
