//midleware
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
const AuthenticateMiddleware = ({ fallbackPath }) => {
  const { error } = useSelector((state) => state.userReducer);
  const isAuthenticated = Cookies.get("isAuthenticate");
  const token = Cookies.get("token");

  if (error == "jwt expired") {
    Cookies.remove("isAuthenticate");
    Cookies.remove("token");
    Cookies.remove("user_id");
    return <Navigate to={fallbackPath["url"]} />;
  } else {
    if (isAuthenticated === "true" && token) {
      return <Outlet />;
    } else {
      return <Navigate to={fallbackPath["url"]} />;
    }
  }
};

export default AuthenticateMiddleware;
