import JumboDdMenu from '@jumbo/components/JumboDdMenu'
import Div from '@jumbo/shared/Div'
import { Box, Button, IconButton, InputAdornment, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AllApis from 'app/Apis/apis'
import { getAllMarketExecutive } from 'app/redux/actions/Masters/MarketExecutive'
import { Axios } from 'index'
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { debounce } from 'lodash';
import ViewMET from '../ViewMET';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { formatDate } from 'app/pages/Component/Function/formatDate';

const tableHeadCellSx = {
    textTransform: "capitalize",
    color: "white",
    minWidth: "175px",
    verticalAlign: "middle",
}


const stickyTableHeadCellSx = {
    textTransform: "capitalize",
    color: "white",
    minWidth: "175px",
    verticalAlign: "middle",
    position: "sticky",
    right: 0,
    zIndex: 1,
    bgcolor: "#7352C7",
}

const tableCellSort = {
    color: "white",
    "&:hover": { color: "white" },
    "&.MuiTableSortLabel-root.Mui-active": {
        color: "white",
    },
}

const MarketExecutiveTableHead = [
    {
        label: "OnBoarding Date",
        field: "current_data.contact_person_details.onboarding_date",
        sort: true
    },
    {
        label: "Company Name",
        field: "current_data.company_details.companyName",
        sort: true
    },
    {
        label: "First Name",
        field: "current_data.contact_person_details.first_name",
        sort: true
    },
    {
        label: "Last Name",
        field: "current_data.contact_person_details.last_name",
        sort: true
    },
    {
        label: "Email Id",
        field: "current_data.contact_person_details.primary_email_id",
        sort: true
    },
    {
        label: "Mobile No",
        field: "current_data.contact_person_details.primary_mobile_no",
        sort: true
    },
    {
        label: "Active Status",
        sort: false
    },
    {
        label: "Stores Mapped",
        sort: false
    },
    {
        label: "City",
        field: "current_data.address.city",
        sort: true
    },
    {
        label: "State",
        field: "current_data.address.state",
        sort: true
    },
    {
        label: "Action",
        sort: false
    }
]

function TextFeild({ label, value, sx }) {
    return <Typography sx={{ mt: 0, letterSpacing: 0.5, fontSize: "16px", ...sx }}>
        <Typography as="span" sx={{ fontWeight: 500, textTransform: "capitalize", fontSize: "16px" }}>{label}</Typography> :- {value}
    </Typography>
}

const ListingMET = ({ filter }) => {
    const marketExecutiveList = useSelector((state) => state?.marketExecutiveReducer);
    const [open, setOpen] = useState(false);
    const [metData, setMetData] = useState({});
    const [sortBy, setSortBy] = useState("created_at");
    const [sort, setSort] = useState("desc");
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSort = (property) => {
        setSort(sort === "asc" ? "desc" : "asc");
        setSortBy(property);
        setPage(1);
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage)
    };

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case "edit":
                navigate(`/dashboards/master/marketExecutiveTeams/edit/${menuItem.metId}`)
                break;
            case "view":
                setOpen(true);
                setMetData(menuItem.metData)
                break;
            case "manage_nominee":
                navigate(`/dashboards/master/marketExecutiveTeams/nominee/${menuItem.metId}`)
                break;
            default:
                navigate(`/dashboards/master/marketExecutiveTeams`)
        }
    }

    const handleIsActive = async (metId, body) => {
        try {
            const { data } = await Axios.patch(AllApis?.marketExective?.update(metId), body);
            if (data?.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: data?.message,
                    timer: 1000,
                    showConfirmButton: false,
                })
                dispatch(getAllMarketExecutive({
                    search_value: searchTerm?.trim(),
                    filters: filter
                }))
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
        }
    }

    const handleSearch = (searchTerm) => {
        setPage(1);
        dispatch(getAllMarketExecutive({
            sortBy: sortBy,
            search_value: searchTerm?.trim(),
            sort: sort,
            page: page,
            filters: filter
        }));
    };

    const debouncedHandleSearch = debounce(handleSearch, 500);

    useEffect(() => {
        if (searchTerm !== "") {
            debouncedHandleSearch(searchTerm);
        }
        return () => {
            debouncedHandleSearch.cancel();
        };
    }, [searchTerm]);


    useEffect(() => {
        dispatch(getAllMarketExecutive({
            sortBy: sortBy,
            sort: sort,
            page: page,
            search_value: searchTerm?.trim(),
            filters: filter
        }))
    }, [page, sort, sortBy, dispatch])

    return (
        <Box>
            <Div sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 4 }}>
                <Div>
                    <TextField
                        id="search"
                        type="search"
                        label="Search"
                        value={searchTerm}
                        size="small"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            if (e.target.value === "") {
                                dispatch(getAllMarketExecutive({
                                    page: 1,
                                    sort: "desc",
                                    sortBy: "created_at",
                                    search_value: "",
                                    filters: filter
                                }));
                            }
                        }}
                        sx={{ width: 400 }}
                        InputProps={{
                            endAdornment: (
                                <Div sx={{ cursor: "pointer" }}>
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </Div>
                            ),
                        }}
                    />
                </Div>
                <Button variant='contained' onClick={() => navigate(`/dashboards/master/marketExecutiveTeams/add`)}>Add Market Executive</Button>
            </Div>
            <Box>
                <TableContainer component={Paper} sx={{ my: "2%" }}>
                    <Table sx={{ minWidth: 600 }} size="medium" >
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#7352C7" }}>
                                {
                                    MarketExecutiveTableHead.map((head, index) => {
                                        return (
                                            <TableCell align={head?.sort !== false ? "left" : "center"} key={index} sx={
                                                head?.label !== "Action" ?
                                                    { ...tableHeadCellSx, textAlign: head?.sort !== false ? "left" : "center" } :
                                                    { ...stickyTableHeadCellSx, height: "100%", borderColor: "#7352C7", textAlign: head?.sort !== false ? "left" : "center" }
                                            }>
                                                {
                                                    head?.sort !== false ? <TableSortLabel
                                                        onClick={() => handleSort(head?.field)}
                                                        direction={sort}
                                                        sx={{ ...tableCellSort }}
                                                    >{head.label}</TableSortLabel> : head.label
                                                }
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                marketExecutiveList?.allMarketExecutive?.data?.MarketExecutive?.map(({ _id, current_data: MET, proposed_changes }, index) => {
                                    return (
                                        <TableRow key={_id} sx={{ "& > td": { color: "black" } }}>
                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {formatDate(MET?.contact_person_details?.onboarding_date)}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize"
                                            }}>
                                                {MET?.company_details?.companyName || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize"
                                            }}>
                                                {MET?.contact_person_details?.first_name || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize"
                                            }}>
                                                {MET?.contact_person_details?.last_name || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {MET?.contact_person_details?.primary_email_id || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {MET?.contact_person_details?.primary_mobile_no || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                            }}>
                                                <Switch
                                                    disabled={!proposed_changes?.status}
                                                    size='small'
                                                    checked={MET?.isActive}
                                                    onChange={(e) => handleIsActive(_id, { isActive: e.target.checked })}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                            }}>
                                                <IconButton aria-label="store" size="small"
                                                    onClick={() => {
                                                        navigate(`/dashboards/master/marketExecutiveTeams/mapped-company/${_id}`)
                                                    }}
                                                >
                                                    <SensorOccupiedIcon sx={{ color: "#7352C7" }} />
                                                </IconButton>
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {MET?.address?.city || "NA"}
                                            </TableCell>

                                            <TableCell align="left" sx={{
                                                textAlign: "left",
                                                verticalAlign: "middle",
                                            }}>
                                                {MET?.address?.state || "NA"}
                                            </TableCell>

                                            <TableCell align="center" sx={{
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                position: "sticky",
                                                right: 0,
                                                zIndex: 1,
                                                bgcolor: "white",
                                            }}>
                                                <JumboDdMenu
                                                    icon={<MoreHorizIcon />}
                                                    menuItems={[
                                                        {
                                                            icon: <EditIcon />,
                                                            title: "Edit",
                                                            action: "edit",
                                                            metId: _id,
                                                            visibility: proposed_changes?.status
                                                        },
                                                        {
                                                            icon: <VisibilityRoundedIcon />,
                                                            title: "View",
                                                            action: "view",
                                                            metId: _id,
                                                            metData: MET,
                                                            visibility: true
                                                        },
                                                        {
                                                            icon: <Diversity2Icon />,
                                                            title: "Manage Nominee",
                                                            action: "manage_nominee",
                                                            metId: _id,
                                                            visibility: true
                                                        },
                                                    ].filter((e) => e?.visibility)}
                                                    onClickCallback={handleItemAction}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                    <Pagination
                        count={marketExecutiveList?.allMarketExecutive?.data?.totalPages || 0}
                        page={page}
                        onChange={handleChangePage}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            p: "1%",
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd",
                        }}
                    />
                </TableContainer>
            </Box>

            <ViewMET open={open} setOpen={setOpen} title={"Market Executive Details"}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ width: "30%" }}>
                        <TextFeild label={"Company Name"} value={metData?.company_details?.companyName || "N/A"} />
                        <TextFeild label={"GST No"} value={metData?.kyc?.gst?.gst_no || "N/A"} />
                        <TextFeild label={"On Boarding date"} value={formatDate(metData?.contact_person_details?.onboarding_date) || "N/A"} />
                        <TextFeild label={"First Name"} value={metData?.contact_person_details?.first_name || "N/A"} />
                        <TextFeild label={"Last Name"} value={metData?.contact_person_details?.last_name || "N/A"} />
                        <TextFeild label={"Primary Email ID"} value={metData?.contact_person_details?.primary_email_id || "N/A"} />
                        <TextFeild label={"Secondary Email ID"} value={metData?.contact_person_details?.secondary_email_id || "N/A"} />
                        <TextFeild label={"Primary Mobile No"} value={metData?.contact_person_details?.primary_mobile_no || "N/A"} />
                        <TextFeild label={"Secondary Mobile No"} value={metData?.contact_person_details?.primary_mobile_no || "N/A"} />
                        <TextFeild label={"Role"} value={metData?.contact_person_details?.role_assign || "N/A"} />
                        <TextFeild label={"Address"} value={`
                            ${metData?.address?.address} 
                            ${metData?.address?.area}
                            ${metData?.address?.location}
                            ${metData?.address?.district}
                            ${metData?.address?.taluka}
                            ${metData?.address?.city}
                            ${metData?.address?.state}
                            ${metData?.address?.pincode}`} />
                    </Box>
                    <Box sx={{ width: "30%" }}>
                        <TextFeild label={"PAN No"} value={metData?.kyc?.pan?.pan_no || "N/A"} />
                        <TextFeild label={"Aadhar No"} value={metData?.kyc?.aadhar?.aadhar_no || "N/A"} />
                        <Box>
                            <Typography sx={{ mt: 2, fontWeight: 500, fontSize: "17px", color: "black" }}>Insurance Details</Typography>
                            <TextFeild label={"Policy No"} value={metData?.insurance?.policy_company_name || "N/A"} />
                            <TextFeild label={"Company Name"} value={metData?.insurance?.policy_no || "N/A"} />
                            <TextFeild label={"Amount"} value={metData?.insurance?.policy_amount || "N/A"} />
                            <TextFeild label={"Renewal Date"} value={formatDate(metData?.insurance?.renewal_date) || "N/A"} />
                        </Box>
                        <Box>
                            <Typography sx={{ mt: 2, fontWeight: 500, fontSize: "17px", color: "black" }}>Bank Details</Typography>
                            <TextFeild label={"Bank Name"} value={metData?.kyc?.bank_details?.bank_name || "N/A"} />
                            <TextFeild label={"Account No"} value={metData?.kyc?.bank_details?.account_no || "N/A"} />
                            <TextFeild label={"IFSC Code"} value={metData?.kyc?.bank_details?.ifsc_code || "N/A"} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontSize: "23px", color: "black" }}>
                        Nominee Details
                    </Typography>
                    <Box>
                        <TableContainer component={Paper} sx={{ my: "2%" }}>
                            <Table sx={{ minWidth: 650 }} size="medium" >
                                <TableHead>
                                    <TableRow sx={{ bgcolor: "#7352C7" }}>
                                        {
                                            ["DOB","Name", "Age", "PAN No", "Aadhar No", "Address",].map((head, index) => {
                                                return (
                                                    <TableCell align={"left"} key={index} sx={{ ...tableHeadCellSx,minWidth: "200px", textAlign: "left" }}>
                                                        {head}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        metData?.nominee?.map((nomineeElem, index) => {
                                            return (
                                                <TableRow key={nomineeElem?._id}>
                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {formatDate(nomineeElem?.nominee_dob)}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {nomineeElem?.nominee_name || "NA"}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {nomineeElem?.nominee_age || "NA"}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {nomineeElem?.kyc?.pan?.pan_no || "NA"}
                                                    </TableCell>

                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {nomineeElem?.kyc?.aadhar?.aadhar_no || "NA"}
                                                    </TableCell>

                                                    <TableCell align="left" sx={{
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                    }}>
                                                        {`
                                                            ${nomineeElem?.address?.address} 
                                                            ${nomineeElem?.address?.area}
                                                            ${nomineeElem?.address?.location}
                                                            ${nomineeElem?.address?.district}
                                                            ${nomineeElem?.address?.taluka}
                                                            ${nomineeElem?.address?.city}
                                                            ${nomineeElem?.address?.state}
                                                            ${nomineeElem?.address?.pincode}`
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                            {/* <Pagination
                        count={marketExecutiveList?.allMarketExecutive?.data?.totalPages || 0}
                        page={page}
                        onChange={handleChangePage}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            p: "1%",
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd",
                        }}
                    /> */}
                        </TableContainer>
                    </Box>
                </Box>
            </ViewMET>
        </Box>
    )
}

export default ListingMET