import Div from '@jumbo/shared/Div'
import { Button, TextField, Typography } from '@mui/material'
import AllApis from 'app/Apis/apis'
import { BranchFormContext } from 'app/pages/Masters/Suppliers/AddEditSuppliers'
import { Form, Formik } from 'formik'
import { Axios } from 'index'
import React, { useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Swal from 'sweetalert2'
import * as yup from "yup"
import axios from 'axios'

const AddKyc = ({ formikRef }) => {
    const { id } = useParams();
    const { branchProps: { data, setData, companyType } } = useContext(BranchFormContext);
    const navigate = useNavigate();


    const handleImagesTab = async (value) => {
        if (value && id && typeof value === "string") {
            const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
            window.open(imageURL.href, '_blank');
        } else {
            const images = URL.createObjectURL(value);
            window.open(images, "__blank")
        }
    }

    const handleUploadFiles = async (feildName, value) => {
        try {
            const formData = new FormData();
            formData.append(feildName, value)
            const fileUpload = await Axios.patch(AllApis?.[companyType]?.branch?.uploadFile(data?.[`${companyType}Id`], id), formData)
            if (fileUpload?.data?.statusCode === 200) {
                Swal.fire({
                    icon: "success",
                    title: fileUpload?.data?.message,
                    text: "",
                    timer: 1000,
                    showConfirmButton: false,
                })
            }
            navigate(`/dashboards/master/${companyType}`)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "File Upload Failed",
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
        }
    }

    return (
        <>
            <Formik
                initialValues={{ ...data.kyc }}
                validationSchema={yup.object().shape({
                    // pan: yup.object().shape({
                    //     pan_no: yup.string()
                    //         .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number')
                    //         .required('PAN number is required'),
                    //     pan_image: yup.mixed(),
                    // }),
                    gst: yup.object().shape({
                        gst_no: yup.string()
                            .matches(/^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})?$/, 'Invalid GST number')
                            .required('GST number is required'),
                        gst_image: yup.mixed(),
                    }),
                    bank_details: yup.object().shape({
                        bank_name: yup.string().required('Bank name is required'),
                        account_no: yup.string().required('Account number is required'),
                        confirm_account_no: yup.string().oneOf([yup.ref('account_no')], 'Account number does not match').required('confirm account number is required'),
                        ifsc_code: yup.string()
                            .matches(/^([A-Z]){4}([0-9]){7}$/, 'Invalid IFSC code')
                            .required('IFSC code is required'),
                        passbook_image: yup.mixed(),
                    }),
                })}
                onSubmit={(values, action) => {
                    setData((pre) => ({ ...pre, kyc: { ...values } }))
                }}
                innerRef={formikRef}
            >
                {({ values, setFieldValue, handleChange, handleBlur, errors, touched }) => {
                    return (
                        <Form>
                            <Typography mt="2%" variant="h4">KYC Details</Typography>
                            {/* <Div>
                                <Typography mt="1%" sx={{ textTransform: "capitalize" }}>PAN</Typography>
                                <Div sx={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    columnGap: 4,
                                }}>
                                    <TextField
                                        required
                                        name={`pan.pan_no`}
                                        label="PAN No"
                                        size="small"
                                        sx={{ width: "45%", marginY: "10px" }}
                                        value={values?.pan?.pan_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.pan?.pan_no && touched?.pan?.pan_no}
                                        helperText={errors?.pan?.pan_no && touched?.pan?.pan_no ? errors?.pan?.pan_no : ""} />
                                    <Div sx={{ width: "45%", marginY: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            name={`pan.pan_image`}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label="PAN Image"
                                            type='file'
                                            size="small"
                                            sx={{ width: id ? "85%" : "100%" }}
                                            // value={values?.pan?.pan_image}
                                            onChange={(e) => {
                                                const { name, files } = e.target
                                                if (files && files.length > 0) {
                                                    setFieldValue(name, files[0])
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            error={errors?.pan?.pan_image && touched?.pan?.pan_image} helperText={errors?.pan?.pan_image && touched?.pan?.pan_image ? errors?.pan?.pan_image : ""} />
                                        {values?.pan?.pan_image && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => { handleImagesTab(values?.pan?.pan_image) }}>
                                            <PermMediaIcon sx={{ height: "100%" }} />
                                        </Button>}
                                        {id && <Button variant='contained' onClick={() => { handleUploadFiles("pan_image", values?.pan?.pan_image) }}>upload</Button>}
                                    </Div>
                                </Div>
                            </Div> */}
                            <Div>
                                <Typography mt="1%" sx={{ textTransform: "capitalize" }}>GST</Typography>
                                <Div sx={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    columnGap: 4,
                                }}>
                                    <TextField
                                        required
                                        name={`gst.gst_no`}
                                        label="GST No"
                                        size="small"
                                        sx={{ width: "45%", marginY: "10px" }}
                                        value={values?.gst?.gst_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.gst?.gst_no && touched?.gst?.gst_no} helperText={errors?.gst?.gst_no && touched?.gst?.gst_no ? errors?.gst?.gst_no : ""} />
                                    <Div sx={{ width: "45%", marginY: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            name={`gst.gst_image`}
                                            label="GST Image"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type='file'
                                            size="small"
                                            sx={{ width: id ? "85%" : "100%" }}
                                            // value={values?.gst?.gst_image}
                                            onChange={(e) => {
                                                const { name, files } = e.target
                                                if (files && files.length > 0) {
                                                    setFieldValue(name, files[0])
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            error={errors?.gst?.gst_image && touched?.gst?.gst_image} helperText={errors?.gst?.gst_image && touched?.gst?.gst_image ? errors?.gst?.gst_image : ""} />
                                        {values?.gst?.gst_image && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => { handleImagesTab(values?.gst?.gst_image) }}>
                                            <PermMediaIcon sx={{ height: "100%" }} />
                                        </Button>}
                                        {id && <Button variant='contained' onClick={() => { handleUploadFiles("gst_image", values?.gst?.gst_image) }}>upload</Button>}
                                    </Div>

                                </Div>
                            </Div>
                            <Div>
                                <Typography mt="1%" sx={{ textTransform: "capitalize" }}>Bank Details</Typography>
                                <Div sx={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    columnGap: 2,
                                }}>
                                    <TextField
                                        required
                                        name={`bank_details.bank_name`}
                                        label="Bank Name"
                                        size="small"
                                        sx={{ width: "30%", marginY: "10px" }}
                                        value={values?.bank_details?.bank_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.bank_details?.bank_name && touched?.bank_details?.bank_name} helperText={errors?.bank_details?.bank_name && touched?.bank_details?.bank_name ? errors?.bank_details?.bank_name : ""} />
                                    <TextField
                                        required
                                        name={`bank_details.account_no`}
                                        label="Account Number"
                                        size="small"
                                        sx={{ width: "30%", marginY: "10px" }}
                                        value={values?.bank_details?.account_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.bank_details?.account_no && touched?.bank_details?.account_no} helperText={errors?.bank_details?.account_no && touched?.bank_details?.account_no ? errors?.bank_details?.account_no : ""} />
                                    <TextField
                                        required
                                        name={`bank_details.confirm_account_no`}
                                        label="Confirm Account Number"
                                        size="small"
                                        sx={{ width: "30%", marginY: "10px" }}
                                        value={values?.bank_details?.confirm_account_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.bank_details?.confirm_account_no && touched?.bank_details?.confirm_account_no} helperText={errors?.bank_details?.confirm_account_no && touched?.bank_details?.confirm_account_no ? errors?.bank_details?.confirm_account_no : ""} />
                                    <TextField
                                        required
                                        name={`bank_details.ifsc_code`}
                                        label="IFSC Code"
                                        size="small"
                                        sx={{ width: "30%", marginY: "10px" }}
                                        value={values?.bank_details?.ifsc_code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors?.bank_details?.ifsc_code && touched?.bank_details?.ifsc_code} helperText={errors?.bank_details?.ifsc_code && touched?.bank_details?.ifsc_code ? errors?.bank_details?.ifsc_code : ""} />

                                    <Div sx={{ width: "30%", marginY: "10px", display: "flex", justifyContent: "space-between" }}>
                                        <TextField
                                            name={`bank_details.passbook_image`}
                                            type='file'
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label="Passbook Image"
                                            sx={{ width: id ? "80%" : "100%" }}
                                            // value={values?.bank_details?.passbook_image}
                                            onChange={(e) => {
                                                const { name, files } = e.target
                                                if (files && files.length > 0) {
                                                    setFieldValue(name, files[0])
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            error={errors?.bank_details?.passbook_image && touched?.bank_details?.passbook_image} helperText={errors?.bank_details?.passbook_image && touched?.bank_details?.passbook_image ? errors?.bank_details?.passbook_image : ""} />
                                        {values?.bank_details?.passbook_image && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => { handleImagesTab(values?.bank_details?.passbook_image) }}>
                                            <PermMediaIcon sx={{ height: "100%" }} />
                                        </Button>}
                                        {id && <Button variant='contained' size='small' onClick={() => { handleUploadFiles("passbook_image", values?.bank_details?.passbook_image) }}>upload</Button>}
                                    </Div>
                                </Div>
                            </Div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default AddKyc