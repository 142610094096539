export const MARKET_EXECUTIVE_COMMISSION_REQUEST = "MARKET_EXECUTIVE_COMMISSION_REQUEST";
export const MARKET_EXECUTIVE_COMMISSION_SUCCESS = "MARKET_EXECUTIVE_COMMISSION_SUCCESS";
export const MARKET_EXECUTIVE_COMMISSION_FAILED = "MARKET_EXECUTIVE_COMMISSION_FAILED";

export const MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST = "MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST";
export const MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS = "MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS";
export const MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED = "MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED";

export const MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST = "MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST";
export const MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS = "MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS";
export const MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED = "MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED";
