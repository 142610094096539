import Page from "@jumbo/shared/Page";
import AuthenticateAndRolesMiddleware from "../Middleware";
import AddEditUsers from "app/pages/User/AddEditUsers";
import ListUsers from "app/pages/User/List";
import AuthenticateMiddleware from "../Middleware/AuthenticateMiddleware";
import DashboardHome from "app/pages/DashboardHomepg";
import ActiveUsersList from "app/pages/User/ActiveUsers";
import UserProfile from "app/pages/Profile";

const routesName = "/dashboards/users";

export const usersRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],

    routes: [
      {
        path: `/dashboard`,
        element: <Page component={DashboardHome} />,
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "user",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListUsers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "user",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddEditUsers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "user",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit`,
            element: <Page component={AddEditUsers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "user",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/active-users`,
            element: <Page component={ActiveUsersList} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "user",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/profile`,
            element: <Page component={UserProfile} />,
          },
        ],
      },
    ],
  },
];
