import AllApis from "app/Apis/apis";
import {
  ALL_DISPATCH_ORDER_FAIL,
  ALL_DISPATCH_ORDER_REQUEST,
  ALL_DISPATCH_ORDER_SUCCESS,
} from "app/utils/constants/dispatchConstant";

import { Axios } from "index";

export const getAllDispatchOrders =
  (search_value, sort, sortBy, page, order_type, deliveryStatus, filter) =>
  async (dispatch) => {
    try {
      const body = {
        filters: {
          ...filter,
        },
        searchFields: {
          string: [
            "current_data.customer_details.bill_to.gst_no",
            "current_data.customer_details.bill_to.address.city",
            "current_data.customer_details.bill_to.address.state",
            "current_data.customer_details.customer_name",
          ],
          numbers: [
            "current_data.dispatch_no",
            "current_data.sales_order_no",
            "current_data.total_weight",
            "current_data.total_amount",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
        order_type: order_type,
        delivery_status: deliveryStatus,
      });

      dispatch({ type: ALL_DISPATCH_ORDER_REQUEST });
      const data = await Axios.post(
        `${AllApis.dispatch.list}?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: ALL_DISPATCH_ORDER_SUCCESS,
        payload: {
          data: data?.data?.data,
          totalPage: data?.data?.totalPages,
          type: order_type,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_DISPATCH_ORDER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
