import AllApis from "app/Apis/apis";
import {
  SAMPLE_LIST_FAIL,
  SAMPLE_LIST_REQUEST,
  SAMPLE_LIST_SUCCESS,
} from "app/utils/constants/sampleConstant";
import { Axios } from "index";

export const getSamples = () => async (dispatch) => {
  try {
    //   const body = {
    //     filters: {},
    //     searchFields: {
    //       string: [
    //       ],
    //       numbers: [],
    //     },
    //   };
    //   if (!search_value) {
    //     search_value = "";
    //   }
    dispatch({ type: SAMPLE_LIST_REQUEST });

    const data = await Axios.get(`${AllApis.sample.list}`);
    dispatch({
      type: SAMPLE_LIST_SUCCESS,
      payload: {
        data: data?.data?.data,
        totalPage: data?.data.totalPages,
      },
    });
  } catch (error) {
    dispatch({
      type: SAMPLE_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};
