import JumboDdMenu from '@jumbo/components/JumboDdMenu'
import Div from '@jumbo/shared/Div'
import { Box, Button, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { FieldArray, Form, Formik, useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StarsIcon from '@mui/icons-material/Stars';
import EditIcon from "@mui/icons-material/Edit";
import * as yup from "yup"
import ContactForm from './ContactForm'
import { useNavigate, useParams } from 'react-router-dom'
import { Axios } from 'index'
import AllApis from 'app/Apis/apis'
import Swal from 'sweetalert2'
import { BranchFormContext } from 'app/pages/Masters/Suppliers/AddEditSuppliers'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const contactData = {
    first_name: "",
    last_name: "",
    role: "",
    primary_email: "",
    secondary_email: "",
    primary_mobile: "",
    secondary_mobile: "",
    isPrimary: false
}

function ModelTextFeild({ name, label, index, handleChange, handleBlur, values, errors, touched }) {
    return (
        <TextField
            name={`contact_person.${index}.${name}`}
            label={label} size="small"
            sx={{ width: "100%", marginY: "10px" }}
            value={values?.contact_person?.[index]?.[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.contact_person?.[index]?.[name] && touched?.contact_person?.[index]?.[name]}
            helperText={touched?.contact_person?.[index]?.[name] && touched?.contact_person?.[index]?.[name] ? errors?.contact_person?.[index]?.[name] : ''} />
    )
}

const AddContact = ({formikRef }) => {
    const {branchProps:{data,setData,companyType,companyId}} = useContext(BranchFormContext);
    const { id } = useParams();
    const [open, setOpen] = useState(null);
    const [contactId, setContactId] = useState('')
    const navigate = useNavigate()

    const addContactFunction = async (values) => {
        try {
            if (id) {
                const contact = await Axios.post(AllApis?.[companyType].contact.add(data?.[`${companyType}Id`], id), values)
                if (contact.data.statusCode === 201) {
                    Swal.fire({
                        icon: "success",
                        title: contact?.data?.status,
                        text: contact?.data?.message,
                        timer: 1000,
                        showConfirmButton: false,
                    })
                    navigate(`/dashboards/master/${companyType}`)
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
        }
    }

    const updateContactFunction = async (values,contactId) => {
        try {
            if (id) {
                const contact = await Axios.patch(AllApis?.[companyType].contact.edit(data?.[`${companyType}Id`], id,contactId), values)
                if (contact.data.statusCode === 200) {
                    Swal.fire({
                        icon: "success",
                        title: contact?.data?.status,
                        text: contact?.data?.message,
                        timer: 1000,
                        showConfirmButton: false,
                    })
                    navigate(`/dashboards/master/${companyType}`)
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
        }
    }

    const addContactDataFormik = useFormik({
        initialValues: { ...contactData },
        validationSchema: yup.object().shape({
            first_name: yup.string().required('First Name is required'),
            last_name: yup.string().required('Last Name is required'),
            role: yup.string().required('Role is required'),
            primary_email: yup.string().email('Invalid email address').required('Primary Email is required'),
            secondary_email: yup.string().email('Invalid email address'),
            primary_mobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Primary Mobile is required'),
            secondary_mobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number'),
            isPrimary: yup.boolean(),
        }),
        onSubmit: ({ arrayHelpers, ...values }, action) => {
            arrayHelpers.push({ ...values })
            if (id) {
                addContactFunction(values)
            }
            action.resetForm();
        }
    })


    const SetPrimaryContact = async (contactId) => {
        try {
            if (id) {
                const primary = await Axios.patch(AllApis?.[companyType].contact.setPrimary(data?.[`${companyType}Id`], id,contactId))
                if (primary.data.statusCode === 200) {
                    Swal.fire({
                        icon: "success",
                        title: primary?.data?.status,
                        text: primary?.data?.message,
                        timer: 1000,
                        showConfirmButton: false,
                    })
                    navigate(`/dashboards/master/${companyType}`)
                }
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
        }
    }

    const handleItemAction = (menuItem) => {
        switch (menuItem.action) {
            case "edit":
                setOpen(menuItem.index)
                setContactId(menuItem?.data?._id)
                break;
            case "setPrimary":
                // setFieldValue(`contact_person.${menuItem.i}.isPrimary`,true)
                if(id){
                    SetPrimaryContact(menuItem?.data?._id)
                }
                break;
            case "remove":
                menuItem.removeList(menuItem.index)
                break;
            default:
                setOpen(false)
        }
    };
    return (
        <>
            <Formik
                initialValues={{
                    contact_person: [...data.contact_person]
                }}
                validationSchema={yup.object().shape({
                    contact_person: yup.array().of(
                        yup.object().shape({
                            first_name: yup.string().required('First Name is required'),
                            last_name: yup.string().required('Last Name is required'),
                            role: yup.string().required('Role is required'),
                            primary_email: yup.string().email('Invalid email address').required('Primary Email is required'),
                            secondary_email: yup.string().email('Invalid email address'),
                            primary_mobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Primary Mobile is required'),
                            secondary_mobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number'),
                            isAcive:yup.boolean()
                        })
                    ).min(1, 'At least one contact person is required')
                })}
                onSubmit={(values, action) => {
                    setData((pre) => ({ ...pre, contact_person: [...values.contact_person] }))
                }}
                innerRef={formikRef}
            >
                {({ values, validateForm, handleBlur, handleChange, setFieldValue,errors, touched }) => {
                    return (
                        <>
                            <Form>
                                <Typography mt="1%" variant="h4">Contact Details</Typography>
                                <Box>
                                    <ContactForm
                                        values={addContactDataFormik.values}
                                        errors={addContactDataFormik.errors}
                                        touched={addContactDataFormik.touched}
                                        handleChange={addContactDataFormik.handleChange}
                                        handleBlur={addContactDataFormik.handleBlur}
                                    />
                                    <FieldArray name="contact_person" render={(arrayHelpers) => {
                                        return (
                                            <>

                                                <Div sx={{ textAlign: "end" }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ height: "100%", margin: "0 125px" }}
                                                        onClick={() => {
                                                            addContactDataFormik.values.arrayHelpers = arrayHelpers
                                                            addContactDataFormik.handleSubmit();
                                                        }}
                                                    >
                                                        Add
                                                    </Button>
                                                </Div>
                                                {values?.contact_person?.length > 0 && <Box sx={{ padding: "2% 8% 2% 0" }}>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }} size="medium" >
                                                            <TableHead>
                                                                <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                                                                    <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >First Name</TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Last Name</TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Role</TableCell>
                                                                    {
                                                                        id && <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "center",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Active</TableCell>
                                                                    }
                                                                    <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Email ID</TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            textTransform: "capitalize",
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Mobile No</TableCell>
                                                                    <TableCell
                                                                        sx={{
                                                                            color: "white",
                                                                            textAlign: "left",
                                                                            minWidth: "150px",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >Action
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    values?.contact_person?.map((ele, i) => {
                                                                        return (
                                                                            <TableRow key={ i && ele._id}>
                                                                                <TableCell align="left" sx={{color:ele.isPrimary ? "rgb(243, 151, 17)" : "black"}}>{ele?.first_name || "NA"}</TableCell>
                                                                                <TableCell align="left" sx={{color:ele.isPrimary ? "rgb(243, 151, 17)" : "black"}}>{ele?.last_name || "NA"}</TableCell>
                                                                                <TableCell align="left" sx={{color:ele.isPrimary ? "rgb(243, 151, 17)" : "black"}}>{ele?.role || "NA"}</TableCell>
                                                                                {id && 
                                                                                <TableCell align="center">
                                                                                    <Switch
                                                                                    disabled={ele?.isPrimary}
                                                                                    name={`contact_person.${i}.isActive`}
                                                                                    size='small'
                                                                                    checked={ele?.isActive}
                                                                                    onChange={(e)=>{
                                                                                        const {name,checked} = e.target
                                                                                        setFieldValue(name,checked)
                                                                                        updateContactFunction({...ele,isActive:checked},ele._id)
                                                                                    }}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    />
                                                                                </TableCell>
                                                                                }
                                                                                <TableCell align="left" sx={{color:ele.isPrimary ? "rgb(243, 151, 17)" : "black"}}>{ele?.primary_email || "NA"}</TableCell>
                                                                                <TableCell align="left" sx={{color:ele.isPrimary ? "rgb(243, 151, 17)" : "black"}}>{ele?.primary_mobile || "NA"}</TableCell>
                                                                                <TableCell sx={{ textAlign: "left" }}>
                                                                                    <JumboDdMenu
                                                                                        icon={<MoreHorizIcon />}
                                                                                        menuItems={[
                                                                                            {
                                                                                                icon: <EditIcon />,
                                                                                                title: "Edit",
                                                                                                action: "edit",
                                                                                                data: ele,
                                                                                                index: i,
                                                                                                visibility:true
                                                                                            },
                                                                                            {
                                                                                                icon: <StarsIcon sx={{color:"rgb(243, 151, 17)"}}/>,
                                                                                                title: "Set As Primary",
                                                                                                action: "setPrimary",
                                                                                                data: ele,
                                                                                                index: i,
                                                                                                setFieldValue:setFieldValue,
                                                                                                visibility:true && id && !ele.isPrimary && ele.isActive
                                                                                            },
                                                                                            {
                                                                                                icon: <ClearIcon />,
                                                                                                title: "Remove",
                                                                                                action: "remove",
                                                                                                data: ele,
                                                                                                index: i,
                                                                                                removeList: arrayHelpers.remove,
                                                                                                visibility:!id
                                                                                            },
                                                                                        ].filter((e,i)=> e.visibility)}
                                                                                        onClickCallback={handleItemAction}
                                                                                    />
                                                                                </TableCell>
                                                                                <Modal
                                                                                    open={open === i}
                                                                                    onClose={() => setOpen(false)}
                                                                                    aria-labelledby="modal-modal-title"
                                                                                    aria-describedby="modal-modal-description"
                                                                                >
                                                                                    <Div sx={style}>
                                                                                        <Typography id="modal-modal-title" variant="h4" component="h2">
                                                                                            Person Details
                                                                                        </Typography>
                                                                                        <Div sx={{
                                                                                            marginLeft: "2%",
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                            flexWrap: "wrap",
                                                                                            columnGap: 4,
                                                                                        }}>
                                                                                            <ModelTextFeild
                                                                                                name="first_name"
                                                                                                label={`First Name`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="last_name"
                                                                                                label={`Last Name`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="role"
                                                                                                label={`Role`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="primary_email"
                                                                                                label={`Primary Email`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="secondary_email"
                                                                                                label={`Secondary Email`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="primary_mobile"
                                                                                                label={`Primary Mobile`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                            <ModelTextFeild
                                                                                                name="secondary_mobile"
                                                                                                label={`Secondary Mobile`}
                                                                                                handleBlur={handleBlur}
                                                                                                handleChange={handleChange}
                                                                                                values={values}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={i}
                                                                                            />
                                                                                        </Div>
                                                                                        <Div sx={{ textAlign: "end" }}>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                sx={{ height: '100%', marginY: '10px' }}
                                                                                                onClick={() => {
                                                                                                    validateForm(values).then((errors) => {
                                                                                                        if (!errors?.contact_person || Object.keys(errors?.contact_person?.[i]).length === 0) {
                                                                                                            if(id){
                                                                                                                updateContactFunction(ele,contactId)
                                                                                                            }
                                                                                                            setOpen(false);
                                                                                                        }
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                Update
                                                                                            </Button>

                                                                                        </Div>
                                                                                    </Div>
                                                                                </Modal>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>}
                                            </>
                                        )
                                    }} />
                                </Box>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </>
    )
}

export default AddContact