import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import deepDiff from "deep-diff";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Heading from "app/pages/Component/Heading";

export default function AddEditTds() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New TDS %" : "Edit TDS %"
  );

  const initialValues = {
    tds_percentage: state?.current_data?.tds_percentage
      ? state?.current_data?.tds_percentage
      : "",
  };

  const validationSchema = yup.object({
    tds_percentage: yup
      .number()
      .typeError("TDS Percentage must be a number")
      .required("TDS Percentage is required"),
  });

  const onUserSave = async (values) => {
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        response = await Axios.post(AllApis.tds.add, values);
      } else {
        response = await Axios.patch(AllApis.tds.edit(state._id), values);
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New TDS % Created"
              : "TDS % Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/master/tds");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(async () => {}, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 1,
                  }}
                >
                  <Div sx={{ width: "45%", mt: 2 }}>
                    <Typography variant="h5">TDS Percentage (%)</Typography>
                    <TextField
                      name="tds_percentage"
                      size="small"
                      error={
                        !!(touched.tds_percentage && errors.tds_percentage)
                      }
                      value={values?.tds_percentage}
                      sx={{
                        width: "100%",
                        borderColor:
                          values.tds_percentage && errors.tds_percentage
                            ? "red"
                            : "",
                      }}
                      onChange={(e) =>
                        setFieldValue("tds_percentage", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="tds_percentage"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={() => navigate("/dashboards/master/tds")}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
