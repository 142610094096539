import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import AllApis from 'app/Apis/apis'
import { getMarketExecutiveCommissionList, getMarketExecutiveDropdown } from 'app/redux/actions/MarketExecutiveCommissionMapping'
import { Field, Form, Formik } from 'formik'
import { Axios } from 'index'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from "yup"

const textFieldSx = {
    width: "100%"
}

const companyType = {
    retailer: "retailers",
    offlinestore: "offlinestores"
}

const AddEditMappingMET = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { MECommissionDropdownReducer } = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleSubmit = async function (values, action) {
        try {
            const { data } = await Axios.post(AllApis?.marketExecutiveCommission?.create, {
                ...values,
                companyId: state?.companyData?.companyId,
                companyType: companyType[state?.companyData?.companyType],
                marketExecutiveId: values.marketExecutiveId._id
            })
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                action.resetForm();
            }
            dispatch(getMarketExecutiveCommissionList({
                companyId: state?.companyData?.companyId
            }))
            dispatch(getMarketExecutiveDropdown(state?.companyData?.companyId))
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    useEffect(() => {
        dispatch(getMarketExecutiveDropdown(state?.companyData?.companyId))
    }, [state?.companyData?.companyId])
    return (
        <>
            <Formik
                initialValues={{
                    marketExecutiveId: {
                        _id: "",
                        name: ""
                    },
                    onBoardingDate: new Date(Date.now()).toISOString().split("T")[0],
                    commissionPercentage: 0
                }}
                validationSchema={yup.object().shape({
                    marketExecutiveId: yup.object().shape({
                        _id: yup.string().required('Market Executive is required'),
                        name: yup.string().required('Market Executive Name is required'),
                    }),
                    onBoardingDate: yup.date().required('Onboarding Date is required'),
                    commissionPercentage: yup.number().min(1, 'Commission Percentage must be greater than 0').max(100, 'Commission Percentage must be less than or equal to 100').required("Commission Percentage is required"),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, errors, touched }) => {
                    return (
                        <Box sx={{ mt: 3 }}>
                            <Typography variant='h3' sx={{ mb: 3 }}>
                                Market Executive
                            </Typography>
                            <Form>
                                <Grid2 container spacing={4}>
                                    <Grid2 xs={4}>
                                        <Field name="companyId">
                                            {({ field, meta }) => {
                                                return <Autocomplete
                                                    sx={{ width: "100%" }}
                                                    size="small"
                                                    id="METcompanyId-autocomplete"
                                                    options={MECommissionDropdownReducer?.marketExecutiveList || []}
                                                    value={values?.marketExecutiveId || ""}
                                                    label="Market Executive"
                                                    getOptionLabel={(option) => option?.name || ""}
                                                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                                                    onChange={(e, newValue) => {
                                                        setFieldValue("marketExecutiveId", {
                                                            _id: newValue?._id,
                                                            name: newValue?.current_data?.contact_person_details?.first_name
                                                        })
                                                    }}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                            {...props}
                                                            key={option?._id}
                                                        >
                                                            {`${option?.current_data?.contact_person_details?.first_name} (${option?.current_data?.contact_person_details?.primary_email_id})`}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" required label="Market Executive"
                                                            error={errors?.marketExecutiveId?._id && touched?.marketExecutiveId?._id}
                                                            helperText={errors?.marketExecutiveId?._id && touched?.marketExecutiveId?._id ? errors?.marketExecutiveId?._id : ""}
                                                        />
                                                    )}
                                                />

                                            }}
                                        </Field>
                                    </Grid2>
                                    <Grid2 xs={4}>
                                        <Field name="onBoardingDate">
                                            {({ field, meta }) => {
                                                return <TextField
                                                    required
                                                    {...field}
                                                    type='date'
                                                    label={"OnBoarding Date"}
                                                    sx={{ ...textFieldSx }}
                                                    size='small'
                                                    error={meta?.error && meta.touched}
                                                    helperText={meta?.error && meta.touched ? meta?.error : ""}
                                                />
                                            }}
                                        </Field>
                                    </Grid2>
                                    <Grid2 xs={4}>
                                        <Field name="commissionPercentage">
                                            {({ field, meta }) => {
                                                return <TextField
                                                    required
                                                    {...field}
                                                    type='number'
                                                    label="Commission Percentage"
                                                    sx={{ ...textFieldSx }}
                                                    size='small'
                                                    error={meta?.error && meta.touched}
                                                    helperText={meta?.error && meta.touched ? meta?.error : ""}
                                                />
                                            }}
                                        </Field>
                                    </Grid2>
                                </Grid2>
                                <Box sx={{ textAlign: "end", my: 4 }}>
                                    <Button variant='outlined' sx={{mx:2}} onClick={()=> navigate(`/dashboards/master/retailer/list/Company`)}>
                                        Back
                                    </Button>
                                    <Button variant='contained' type='submit'>
                                        Map Market Executive
                                    </Button>
                                </Box>
                            </Form>
                        </Box>
                    )
                }}
            </Formik>
        </>
    )
}

export default React.memo(AddEditMappingMET)