import Page from "@jumbo/shared/Page";
import AddPayout from "app/pages/PaymentsAndPayouts/Payouts/AddPayout";
import ListingPayout from "app/pages/PaymentsAndPayouts/Payouts/ListingPayouts";
import TransactionHistory from "app/pages/PaymentsAndPayouts/Payouts/TransactionHistory";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";


const routesName = "/dashboards/payouts";
const modules = "payouts";

export const payoutsRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: "/admin/dashboard",
        module: modules
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: (
              <Page component={ListingPayout} />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add-payout`,
            element: (
              <Page component={AddPayout} />
            ),
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/transactionHistory/:metId`,
            element: (
              <Page component={TransactionHistory} />
            ),
          },
        ],
      },
    ],
  },]
