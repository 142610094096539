import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CreateIcon from "@mui/icons-material/Create";
import EditIcon from "@mui/icons-material/Edit";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewOrderModel from "app/pages/OrderProcessing/Orders/ViewOrderModel";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "app/redux/actions/OrderProcessing/Orders";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Swal from "sweetalert2";
import { formatDate } from "app/pages/Component/Function/formatDate";
import ViewCreateSalesOrderModel from "../ViewCreateSalesOrder";

export default function OrderTable({
  tabletype,
  type,
  page,
  setPage,
  search,
  filter,
  sort,
  setSort,
  sortBy,
  setSortBy,
}) {
  const [openModel, setOpenModel] = useState({
    orderModel: false,
    salesCreateModel: false,
  });
  const orderData = useSelector((state) => state?.orderReducer?.[type]);
  const { TotalPage } = useSelector((state) => state?.orderReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const [orderDetails, setOrderDetails] = useState({});

  const dispatch = useDispatch();
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        // navigate(`/dashboards/users/edit`, { state: { ...menuItem.data } });
        break;
      case "view":
        setOpenModel({ ...openModel, orderModel: true });
        setOrderDetails(menuItem?.data?.current_data);
        break;
      case "create-sales":
        setOpenModel({ ...openModel, salesCreateModel: true });
        setOrderDetails(menuItem?.data?.current_data);
        break;
      default:
      // navigate(`/dashboard/users`);
    }
  };

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
    // dispatch(getAllOrders(search, sortValue, property, page, type, filter()));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeStatus = async (e, row) => {
    try {
      const response = await Axios.patch(AllApis.order.status(row._id), {
        status: e.target.value,
      });
      if (response) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllOrders("", sort, sortBy, page, type, filter()));
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  // useEffect(() => {
  //   dispatch(getAllOrders(search, sort, sortBy, page, type, filter()));
  // }, [sort, page, type]);

  // const changeStatus = async (e, row) => {
  //     try {
  //         const response = await Axios.patch(AllApis.gst.edit(row._id), {
  //             isActive: e.target.checked,
  //         });
  //         if (response) {
  //             Swal.fire({
  //                 title: "Updated",
  //                 icon: "success",
  //                 timer: 1000,
  //                 showConfirmButton: false,
  //             });
  //             dispatch(getAllOrders("", sort, sortBy, page));
  //         }
  //     } catch (error) {
  //         Swal.fire({
  //             title: error.message,
  //             icon: "error",
  //             timer: 1000,
  //             showConfirmButton: false,
  //         });
  //     }
  // };

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.order_no")}
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Order No
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort("current_data.customer_details.customer_name")
                }
                direction={sort}
                sx={{
                  color: "white",
                  minWidth: "150px",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                {tabletype}
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort("current_data.customer_details.bill_to.gst_no")
                }
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                GST No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort(
                    "current_data.customer_details.bill_to.address.city"
                  )
                }
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                City
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() =>
                  handleSort(
                    "current_data.customer_details.bill_to.address.state"
                  )
                }
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                State
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                // onClick={() => handleSort("current_data.gst_percentage")}
                // direction={sort}
                hideSortIcon={true}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Order Date
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "200px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Est. Delivery Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                // onClick={() => handleSort("current_data.gst_percentage")}
                // direction={sort}
                hideSortIcon={true}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.total_weight")}
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Total Weight
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                onClick={() => handleSort("current_data.total_amount")}
                direction={sort}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Total Amount
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
                color: "white",
                position: "sticky",
                height: "58px",
                right: 0,
                zIndex: 1,
                bgcolor: "#7352C7",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderData?.map((row, i) => {
            return (
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {/* {tabledata?.order_no} */}
                  {row?.current_data?.order_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.customer_name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.customer_details?.bill_to?.gst_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.bill_to?.address?.city}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.customer_details?.bill_to?.address?.state}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {formatDate(row?.current_data?.order_date)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {formatDate(row?.current_data?.estimate_delivery_date)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Select
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={!Permission?.["order"]?.["edit"]}
                    value={row?.current_data?.order_status}
                    onChange={(e) => changeStatus(e, row)}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                    <MenuItem value={"closed"}>Closed</MenuItem>
                  </Select>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.total_weight}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  ₹ {row?.current_data?.total_amount?.toFixed(2)}
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <VisibilityRoundedIcon />,
                        title: "View Order",
                        action: "view",
                        data: row,
                        visibility: Permission?.["order"]?.["view"],
                      },
                      {
                        icon: <CreateIcon />,
                        title: "Create Sales Order",
                        action: "create-sales",
                        data: row,
                        visibility: Permission?.["salesOrder"]?.["add"],
                      },
                    ].filter((ele) => ele.visibility == true)}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          p: 1,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
      {openModel.orderModel && (
        <ViewOrderModel
          open={openModel}
          setOpen={setOpenModel}
          selectedRow={orderDetails}
        />
      )}
      {openModel.salesCreateModel && (
        <ViewCreateSalesOrderModel
          open={openModel}
          setOpen={setOpenModel}
          selectedRow={orderDetails}
          type={type}
        />
      )}
    </TableContainer>
  );
}
