import AllApis from "app/Apis/apis";
import { ALL_TDS_FAIL, ALL_TDS_REQUEST, ALL_TDS_SUCCESS } from "app/utils/constants/tdsConstant";
import { Axios } from "index";

export const getAllTds =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "current_data.created_by.name",
          ],
          numbers:[
            "current_data.tds_percentage",
          ]
        },
      };
      if (!search_value) {
        search_value = "";
      }
      
      const urlParams = new URLSearchParams({
        search:search_value.trim(),
        page:page,
        sort:sort,
        sortBy:sortBy
      })

      dispatch({ type: ALL_TDS_REQUEST });
      const data = await Axios.post(
        `${AllApis.tds.list}?${urlParams.toString()}`,
        body
      );
      console.log(data?.data?.data)
      dispatch({
        type: ALL_TDS_SUCCESS,
        payload: {
          data: data?.data?.data?.tds,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_TDS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
