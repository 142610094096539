import AllApis from "app/Apis/apis";
import {
  ALL_UNIT_FAIL,
  ALL_UNIT_REQUEST,
  ALL_UNIT_SUCCESS,
} from "app/utils/constants/unitsConstant";

import { Axios } from "index";

export const getAllUnit =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "current_data.unit_name",
            "current_data.unit_symbol",
            "current_data.created_by.name",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_UNIT_REQUEST });
      const data = await Axios.post(
        `${AllApis.unit.list}?${urlParams.toString()}`,
        body
      );
      dispatch({
        type: ALL_UNIT_SUCCESS,
        payload: {
          data: data?.data?.data?.units,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_UNIT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
