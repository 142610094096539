import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { TextField, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import { getAllTicket } from "app/redux/actions/Tickets";
import SendIcon from "@mui/icons-material/Send";
import CollectionsIcon from '@mui/icons-material/Collections';
import styled from "@emotion/styled";
import { formatDate } from "app/pages/Component/Function/formatDate";
import formatTime from "app/pages/Component/Function/formatTime";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ReplyTicketModal = ({ open, onClose, selectedRow }) => {

  const [isSubmitting, setSubmitting] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [attachment, setAttachment] = useState(null);
  const divRef = useRef(null);
  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.userReducer);

  const handleReplySubmit = async (values) => {
    // const body = {
    //   user_id: userData?._id,
    //   type: "consultant",
    //   reply: replyText,
    // };
    const body = new FormData();
    body.append("user_id", userData?._id)
    body.append("type", "consultant")
    body.append("reply", replyText)
    if (attachment) {
      const { length, ...rest } = attachment
      for (let i in rest) {
        body.append(`attachment`, rest[i]);
      }
    }
    let response;
    try {
      if (replyText || attachment?.length > 0) {
        setSubmitting(true);
        response = await Axios.patch(
          AllApis.ticket.reply(selectedRow?._id),
          body
        );
        if (response?.data?.statusCode) {
          dispatch(getAllTicket("", "desc", "created_at", 1));
        }
        setReplyText("");
        setAttachment(null);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    const scrollToBottom = () => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    };

    // Scroll to the bottom of the replies container when modal is opened
    if (open) {
      setTimeout(scrollToBottom, 100); // Add a small delay (e.g., 100 milliseconds)
    }
  }, [open, selectedRow]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Div className="ticket-viewer">
          <Div className="ticket">
            <Typography variant="h5">
              <span style={{ fontWeight: 600 }}>Ticket:</span>{" "}
              {selectedRow?.complaint}
            </Typography>
            <Div
              className="replies"
              ref={divRef}
              sx={{
                border: "1px solid",
                height: "60vh",
                p: 2,
                overflowY: "scroll",
                scrollBehavior: "smooth",
                scrollMarginTop: "auto",
              }}
            >
              {selectedRow?.replies?.map((reply, replyIndex) => (
                <Div
                  sx={{ width: "100%", columnGap: 2, display: "flex" }}
                  key={replyIndex}
                >
                  <Div
                    sx={{
                      width: "50%",
                    }}
                  >
                    {reply.type == "user" && (
                      <>
                        <Div>
                          {
                            reply?.attachment?.length > 0 && reply?.attachment.filter((file) => file.fileType.split("/")[0] === "image").map((file) => {
                              return (
                                <>
                                  <Div sx={{
                                    width: "150px", bgcolor: "lightgrey",
                                    p: 1,
                                    borderRadius: "10px",
                                    color: "black",
                                    mb: "8px",
                                    float: "left"
                                  }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}//uploads/admin/ticketDocument/${file.fileName}`} alt="" style={{ width: "100%", height: "100%" }} />
                                    <Typography sx={{ fontSize: "10px", textAlign: 'end' }}>{formatTime(reply.replyDateTime)}</Typography>
                                  </Div>
                                </>
                              )
                            })
                          }
                        </Div>
                        <Div
                          sx={{
                            bgcolor: "lightgrey",
                            py: 0.5,
                            px: 1,
                            borderRadius: "10px",
                            color: "white",
                          }}
                        >
                          <Typography sx={{ fontSize: "17px", color: "black", }}>{reply.reply} <span style={{ fontSize: "10px", marginLeft: "3px" }}>{formatTime(reply.replyDateTime)}</span></Typography>
                        </Div>
                      </>
                      // <Typography variant="h6" sx={{ float: "left" }}>
                      //   <Div
                      //     sx={{
                      //       bgcolor: "lightgrey",
                      //       py: 0.5,
                      //       px: 1,
                      //       borderRadius: "10px",
                      //       color: "black",
                      //     }}
                      //   >
                      //     <Typography>{reply.reply}</Typography>
                      //   </Div>
                      // </Typography>
                    )}
                  </Div>
                  <Div
                    key={replyIndex}
                    sx={{
                      width: "50%",
                    }}
                  >
                    {reply.type != "user" && (
                      <>
                        <Div>
                          {
                            reply?.attachment?.length > 0 && reply?.attachment.filter((file) => file.fileType.split("/")[0] === "image").map((file) => {
                              return (
                                <>
                                  <Div sx={{
                                    width: "150px", bgcolor: "#7352C7",
                                    p: 1,
                                    borderRadius: "10px",
                                    color: "white",
                                    mb: "8px",
                                    float: "right"
                                  }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}//uploads/admin/ticketDocument/${file.fileName}`} alt="" style={{ width: "100%", height: "100%" }} />
                                    <Typography sx={{ fontSize: "10px", textAlign: 'end' }}>{formatTime(reply.replyDateTime)}</Typography>
                                  </Div>
                                </>
                              )
                            })
                          }
                          <Div
                            sx={{
                              bgcolor: "#7352C7",
                              py: 0.5,
                              px: 1,
                              borderRadius: "10px",
                              color: "white",
                              float: "right"
                            }}
                          >
                            <Typography sx={{ fontSize: "17px" }}>{reply.reply} <span style={{ fontSize: "10px", marginLeft: "3px" }}>{formatTime(reply.replyDateTime)}</span></Typography>
                          </Div>
                        </Div>
                      </>
                      // <Typography variant="h6" sx={{ float: "right" }}>
                      //   <Div
                      //     sx={{
                      //       bgcolor: "#7352C7",
                      //       py: 0.5,
                      //       px: 1,
                      //       borderRadius: "10px",
                      //       color: "white",
                      //     }}
                      //   >
                      //     <Typography>{reply.reply}</Typography>
                      //   </Div>
                      // </Typography>
                    )}
                  </Div>
                </Div>
              ))}
            </Div>
            {/* Input box for replying */}
            {selectedRow.ticket_status == "pending" && (
              <Div
                sx={{
                  display: "flex",
                  width: "100%",
                  columnGap: 2,
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Your Reply"
                  variant="outlined"
                  fullWidth
                  multiline
                  value={replyText}
                  onChange={handleReplyChange}
                  sx={{ width: "80%" }}
                  margin="normal"
                />
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  startIcon={<CollectionsIcon sx={{ color: "#1976d2" }}></CollectionsIcon>}
                >
                  <VisuallyHiddenInput type="file" multiple onChange={(e) => {
                    console.log(e.target.files)
                    setAttachment(e.target.files)
                  }} />
                </Button>
                <Div
                  sx={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    bgcolor: "#7352C7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 1,
                    pl: 0.5,
                    cursor: replyText != "" && "pointer",
                  }}
                  onClick={handleReplySubmit}
                >
                  <SendIcon sx={{ color: "white" }} />
                </Div>
                {/* <LoadingButton
              onClick={handleReplySubmit}
              variant="contained"
              disabled={replyText == ""}
              size="medium"
              sx={{ width: "30%" }}
              loading={isSubmitting}
            >
              Submit Reply
            </LoadingButton> */}
              </Div>
            )}
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

export default ReplyTicketModal;
