import AllApis from "app/Apis/apis";
import {
  ALL_TICKETS_FAIL,
  ALL_TICKETS_REQUEST,
  ALL_TICKETS_SUCCESS,
} from "app/utils/constants/ticketConstant";
import { Axios } from "index";

export const getAllTicket =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "complaint",
            "user_id.current_data.company_name",
            "ticket_status",
          ],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ALL_TICKETS_REQUEST });
      const data = await Axios.post(
        `${AllApis.ticket.list}?${urlParams.toString()}`,
        body
      );
      console.log(data.data.data);
      dispatch({
        type: ALL_TICKETS_SUCCESS,
        payload: {
          data: data?.data?.data?.tickets,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ALL_TICKETS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
