const { ALL_MARKET_EXECUTIVE_FAILED, ALL_MARKET_EXECUTIVE_SUCCESS, ALL_MARKET_EXECUTIVE_REQUEST, SINGLE_MARKET_EXECUTIVE_REQUEST, SINGLE_MARKET_EXECUTIVE_SUCCESS, SINGLE_MARKET_EXECUTIVE_FAILED } = require("app/utils/constants/MarketExecutiveConstant")

const INTI_STATE = {
    loading: false,
    singleMarketExecutive: {},
    allMarketExecutive: [],
    totalPage: 0,
    error: false,
    errorMessage: null
}

export const marketExecutiveReducer = (state = INTI_STATE, action) => {
    switch (action.type) {
        case ALL_MARKET_EXECUTIVE_REQUEST:
            return {
                ...state, loading: true
            }
        case ALL_MARKET_EXECUTIVE_SUCCESS:
            return {
                ...state,
                allMarketExecutive: action.payload.data,
                totalPage: action.payload.totalPage,
                loading: false
            }
        case ALL_MARKET_EXECUTIVE_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                loading: false
            }
        //single market executive
        case SINGLE_MARKET_EXECUTIVE_REQUEST:
            return {
                ...state, loading: true
            }
        case SINGLE_MARKET_EXECUTIVE_SUCCESS:
            return {
                ...state,
                singleMarketExecutive: action.payload.data,
                loading: false
            }
        case SINGLE_MARKET_EXECUTIVE_FAILED:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                loading: false
            }
        default:
            return state
    }
}