import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import OTPInput from "react-otp-input";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import {
  clearUserDetailsError,
  getUserDetails,
  login,
} from "app/redux/actions/User";
import AllApis from "app/Apis/apis";
import { Axios, socket } from "index";
import Cookies from "js-cookie";
import "./item.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = yup.object({
  username: yup.string("Enter your Username").required("Username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = ({ disableSmLogin }) => {
  // const { error, loading, isAuthenticated, user } = useSelector(
  //   (state) => state.userReducer
  // );
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [type, setType] = useState({
    signIn: true,
    otp: false,
    reset: false,
    verify: false,
  });
  const [showPassword, setShowPassword] = React.useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [username, setUsername] = useState("");
  const isAuthenticate = Cookies.get("isAuthenticate");
  const token = Cookies.get("token");

  const handleClickShowPassword = (name) => {
    setShowPassword((pre) => ({ ...pre, [name]: !pre[name] }));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUserDetailsError());
  }, []);

  const onSignIn = async (values) => {
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.users.login, values);
      if (response?.data?.statusCode == 200) {
        Cookies.set("isAuthenticate", true);
        Cookies.set("user_id", response.data.user._id);
        Cookies.set("token", response.data.token);
        Swal.fire({
          title: "Login Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        socket.emit("activeUser", {
          userID: response.data.user._id,
          token: response.data.token
        });
        navigate("/dashboard");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
        customClass: {
          container: "popupImportant",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onSendOTP = async (values) => {
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.users.forgotpassword, {
        email: values.username,
      });
      if (response?.data?.statusCode == 200) {
        setType((prev) => ({ ...prev, verify: true }));
        setUsername(values.username);
        Swal.fire({
          title: "OTP Send Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
        customClass: {
          container: "popupImportant",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };
  const verifyOtp = async () => {
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.users.verifyotp, {
        email: username,
        otp: forgetPassword,
      });
      if (response?.data?.statusCode == 200) {
        setType((prev) => ({
          ...prev,
          otp: false,
          verify: false,
          reset: true,
        }));
        Swal.fire({
          title: "OTP Verified Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
        customClass: {
          container: "popupImportant",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onReset = async (values) => {
    try {
      setSubmitting(true);
      const body = {
        newPassword: values.newPassword,
        email: username,
      };
      const response = await Axios.post(AllApis.users.updatePassword, body);
      if (response?.data?.statusCode == 200) {
        setType((prev) => ({
          ...prev,
          signIn: true,
          otp: false,
          reset: false,
          verify: false,
        }));
        setForgetPassword("");
        setUsername("");
        Swal.fire({
          title: "Password Updated Successful",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
        customClass: {
          container: "popupImportant",
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPassword = () => {
    setType((prev) => ({ ...prev, signIn: false, otp: true }));
  };

  useEffect(() => {
    isAuthenticate && token && navigate("/dashboard");
  }, []);

  return (
    <Div
      sx={{
        width: "100vw",
        height: "100vh",
        margin: "auto",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 9999,
        bgcolor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          width: 720,
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            // background: `#0267a0 url(${getAssetPath(
            //   `${ASSET_IMAGES}/widgets/keith-luke.jpg`,
            //   "640x428"
            // )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                {type.signIn && "Sign In"}
                {type.otp && "OTP"}
                {type.reset && "Reset Password"}
              </Typography>
              {type?.signIn && (
                <Typography variant={"body1"} mb={2}>
                  By signing in, you can access the dashboard of SSK Bharat.
                </Typography>
              )}
            </Div>

            <Div sx={{ mt: "auto" }}></Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          {type.signIn && (
            <Formik
              validateOnChange={true}
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSignIn}
            >
              {({ values }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name="username"
                      label="Username"
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="password"
                      label="Password"
                      type={isCheckbox ? "text" : "password"} // Step 4
                    />
                  </Div>
                  <Div sx={{ mb: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckbox}
                          onChange={() => setIsCheckbox(!isCheckbox)}
                        />
                      }
                      label="Show Password"
                    />
                  </Div>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    size="medium"
                    type="submit"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Sign In
                  </LoadingButton>

                  {!disableSmLogin && (
                    <React.Fragment>
                      <Typography
                        variant={"body1"}
                        mb={2}
                        sx={{
                          "&:hover": { cursor: "pointer", color: "blue" },
                          width: "40%",
                        }}
                        onClick={handleForgotPassword}
                      >
                        Forgot Password?
                      </Typography>
                    </React.Fragment>
                  )}
                </Form>
              )}
            </Formik>
          )}
          {type.otp && (
            <Formik
              validateOnChange={true}
              initialValues={{
                username: "",
              }}
              validationSchema={yup.object({
                username: yup
                  .string("Enter your Username")
                  .required("Username is required"),
              })}
              onSubmit={onSendOTP}
            >
              {({ values }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name="username"
                      label="Username"
                    />
                  </Div>
                  {type.verify && (
                    <Box mb={2} px={0.5}>
                      <Typography mb={2}>Enter OTP</Typography>
                      <OTPInput
                        value={forgetPassword?.otp}
                        shouldAutoFocus={true}
                        containerStyle={{
                          justifyContent: "space-between",
                        }}
                        inputStyle={{
                          width: "3rem",
                          height: "3rem",
                          border: "2px solid #7352C7",
                          borderRadius: "5px",
                        }}
                        onChange={(value) => {
                          setForgetPassword({
                            ...forgetPassword,
                            otp: value,
                          });
                        }}
                        numInputs={5}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </Box>
                  )}
                  {!type.verify && (
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      size="medium"
                      type="submit"
                      sx={{ mb: 3 }}
                      loading={isSubmitting}
                    >
                      Send OTP
                    </LoadingButton>
                  )}

                  {type.verify && (
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={() => verifyOtp()}
                      sx={{ mb: 3 }}
                      loading={isSubmitting}
                    >
                      Verify OTP
                    </LoadingButton>
                  )}
                </Form>
              )}
            </Formik>
          )}
          {type.reset && (
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={yup.object().shape({
                newPassword: yup.string().required("New password is required"),
                confirmPassword: yup
                  .string()
                  .oneOf([yup.ref("newPassword"), null], "Passwords must match")
                  .required("Confirm password is required"),
              })}
              onSubmit={onReset}
            >
              {({ values }) => (
                <Form>
                  <Box sx={{ mt: 4 }}>
                    <Field name="newPassword">
                      {({ field, meta }) => {
                        return (
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-new-password">
                              New Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-new-password"
                              type={
                                showPassword?.newPassword ? "text" : "password"
                              }
                              {...field}
                              name="newPassword"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    name="newPassword"
                                    onClick={() =>
                                      handleClickShowPassword("newPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showPassword?.newPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="New Password"
                              error={meta?.error && meta?.touched}
                            />
                            {meta?.error && meta?.touched && (
                              <FormHelperText
                                id="outlined-adornment-new-password"
                                sx={{ color: "#E73145" }}
                              >
                                {meta?.error}
                              </FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                    <Field name="confirmPassword">
                      {({ field, meta }) => {
                        return (
                          <FormControl
                            sx={{ width: "100%", mt: 3 }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-confirm-password">
                              Confirm Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-confirm-password"
                              type={
                                showPassword?.confirmPassword
                                  ? "text"
                                  : "password"
                              }
                              {...field}
                              name="confirmPassword"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    name="confirmPassword"
                                    onClick={() =>
                                      handleClickShowPassword("confirmPassword")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showPassword?.confirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Confirm Password"
                              error={meta?.error && meta?.touched}
                            />
                            {meta?.error && meta?.touched && (
                              <FormHelperText
                                id="outlined-adornment-confirm-password"
                                sx={{ color: "#E73145" }}
                              >
                                {meta?.error}
                              </FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ my: 3 }}
                    >
                      Reset Password
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
