import Div from "@jumbo/shared/Div";
import {
  Button,
  InputAdornment,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "app/pages/Component/Heading";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { getAllCompanyList } from "app/redux/actions/Masters/Suppliers";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import EditCompanyModel from "../EditCompanyName";
import { debounce, set } from "lodash";
import Swal from "sweetalert2";
import { updateCompanyList } from "app/Apis/Masters/Suppliers";
import Loader from "app/components/Loader";
import { formatDate } from "app/pages/Component/Function/formatDate";

const tableCellSx = {
  textTransform: "capitalize",
  color: "white",
  textAlign: "left",
  minWidth: "175px",
  verticalAlign: "middle",
};

const tableCellSort = {
  color: "white",
  "&:hover": { color: "white" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white",
  },
};

const ListCompanyName = ({ companyType }) => {
  const companyNameList = useSelector(
    (state) => state?.[`${companyType}Reducer`]?.company
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [sortBy, setSortBy] = useState("created_at");
  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(1);

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        setOpen(true);
        setCompanyData({
          _id: menuItem?.companyId,
          username: menuItem?.companyData?.username,
          company_name: menuItem?.companyData?.company_name,
          onboarding_date: menuItem?.companyData?.onboarding_date,
          pan: {
            pan_no: menuItem?.companyData?.pan?.pan_no,
            pan_image: menuItem?.companyData?.pan?.pan_image,
          },
        });
        break;
      case "manageMarketExecutive":
        navigate(`/dashboards/master/${companyType}/map-market-executive`, {
          state: {
            companyData: menuItem?.companyData,
          },
        });
        break;
      default:
        navigate(`/dashboard/master/${companyType}`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleIsActive = async (companyId, body, companyType) => {
    try {
      const data = await updateCompanyList(companyId, body, companyType);
      if (data?.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: data?.status,
          text: data?.message,
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(
          getAllCompanyList({
            companyType: companyType,
          })
        );
      }
      setOpen(false);
    } catch (error) {
      Swal.fire({
        icon: "success",
        title: error?.response?.data?.message,
        text: "",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  const handleSearch = (searchTerm) => {
    setPage(1);
    dispatch(
      getAllCompanyList({
        companyType: companyType,
        sortBy: sortBy,
        search_value: searchTerm.trim(),
        sort: sort,
        page: page,
      })
    );
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getAllCompanyList({
        companyType: companyType,
        search_value: searchTerm.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      })
    );
  }, [companyType, page, sort]);

  return (
    <>
      {companyNameList?.loading && <Loader />}

      <Typography as="div" sx={{ mt: -4, textTransform: "capitalize" }}>
        <Heading Name={companyType}></Heading>
      </Typography>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Div>
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === "") {
                dispatch(
                  getAllCompanyList({
                    companyType: companyType,
                    page: 1,
                    sort: "desc",
                    sortBy: "created_at",
                    search_value: "",
                  })
                );
              }
            }}
            sx={{ width: 400 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Div>
        <Div>
          <Button
            variant="outlined"
            sx={{ mx: 2 }}
            onClick={() => {
              navigate(`/dashboards/master/${companyType}`);
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/dashboards/master/${companyType}/add`);
            }}
          >
            Add New {companyType}
          </Button>
        </Div>
      </Div>
      <TableContainer component={Paper} sx={{ my: "2%" }}>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7" }}>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.onboarding_date`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >
                  OnBoarding Date
                </TableSortLabel>
              </TableCell>
              {
                (companyType === "retailer" || companyType === "offlinestore") && <TableCell align={"left"} sx={{ ...tableCellSx }}>
                  <TableSortLabel
                    onClick={() => handleSort(`current_data.username`)}
                    direction={sort}
                    sx={{ ...tableCellSort }}
                  >
                    User Name
                  </TableSortLabel>
                </TableCell>
              }
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.company_name`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >
                  Company Name
                </TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                <TableSortLabel
                  onClick={() => handleSort(`current_data.pan.pan_no`)}
                  direction={sort}
                  sx={{ ...tableCellSort }}
                >
                  PAN No
                </TableSortLabel>
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                Active
              </TableCell>
              <TableCell align={"left"} sx={{ ...tableCellSx }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyNameList?.allCompany?.map(
              ({ _id, current_data: company, proposed_changes }, index) => {
                return (
                  <TableRow key={_id}>
                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {
                        formatDate(company?.onboarding_date)
                      }
                    </TableCell>

                    {(companyType === "retailer" || companyType === "offlinestore") && <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      {company?.username || "N/A"}
                    </TableCell>}

                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                        textTransform: "capitalize",
                      }}
                    >
                      {company?.company_name}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                        textTransform: "capitalize",
                      }}
                    >
                      {company?.pan?.pan_no || "N/A"}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      <Switch
                        disabled={!proposed_changes?.status}
                        size="small"
                        checked={company?.isActive}
                        onChange={(e) =>
                          handleIsActive(
                            _id,
                            { isActive: e.target.checked },
                            companyType
                          )
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        textAlign: "left",
                        verticalAlign: "middle",
                      }}
                    >
                      <JumboDdMenu
                        icon={<MoreHorizIcon />}
                        menuItems={[
                          {
                            icon: <EditIcon />,
                            title: "Edit",
                            action: "edit",
                            companyId: _id,
                            companyData: company,
                            visibility: proposed_changes?.status,
                          },
                          {
                            icon: <ManageAccountsIcon />,
                            title: "Manage Market Executive",
                            action: "manageMarketExecutive",
                            companyData: {
                              companyId: _id,
                              username: company?.username,
                              companyName: company?.company_name,
                              companyType: companyType,
                            },
                            visibility:
                              companyType === "retailer" ||
                              companyType === "offlinestore",
                          },
                        ].filter((e) => e?.visibility)}
                        onClickCallback={handleItemAction}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
        <Pagination
          count={companyNameList?.TotalPage || 0}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: "1%",
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
      {open && (
        <EditCompanyModel
          open={open}
          setOpen={setOpen}
          companyData={companyData}
          companyType={companyType}
        />
      )}
    </>
  );
};

export default ListCompanyName;
