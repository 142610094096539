import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Heading from "app/pages/Component/Heading";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";

export default function AddEditHsn() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [gstList, setGstList] = useState([]);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Add New HSN Code" : "Edit HSN Code"
  );

  const initialValues = {
    hsn_code: state?.current_data?.hsn_code
      ? state?.current_data?.hsn_code
      : "",
    gst_percentage: state?.current_data?.gst_percentage?._id
      ? state?.current_data?.gst_percentage?._id
      : "",
  };
  
  const validationSchema = yup.object({
    hsn_code: yup
      .string()
      .typeError("HSN Code must be a number")
      .required("HSN Code is required"),
    gst_percentage: yup.string().required("GST Percentage is required"),
  });

  const onUserSave = async (values) => {
    let response;
    try {
      setSubmitting(true);
      if (pathname.split("/").pop() === "add") {
        response = await Axios.post(AllApis.hsn.add, values);
      } else {
        // Assuming you have a valid 'state' object
        response = await Axios.patch(AllApis.hsn.edit(state._id), getUpdatedFields(state.current_data, values));
      }
      if (response?.data?.statusCode) {
        Swal.fire({
          title:
            pathname.split("/").pop() === "add"
              ? "New GST % Created"
              : "GST % Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/master/hsnCode");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(async () => {
    try {
      const data = await Axios.get(`${AllApis.gst.dropdown}`);
      setGstList(data?.data?.data);
    } catch (error) {}
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 1,
                  }}
                >
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">HSN Code</Typography>
                    <TextField
                      name="hsn_code"
                      size="small"
                      error={touched.hsn_code && errors.hsn_code}
                      value={values?.hsn_code}
                      sx={{
                        width: "100%",
                      }}
                      onChange={(e) =>
                        setFieldValue("hsn_code", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="hsn_code"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">GST Percentage(%)</Typography>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={gstList || []}
                      value={
                        gstList.filter(
                          (item) => item._id == values?.gst_percentage
                        )?.[0] || null
                      }
                      getOptionLabel={(option) => option?.gst_percentage}
                      //   isOptionEqualToValue={(option, value) =>
                      //     value == option._id
                      //     console.log(value,option)
                      //   }
                      onChange={(e, newValue) =>
                        setFieldValue(
                          "gst_percentage",
                          newValue == null ? "" : newValue?._id
                        )
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option?.gst_percentage}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={
                            touched.gst_percentage && errors.gst_percentage
                          }
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />

                    <ErrorMessage
                      name="gst_percentage"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>

                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={() => navigate("/dashboards/master/hsnCode")}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Div>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
