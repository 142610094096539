import {
  Box,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import Heading from "app/components/Heading";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Div from "@jumbo/shared/Div";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllMarketExecutivePayout } from "app/redux/actions/PaymentsAndPayouts/Payouts";
import EditIcon from "@mui/icons-material/Edit";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import PaidIcon from "@mui/icons-material/Paid";
import Loader from "app/components/Loader";

const TableHeadCell = {
  textAlign: "left",
  minWidth: "175px",
  verticalAlign: "middle",
  textTransform: "capitalize",
  color: "white",
};

const TableRowCell = { textAlign: "left", color: "black" };

const stickyTableHeadCellSx = {
  textTransform: "capitalize",
  color: "white",
  minWidth: "175px",
  verticalAlign: "middle",
  position: "sticky",
  right: 0,
  zIndex: 1,
  bgcolor: "#7352C7",
};

const TableSortLabelCell = {
  color: "white !important",
  "&:hover": { color: "white !important" },
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important", // Set the color for the active state
  },
};

const ListingPayout = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [page, setPage] = useState(1);
  const { payoutReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.payoutReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const tableHead = [
    {
      title: "First Name",
      fieldName: "current_data.contact_person_details.first_name",
    },
    {
      title: "Last Name",
      fieldName: "current_data.contact_person_details.last_name",
    },
    {
      title: "Email Id",
      fieldName: "current_data.contact_person_details.primary_email_id",
    },
    {
      title: "Mobile No",
      fieldName: "current_data.contact_person_details.primary_mobile_no",
    },
    {
      title: "PAN No",
      fieldName: "current_data.kyc.pan_no",
    },
    {
      title: "Aadhar No",
      fieldName: "current_data.kyc.aadhar_no",
    },
    {
      title: "Balance Amount",
      fieldName: "account_balance",
    },
    {
      title: "Transaction History",
      sort: false,
    },
    {
      title: "Action",
      sort: false,
      visibility: Permission?.["payouts"]?.["add"],
    },
  ];

  const handleSort = (property) => {
    setSort(sort === "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "addPayout":
        navigate("/dashboards/payouts/add-payout", {
          state: { marketExectiveData: menuItem.marketExectiveData },
        });
        break;
      default:
        break;
    }
  };

  const handleSearch = (searchTerm) => {
    setPage(1);
    dispatch(
      getAllMarketExecutivePayout({
        sortBy: sortBy,
        search_value: searchTerm?.trim(),
        sort: sort,
        page: page,
      })
    );
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getAllMarketExecutivePayout({
        sortBy: sortBy,
        search_value: searchTerm?.trim(),
        sort: sort,
        page: page,
      })
    );
  }, [sort, sortBy, page, dispatch]);

  return (
    <Box sx={{ mt: -4 }}>
      {loading && <Loader />}

      <Box>
        <Typography as="div">
          <Heading Name={"Market Executive Payouts"} />
        </Typography>
      </Box>
      <Box>
        <Box sx={{ my: 4 }}>
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === "") {
                dispatch(
                  getAllMarketExecutivePayout({
                    page: 1,
                    sort: "desc",
                    sortBy: "created_at",
                    search_value: "",
                  })
                );
              }
            }}
            sx={{ width: 400 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                  {tableHead
                    ?.filter(
                      (ele) =>
                        ele?.visibility !== false
                    )
                    ?.map((e, i) => {
                      return (
                        <TableCell
                          key={i}
                          sx={
                            e.title !== "Action"
                              ? { ...TableHeadCell }
                              : {
                                ...stickyTableHeadCellSx,
                                textAlign: "center",
                                borderColor: "#7352C7",
                              }
                          }
                        >
                          {e.sort !== false ? (
                            <TableSortLabel
                              onClick={() => handleSort(e.fieldName)}
                              direction={sort}
                              sx={{ ...TableSortLabelCell }}
                            >
                              {e.title}
                            </TableSortLabel>
                          ) : (
                            e.title
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {payoutReducer?.marketExecutive?.map(
                  ({
                    _id,
                    account_balance,
                    current_data: marketExective,
                    proposed_changes,
                  }) => {
                    return (
                      <TableRow key={_id}>
                        <TableCell sx={{ ...TableRowCell }}>
                          {marketExective.contact_person_details?.first_name}
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          {marketExective.contact_person_details?.last_name}
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          {
                            marketExective.contact_person_details
                              ?.primary_email_id
                          }
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          {
                            marketExective.contact_person_details
                              ?.primary_mobile_no
                          }
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          {marketExective?.kyc?.pan?.pan_no}
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          {marketExective?.kyc?.aadhar?.aadhar_no}
                        </TableCell>
                        <TableCell sx={{ ...TableRowCell }}>
                          ₹ {Math.abs(Number(account_balance)).toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/dashboards/payouts/transactionHistory/${_id}`
                              );
                            }}
                          >
                            <PaidIcon />
                          </IconButton>
                        </TableCell>
                        {Permission?.["payouts"]?.["add"] && (
                          <TableCell
                            sx={{
                              ...TableRowCell,
                              textAlign: "center",
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            <JumboDdMenu
                              icon={<MoreHorizIcon />}
                              menuItems={[
                                {
                                  icon: <EditIcon />,
                                  title: "Add Payout",
                                  action: "addPayout",
                                  marketExectiveData: {
                                    metId: _id,
                                    ...marketExective,
                                    account_balance: account_balance,
                                  },
                                  visibility: Permission?.["payouts"]?.["add"],
                                },
                              ].filter((e) => e.visibility === true)}
                              onClickCallback={handleItemAction}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
            <Pagination
              count={payoutReducer?.totalPage || 1}
              page={page}
              onChange={handleChangePage}
              sx={{
                position: "sticky",
                bottom: 0,
                left: 0,
                p: 1,
                backgroundColor: "white",
                borderTop: "1px solid #ddd",
              }}
            />
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default ListingPayout;
