import AllApis from "app/Apis/apis";
import { ALL_MARKET_EXECUTIVE_FAILED, ALL_MARKET_EXECUTIVE_REQUEST, ALL_MARKET_EXECUTIVE_SUCCESS, SINGLE_MARKET_EXECUTIVE_FAILED, SINGLE_MARKET_EXECUTIVE_REQUEST, SINGLE_MARKET_EXECUTIVE_SUCCESS } from "app/utils/constants/MarketExecutiveConstant"
import { Axios } from "index";

export const getAllMarketExecutive = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {} })=>{
    return async (dispatch)=>{
        try {
            dispatch({type:ALL_MARKET_EXECUTIVE_REQUEST});
            const payload = {
                filters: filters,
                searchFields: {
                  string: [
                    "current_data.company_details.companyName",
                    "current_data.contact_person_details.first_name",
                    "current_data.contact_person_details.last_name",
                    "current_data.contact_person_details.primary_email_id",
                    "current_data.contact_person_details.primary_mobile_no",
                    "current_data.branch_address.state",
                    "current_data.branch_address.city",
                  ],
                },
              };
              if (!search_value) {
                search_value = "";
              }
          
              const urlParams = new URLSearchParams({
                search: search_value.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
              });
            
            const {data} = await Axios.post(`${AllApis.marketExective.list}?${urlParams.toString()}`,{...payload});
            
            dispatch({type:ALL_MARKET_EXECUTIVE_SUCCESS,payload:{
                data:data,
                totalPage:data?.data?.totalPages
            }})
        } catch (error) {
            dispatch({type:ALL_MARKET_EXECUTIVE_FAILED,payload: error?.response?.data?.message});
        }
    }
}

export const getSingleMarketExecutive = (metId)=>{
  return async (dispatch)=>{
    try {
      dispatch({type:SINGLE_MARKET_EXECUTIVE_REQUEST});
      const {data} = await Axios.get(AllApis?.marketExective?.getById(metId));
      dispatch({type:SINGLE_MARKET_EXECUTIVE_SUCCESS,payload:{
        data:data
      }})
    } catch (error) {
      dispatch({type:SINGLE_MARKET_EXECUTIVE_FAILED,payload: error?.response?.data?.message});
    }
  }
}