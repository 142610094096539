const AllApis = {
  users: {
    add: `/users/adduser`,
    list: `/users/userslist`,
    profile: function (userId) {
      return `users/editprofile/${userId}`;
    },
    edit: function (userId) {
      return `users/edituser/${userId}`;
    },
    login: "/auth/login",
    getUserDetails: function (userId) {
      return `/auth/getUser/${userId}`;
    },
    forgotpassword: "/auth/forgotpassword",
    verifyotp: "/auth/verifyotp",
    updatePassword: "/auth/updatePassword",
    activeuserslist: "/users/active-users",
  },
  roles: {
    list: "/roles/getRoles",
    add: "/roles/createRole",
    rolelist: "/roles/rolesList",
    edit: function (id) {
      return `/roles/updateRole/${id}`;
    },
    logs: function (page) {
      return `/roles/rolesLog?page=${page}`;
    },
  },
  category: {
    list: "/category/getCategory",
    add: "/category/createCategory",
    edit: function (id) {
      return `/category/updateCategory/${id}`;
    },
    logs: function (page) {
      return `/category/categoryLog?page=${page}`;
    },
    dropdown: "/category/categoryList",
  },
  unit: {
    list: "/unit/getUnits",
    add: "/unit/createUnit",
    edit: function (id) {
      return `/unit/updateUnit/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/unit/unitsList",
  },
  gst: {
    list: "/gst/getGst",
    add: "/gst/createGst",
    edit: function (id) {
      return `/gst/updateGst/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/gst/gstList",
  },
  hsn: {
    list: "/hsn/getHSNCode",
    add: "/hsn/createHsnCode",
    edit: function (id) {
      return `/hsn/updateHsnCode/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/hsn/hsnCodeList",
  },
  tds: {
    list: "/tds/getTds",
    add: "/tds/createTds",
    edit: function (id) {
      return `/tds/updateTds/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/tds/tdsList",
  },
  ptd: {
    list: "/termDays/get",
    add: "/termDays/create",
    edit: function (id) {
      return `/termDays/update/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/termDays/list",
  },
  product: {
    list: "/product/getProduct",
    add: "/product/createProduct",
    status: function (id) {
      return `/product/updateStatus/${id}`;
    },
    edit: function (id) {
      return `/product/updateProduct/${id}`;
    },
    logs: function (page) {
      return `/unit/unitsLog?page=${page}`;
    },
    dropdown: "/product/getProductList",
    delete: function (id, img) {
      return `/product/deleteProductImage/${id}/${img}`;
    },
    uploadImg: function (id) {
      return `/product/addProductImage/${id}`;
    },
    deleteImg: function (id, imageName) {
      return `/product/deleteProductImage/${id}?imageName=${imageName}`;
    },
    getProductById: function (id) {
      return `/product/getProduct/${id}`;
    },
  },
  purchaseOrder: {
    list: "/ssk/po/fetch",
    add: "/ssk/po/create",
    updateStatus: function (id) {
      return `/ssk/po/update/status/${id}`;
    },
  },
  salesOrder: {
    list: "sales/fetch",
    add: "/sales/create",
    offlineSalesOrderNo: "/sales/offlineSalesOrder/dropdown",
    orderNoFromSales: function (type) {
      return `/sales/orderNoFromSales/dropdown/${type}`;
    },
  },
  addressDropdown: {
    add: function (type) {
      return `/address/dropdown/create/${type}`;
    },
    get: function (type) {
      return `/address/dropdown/get/${type}`;
    },
  },
  supplier: {
    add: "/supplier",
    list: "/supplier/getAllCompany",
    dropdown: "/supplier/dropdown/list",
    supplierById: function (companyId) {
      return `/supplier/${companyId}`;
    },
    edit: function (companyId) {
      return `/supplier/${companyId}`;
    },
    setPrimaryBranch: function (companyId, branchId) {
      return `/supplier/primaryBranch/${companyId}/${branchId}`;
    },
    branch: {
      add: "/supplier/branch",
      edit: function (companyId, branchId) {
        return `/supplier/branch/${companyId}?branchId=${branchId}`;
      },
      list: "/supplier/BranchSupplier/all",
      uploadFile: function (campanyId, branchId) {
        return `/supplier/branch/upload/${campanyId}/${branchId}`;
      },
    },
    contact: {
      add: function (campanyId, branchId) {
        return `/supplier/branch/contact/${campanyId}/${branchId}`;
      },
      edit: function (campanyId, branchId, contactId) {
        return `/supplier/branch/contact/${campanyId}/${branchId}?contactId=${contactId}`;
      },
      setPrimary: function (companyId, branchId, contactId) {
        return `/supplier/contact/setprimary/${companyId}/${branchId}?contactId=${contactId}`;
      },
    },
  },
  sskCompany: {
    add: "/sskcompany",
    list: "/sskcompany/getAllCompany",
    sskCompanyById: function (companyId) {
      return `/sskcompany/${companyId}`;
    },
    edit: function (companyId) {
      return `/sskcompany/${companyId}`;
    },
    setPrimaryBranch: function (companyId, branchId) {
      return `/sskcompany/primaryBranch/${companyId}/${branchId}`;
    },
    dropdown: "/sskcompany/dropdown/list",
    branch: {
      add: "/sskcompany/branch",
      getBranchByCompanyId: function (companyId) {
        return `/sskcompany/branch/dropdown/list/${companyId}`;
      },
      edit: function (companyId, branchId) {
        return `/sskcompany/branch/${companyId}?branchId=${branchId}`;
      },
      list: "/sskcompany/BranchSskcompany/all",
      uploadFile: function (campanyId, branchId) {
        return `/sskcompany/branch/upload/${campanyId}/${branchId}`;
      },
    },
    contact: {
      add: function (campanyId, branchId) {
        return `/sskcompany/branch/contact/${campanyId}/${branchId}`;
      },
      edit: function (campanyId, branchId, contactId) {
        return `/sskcompany/branch/contact/${campanyId}/${branchId}?contactId=${contactId}`;
      },
      setPrimary: function (companyId, branchId, contactId) {
        return `/sskcompany/contact/setprimary/${companyId}/${branchId}?contactId=${contactId}`;
      },
    },
  },
  retailer: {
    add: "/retailer",
    list: "/retailer/getAllCompany",
    edit: function (companyId) {
      return `/retailer/${companyId}`;
    },
    setPrimaryBranch: function (companyId, branchId) {
      return `/retailer/primaryBranch/${companyId}/${branchId}`;
    },
    dropdown: "/retailer/dropdown/list",
    branch: {
      add: "/retailer/branch",
      edit: function (companyId, branchId) {
        return `/retailer/branch/${companyId}?branchId=${branchId}`;
      },
      getBranchByCompanyId: function (companyId) {
        return `/retailer/branch/dropdown/list/${companyId}`;
      },
      list: "/retailer/BranchRetailer/all",
      uploadFile: function (campanyId, branchId) {
        return `/retailer/branch/upload/${campanyId}/${branchId}`;
      },
    },
    contact: {
      add: function (campanyId, branchId) {
        return `/retailer/branch/contact/${campanyId}/${branchId}`;
      },
      edit: function (campanyId, branchId, contactId) {
        return `/retailer/branch/contact/${campanyId}/${branchId}?contactId=${contactId}`;
      },
      setPrimary: function (companyId, branchId, contactId) {
        return `/retailer/contact/setprimary/${companyId}/${branchId}?contactId=${contactId}`;
      },
    },
  },
  offlinestore: {
    add: "/offlineStore",
    list: "/offlineStore/getAllCompany",
    edit: function (companyId) {
      return `/offlineStore/${companyId}`;
    },
    getBranchByCompanyId: function (companyId) {
      return `/offlineStore/branch/dropdown/list/${companyId}`;
    },
    setPrimaryBranch: function (companyId, branchId) {
      return `/offlineStore/primaryBranch/${companyId}/${branchId}`;
    },
    dropdown: "/offlineStore/dropdown/list",
    branch: {
      add: "/offlineStore/branch",
      edit: function (companyId, branchId) {
        return `/offlineStore/branch/${companyId}?branchId=${branchId}`;
      },
      list: "/offlineStore/BranchOfflineStore/all",
      uploadFile: function (campanyId, branchId) {
        return `/offlineStore/branch/upload/${campanyId}/${branchId}`;
      },
    },
    contact: {
      add: function (campanyId, branchId) {
        return `/offlineStore/branch/contact/${campanyId}/${branchId}`;
      },
      edit: function (campanyId, branchId, contactId) {
        return `/offlineStore/branch/contact/${campanyId}/${branchId}?contactId=${contactId}`;
      },
      setPrimary: function (companyId, branchId, contactId) {
        return `/offlineStore/contact/setprimary/${companyId}/${branchId}?contactId=${contactId}`;
      },
    },
  },
  marketExective: {
    add: `/market-executive`,
    list: `/market-executive/getAllMet`,
    dropdown: `/market-executive/dropdown/list`,
    getById: function (metId) {
      return `/market-executive/${metId}`;
    },
    update: function (metId) {
      return `/market-executive/${metId}`;
    },
    uploadFile: function (metId) {
      return `/market-executive/uploadImage/${metId}`;
    },
    nominee: {
      add: function (metId) {
        return `/market-executive/nominee/add/${metId}`;
      },
      update: function (metId, nomineeId) {
        return `/market-executive/nominee/update/${metId}/${nomineeId}`;
      },
    },
  },
  marketExecutiveCommission: {
    listing: function (companyId) {
      return `/MECommission/${companyId}`;
    },
    metCompanyList: function (MetId) {
      return `/MECommission/marketExecutiveCompany/${MetId}`;
    },
    dropdown: function (companyId) {
      return `/MECommission/dropdown/${companyId}`;
    },
    create: `/MECommission`,
    update: function (commisionId) {
      return `/MECommission/${commisionId}`;
    },
  },
  order: {
    add: "/orders/neworder",
    list: `orders/fetch`,
    status: function (id) {
      return `orders/update/status/${id}`;
    },
  },
  payments:  {
    offlinePayment:  {
      listing:  `/offlinePayment`,
      addPayment:  function  (offlinePaymentId)  {
        return `/offlinePayment/payment/${offlinePaymentId}`;;
      },
      updatePaymentStatus: function (offlinePaymentId) {
        return `/offlinePayment/payment/${offlinePaymentId}`;
      },
      addFollowupAndRemark: function (offlinePaymentId) {
        return `/offlinePayment/followup-remark/${offlinePaymentId}`;
      },
    },
  },
  payout: {
    addPayout: (marketExecutiveId) => {
      return `/payoutAndCommission/${marketExecutiveId}`;
    },
  },
  transactionHistroy: {
    listing: function (metId) {
      return `/payoutAndCommission/transactionHistory/${metId}`;
    },
  },
  refund: {
    add: "/refund/create",
    list: `orders/fetch`,
  },
  dispatch: {
    add: "/dispatch/create",
    list: "/dispatch/fetch",
    outForDelivery: function (id) {
      return `/dispatch/outForDelivery/${id}`;
    },
    delivered: function (id) {
      return `/dispatch/delivered/${id}`;
    },
    salesNoFromDispatch: function (orderType, deliveryStatus) {
      return `/dispatch/SalesNoFromDispatch/dropdown/${orderType}/${deliveryStatus}`;
    },
  },
  inventory: {
    add: `/ssk/inventory/addstock`,
    supplierPO: function (value) {
      return `/ssk/po/supplierId/${value}`;
    },
    list: `/ssk/inventory/list`,
    productHistory: function (value) {
      return `/ssk/inventory/view/${value}`;
    },
  },
  sample: {
    add: `/sample/outward`,
    list: `/sample/list`,
  },
  faq: {
    add: "/faq/add",
    list: "/faq/getFaq",
    edit: function (id) {
      return `/faq/update/${id}`;
    },
    delete: function (id) {
      return `/faq/delete/${id}`;
    },
  },

  ticket: {
    list: "/ticket/list",
    updateStatus: function (id) {
      return `/ticket/update-status/${id}`;
    },
    reply: function (id) {
      return `/ticket/reply/${id}`;
    },
  },
};
export default AllApis;
