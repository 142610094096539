import AllApis from "app/Apis/apis";
import {
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
} from "app/utils/constants/InventoryConstant";
import { Axios } from "index";

export const getProducts =
  (search_value, sortOrder, sortField, page = 1) =>
  async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [],
          numbers: [],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      dispatch({ type: PRODUCT_LIST_REQUEST });

      const data = await Axios.get(`${AllApis.inventory.list}`);
      console.log(data, "actions--------------------");
      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: {
          data: data?.data?.data,
          totalPage: data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload: error.response.data.message,
      });
    }
  };
