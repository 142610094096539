import Page from "@jumbo/shared/Page";
import AddEditUnit from "app/pages/Masters/Units/AddEditUnit";
import ListUnit from "app/pages/Masters/Units/ListUnit";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";

const routesName = "/dashboards/master/unit";

export const unitRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "unit",
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page component={ListUnit} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "unit",
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page component={AddEditUnit} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: "unit",
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit`,
            element: <Page component={AddEditUnit} />,
          },
        ],
      },
    ],
  },
];
