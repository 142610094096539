import Div from '@jumbo/shared/Div'
import { Button, TextField } from '@mui/material'
import React from 'react'

const ContactForm = ({ handleChange, values, handleBlur, errors, touched, handleSubmit }) => {
    return (
        <>
            <Div sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                columnGap: 2,
            }}>
                <TextField
                    required
                    name="first_name"
                    label="First Name"
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.first_name && touched?.first_name}
                    helperText={touched?.first_name && touched?.first_name ? errors?.first_name : ''} />
                <TextField
                    required
                    name="last_name"
                    label="Last Name"
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.last_name && touched?.last_name}
                    helperText={touched?.last_name && touched?.last_name ? errors?.last_name : ''} />
                <TextField
                    required
                    name="role"
                    label="Role"
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.role && touched?.role}
                    helperText={touched?.role && touched?.role ? errors?.role : ''} />
                <TextField
                    required
                    name="primary_email"
                    label="Primary Email"
                    type='email'
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.primary_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.primary_email && touched?.primary_email}
                    helperText={touched?.primary_email && touched?.primary_email ? errors?.primary_email : ''} />
                <TextField
                    name="secondary_email"
                    label="Secondary Email"
                    type='email'
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.secondary_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.secondary_email && touched?.secondary_email}
                    helperText={touched?.secondary_email && touched?.secondary_email ? errors?.secondary_email : ''} />
                <TextField
                    required
                    name="primary_mobile"
                    label="Primary Mobile No"
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.primary_mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.primary_mobile && touched?.primary_mobile}
                    helperText={touched?.primary_mobile && touched?.primary_mobile ? errors?.primary_mobile : ''} />
                <TextField
                    name="secondary_mobile"
                    label="Secondary Mobile No"
                    size="small"
                    sx={{ textTransform: "capitalize", width: "30%", marginY: "10px" }}
                    value={values?.secondary_mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.secondary_mobile && touched?.secondary_mobile}
                    helperText={touched?.secondary_mobile && touched?.secondary_mobile ? errors?.secondary_mobile : ''} />
            </Div>
            {/* <Div sx={{ textAlign: "end" }}>
                <Button type='submit'
                    variant="contained"
                    sx={{ height: "100%", margin: "0 125px" }}
                    onClick={handleSubmit}
                >
                    Add
                </Button>
            </Div> */}
        </>
    )
}

export default ContactForm