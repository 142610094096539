import { MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED, MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST, MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST, MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS, MARKET_EXECUTIVE_COMMISSION_FAILED, MARKET_EXECUTIVE_COMMISSION_REQUEST, MARKET_EXECUTIVE_COMMISSION_SUCCESS } from "app/utils/constants/MarketExecutiveCommission";

const INTI_VALUES = {
    loading: false,
    marketExecutiveCommissionList: [],
    totalPage:0,
    error: false,
    errorMessage: null
}

const INTI_VALUES_DROPDOWN = {
    loading: false,
    marketExecutiveList: [],
    error: false,
    errorMessage: null
}

const INTI_VALUES_COMPANY = {
    loading: false,
    marketExecutiveCommissionCompanyList: [],
    totalPage:0,
    error: false,
    errorMessage: null
}


export const marketExcutiveCommissionReducer = (state = INTI_VALUES, action) => {
    switch (action.type) {
        case MARKET_EXECUTIVE_COMMISSION_REQUEST:
            return { ...state, loading: true }
        case MARKET_EXECUTIVE_COMMISSION_SUCCESS:
            return {
                ...state,
                marketExecutiveCommissionList: action.payload.marketExecutiveCommissionList,
                totalPage:action.payload.totalPage,
                loading: false
            }
        case MARKET_EXECUTIVE_COMMISSION_FAILED:
            return {
                ...state,
                error: true,
                message: action.payload
            }
        default:
            return state;
    }
}

export const marketExcutiveCommissionCompanyReducer = (state = INTI_VALUES_COMPANY, action) => {
    switch (action.type) {
        case MARKET_EXECUTIVE_COMMISSION_COMPANY_REQUEST:
            return { ...state, loading: true }
        case MARKET_EXECUTIVE_COMMISSION_COMPANY_SUCCESS:
            return {
                ...state,
                marketExecutiveCommissionCompanyList: action.payload.marketExecutiveCommissionCompanyList,
                totalPage:action.payload.totalPage,
                loading: false
            }
        case MARKET_EXECUTIVE_COMMISSION_COMPANY_FAILED:
            return {
                ...state,
                error: true,
                message: action.payload
            }
        default:
            return state;
    }
}

export const MECommissionDropdownReducer = (state = INTI_VALUES_DROPDOWN, action) => {
    switch (action.type) {
        case MARKET_EXECUTIVE_COMMISSION_DROPDOWN_REQUEST:
            return { ...state, loading: true }
        case MARKET_EXECUTIVE_COMMISSION_DROPDOWN_SUCCESS:
            return {
                ...state,
                marketExecutiveList: action.payload.marketExecutiveList,
                loading: false
            }
        case MARKET_EXECUTIVE_COMMISSION_DROPDOWN_FAILED:
            return {
                ...state,
                error: true,
                message: action.payload
            }
        default:
            return state;
    }
}