import { Box, Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Heading from 'app/components/Heading';
import { FastField, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Axios } from 'index';
import AllApis from 'app/Apis/apis';


const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
        color: "#475259", // Change label color to blue
    },
};

const AddPayout = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const marketExectiveData = state?.marketExectiveData;

    const handlePayoutSubmit = async function (values, action) {
        const { tdsPercentage,tdsAmount,amountPaid,...payoutData} = values;
        console.log(payoutData)
        try {
            const { data } = await Axios.post(AllApis?.payout?.addPayout(marketExectiveData?.metId), {
                payouts:{...payoutData}
            })
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.message,
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                action.resetForm();
                navigate("/dashboards/payouts")
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    return (
        <>
            {!marketExectiveData ? <Navigate to={"/dashboards/payouts"} /> : <Box sx={{ mt: -4 }}>
                <Box>
                    <Heading Name={"Add Payout"} />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Grid2 container spacing={5}>
                        <Grid2 xs={3}>
                            <TextField
                                sx={{ width: "100%", ...disabledStyling }}
                                size='small'
                                disabled
                                label="First Name"
                                value={marketExectiveData?.contact_person_details?.first_name || ""}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                sx={{ width: "100%", ...disabledStyling }}
                                size='small'
                                disabled
                                label="Last Name"
                                value={marketExectiveData?.contact_person_details?.last_name || ""}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                sx={{ width: "100%", ...disabledStyling }}
                                size='small'
                                disabled
                                label="Aadhar No"
                                value={marketExectiveData?.kyc?.aadhar?.aadhar_no || ""}
                            />
                        </Grid2>
                        <Grid2 xs={3}>
                            <TextField
                                sx={{ width: "100%", ...disabledStyling }}
                                size='small'
                                disabled
                                label="Balance Amount"
                                value={`₹ ${(marketExectiveData?.account_balance).toFixed(2)}` || 0}
                            />
                        </Grid2>
                    </Grid2>
                </Box>
                <Box>
                    <Typography variant='h4' sx={{ my: 3 }}>Add Payment</Typography>
                    <Box>
                        <Formik
                            initialValues={{
                                payoutDate: new Date(Date.now()).toISOString().split("T")[0],
                                payoutType: "",
                                transactionId: "",
                                payoutAmount: 0,
                                tdsPercentage: 10,
                                tdsAmount: 0,
                                amountPaid: 0
                            }}
                            validationSchema={yup.object().shape({
                                payoutDate: yup.date().required('Payout Date is required'),
                                payoutType: yup.string().required('Payout Type is required'),
                                transactionId: yup.string().matches(/^[a-zA-Z0-9]*$/, 'Invalid transaction ID').required("Transaction Id is required"),
                                payoutAmount: yup.number().min(1, 'Payout Amount must be greater than 0').required('Payout Amount is required'),
                            })}
                            onSubmit={handlePayoutSubmit}
                        >
                            {({ values, setFieldValue }) => {
                                return <Form>
                                    <Grid2 container spacing={3}>
                                        <Grid2 xs={4}>
                                            <FastField name={"payoutDate"}>
                                                {({ field, meta }) => {
                                                    return <TextField
                                                        {...field}
                                                        size='small'
                                                        type='date'
                                                        required
                                                        label={"Payout Date"}
                                                        sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                        error={meta?.error && meta?.touched}
                                                        helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                    />
                                                }}
                                            </FastField>
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <FastField name={"payoutType"}>
                                                {({ field, meta }) => {
                                                    return <TextField
                                                        {...field}
                                                        size='small'
                                                        required
                                                        label={"Payout Type"}
                                                        sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                        error={meta?.error && meta?.touched}
                                                        helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                    />
                                                }}
                                            </FastField>
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <FastField name={"transactionId"}>
                                                {({ field, meta }) => {
                                                    return <TextField
                                                        {...field}
                                                        size='small'
                                                        required
                                                        label={"Transaction Id"}
                                                        sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                        error={meta?.error && meta?.touched}
                                                        helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                    />
                                                }}
                                            </FastField>
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <FastField name={"payoutAmount"} >
                                                {({ field, meta }) => {
                                                    return <TextField
                                                        {...field}
                                                        type='number'
                                                        required
                                                        onChange={(e) => {
                                                            const payoutAmount = e.target.value;
                                                            const tdsAmount = (payoutAmount * (values.tdsPercentage / 100)).toFixed(2);
                                                            setFieldValue("tdsAmount", tdsAmount);
                                                            setFieldValue("amountPaid", (payoutAmount - tdsAmount).toFixed(2));
                                                            setFieldValue("payoutAmount", payoutAmount)
                                                        }}
                                                        size='small'
                                                        label={"Payout Amount"}
                                                        sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                                        error={meta?.error && meta?.touched}
                                                        helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                    />
                                                }}
                                            </FastField>
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <TextField
                                                size='small'
                                                disabled
                                                value={values.tdsPercentage}
                                                label={"TDS Percentage"}
                                                sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                            />
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <TextField
                                                size='small'
                                                disabled
                                                value={values.tdsAmount}
                                                label={"TDS Amount"}
                                                sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                            />
                                        </Grid2>
                                        <Grid2 xs={4}>
                                            <TextField
                                                size='small'
                                                disabled
                                                value={values.amountPaid}
                                                label={"Amount Payable"}
                                                sx={{ width: "100%", textTransform: "capitalize", ...disabledStyling }}
                                            />
                                        </Grid2>
                                    </Grid2>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", my: 4 }}>
                                        <Button variant='outlined' onClick={()=> navigate(`/dashboards/payouts`)}>Cancel</Button>
                                        <Button variant='contained' type='submit' sx={{ mx: 1 }}>Submit</Button>
                                    </Box>
                                </Form>
                            }}
                        </Formik>
                    </Box>
                </Box>

            </Box>}
        </>
    )
}

export default AddPayout