import Page from "@jumbo/shared/Page";
import MapMarketExecutive from "app/pages/Masters/MappingMarketExecutive";
import Suppliers from "app/pages/Masters/Suppliers";
import AddEditSuppliers from "app/pages/Masters/Suppliers/AddEditSuppliers";
import ListCompanyName from "app/pages/Masters/Suppliers/ListCompnayName";
import AuthenticateAndRolesMiddleware from "app/routes/Middleware";
import AuthenticateMiddleware from "app/routes/Middleware/AuthenticateMiddleware";


const routesName = "/dashboards/master/offlinestore";
const modules = "offlineStore";

export const offlineStoreRoute = [
  {
    middleware: [
      {
        element: AuthenticateMiddleware,
        fallbackPath: {
          url: "/login",
        },
      },
    ],
    routes: [
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={Suppliers} />
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "view",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/list/Company`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={ListCompanyName} />
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={AddEditSuppliers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "edit",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/edit/:id`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={AddEditSuppliers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/add-branch`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={AddEditSuppliers} />,
          },
        ],
      },
      {
        middleware: [
          {
            element: AuthenticateAndRolesMiddleware,
            fallbackPath: {
              url: "/dashboard",
              module: modules,
              role: "add",
            },
          },
        ],
        routes: [
          {
            path: `${routesName}/map-market-executive`,
            element: <Page companyType={"offlinestore"} label={"Offline Store"} component={MapMarketExecutive} />,
          },
        ],
      },
    ],
  },
];
