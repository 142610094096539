import {
  ALL_SALES_ORDER_FAIL,
  ALL_SALES_ORDER_REQUEST,
  ALL_SALES_ORDER_SUCCESS,
} from "app/utils/constants/salesOrderConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  websites: [],
  offlinestores: [],
  retailers: [],
  TotalPage: null,
};

export const salesOrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_SALES_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_SALES_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.payload.type]: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_SALES_ORDER_FAIL:
      return {
        ...state,

        loading: false,
        [action.payload.type]: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
