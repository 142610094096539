import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Heading from "app/pages/Component/Heading";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";
import ProductTable from "./productTable";
import { formatDate } from "app/pages/Component/Function/formatDate";
import dayjs from "dayjs";

export default function AddEditOrder() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [offlineStoreList, setOfflineStoreList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [patmentDaysList, setPaymentDaysList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [headingName, setHeadingName] = useState(
    pathname.split("/").pop() == "add" ? "Create Order" : "Edit Order"
  );
  const todaysDate = new Date(Date.now()).toISOString().split("T")[0];
  const disabledStyling = {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#475259",
    },
    "& .MuiInputLabel-root": {
      color: "#475259", // Change label color to blue
    },
  };
  const initialValues = {
    order_date: todaysDate,
    estimate_delivery_date: todaysDate,
    order_type: "offlinestores",
    est_payment_days: "",
    ssk_details: {
      ssk_name: "",
      ssk_id: "",
      branch_id: "",
      company_name: "",
      gst_no: "",
      first_name: "",
      last_name: "",
      primary_email_id: "",
      secondary_email_id: "",
      primary_mobile_no: "",
      secondary_mobile_no: "",
      address: {
        city: "",
        state: "",
        pincode: "",
        country: "",
        taluka: "",
        district: "",
        area: "",
        location: "",
        address: "",
      },
    },
    customer_details: {
      customer_name: "",
      customer_id: "",
      bill_to: {
        branch_id: "",
        branch_name: "",
        gst_no: "",
        first_name: "",
        last_name: "",
        primary_email_id: "",
        secondary_email_id: "",
        primary_mobile_no: "",
        secondary_mobile_no: "",
        address: {
          city: "",
          state: "",
          pincode: "",
          country: "",
          taluka: "",
          district: "",
          area: "",
          location: "",
          address: "",
        },
      },
      ship_to: {
        branch_id: "",
        branch_name: "",
        gst_no: "",
        first_name: "",
        last_name: "",
        primary_email_id: "",
        secondary_email_id: "",
        primary_mobile_no: "",
        secondary_mobile_no: "",
        address: {
          city: "",
          state: "",
          pincode: "",
          country: "",
          taluka: "",
          district: "",
          area: "",
          location: "",
          address: "",
        },
      },
    },
    Items: [
      {
        product_id: "",
        item_name: "",
        category: "",
        sku: "",
        hsn_code: "",
        weight: "",
        unit: "",
        rate_per_unit: "",
        quantity: "",
        pending_quantity: "",
        item_amount: "",
        gst: {
          cgst: "",
          sgst: "",
          igst: "",
        },
        total_amount: "",
      },
    ],
    total_weight: 0,
    total_quantity: 0,
    total_item_amount: 0,
    total_gst: 0,
    total_cgst: 0,
    total_sgst: 0,
    total_igst: 0,
    total_amount: 0,
  };

  const validationSchema = yup.object({
    est_payment_days: yup.string().required("Payment Term Days is Required"),

    ssk_details: yup.object({
      ssk_name: yup.string().required("SSK Name is Required"),
    }),
    customer_details: yup.object({
      customer_name: yup.string().required("Offline Store Name is Required"),
      bill_to: yup.object().shape({
        branch_id: yup.string().required("Branch Name is Required"),
      }),
      ship_to: yup.object().shape({
        branch_id: yup.string().required("Branch Name is Required"),
      }),
    }),
    Items: yup.array().of(
      yup.object().shape({
        product_id: yup.string().required("Product is Required"),
        item_name: yup.string().required("Item Name is Required"),
        category: yup.string().required("Category is Required"),
        sku: yup.string(),
        hsn_code: yup.string(),
        weight: yup.number().positive("Weight must be a positive number"),
        unit: yup.string(),
        rate_per_unit: yup
          .number()
          .positive("Rate per unit must be a positive number"),
        quantity: yup.number().positive("Quantity must be a positive number"),
        item_amount: yup
          .number()
          .positive("Item amount must be a positive number"),
        cgst: yup.number(),
        sgst: yup.number(),
        igst: yup.number(),
        total_amount: yup
          .number()
          .positive("Total amount must be a positive number"),
      })
    ),
  });

  const sskData = async (newValue, setFieldValue) => {
    try {
      const sskDetails = await Axios.get(
        `${AllApis.sskCompany.sskCompanyById(newValue._id)}`
      );
      const data = sskDetails?.data?.data?.sskCompany?.current_data;

      const primaryContactPerson =
        data?.primaryBranch?.current_data?.contact_person?.filter(
          (ele) => ele.isPrimary == true
        )[0];
      const addressData = data?.primaryBranch?.current_data?.branch_address;
      const ssk_details = {
        ssk_id: newValue._id,
        ssk_name: newValue.company_name,
        branch_id: data?.primaryBranch?._id,
        company_name: data?.company_name,
        gst_no: data?.primaryBranch?.current_data?.kyc?.gst?.gst_no,
        first_name: primaryContactPerson?.first_name,
        last_name: primaryContactPerson?.last_name,
        primary_email_id: primaryContactPerson?.primary_email,
        secondary_email_id: primaryContactPerson?.secondary_email,
        primary_mobile_no: primaryContactPerson?.primary_mobile,
        secondary_mobile_no: primaryContactPerson?.secondary_mobile,
        address: {
          city: addressData?.city,
          state: addressData?.state,
          pincode: addressData?.pincode,
          country: addressData?.country,
          taluka: addressData?.taluka,
          district: addressData?.district,
          area: addressData?.area,
          location: addressData?.location,
          address: addressData?.address,
        },
      };
      setFieldValue("ssk_details", ssk_details);
    } catch (error) {}
  };

  const billShipToData = async (data, setFieldValue, type) => {
    const primaryContactPerson = data?.current_data?.contact_person?.filter(
      (ele) => ele.isPrimary == true
    )[0];
    const {
      first_name,
      last_name,
      primary_email,
      secondary_email,
      primary_mobile,
      secondary_mobile,
    } = primaryContactPerson;

    const {
      city,
      location,
      state,
      pincode,
      taluka,
      district,
      area,
      address,
      country,
    } = data.current_data.branch_address;

    const inputData = {
      branch_id: data?._id,
      branch_name: data?.current_data?.branch_name,
      gst_no: data.current_data?.kyc?.gst?.gst_no,
      first_name: first_name,
      last_name: last_name,
      primary_email_id: primary_email,
      secondary_email_id: secondary_email,
      primary_mobile_no: primary_mobile,
      secondary_mobile_no: secondary_mobile,
      address: {
        city: city,
        state: state,
        pincode: pincode,
        country: country,
        taluka: taluka,
        district: district,
        area: area,
        location: location,
        address: address,
      },
    };
    setFieldValue(
      `customer_details.${type == "bill" ? "bill_to" : "ship_to"}`,
      inputData
    );
  };
  const handleCheck = (e, values, setFieldValue, initialValues) => {
    if (e.target.checked == true) {
      setFieldValue(
        "customer_details.ship_to",
        values.customer_details.bill_to
      );
    } else {
      setFieldValue(
        "customer_details.ship_to",
        initialValues.customer_details.ship_to
      );
    }
  };
  const onUserSave = async (values) => {
    try {
      setSubmitting(true);
      const response = await Axios.post(AllApis.order.add, values);
      if (response?.data?.statusCode) {
        Swal.fire({
          title: "Order Created",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/orderProcessing/orders");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(async () => {
    try {
      const offlineStoreData = await Axios.get(
        `${AllApis.offlinestore.dropdown}`
      );
      const CompanyData = await Axios.get(`${AllApis.sskCompany.dropdown}`);
      const paymentDaysData = await Axios.get(`${AllApis.ptd.dropdown}`);
      setPaymentDaysList(paymentDaysData?.data?.data);
      setCompanyList(CompanyData?.data?.data?.sskCompany);
      setOfflineStoreList(offlineStoreData?.data?.data?.offlinestore);
    } catch (error) {}
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={headingName} />
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            initialValues,
            setValues,
          }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                    rowGap: 2,
                  }}
                >
                  <Div
                    sx={{
                      width: "100%",
                      display: "flex",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Div sx={{ width: "21%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Order Date"
                          id="order_date"
                          format="DD-MM-YYYY"
                          name="order_date"
                          sx={{ width: "100%" }}
                          defaultValue={dayjs(values?.order_date)}
                          onChange={(newValue) => {
                            setFieldValue(
                              "order_date",
                              newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                            );
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                      <ErrorMessage
                        name="order_date"
                        component={"div"}
                        style={{ color: "red" }}
                      ></ErrorMessage>
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Estimate Delivery Date"
                          id="estimate_delivery_date"
                          format="DD-MM-YYYY"
                          name="estimate_delivery_date"
                          sx={{ width: "100%" }}
                          defaultValue={dayjs(values?.estimate_delivery_date)}
                          onChange={(newValue) => {
                            setFieldValue(
                              "estimate_delivery_date",
                              newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                            );
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                      <ErrorMessage
                        name="estimate_delivery_date"
                        component={"div"}
                        style={{ color: "red" }}
                      ></ErrorMessage>
                    </Div>

                    <Div sx={{ width: "21%" }}>
                      <Autocomplete
                        size="small"
                        id="tags-standard"
                        options={patmentDaysList || []}
                        value={
                          patmentDaysList.filter(
                            (item) =>
                              item.payment_term_days == values?.est_payment_days
                          )?.[0] || null
                        }
                        getOptionLabel={(option) => option?.payment_term_days}
                        onChange={async (e, newValue) => {
                          setFieldValue(
                            "est_payment_days",
                            newValue != null ? newValue?.payment_term_days : ""
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option?.payment_term_days}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            label={"Payment Term Days"}
                            {...params}
                            variant="outlined"
                            sx={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="est_payment_days"
                        component={"div"}
                        style={{ color: "red" }}
                      ></ErrorMessage>
                    </Div>
                  </Div>
                  <Typography sx={{ width: "100%", mb: 0 }} variant="h3">
                    SSK
                  </Typography>
                  <Div sx={{ width: "100%" }}>
                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={companyList || []}
                      value={
                        companyList.filter(
                          (item) => item._id == values?.ssk_details?.ssk_id
                        )?.[0] || null
                      }
                      getOptionLabel={(option) => option?.company_name}
                      onChange={async (e, newValue) => {
                        if (newValue == null) {
                          setFieldValue(
                            "ssk_details",
                            initialValues.ssk_details
                          );
                          setFieldValue(
                            "customer_details",
                            initialValues.customer_details
                          );
                        } else {
                          sskData(newValue, setFieldValue);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            textTransform: "capitalize",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option?.company_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={"Company Name"}
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "21%",
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="ssk_details.ssk_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>

                  <Div sx={{ width: "21%" }}>
                    <TextField
                      sx={{
                        width: "100%",
                        textTransform: "capitalize",
                        ...disabledStyling,
                        "& .MuiInputBase-input": {
                          textTransform: "capitalize", // Capitalize first letter
                        },
                      }}
                      disabled
                      size="small"
                      label={"First Name"}
                      value={values?.ssk_details?.first_name}
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"Last Name"}
                      value={values?.ssk_details?.last_name}
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                        "& .MuiInputBase-input": {
                          textTransform: "capitalize", // Capitalize first letter
                        },
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"GST No."}
                      value={values?.ssk_details?.gst_no}
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"Primary Email Id"}
                      value={values?.ssk_details?.primary_email_id}
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"Secondary Email Id"}
                      value={values?.ssk_details?.secondary_email_id}
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"Primary Mobile No."}
                      value={values?.ssk_details?.primary_mobile_no}
                      type="number"
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div sx={{ width: "21%" }}>
                    <TextField
                      label={"Secondary Mobile No."}
                      value={values?.ssk_details?.secondary_mobile_no}
                      type="number"
                      sx={{
                        width: "100%",
                        ...disabledStyling,
                      }}
                      disabled
                      size="small"
                    />
                  </Div>
                  <Div
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: 5,
                      rowGap: 2,
                    }}
                  >
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"Country"}
                        value={values?.ssk_details?.address?.country}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"State"}
                        value={values?.ssk_details?.address?.state}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"City"}
                        value={values?.ssk_details?.address?.city}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"Pincode"}
                        value={values?.ssk_details?.address?.pincode}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"Taluka"}
                        value={values?.ssk_details?.address?.taluka}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"District"}
                        value={values?.ssk_details?.address?.district}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"Area"}
                        value={values?.ssk_details?.address?.area}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "21%" }}>
                      <TextField
                        label={"Location"}
                        value={values?.ssk_details?.address?.location}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                    <Div sx={{ width: "44.5%" }}>
                      <TextField
                        multiline
                        label={"Address"}
                        value={values?.ssk_details?.address?.address}
                        sx={{
                          width: "100%",
                          ...disabledStyling,
                          "& .MuiInputBase-input": {
                            textTransform: "capitalize", // Capitalize first letter
                          },
                        }}
                        disabled
                        size="small"
                      />
                    </Div>
                  </Div>
                  {/* ssk */}

                  <Div sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%" }} variant="h3">
                      Offline Store
                    </Typography>

                    <Autocomplete
                      size="small"
                      id="tags-standard"
                      options={offlineStoreList || []}
                      value={
                        offlineStoreList.filter(
                          (item) =>
                            item._id == values?.customer_details?.customer_id
                        )?.[0] || null
                      }
                      getOptionLabel={(option) => option?.company_name}
                      onChange={async (e, newValue) => {
                        if (newValue == null) {
                          setFieldValue("customer_details.customer_name", "");
                          setFieldValue("customer_details.customer_id", "");
                          setBranchList([]);
                          setFieldValue(
                            "customer_details.bill_to",
                            initialValues.customer_details.bill_to
                          );
                          setFieldValue(
                            "customer_details.ship_to",
                            initialValues.customer_details.ship_to
                          );
                        } else {
                          setFieldValue(
                            "customer_details.customer_name",
                            newValue?.company_name
                          );
                          setFieldValue(
                            "customer_details.customer_id",
                            newValue?._id
                          );
                          const branchData = await Axios.get(
                            `${AllApis.offlinestore.getBranchByCompanyId(
                              newValue?._id
                            )}`
                          );
                          setBranchList(branchData?.data?.data?.offlinestore);
                        }
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            textTransform: "capitalize",
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option?.company_name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          label={"Company Name"}
                          {...params}
                          variant="outlined"
                          sx={{
                            width: "21%",
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                        />
                      )}
                    />

                    <ErrorMessage
                      name="customer_details.customer_name"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                  {/* billto */}
                  <Div sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%" }} variant="h3">
                      Bill To
                    </Typography>

                    <Div
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        columnGap: 5,
                        rowGap: 2,
                      }}
                    >
                      <Div sx={{ width: "100%" }}>
                        <Autocomplete
                          size="small"
                          id="tags-standard"
                          options={branchList || []}
                          value={
                            branchList?.filter(
                              (item) =>
                                item._id ==
                                values?.customer_details?.bill_to?.branch_id
                            )?.[0] || null
                          }
                          getOptionLabel={(option) =>
                            option?.current_data?.branch_name
                          }
                          onChange={(e, newValue) => {
                            if (newValue == null) {
                              setFieldValue(
                                "customer_details.bill_to",
                                initialValues.customer_details.bill_to
                              );
                            } else {
                              billShipToData(newValue, setFieldValue, "bill");
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                textTransform: "capitalize",
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.current_data?.branch_name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              label={"Branch Name"}
                              {...params}
                              variant="outlined"
                              sx={{
                                width: "21%",
                                "& .MuiInputBase-input": {
                                  textTransform: "capitalize", // Capitalize first letter
                                },
                              }}
                            />
                          )}
                        />

                        <ErrorMessage
                          name="customer_details.bill_to.branch_id"
                          component={"div"}
                          style={{ color: "red" }}
                        ></ErrorMessage>
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                          label={"First Name"}
                          value={values?.customer_details.bill_to?.first_name}
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Last Name"}
                          value={values?.customer_details.bill_to?.last_name}
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"GST No."}
                          value={values?.customer_details.bill_to?.gst_no}
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Primary Email Id"}
                          value={
                            values?.customer_details.bill_to?.primary_email_id
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Secondary Email Id"}
                          value={
                            values?.customer_details?.bill_to
                              ?.secondary_email_id
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Primary Mobile No."}
                          value={
                            values?.customer_details.bill_to?.primary_mobile_no
                          }
                          type="number"
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Secondary Mobile No."}
                          value={
                            values?.customer_details.bill_to
                              ?.secondary_mobile_no
                          }
                          type="number"
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>

                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Country"}
                          value={
                            values?.customer_details.bill_to?.address?.country
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                        <ErrorMessage
                          name="address.country"
                          component={"div"}
                          style={{ color: "red" }}
                        ></ErrorMessage>
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"State"}
                          value={
                            values?.customer_details.bill_to?.address?.state
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"City"}
                          value={
                            values?.customer_details.bill_to?.address?.city
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Pincode"}
                          value={
                            values?.customer_details.bill_to?.address?.pincode
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Taluka"}
                          value={
                            values?.customer_details?.bill_to?.address?.taluka
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"District"}
                          value={
                            values?.customer_details?.bill_to?.address?.district
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Area"}
                          value={
                            values?.customer_details?.bill_to?.address?.area
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Location"}
                          value={
                            values?.customer_details?.bill_to?.address?.location
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "44.5%" }}>
                        <TextField
                          multiline
                          label={"Address"}
                          value={
                            values?.customer_details?.bill_to?.address?.address
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                    </Div>
                  </Div>
                  {/* ship to */}
                  <Div sx={{ width: "100%" }}>
                    <Typography sx={{ width: "100%" }} variant="h3">
                      Ship To [
                      <Checkbox
                        checked={
                          values?.customer_details?.bill_to?.branch_id != "" &&
                          values?.customer_details?.bill_to?.branch_id != "" &&
                          values?.customer_details?.bill_to?.branch_id ==
                            values?.customer_details?.ship_to?.branch_id
                        }
                        onChange={(e) =>
                          handleCheck(e, values, setFieldValue, initialValues)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      Same as Bill To? ]
                    </Typography>

                    <Div
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        columnGap: 5,
                        rowGap: 2,
                      }}
                    >
                      <Div sx={{ width: "100%" }}>
                        <Autocomplete
                          size="small"
                          id="tags-standard"
                          options={branchList || []}
                          value={
                            branchList?.filter(
                              (item) =>
                                item._id ==
                                values?.customer_details?.ship_to?.branch_id
                            )?.[0] || null
                          }
                          getOptionLabel={(option) =>
                            option?.current_data?.branch_name
                          }
                          onChange={(e, newValue) => {
                            if (newValue == null) {
                              setFieldValue(
                                "customer_details.ship_to",
                                initialValues.customer_details.ship_to
                              );
                            } else {
                              billShipToData(newValue, setFieldValue, "ship");
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                textTransform: "capitalize",
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.current_data?.branch_name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              label={"Branch Name"}
                              {...params}
                              variant="outlined"
                              sx={{
                                width: "21%",
                                "& .MuiInputBase-input": {
                                  textTransform: "capitalize", // Capitalize first letter
                                },
                              }}
                            />
                          )}
                        />

                        <ErrorMessage
                          name="customer_details.ship_to.branch_id"
                          component={"div"}
                          style={{ color: "red" }}
                        ></ErrorMessage>
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                          label={"First Name"}
                          value={values?.customer_details.ship_to?.first_name}
                          name="customer_details.ship_to?.first_name"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Last Name"}
                          value={values?.customer_details.ship_to?.last_name}
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"GST No."}
                          value={values?.customer_details.ship_to?.gst_no}
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Primary Email Id"}
                          value={
                            values?.customer_details.ship_to?.primary_email_id
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Secondary Email Id"}
                          value={
                            values?.customer_details?.ship_to
                              ?.secondary_email_id
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Primary Mobile No."}
                          value={
                            values?.customer_details.ship_to?.primary_mobile_no
                          }
                          type="number"
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Secondary Mobile No."}
                          value={
                            values?.customer_details.ship_to
                              ?.secondary_mobile_no
                          }
                          type="number"
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>

                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Country"}
                          value={
                            values?.customer_details.ship_to?.address?.country
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                        <ErrorMessage
                          name="address.country"
                          component={"div"}
                          style={{ color: "red" }}
                        ></ErrorMessage>
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"State"}
                          value={
                            values?.customer_details.ship_to?.address?.state
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"City"}
                          value={
                            values?.customer_details.ship_to?.address?.city
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Pincode"}
                          value={
                            values?.customer_details.ship_to?.address?.pincode
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Taluka"}
                          value={
                            values?.customer_details?.ship_to?.address?.taluka
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"District"}
                          value={
                            values?.customer_details.ship_to?.address?.district
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Area"}
                          value={
                            values?.customer_details?.ship_to?.address?.area
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "21%" }}>
                        <TextField
                          label={"Location"}
                          value={
                            values?.customer_details?.ship_to?.address?.location
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                      <Div sx={{ width: "44.5%" }}>
                        <TextField
                          multiline
                          label={"Address"}
                          value={
                            values?.customer_details?.ship_to?.address?.address
                          }
                          sx={{
                            width: "100%",
                            ...disabledStyling,
                            "& .MuiInputBase-input": {
                              textTransform: "capitalize", // Capitalize first letter
                            },
                          }}
                          disabled
                          size="small"
                        />
                      </Div>
                    </Div>
                  </Div>
                  <ProductTable
                    values={values}
                    setFieldValue={setFieldValue}
                    error={errors}
                    initialValues={initialValues}
                    setValues={setValues}
                  />
                  <Div
                    sx={{
                      width: "93.5%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 3,
                      mt: 3,
                    }}
                  >
                    <Button
                      sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={() => navigate("/dashboards/purchase-order")}
                    >
                      Back
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ width: "100px" }}
                      loading={isSubmitting}
                    >
                      Create
                    </LoadingButton>
                  </Div>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
