import { ALL_SSKCOMPANY_COMPANY_FAIL, ALL_SSKCOMPANY_COMPANY_REQUEST, ALL_SSKCOMPANY_COMPANY_SUCCESS, ALL_SSKCOMPANY_FAIL, ALL_SSKCOMPANY_REQUEST, ALL_SSKCOMPANY_SUCCESS } from "app/utils/constants/sskCompnayConstant";



const INIT_STATE = {
  branch: {
    loading: false,
    error: null,
    allBranch: [],
    TotalPage: null,
  },
  company: {
    loading: false,
    error: null,
    allCompany: [],
    TotalPage: null,
  }
};

export const sskCompanyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_SSKCOMPANY_REQUEST:
      return { ...state, branch: { ...state.branch, loading: true } };
    case ALL_SSKCOMPANY_SUCCESS:
      return {
        ...state,
        branch: {
          ...state.branch,
          loading: false,
          allBranch: action.payload.data,
          TotalPage: action.payload.totalPage,
        }
      };
    case ALL_SSKCOMPANY_FAIL:
      return {
        ...state, branch: {
          ...state.branch,
          loading: false,
          allRoles: [],
          error: action.payload
        }
      };
    case ALL_SSKCOMPANY_COMPANY_REQUEST:
      return { ...state, company: { ...state.company, loading: true } };
    case ALL_SSKCOMPANY_COMPANY_SUCCESS:
      return {
        ...state, company: {
          ...state.company,
          loading: false,
          allCompany: action.payload.data,
          TotalPage: action.payload.totalPage
        }
      };
    case ALL_SSKCOMPANY_COMPANY_FAIL:
      return {
        ...state, company: {
          ...state.company,
          loading: false,
          allCompany: [],
          error: action.payload
        }
      }
    default:
      return state;
  }
};

