import Div from "@jumbo/shared/Div/Div";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Heading from "app/pages/Component/Heading";
// import CategoryLogsModal from "../Logs";
import Swal from "sweetalert2";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import { getAllPurchaseOrders } from "app/redux/actions/PurchaseOrder";
import { formatDate } from "app/pages/Component/Function/formatDate";
import ViewPOModel from "../viewModal";
import { State, City } from "country-state-city";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Loader from "app/components/Loader";

export default function ListPurchaseOrder() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [openModel, setOpenModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [emptyVal, setEmptyVal] = useState(false);
  const [dropdownList, setDropdownList] = useState({
    districtList: [],
    areaList: [],
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
  });
  const [filters, setFilters] = useState({
    "supplier_details.company_name": "",
    order_status: "",
    from: "",
    to: "",
  });
  const { allPO, TotalPage, loading } = useSelector(
    (state) => state.purchaseOrderReducer
  );
  const { district, taluka } = useSelector((state) => state?.addressDropdown);
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  const filterFun = () => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        filter[`current_data.${i}`] = filters[i];
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };
  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllPurchaseOrders(value, sort, sortBy, 1, filterFun()));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const changeStatus = async (e, row) => {
    try {
      const response = await Axios.patch(
        AllApis.purchaseOrder.updateStatus(row._id),
        { status: e.target.value }
      );
      if (response) {
        Swal.fire({
          title: "Updated",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllPurchaseOrders("", sort, sortBy, page));
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "view":
        setOpenModel(true);
        setSelectedRow({ ...menuItem.data });
    }
  };
  const onChangeFilter = (e) => {
    setFilters({ ...filters, [e?.target?.name]: e?.target?.value });
  };
  const handleFilter = () => {
    dispatch(getAllPurchaseOrders(searchTerm, sort, sortBy, "", filterFun()));
    setPage(1);
  };
  const handleClear = () => {
    setFilters({
      "supplier_details.company_name": "",
      order_status: "",
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    dispatch(getAllPurchaseOrders(searchTerm, sort, sortBy, page, filterFun()));
  };
  useEffect(() => {
    dispatch(getAllPurchaseOrders(searchTerm, sort, sortBy, page, filterFun()));
  }, [sort, page]);

  useEffect(() => {
    (async function () {
      const supplierList = await Axios.get(`${AllApis.supplier.dropdown}`);
      setDropdownList({
        ...dropdownList,
        supplierList: supplierList?.data?.data?.supplier,
      });
    })();
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Heading Name={"Purchase Order"} />
      {loading && <Loader />}
      {/* filters */}
      <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          Filters
        </Typography>
        <Box
          sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
        >
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={dropdownList?.supplierList || []}
              getOptionLabel={(option) => option?.company_name || ""}
              // isOptionEqualToValue={
              //   // (option, value) => console.log(option?.company_name, value)
              //   option?.company_name === value?.company_name
              // }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.company_name":
                    newValue != null ? newValue?.company_name : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  textTransform={"capitalize"}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.company_name}{" "}
                  {option?.pan_no && `(${option.pan_no})`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Supplier Name"
                />
              )}
            />
          </Div>

          <FormControl sx={{ width: "23%" }} size="small">
            <InputLabel id="demo-select-small-label">Order Status</InputLabel>
            <Select
              size="small"
              labelId="demo-select-small-label"
              id="demo-simple-select"
              label="Order Status"
              value={filters.order_status}
              name="order_status"
              onChange={(e) => onChangeFilter(e)}
            >
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
            </Select>
          </FormControl>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={dropdownList?.stateList || []}
              getOptionLabel={(option) => option?.name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.address.state":
                    newValue != null ? newValue?.name?.toLowerCase() : "",
                });
                if (newValue != null) {
                  const cityNames = City.getCitiesOfState(
                    "IN",
                    newValue.isoCode
                  );
                  setDropdownList({ ...dropdownList, cityList: cityNames });
                } else {
                  setDropdownList({
                    ...dropdownList,
                    cityList: City.getCitiesOfState("IN", "MH"),
                  });
                }
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="State" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={dropdownList?.cityList || []}
              getOptionLabel={(option) => option?.name || ""}
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.address.city":
                    newValue != null ? newValue?.name?.toLowerCase() : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="City" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={taluka || []}
              getOptionLabel={(option) => option?.name || ""}
              // isOptionEqualToValue={
              //   (option, value) => console.log(option?.name, value)
              //   option?.name === value?.name
              // }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.address.taluka":
                    newValue != null ? newValue?.name?.toLowerCase() : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Taluka" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Autocomplete
              key={emptyVal ? "refresh1" : "refresh2"}
              sx={{ width: "100%", textTransform: "capitalize" }}
              size="small"
              id="company-autocomplete"
              options={district || []}
              getOptionLabel={(option) => option?.name || ""}
              // isOptionEqualToValue={
              //   (option, value) => console.log(option?.name, value)
              //   option?.name === value?.name
              // }
              onChange={(e, newValue) => {
                setFilters({
                  ...filters,
                  "supplier_details.address.district":
                    newValue != null ? newValue?.name?.toLowerCase() : "",
                });
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="District" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="from"
                format="DD-MM-YYYY"
                name="from"
                label={"From Order Date"}
                sx={datepickerStyling}
                defaultValue={filters.from !== "" ? dayjs(filters.from) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    from: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {filters?.to && !filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                Select From Order date{" "}
              </Typography>
            )}
          </Div>
          <Div sx={{ width: "23%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={emptyVal ? "refresh1" : "refresh2"}
                id="to"
                format="DD-MM-YYYY"
                name="to"
                sx={datepickerStyling}
                label="To Est. Delivery Date"
                defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    to: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                  });
                }}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            {!filters?.to && filters?.from && (
              <Typography
                sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
              >
                Select To Est. Delivery Date{" "}
              </Typography>
            )}
          </Div>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            sx={{ marginRight: 1 }}
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
          <Button variant="outlined" onClick={() => handleClear()}>
            Clear
          </Button>
        </Box>
      </Div>
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          size="small"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value == "") {
              setSort("desc");
              setSortBy("created_at");
              dispatch(
                getAllPurchaseOrders("", "desc", "created_at", 1, filterFun())
              );
            }
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        <Div>
          {/* <Button
            variant="contained"
            sx={{
              mr: 2,
              p: 1,
              pl: 4,
              pr: 4,
            }}
            onClick={() => setLogModalOpen(true)}
          >
            Logs
          </Button> */}
          {Permission?.["ssk_po"]?.["add"] && (
            <Button
              variant="contained"
              size="small"
              sx={{
                mr: 2,
                p: 1,
                pl: 4,
                pr: 4,
              }}
              onClick={() => navigate("/dashboards/purchase-order/add")}
            >
              Create Purchase Order
            </Button>
          )}
        </Div>
      </Div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "190px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.purchase_order_no")}
                  direction={sort}
                  sx={{
                    color: "white",
                    width: "100%",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Purchase Order No
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() =>
                    handleSort("current_data?.supplier_details?.company_name")
                  }
                  direction={sort}
                  sx={{
                    color: "white",
                    width: "100%",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() =>
                    handleSort("current_data?.supplier_details?.gst_no")
                  }
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  GST No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() =>
                    handleSort("current_data?.supplier_details?.address?.city")
                  }
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() =>
                    handleSort("current_data?.supplier_details?.address?.state")
                  }
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "230px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Est. Delivery Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.total_weight")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Total Weight
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  onClick={() => handleSort("current_data.total_amount")}
                  direction={sort}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Total Amount
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "150px",
                  verticalAlign: "middle",
                  color: "white",
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  bgcolor: "#7352C7",
                  height: "58px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPO?.map((row, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.purchase_order_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.supplier_details?.company_name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.supplier_details?.gst_no}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.supplier_details?.address?.city}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {row?.current_data?.supplier_details?.address?.state}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {formatDate(row?.current_data?.purchase_order_date)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {formatDate(row?.current_data?.estimate_delivery_date)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Select
                    sx={{ width: "100%" }}
                    size="small"
                    disabled={!Permission?.["ssk_po"]?.["edit"]}
                    value={row?.current_data?.order_status}
                    onChange={(e) => changeStatus(e, row)}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                    <MenuItem value="closed">Closed</MenuItem>
                  </Select>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {row?.current_data?.total_weight}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                  }}
                >
                  ₹ {row?.current_data?.total_amount?.toFixed(2)}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <JumboDdMenu
                    icon={<MoreHorizIcon />}
                    menuItems={[
                      {
                        icon: <VisibilityRoundedIcon />,
                        title: "View",
                        action: "view",
                        data: row,
                      },
                    ]}
                    onClickCallback={handleItemAction}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            p: 1,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
      <ViewPOModel
        open={openModel}
        setOpen={setOpenModel}
        selectedRow={selectedRow}
      />

      {/* <CategoryLogsModal
        open={isLogModalOpen}
        onClose={() => setLogModalOpen(false)}
      /> */}
    </Div>
  );
}
