import React from "react";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import LabelIcon from '@mui/icons-material/Label';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Divider, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";

const Heading = ({Name}) => {
  return (
    <>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <KeyboardDoubleArrowRightIcon sx={{ mb: 1 }} fontSize="medium" />
        <Typography variant="h1" sx={{ml:0.5}}>
         {Name}
        </Typography>
      </Div>
      <Divider color="#adb5bd" style={{ height: "2px" }}></Divider>
    </>
  );
};

export default Heading;