const { OFFLINE_PAYMENT_FAILED, OFFLINE_PAYMENT_SUCCESSS, OFFLINE_PAYMENT_REQUEST } = require("app/utils/constants/paymentContanst");

const INIT_VALUE = {
    loading: false,
    offlinePaymentList: [],
    totalPage: 0,
    error: false,
    errorMessage: null
}


const offlinePaymentReducer = (state = INIT_VALUE, action) => {
    switch (action.type) {
        case OFFLINE_PAYMENT_REQUEST:
            return { ...state, loading: true }
        case OFFLINE_PAYMENT_SUCCESSS:
            return {
                ...state,
                offlinePaymentList: action.payload.offlinePaymentList,
                totalPage: action.payload.totalPage,
                loading: false
            }
        case OFFLINE_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                offlinePaymentList: [],
                totalPage: 0,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default offlinePaymentReducer