import { ALL_PURCHASE_ORDER_FAIL, ALL_PURCHASE_ORDER_REQUEST, ALL_PURCHASE_ORDER_SUCCESS } from "app/utils/constants/purchaseOrderConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allPO: [],
  TotalPage: null,
};

export const PurchaseOrderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        allPO: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_PURCHASE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        allPO: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
