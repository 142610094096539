import {
  ALL_TICKETS_FAIL,
  ALL_TICKETS_REQUEST,
  ALL_TICKETS_SUCCESS,
} from "app/utils/constants/ticketConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  allTicket: [],
};

export const ticketReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTicket: action.payload.data,
        TotalPage: action.payload.totalPage,
      };

    case ALL_TICKETS_FAIL:
      return {
        ...state,
        loading: false,
        allTicket: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
