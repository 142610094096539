import AllApis from "app/Apis/apis";
import {
  ACTIVE_USER_LIST_FAIL,
  ACTIVE_USER_LIST_REQUEST,
  ACTIVE_USER_LIST_SUCCESS,
  CLEAR_USER_ERROR,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
} from "app/utils/constants/Users/usersConstant";

import { Axios } from "index";
import Cookies from "js-cookie";
export const getUsers =
  (search_value, sortOrder, sortField, page = 1) =>
  async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: [
            "current_data.employee_id",
            "current_data.first_name",
            "current_data.last_name",
            "current_data.primary_email_id",
            "current_data?.role_id.current_data.role_name",
            "current_data.approver_one.name",
            "current_data.approver_two.name",
            "current_data.address.state",
            "current_data.address.city",
          ],
          numbers: ["current_data.primary_mobile_no"],
        },
      };
      if (!search_value) {
        search_value = "";
      }
      dispatch({ type: USER_LIST_REQUEST });

      const data = await Axios.post(
        `${AllApis.users.list}?search=${search_value}&page=${page}&sortOrder=${sortOrder}&sortField=${sortField}`,
        body
      );
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: {
          data: data?.data?.data,
          totalPage: data?.data?.data?.length,
        },
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getAllActiveUsers =
  (search_value, sort, sortBy, page) => async (dispatch) => {
    try {
      const body = {
        filters: {},
        searchFields: {
          string: ["user_id.current_data.first_name","user_id.current_data.last_name","user_id.current_data.role_id.current_data.role_name"],
        },
      };
      if (!search_value) {
        search_value = "";
      }

      const urlParams = new URLSearchParams({
        search: search_value.trim(),
        page: page,
        sort: sort,
        sortBy: sortBy,
      });

      dispatch({ type: ACTIVE_USER_LIST_REQUEST });
      const data = await Axios.post(
        `${AllApis.users.activeuserslist}?${urlParams.toString()}`,
        body
      );
  
      dispatch({
        type: ACTIVE_USER_LIST_SUCCESS,
        payload: {
          data: data?.data?.data,
          totalPage: data?.data?.data?.totalPages,
        },
      });
    } catch (error) {
      dispatch({
        type: ACTIVE_USER_LIST_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch) => {
  try {
    // const id = localStorage.getItem("user_id");
    const id = Cookies.get("user_id");
    dispatch({ type: LOAD_USER_REQUEST });
    const data = await Axios.get(`${AllApis.users.getUserDetails(id)}`);
    dispatch({ type: LOAD_USER_SUCCESS, payload: data.data.data.user });
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const clearUserDetailsError = () => async (dispatch) => {
  try {
    console.log("clear error");
    dispatch({ type: CLEAR_USER_ERROR });
  } catch (error) {
    console.log(error);
  }
};
