//midleware
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
const AuthenticateAndRolesMiddleware = ({ fallbackPath }) => {
  const { userData } = useSelector((state) => state.userReducer);
  const { url, module, role } = fallbackPath;

  // const isAuthenticated = localStorage.getItem("isAuthenticate");
  const isAuthenticated = Cookies.get("isAuthenticate");
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  if (Permission) {
    if (isAuthenticated === "true" && Permission?.[module]?.[role]) {
      return <Outlet />;
    } else {
      return <Navigate to={url} />;
    }
  } else {
    if (isAuthenticated === "true") {
      return <Outlet />;
    } else {
      return <Navigate to={url} />;
    }
  }
};

export default AuthenticateAndRolesMiddleware;
