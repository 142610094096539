import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { formatDate } from 'app/pages/Component/Function/formatDate';

const TableHeadCell = {
    textAlign: "left",
    minWidth: "190px",
    verticalAlign: "middle",
    textTransform: "capitalize",
    color: "white"
}
const TableRowCell = { textAlign: "left", color: "black",borderBottom:"1px solid #fbfbfc",borderColor:"#0000001a" }

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{...TableRowCell}}>
                    {formatDate(row?.paymentDate) || formatDate(new Date())}
                </TableCell>
                <TableCell align="left" sx={{...TableRowCell}}>{row.paymentType}</TableCell>
                <TableCell align="left" sx={{...TableRowCell}}>{row.transactionId}</TableCell>
                <TableCell align="left" sx={{...TableRowCell}}>{row.upiId}</TableCell>
                <TableCell align="left" sx={{...TableRowCell}}>₹ {row.paymentAmount}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h4" gutterBottom component="div" my={3} color={"#7352C7"}>
                                Follow Up & Remarks
                            </Typography>
                            {/* <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Follow Up date</TableCell>
                                        <TableCell>Remarks</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {row?.followUp}
                                        </TableCell>
                                        <TableCell>{row?.remarks}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table> */}
                            <Box >
                                <Typography sx={{ color:"black",fontSize:"16px" }}>Follow Up Date :- {formatDate(row?.followUpDate) || "N/A"}</Typography>
                                <Typography sx={{ color:"black",fontSize:"16px", my: 1 }}>Remarks : {!row?.remark && "N/A"}</Typography>
                                {row?.remark && <Typography sx={{ color:"black",fontSize:"16px", ml: 2 }}>{row?.remark || "N/A"}</Typography>}
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ViewOfflinePayment({ offlinePaymentData }) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: 5 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Follow Up & Remarks" {...a11yProps(0)} />
                    <Tab label="Payment Details" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead sx={{ bgcolor: "#7352C7", color: "white" }}>
                            <TableRow>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Follow Up Date</TableCell>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                offlinePaymentData?.followUp?.map((followUp) => {
                                    return <TableRow>
                                        <TableCell align="left" sx={{...TableRowCell}}>{formatDate(followUp.followUpDate)}</TableCell>
                                        <TableCell align="left" sx={{...TableRowCell}}>{followUp.remark}</TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead sx={{ bgcolor: "#7352C7", color: "white" }}>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Payment Date</TableCell>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Payment Type</TableCell>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Transaction Id</TableCell>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>UPI Id</TableCell>
                                <TableCell align="left" sx={{ ...TableHeadCell }}>Payment Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                offlinePaymentData?.payments?.map((paymentDetails) => {
                                    return (
                                        <Row key={paymentDetails._id} row={paymentDetails} />
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CustomTabPanel>
        </Box>
    );
}