import Div from "@jumbo/shared/Div/Div";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import EditIcon from "@mui/icons-material/Edit";
import Heading from "app/pages/Component/Heading";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import OrderTable from "../Tables";
import { getAllOrders } from "app/redux/actions/OrderProcessing/Orders";
import { State, City } from "country-state-city";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Loader from "app/components/Loader";

export default function ListOrders() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [type, settype] = useState("retailers");
  const [emptyVal, setEmptyVal] = useState();
  const { area, district, location, taluka } = useSelector(
    (state) => state?.addressDropdown
  );
  const { loading } = useSelector((state) => state?.orderReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const Permission = userData?.current_data?.role_id?.current_data?.permissions;

  const [dropdownList, setDropdownList] = useState({
    districtList: district,
    talukaList: taluka,
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
  });
  const [filters, setFilters] = useState({
    order_status: "",
    from: "",
    to: "",
  });

  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("created_at");
    setSearchTerm("");
  };

  const filterFun = () => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        filter[`current_data.${i}`] = filters[i];
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };

  const handleFilter = () => {
    setPage(1);
    dispatch(getAllOrders(searchTerm, sort, sortBy, "", type, filterFun()));
  };
  const handleClear = () => {
    TabChange();
    dispatch(getAllOrders(searchTerm, sort, sortBy, page, type, {}));
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllOrders(value, sort, sortBy, page, type, filterFun()));
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Div sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Div>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllOrders(searchTerm, sort, sortBy, page, type, filterFun()));
  }, [sort, page]);

  useEffect(() => {
    dispatch(getAllOrders("", "desc", "created_at", 1, type, {}));
  }, [type]);

  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Heading Name={"Orders"} />
        {loading && <Loader />}
        <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
          <Typography variant="h1" sx={{ mb: 3 }}>
            Filters
          </Typography>
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={dropdownList?.stateList || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   // (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.state":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                  if (newValue != null) {
                    const cityNames = City.getCitiesOfState(
                      "IN",
                      newValue.isoCode
                    );
                    setDropdownList({ ...dropdownList, cityList: cityNames });
                  } else {
                    setDropdownList({
                      ...dropdownList,
                      cityList: City.getCitiesOfState("IN", "MH"),
                    });
                  }
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="State" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={dropdownList?.cityList || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.city":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="City" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={taluka || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.taluka":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Taluka" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                freeSolo
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={district || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.district":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="District" />
                )}
              />
            </Div>

            <FormControl sx={{ width: "23%" }} size="small">
              <InputLabel id="demo-select-small-label">Order Status</InputLabel>
              <Select
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%" }}
                size="small"
                labelId="demo-select-small-label"
                id="demo-simple-select"
                label="Order Status"
                name="order_status"
                defaultValue={""}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    order_status: e.target.value ? e.target.value : "",
                  });
                }}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                <MenuItem value={"closed"}>Closed</MenuItem>
              </Select>
            </FormControl>

            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  label={"From Order Date"}
                  sx={datepickerStyling}
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  }
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <Typography
                  sx={{
                    color: "#E73145",
                    fontSize: "0.75rem",
                    ml: 1,
                    mt: 0.5,
                  }}
                >
                  Select From Order date{" "}
                </Typography>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label="To Est. Delivery Date"
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <Typography
                  sx={{ color: "#E73145", fontSize: "0.75rem", ml: 1, mt: 0.5 }}
                >
                  Select Est. Delivery Date{" "}
                </Typography>
              )}
            </Div>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Box>
        </Div>
      </Div>

      <Div>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setPage(1);
                setSort("desc");
                setSortBy("created_at");
                dispatch(
                  getAllOrders("", "desc", "created_at", 1, type, filterFun())
                );
              }
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Div>
            {Permission?.["order"]?.["add"] && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  mr: 2,
                  p: 1,
                  pl: 4,
                  pr: 4,
                }}
                onClick={() =>
                  navigate("/dashboards/orderProcessing/orders/add")
                }
              >
                Create Order
              </Button>
            )}
          </Div>
        </Div>

        <Div>
          <JumboDemoCard
            title={"Supplier Type"}
            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
          >
            <Div sx={{ width: "100%" }}>
              <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={(event, newValue) => setValue(newValue)}
                >
                  <Tab
                    label="Retailers"
                    {...a11yProps(0)}
                    onClick={() => {
                      TabChange();
                      settype("retailers");
                    }}
                  />
                  <Tab
                    label="Offline Store"
                    {...a11yProps(1)}
                    onClick={() => {
                      TabChange();
                      settype("offlinestores");
                    }}
                  />
                  {/* <Tab
                    label="Website"
                    {...a11yProps(2)}
                    onClick={() => {
                      TabChange();
                      settype("websites");
                    }}
                  /> */}
                </Tabs>
              </Div>

              <TabPanel value={value} index={0}>
                <OrderTable
                  filter={filterFun}
                  tabletype={"Retailer Name"}
                  page={page}
                  setPage={setPage}
                  type={type}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <OrderTable
                  filter={filterFun}
                  tabletype={"Store Name"}
                  page={page}
                  setPage={setPage}
                  type={type}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <OrderTable
                  filter={filterFun}
                  tabletype={"Website"}
                  page={page}
                  setPage={setPage}
                  type={type}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>
            </Div>
          </JumboDemoCard>
        </Div>
      </Div>
    </>
  );
}
