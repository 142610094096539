import Div from "@jumbo/shared/Div"
import Address from "app/components/Address/Index"
import * as yup from "yup"
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Form, Formik, } from "formik"
import AddCompany from "../AddCompany";
import AddKyc from "app/components/AddBranch/AddKyc";
import AddContact from "app/components/AddBranch/AddContact";
import { getCompanyDropdown } from "app/Apis/Masters/Suppliers";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import AddBranch from "app/components/AddBranch/AddBranch";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Heading from "app/components/Heading";

export const BranchFormContext = createContext(null)

const AddEditSuppliers = function ({ companyType,label }) {

    //while editing companyBranch 
    const { id } = useParams(); //branch id
    const { state } = useLocation();
    const branchData = state?.branchData //company branch data

    //creating refernce for all form to submit in one button
    const branchRef = useRef(null);
    const kycFormikRef = useRef(null);
    const addressFormikRef = useRef(null);
    const contactFormikRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate();
    //setting the array of masters which i used as dropdown in addbranch components
    const [companyDropdown, setCompanyDropdown] = useState([]);

    //selected one companyId from dropdown 
    //while editing if id and branchData is there it will set directly
    const [companyId, setCompanyId] = useState(!(id && branchData) ? {
        _id: "",
        company_name: ""
    } : {
        _id: branchData?.[`${companyType}Id`]?._id,
        company_name: branchData?.[`${companyType}Id`]?.current_data?.company_name
    });

    //while editing if id and branchData setting data initail value is branch data
    const [data, setData] = useState(!(id && branchData) ? {
        [`${companyType}Id`]: companyId?._id,
        branch_name: "",
        branch_onboarding_date: new Date(Date.now()).toISOString().split('T')[0],
        kyc: {
            // pan: {
            //     pan_no: "",
            //     pan_image: "",
            // },
            gst: {
                gst_no: "",
                gst_image: "",
            },
            bank_details: {
                bank_name: "",
                account_no: "",
                confirm_account_no: "",
                ifsc_code: "",
                passbook_image: "",
            },
        },
        branch_address: {
            address: "",
            location: "",
            area: "",
            district: "",
            taluka: "",
            state: "",
            city: "",
            country: "",
            pincode: "",
        },
        contact_person: [],
    } : { ...branchData, [`${companyType}Id`]: branchData?.[`${companyType}Id`]?._id, branch_onboarding_date: new Date(branchData?.branch_onboarding_date).toISOString().split('T')[0] })



    const handleSubmitssss = async () => {
        const formRefs = [branchRef, kycFormikRef, addressFormikRef, contactFormikRef];
        try {
            setIsSubmitting(true)
            await Promise.all(formRefs.map((ref) => ref.current.submitForm()))
            const errors = await Promise.all(formRefs.map((ref) => ref.current.validateForm()));
            if (contactFormikRef?.current?.values?.contact_person.length <= 0) {
                Swal.fire({
                    icon: "warning",
                    title: "At least one contact person is required",
                    text: "",
                    timer: 1200,
                    showConfirmButton: false,
                })
                setIsSubmitting(false)
                return;
            }
            if (errors.every((formErrors) => Object.values(formErrors).every((fieldErrors) => !fieldErrors))) {
                const payload = {
                    [`${companyType}Id`]: branchRef?.current?.values?.[`${companyType}Id`],
                    branch_name: branchRef?.current?.values?.branch_name,
                    branch_onboarding_date: branchRef?.current?.values?.branch_onboarding_date,
                    kyc: kycFormikRef?.current?.values,
                    branch_address: addressFormikRef?.current?.values,
                    contact_person: contactFormikRef?.current?.values?.contact_person,
                }
                const formData = new FormData();
                formData.append("branchData",JSON.stringify(payload));
                formData.append("pan_image", payload?.kyc?.pan?.pan_image)
                formData.append("gst_image", payload?.kyc?.gst?.gst_image)
                formData.append("passbook_image", payload?.kyc?.bank_details?.passbook_image);

                let AddBranch;

                if (!(id && branchData)) {
                    //Add Branch
                    AddBranch = await Axios.post(AllApis?.[companyType].branch.add, formData);
                    if (AddBranch?.data?.statusCode === 201) {
                        Swal.fire({
                            icon: "success",
                            title: AddBranch?.data?.status,
                            text: AddBranch?.data?.message,
                            timer: 1000,
                            showConfirmButton: false,
                        })
                        setIsSubmitting(false)
                    }
                    navigate(`/dashboards/master/${companyType}`)
                } else {
                    //Edit branch
                    AddBranch = await Axios.patch(AllApis?.[companyType].branch.edit(branchData?.[`${companyType}Id`]?._id, id), payload);
                    if (AddBranch?.data?.statusCode === 200) {
                        Swal.fire({
                            icon: "success",
                            title: AddBranch?.data?.status,
                            text: AddBranch?.data?.message,
                            timer: 1000,
                            showConfirmButton: false,
                        })
                        setIsSubmitting(false)
                    }
                    navigate(`/dashboards/master/${companyType}`)
                }
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "Invalid Form Details",
                    text: "please fill out the form correctly",
                    // timer: 1200,
                    showConfirmButton: true,
                })
            }
            setIsSubmitting(false)
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message,
                text: "",
                timer: 1000,
                showConfirmButton: false,
            })
            setIsSubmitting(false)
        }
    };

    useEffect(() => {
        try {
            getCompanyDropdown(companyType).then((list) => {
                if (list) {
                    setCompanyDropdown([...list])
                }
            })

        } catch (error) {
            console.log(error)
        }
    }, [companyId, companyType])

    useEffect(() => {
        setData((pre) => ({ ...pre, companyId: companyId?._id }))
    }, [companyId])

    return (
        <>
            <Div sx={{ mt: -4 }}>
                {state?.addBranch !== true && <Heading Name={!(id && branchData) ? `Add ${label}` : `Edit ${label}`}></Heading>}

                {!(id && branchData) && state?.addBranch !== true && <AddCompany
                    setCompanyId={setCompanyId}
                    companyId={companyId}
                    companyType={companyType}
                />}

                {/* branch */}
                {(companyId?._id || branchData || state?.addBranch === true) && <BranchFormContext.Provider value={{
                    branchProps: {
                        companyId: companyId,
                        setCompanyId: setCompanyId,
                        addBranch: state?.addBranch,
                        data: data,
                        setData: setData,
                        companyDropdown: companyDropdown,
                        companyType: companyType,
                    }
                }}>
                    <Div sx={{ marginTop: "2%" }}>
                        <Typography variant="h2">{!(id && branchData) ? "Add" : "Edit"} Branch</Typography>

                        <AddBranch formikRef={branchRef} />

                        <AddKyc formikRef={kycFormikRef} />

                        <Address formikRef={addressFormikRef} />

                        <AddContact formikRef={contactFormikRef} />

                        <Div
                            sx={{
                                width: "92%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 3,
                                mt: 4,
                            }}
                        >
                            <Button
                                sx={{ width: "100px" }}
                                variant="outlined"
                                onClick={() => navigate(`/dashboards/master/${companyType}`)}
                            >
                                Back
                            </Button>

                            <LoadingButton
                                variant="contained"
                                type="submit"
                                onClick={handleSubmitssss}
                                sx={{ width: "100px" }}
                                loading={isSubmitting}
                            >
                                submit
                            </LoadingButton>
                        </Div>
                    </Div>
                </BranchFormContext.Provider>}
            </Div>
        </>
    )
}
export default AddEditSuppliers