import Div from "@jumbo/shared/Div";
import {
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AllApis from "app/Apis/apis";
import { Axios } from "index";
import Swal from "sweetalert2";


function AddStockForm({ POdata }) {
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    // Reset Formik state when POdata changes
    setFormKey((prevKey) => prevKey + 1);
  }, [POdata]);
  const handleImagesTab = async (value) => {
    if (value && typeof value === "string") {
      const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
      window.open(imageURL.href, "_blank");
    } else {
      const images = URL.createObjectURL(value);
      window.open(images, "__blank");
    }
  };
  const InitialValues = {
    purchaseOrderNo: POdata?.current_data?.purchase_order_no
      ? POdata?.current_data?.purchase_order_no
      : "",
    supplierDetails: {
      SupplierCompanyName: POdata?.current_data?.supplier_details?.company_name
        ? POdata?.current_data?.supplier_details?.company_name
        : "",
      supplierId: POdata?.current_data?.supplier_details?.supplier_id
        ? POdata?.current_data?.supplier_details?.supplier_id
        : "",
      branchId: POdata?.current_data?.supplier_details?.branch_id
        ? POdata?.current_data?.supplier_details?.branch_id
        : "",
      gstNo: POdata?.current_data?.supplier_details?.gst_no
        ? POdata?.current_data?.supplier_details?.gst_no
        : "",
      firstName: POdata?.current_data?.supplier_details?.first_name
        ? POdata?.current_data?.supplier_details?.first_name
        : "",
      lastName: POdata?.current_data?.supplier_details?.last_name
        ? POdata?.current_data?.supplier_details?.last_name
        : "",
      primaryEmailID: POdata?.current_data?.supplier_details?.primary_email_id
        ? POdata?.current_data?.supplier_details?.primary_email_id
        : "",
      secondaryEmailID: POdata?.current_data?.supplier_details
        ?.secondary_email_id
        ? POdata?.current_data?.supplier_details?.secondary_email_id
        : "",
      primaryMobileNo: POdata?.current_data?.supplier_details?.primary_mobile_no
        ? POdata?.current_data?.supplier_details?.primary_mobile_no
        : "",
      secondaryMobileNo: POdata?.current_data?.supplier_details
        ?.secondary_mobile_no
        ? POdata?.current_data?.supplier_details?.secondary_mobile_no
        : "",
      address: {
        address: POdata?.current_data?.supplier_details?.address?.address
          ? POdata?.current_data?.supplier_details?.address?.address
          : "",
        location: POdata?.current_data?.supplier_details?.address?.location
          ? POdata?.current_data?.supplier_details?.address?.location
          : "",
        area: POdata?.current_data?.supplier_details?.address?.area
          ? POdata?.current_data?.supplier_details?.address?.area
          : "",
        district: POdata?.current_data?.supplier_details?.address?.district
          ? POdata?.current_data?.supplier_details?.address?.district
          : "",
        taluka: POdata?.current_data?.supplier_details?.address?.taluka
          ? POdata?.current_data?.supplier_details?.address?.taluka
          : "",
        state: POdata?.current_data?.supplier_details?.address?.state
          ? POdata?.current_data?.supplier_details?.address?.state
          : "",
        city: POdata?.current_data?.supplier_details?.address?.city
          ? POdata?.current_data?.supplier_details?.address?.city
          : "",
        country: POdata?.current_data?.supplier_details?.address?.country
          ? POdata?.current_data?.supplier_details?.address?.country
          : "",
        pincode: POdata?.current_data?.supplier_details?.address?.pincode
          ? POdata?.current_data?.supplier_details?.address?.pincode
          : "",
      },
    },
    itemsDetails: POdata?.current_data?.Items
      ? POdata?.current_data?.Items.map((item) => ({
          ...item,
          receivedQuantity: "",
        }))
      : [],
    transportDetails: {
      deliveryChallanNo: "",
      transportType: {
        handDelivery: {
          personName: "",
          personNumber: "",
        },
        courier: {
          companyName: "",
          docketNumber: "",
        },
        road: {
          transporterName: "",
          vehicleNumber: "",
          driverName: "",
          driverNumber: "",
        },
        airRail: {
          transporterName: "",
          airRail: "",
          awbNumber: "",
        },

        deliveryChallanNo_image: "",
      },
    },
    invoiceDetails: {
      invoiceNo: "",
      invoiceDate: "",
      itemsAmount: "",
      discountPercentage: "",
      discountAmount: "",
      gstPercentage: "",
      gstAmount: "",
      totalAmount: "",
      uploadInviocePDF: "",
    },
    receivedDate: "",
  };
  const handleSubmit = async (values) => {
    try {
       let formdata = new FormData();
       formdata.append("supplierDetails",JSON.stringify(values.supplierDetails) );
       formdata.append("itemsDetails", JSON.stringify(values.itemsDetails));
       formdata.append("transportDetails",JSON.stringify(values.transportDetails));
       formdata.append("invoiceDetails", JSON.stringify(values.invoiceDetails));
       formdata.append("deliveryChallanNo_image", values.transportDetails.transportType.deliveryChallanNo_image);
       formdata.append("uploadInviocePDF", values.invoiceDetails.uploadInviocePDF);
       formdata.append("receivedDate", values.receivedDate);
       let response = await Axios.post(AllApis.inventory.add, formdata);
       if (response?.data?.statusCode) {
         Swal.fire({
           title: "Inventory Updated successfully",
           icon: "success",
           timer: 2000,
           showConfirmButton: false,
         });
         navigate("/dashboards/inventory");
       }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    }
   
  };
  const validationSchema = yup.object().shape({});

  return (
    <>
      <Div sx={{ mt: "-4px" }}>
        <Box sx={{ marginTop: "2%" }}>
          <Formik
            key={formKey}
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              handleSubmit,
              touched,
              errors,
            }) => {
              return (
                <>
                  <Form>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "2% 0",
                      }}
                    >
                      <TextField
                        name="purchaseOrderNo"
                        type="text"
                        value={values.purchaseOrderNo}
                        label="Purchase Order No"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />

                      <TextField
                        name="receivedDate"
                        type="date"
                        value={values.receivedDate}
                        label="Received Date"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Div>
                    <Typography variant="h1">Supplier Details</Typography>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "2% 0",
                      }}
                    >
                      <TextField
                        name="SupplierCompanyName"
                        type="text"
                        value={values.supplierDetails.SupplierCompanyName}
                        label="Supplier Name"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.gstNo"
                        type="text"
                        value={values?.supplierDetails?.gstNo}
                        label="GST No"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.firstName"
                        type="text"
                        value={values?.supplierDetails?.firstName}
                        label="First Name"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.lastName"
                        type="text"
                        value={values?.supplierDetails?.lastName}
                        label="Last Name"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.primaryEmailID"
                        type="text"
                        value={values?.supplierDetails?.primaryEmailID}
                        label="Primary Email ID"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.secondaryEmailID"
                        type="text"
                        value={values?.supplierDetails?.secondaryEmailID}
                        label="Secondary Email ID"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.primaryMobileNo"
                        type="text"
                        value={values?.supplierDetails?.primaryMobileNo}
                        label="Primary MobileNo"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.secondaryMobileNo"
                        type="text"
                        value={values?.supplierDetails?.secondaryMobileNo}
                        label="Secondary Mobile No."
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                    </Div>
                    <Typography variant="h4">Supplier Address</Typography>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "2% 0",
                      }}
                    >
                      <TextField
                        name="supplierDetails.address.address"
                        type="text"
                        value={values?.supplierDetails?.address?.address}
                        label="Address"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.location"
                        type="text"
                        value={values?.supplierDetails?.address?.location}
                        label="Address"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.area"
                        type="text"
                        value={values?.supplierDetails?.address?.area}
                        label="Area"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.district"
                        type="text"
                        value={values?.supplierDetails?.address?.district}
                        label="District"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.taluka"
                        type="text"
                        value={values?.supplierDetails?.address?.taluka}
                        label="Taluka"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.state"
                        type="text"
                        value={values?.supplierDetails?.address?.state}
                        label="State"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.city"
                        type="text"
                        value={values?.supplierDetails?.address?.city}
                        label="City"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.country"
                        type="text"
                        value={values?.supplierDetails?.address?.country}
                        label="Country"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="supplierDetails.address.pincode"
                        type="text"
                        value={values?.supplierDetails?.address?.pincode}
                        label="Pincode"
                        disabled="true"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                    </Div>
                    <Typography variant="h1">Item Details</Typography>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                          <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                            {[
                              "Items Name",
                              "Category",
                              "SKU",
                              "HSN Code",
                              "Unit",
                              "Rate per unit",
                              "Items Weight",
                              "Quantity",
                              "Item Amount",
                              "CGST",
                              "SGST",
                              "IGST",
                              "Total Amount",
                              "Received Quantity",
                            ].map((ele, i) => {
                              return (
                                <>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      textTransform: "capitalize",
                                      color: "white",
                                      textAlign: "left",
                                      minWidth: "150px",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {ele}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values?.itemsDetails &&
                            values?.itemsDetails.map((row, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.item_name}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.category}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.sku}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.hsn_code}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.unit}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.rate_per_unit}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.weight}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.quantity}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.item_amount}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.gst?.cgst}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.gst?.sgst}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.gst?.igst}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "left" }}>
                                    {row?.total_amount}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    <TextField
                                      name={`itemsDetails[${i}].receivedQuantity`}
                                      type="text"
                                      value={
                                        values.itemsDetails[i].receivedQuantity
                                      }
                                      label="Received Quantity"
                                      size="small"
                                      sx={{
                                        width: "100%",
                                      }}
                                      onChange={handleChange}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          <TableRow>
                            <TableCell colSpan={6} align="right">
                              Total:
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_weight}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_quantity}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_item_amount}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_cgst}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_sgst}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_igst}
                            </TableCell>
                            <TableCell align="left">
                              {POdata?.current_data?.total_amount}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography variant="h1" sx={{ margin: "2% 0" }}>
                      Invoice Details
                    </Typography>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "2% 0",
                      }}
                    >
                      <TextField
                        name="invoiceDetails.invoiceNo"
                        type="text"
                        value={values?.invoiceDetails?.invoiceNo}
                        label="Invoice Number"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.invoiceDate"
                        type="date"
                        value={values?.invoiceDetails?.invoiceDate}
                        label="Invoice Date"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.itemsAmount"
                        type="text"
                        value={values?.invoiceDetails?.itemsAmount}
                        label="Items Amount"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.discountPercentage"
                        type="text"
                        value={values?.invoiceDetails?.discountPercentage}
                        label="Discount Percentage"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.discountAmount"
                        type="text"
                        value={values?.invoiceDetails?.discountAmount}
                        label="Discount Amount"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.gstPercentage"
                        type="text"
                        value={values?.invoiceDetails?.gstPercentage}
                        label="GST Percentage"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.gstAmount"
                        type="text"
                        value={values?.invoiceDetails?.gstAmount}
                        label="GST Amount"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.totalAmount"
                        type="text"
                        value={values?.invoiceDetails?.totalAmount}
                        label="Total Amount"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "25%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="invoiceDetails.uploadInviocePDF"
                        type="file"
                        size="small"
                        sx={{ width: "30%" }}
                        // value={values.profile_pic}
                        label="Upload Invoice"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // onChange={(e) => handleFileChange(e, "profile_pic")}
                        onChange={(e) =>
                          setFieldValue(
                            "invoiceDetails.uploadInviocePDF",
                            e.target.files[0]
                          )
                        }
                        variant="outlined"
                      />
                      {values?.invoiceDetails?.uploadInviocePDF && (
                        <Button
                          sx={{ "&:hover": { bgcolor: "transparent" } }}
                          onClick={() => {
                            handleImagesTab(
                              values?.invoiceDetails?.uploadInviocePDF
                            );
                          }}
                        >
                          <PermMediaIcon sx={{ height: "100%" }} />
                        </Button>
                      )}
                    </Div>
                    <Typography variant="h1" sx={{ pt: "3%" }}>
                      Transport Details
                    </Typography>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "2% 0",
                      }}
                    >
                      <TextField
                        name="transportDetails.deliveryChallanNo"
                        type="text"
                        value={values?.transportDetails?.deliveryChallanNo}
                        label="Delivery Challan Number"
                        // error={errors?.employee_id && touched?.employee_id}
                        // helperText={
                        //   errors?.employee_id && touched?.employee_id
                        //     ? errors?.employee_id
                        //     : ""
                        // }
                        size="small"
                        sx={{ width: "30%" }}
                        onChange={handleChange}
                      />
                      <TextField
                        name="transportDetails.transportType.deliveryChallanNo_image"
                        type="file"
                        size="small"
                        sx={{ width: "30%" }}
                        // value={values.profile_pic}
                        label="Upload Delivery Challan"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // onChange={(e) => handleFileChange(e, "profile_pic")}
                        onChange={(e) =>
                          setFieldValue(
                            "transportDetails.transportType.deliveryChallanNo_image",
                            e.target.files[0]
                          )
                        }
                        variant="outlined"
                      />
                      {values?.transportDetails?.transportType
                        ?.deliveryChallanNo_image && (
                        <Button
                          sx={{ "&:hover": { bgcolor: "transparent" } }}
                          onClick={() => {
                            handleImagesTab(
                              values?.transportDetails?.transportType
                                ?.deliveryChallanNo_image
                            );
                          }}
                        >
                          <PermMediaIcon sx={{ height: "100%" }} />
                        </Button>
                      )}
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                Hand Delivery
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.handDelivery.personName"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.handDelivery?.personName
                                  }
                                  label="Person Name"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.handDelivery.personNumber"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.handDelivery?.personNumber
                                  }
                                  label="Person Mobile No."
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                courier Delivery
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.courier.companyName"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.courier?.companyName
                                  }
                                  label="Company Name"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.courier.docketNumber"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.courier?.docketNumber
                                  }
                                  label="Docket Number"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                Air/Rail Delivery
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.airRail.transporterName"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.airRail?.transporterName
                                  }
                                  label="Transporter Name"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.airRail.airRail"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.airRail?.airRail
                                  }
                                  label="Air/Rail"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.airRail.awbNumber"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.airRail?.awbNumber
                                  }
                                  label="AWB Number"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ textAlign: "center" }}>
                                Road Delivery
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.road.transporterName"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.road?.transporterName
                                  }
                                  label="Transporter Name"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.road.vehicleNumber"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.road?.vehicleNumber
                                  }
                                  label="Vehicle Number"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.road.driverName"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.road?.driverName
                                  }
                                  label="Driver Name"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <TextField
                                  name="transportDetails.transportType.road.driverNumber"
                                  type="text"
                                  value={
                                    values?.transportDetails?.transportType
                                      ?.road?.driverNumber
                                  }
                                  label="Driver Number"
                                  size="small"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={handleChange}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Div>
                    <Div
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        columnGap: 4,
                        gap: 5,
                        margin: "1% 0",
                      }}
                    >
                      <Button
                        sx={{ width: "100px" }}
                        variant="contained"
                        onClick={() => navigate("/dashboards/inventory")}
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Div>
    </>
  );
}

export default AddStockForm;
