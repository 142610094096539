import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoCompletewithDialog from "app/components/Autocomplete";
import { Label } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Heading from "app/pages/Component/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList } from "app/redux/actions/Roles";
import { getUsers } from "app/redux/actions/User";
import { getUpdatedFields } from "app/pages/Component/Function/updatedFields";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import * as yup from "yup";
import AddressTypeDialog from "app/pages/Masters/Suppliers/AutoAddAddress";
import DeleteIcon from "@mui/icons-material/Delete";
const AddSample = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { district, location, area, taluka } = useSelector(
    (state) => state?.addressDropdown
  );
  // useSelector
  const { allroles } = useSelector((state) => state.roleReducer);
  const { user } = useSelector((state) => state?.userReducer);
  //  useState
  const [isSubmitting, setSubmitting] = useState(false);
  const [ProductList, setProductList] = useState([]);
  console.log(ProductList, "4444444444444444444444");
  const [products, setProducts] = useState([]);
  const InitialValues = {
    items: [],
    contactDetails: {
      personName: "",
      email: "",
      mobileNo: "",
      companyName: "",
      gstNo: "",
    },
    address: {
      address: "",
      location: "",
      area: "",
      city: "",
      taluka: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
    },
    deliveryChallanNo: "",
    dateofsample: "",
  };
  const handleAddProduct = (product) => {
    setProducts([...products, product]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  const handleprodChange = (index, event) => {
    const { name, value } = event.target;
    const updatedProducts = [...products];
    updatedProducts[index][name] = value;
    setProducts(updatedProducts);
  };

  const handleSubmit = async (values) => {
    console.log(values);
    let response;
    try {
      const hasProduct = products.some(
        (product) => product.product_name.trim() !== ""
      );
      if (!hasProduct) {
        return Swal.fire({
          title: "At least one product must be added.",
          icon: "error",
          // timer: 1000,
          showConfirmButton: true,
        });
      }
      const data = { ...values, items: products };
      response = await Axios.post(`${AllApis.sample.add}`, data);
      console.log(response, "-----------45");
      if (response?.data?.statusCode) {
        Swal.fire({
          title: "Sample Added",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        navigate("/dashboards/sampleTracking");
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const validationSchema = yup.object().shape({
    contactDetails: yup.object().shape({
      personName: yup.string().required("Person Name is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      mobileNo: yup
        .string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .required("Mobile Number is required"),
      companyName: yup.string("Company Name is required"),
      gstNo: yup.string("GST Number is required"),
    }),
    address: yup.object().shape({
      address: yup.string().required("Address is required"),
      district: yup.string().required("District is required"),
      location: yup.string().required("Location is required"),
      area: yup.string().required("Area is required"),
      taluka: yup.string().required("Taluka is required"),
      state: yup.string().required("State is required"),
      city: yup.string().required("City is required"),
      country: yup.string().required("Country is required"),
      pincode: yup
        .string()
        .matches(/^[0-9]{6}$/, "Invalid pincode")
        .required("Pincode is required"),
    }),

    deliveryChallanNo: yup
      .string()
      .required("Delivery Challan Number is required"),
  });

  // useEffect
  useEffect(async () => {
    dispatch(getUsers());
    const productData = await Axios.get(`${AllApis.product.dropdown}`);

    setProductList(productData?.data?.data);
  }, []);
  return (
    <>
      <Div sx={{ mt: "-4px" }}>
        <Heading Name={"Sample Tracking"} />
        <Box sx={{ marginTop: "2%" }}>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              touched,
              errors,
            }) => {
              return (
                <>
                  <Form>
                    <Div>
                      <Typography variant="h1">Contact Details</Typography>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "2% 0",
                        }}
                      >
                        <TextField
                          required
                          name="contactDetails.personName"
                          type="text"
                          value={values?.contactDetails?.personName}
                          label="Person Name"
                          error={
                            errors?.contactDetails?.personName &&
                            touched?.contactDetails?.personName
                          }
                          helperText={
                            errors?.contactDetails?.personName &&
                            touched?.contactDetails?.personName
                              ? errors?.contactDetails?.personName
                              : ""
                          }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                        <TextField
                          required
                          name="contactDetails.email"
                          type="text"
                          value={values?.contactDetails?.email}
                          label="Email ID"
                          error={
                            errors?.contactDetails?.email &&
                            touched?.contactDetails?.email
                          }
                          helperText={
                            errors?.contactDetails?.email &&
                            touched?.contactDetails?.email
                              ? errors?.contactDetails?.email
                              : ""
                          }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                        <TextField
                          required
                          name="contactDetails.mobileNo"
                          type="text"
                          value={values?.contactDetails?.mobileNo}
                          label="Mobile Number"
                          error={
                            errors?.contactDetails?.mobileNo &&
                            touched?.contactDetails?.mobileNo
                          }
                          helperText={
                            errors?.contactDetails?.mobileNo &&
                            touched?.contactDetails?.mobileNo
                              ? errors?.contactDetails?.mobileNo
                              : ""
                          }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                        <TextField
                          name="contactDetails.companyName"
                          type="text"
                          value={values.contactDetails.companyName}
                          label="Company Name"
                          //   error={
                          //     errors?.contactDetails.companyName &&
                          //     touched?.contactDetails.companyName
                          //   }
                          //   helperText={
                          //     errors?.contactDetails.companyName &&
                          //     touched?.contactDetails.companyName
                          //       ? errors?.contactDetails.companyName
                          //       : ""
                          //   }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                        <TextField
                          name="contactDetails.gstNo"
                          type="text"
                          value={values.contactDetails.gstNo}
                          label="GST Number"
                          //   error={
                          //     errors?.contactDetails.gstNo &&
                          //     touched?.contactDetails.gstNo
                          //   }
                          //   helperText={
                          //     errors?.contactDetails.gstNo &&
                          //     touched?.contactDetails.gstNo
                          //       ? errors?.contactDetails.gstNo
                          //       : ""
                          //   }
                          size="small"
                          sx={{ width: "25%" }}
                          onChange={handleChange}
                        />
                      </Div>
                      <Typography mt="1%" variant="h1">
                        Address
                      </Typography>
                      <Div
                        sx={{
                          // marginLeft: "2%",
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 2,
                        }}
                      >
                        <TextField
                          required
                          name="address.address"
                          label="Address"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.address &&
                            touched?.address?.address
                          }
                          helperText={
                            errors?.address?.address &&
                            touched?.address?.address
                              ? errors?.address?.address
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.address}
                          onBlur={handleBlur}
                        />
                        <AddressTypeDialog
                          type={"district"}
                          name={"address.district"}
                          label={"District"}
                          list={district}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          value={values?.address?.district}
                          error={
                            errors?.address?.district &&
                            touched?.address?.district
                          }
                          helperText={
                            errors?.address?.district &&
                            touched?.address?.district
                              ? errors?.address?.district
                              : ""
                          }
                        />
                        <AddressTypeDialog
                          type={"location"}
                          name={"address.location"}
                          list={location}
                          sx={{ width: "30%", marginY: "10px" }}
                          label="Location"
                          setFieldValue={setFieldValue}
                          error={
                            errors?.address?.location &&
                            touched?.address?.location
                          }
                          helperText={
                            errors?.address?.location &&
                            touched?.address?.location
                              ? errors?.address?.location
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.location}
                        />
                        <AddressTypeDialog
                          type={"area"}
                          name={"address.area"}
                          label={"Area"}
                          list={area}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          value={values?.address?.area}
                          error={
                            errors?.address?.area && touched?.address?.area
                          }
                          helperText={
                            errors?.address?.area && touched?.address?.area
                              ? errors?.address?.area
                              : ""
                          }
                        />
                        <AddressTypeDialog
                          type={"taluka"}
                          name={"address.taluka"}
                          label={"Taluka"}
                          list={taluka}
                          sx={{ width: "30%", marginY: "10px" }}
                          setFieldValue={setFieldValue}
                          value={values?.address?.taluka}
                          error={
                            errors?.address?.taluka && touched?.address?.taluka
                          }
                          helperText={
                            errors?.address?.taluka && touched?.address?.taluka
                              ? errors?.address?.taluka
                              : ""
                          }
                        />
                        <TextField
                          required
                          name="address.state"
                          label="State"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.state && touched?.address?.state
                          }
                          helperText={
                            errors?.address?.state && touched?.address?.state
                              ? errors?.address?.state
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.state}
                          onBlur={handleBlur}
                        />
                        <TextField
                          required
                          name="address.city"
                          label="City"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.city && touched?.address?.city
                          }
                          helperText={
                            errors?.address?.city && touched?.address?.city
                              ? errors?.address?.city
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.city}
                          onBlur={handleBlur}
                        />
                        <TextField
                          required
                          name="address.country"
                          label="Country"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.country &&
                            touched?.address?.country
                          }
                          helperText={
                            errors?.address?.country &&
                            touched?.address?.country
                              ? errors?.address?.country
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.country}
                          //   onBlur={handleBlur}
                        />
                        <TextField
                          required
                          name="address.pincode"
                          label="Pincode"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                          }
                          helperText={
                            errors?.address?.pincode &&
                            touched?.address?.pincode
                              ? errors?.address?.pincode
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.address?.pincode}
                          onBlur={handleBlur}
                        />
                      </Div>
                      <Typography variant="h1">Items Details</Typography>
                      <Div
                        sx={{
                          //   marginLeft: "2%",
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 2,
                        }}
                      >
                        {products.map((product, index) => (
                          <Div
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              margin: "1%",
                            }}
                          >
                            <Autocomplete
                              sx={{ width: "18%" }}
                              id={`product-autocomplete-${index}`}
                              options={ProductList}
                              getOptionLabel={(option) =>
                                option?.current_data.product_name
                              }
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  setProducts((prevProducts) => {
                                    const updatedProducts = [...prevProducts];
                                    updatedProducts[index] = {
                                      product_name:
                                        newValue?.current_data?.product_name,
                                      sku: newValue?.current_data?.sku,
                                      category:
                                        newValue?.current_data?.category
                                          ?.category_name,
                                      hsn_code:
                                        newValue?.current_data?.hsn_code
                                          .hsn_code,
                                      gstpercentage:
                                        newValue?.current_data?.hsn_code
                                          ?.gst_percentage,
                                      quantity: product?.quantity || "",
                                      product_Id: newValue?._id,
                                      isActive:
                                        newValue?.current_data?.isActive,
                                      short_description:
                                        newValue?.current_data
                                          ?.short_description,
                                      long_description:
                                        newValue?.current_data
                                          ?.long_description,
                                      show_in_website:
                                        newValue?.current_data?.show_in_website,
                                      show_in_retailer:
                                        newValue?.current_data
                                          ?.show_in_retailer,
                                      show_in_offline_store:
                                        newValue?.current_data
                                          ?.show_in_offline_store,
                                      prices: newValue?.current_data?.prices,
                                      mrp: newValue?.current_data?.mrp,
                                      item_weight:
                                        newValue?.current_data?.item_weight,
                                      unit: newValue?.current_data?.unit,
                                    };
                                    return updatedProducts;
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`Product ${index + 1}`}
                                />
                              )}
                            />
                            <TextField
                              name="sku"
                              label="SKU"
                              value={product.sku}
                              onChange={(event) =>
                                handleprodChange(index, event)
                              }
                            />
                            <TextField
                              name="category"
                              label="Category"
                              value={product.category}
                              onChange={(event) =>
                                handleprodChange(index, event)
                              }
                            />
                            <TextField
                              name="hsn_code"
                              label="HSN Code"
                              value={product.hsn_code}
                              onChange={(event) =>
                                handleprodChange(index, event)
                              }
                            />
                            <TextField
                              name="quantity"
                              label="Quantity"
                              value={product.quantity}
                              onChange={(event) =>
                                handleprodChange(index, event)
                              }
                            />
                            <IconButton
                              onClick={() => handleRemoveProduct(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Div>
                        ))}
                        <Div
                          sx={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            columnGap: 4,
                            gap: 5,
                            margin: "1% 0",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleAddProduct}
                          >
                            Add Product
                          </Button>
                        </Div>
                      </Div>
                      <Typography variant="h1">Delivery Details</Typography>
                      <Div
                        sx={{
                          // marginLeft: "2%",
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 2,
                        }}
                      >
                        <TextField
                          required
                          name="deliveryChallanNo"
                          label="delivery Challan Number"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          error={
                            errors?.deliveryChallanNo &&
                            touched?.deliveryChallanNo
                          }
                          helperText={
                            errors?.deliveryChallanNo &&
                            touched?.deliveryChallanNo
                              ? errors?.deliveryChallanNo
                              : ""
                          }
                          onChange={handleChange}
                          value={values?.deliveryChallanNo}
                          onBlur={handleBlur}
                        />
                        <TextField
                          type="date"
                          name="dateofsample"
                          label="Date"
                          size="small"
                          sx={{ width: "30%", marginY: "10px" }}
                          onChange={handleChange}
                          value={values?.dateofsample}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={handleBlur}
                        />
                      </Div>
                      <Div
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          columnGap: 4,
                          gap: 5,
                          margin: "1% 0",
                        }}
                      >
                        <Button
                          sx={{ width: "100px" }}
                          variant="contained"
                          onClick={() => navigate("/dashboards/sampleTracking")}
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Div>
                    </Div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Div>
    </>
  );
};

export default AddSample;
