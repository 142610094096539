import { SAMPLE_LIST_SUCCESS } from "app/utils/constants/sampleConstant";

const INIT_STATE = {
  loading: false,
  error: null,
  samples: [],
  TotalPage: null,
};
export const sampleReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAMPLE_LIST_SUCCESS: return {
      ...state,
      loading: false,
      samples: action.payload.data,
      TotalPage: action.payload.totalPages,
    };
    default:
      return state;
  }
};