import { MET_COMPANY_ORDER_COMMISSION_FAILED, MET_COMPANY_ORDER_COMMISSION_REQUEST, MET_COMPANY_ORDER_COMMISSION_SUCCESS } from "app/utils/constants/METCompanyOrder"

const TRANSACTION_INIT = {
    loading:false,
    companyOrderHistory:[],
    totals:[],
    totalPage:0,
    error:false,
    errorMessage:null,
}

const metCompnayOrderCommissionReducer = (state = TRANSACTION_INIT, action) => {
    switch (action.type) {
        case MET_COMPANY_ORDER_COMMISSION_REQUEST:
            return { ...state, loading: true }
        case MET_COMPANY_ORDER_COMMISSION_SUCCESS:
            return {
                ...state,
                companyOrderHistory: action.payload.companyOrderHistory,
                totalPage: action.payload.totalPage,
                totals:action.payload.totals,
                loading: false
            }
        case MET_COMPANY_ORDER_COMMISSION_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                companyOrderHistory: [],
                totalPage: 0,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default metCompnayOrderCommissionReducer;