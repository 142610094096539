import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { FastField, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import AddressTypeDialog from '../../../Suppliers/AutoAddAddress'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Div from '@jumbo/shared/Div'
import { LoadingButton } from '@mui/lab'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Heading from 'app/pages/Component/Heading'
import { Axios } from 'index'
import AllApis from 'app/Apis/apis'
import Swal from 'sweetalert2'
import * as yup from "yup"
import { State, City, Country } from "country-state-city";

const AddNomineeForm = ({ arrayHelper }) => {
    const { addressDropdown } = useSelector((state) => state);
    const [stateCityList, setStateCityList] = useState({
        stateList: State.getStatesOfCountry("IN"),
        cityList: City.getCitiesOfState("IN", "MH"),
        countryList: Country.getCountryByCode("IN"),
    });
    const navigate = useNavigate();
    const { state } = useLocation();
    const { metId, nomineeId } = useParams()


    const nominee = {
        nominee_name: "",
        nominee_dob: new Date(Date.now()).toISOString().split('T')[0],
        nominee_age: "",
        address: {
            address: "",
            location: "",
            area: "",
            district: "",
            taluka: "",
            state: "",
            city: "",
            pincode: "",
        },
        kyc: {
            pan: {
                pan_no: "",
                pan_image: "",
            },
            // gst: {
            //     gst_no: "",
            //     gst_image: "",
            // },
            aadhar: {
                aadhar_no: "",
                aadhar_image: "",
            },
        },
    }
    const { kyc, address, ...nomineeDetails } = nominee;

    const handleImagesTab = async (value) => {
        if (value && typeof value === "string") {
            const imageURL = new URL(`${process.env.REACT_APP_IMAGE_URL}/${value}`);
            window.open(imageURL.href, '_blank');
        } else {
            const images = URL.createObjectURL(value);
            window.open(images, "__blank")
        }
    }

    const handleAddEditNominee = async function (values, action) {
        const formData = new FormData();
        formData.append("nomineeData", JSON.stringify({
            ...values,
            kyc: {
                pan: {
                    pan_no: values?.kyc?.pan?.pan_no,
                },
                // gst: {
                //     gst_no: values?.kyc?.gst?.gst_no,
                // },
                aadhar: {
                    aadhar_no: values?.kyc?.aadhar?.aadhar_no,
                }
            },
        }))
        formData.append("pan_image", values?.kyc?.pan?.pan_image)
        // formData.append("gst_image", values?.kyc?.gst?.gst_image)
        formData.append("aadhar_image", values?.kyc?.aadhar?.aadhar_image)
        try {
            const { data } = !nomineeId ? await Axios.post(AllApis?.marketExective?.nominee?.add(metId), formData) : await Axios.patch(AllApis?.marketExective?.nominee?.update(metId, nomineeId), formData);
            if (data?.statusCode === 201) {
                Swal.fire({
                    icon: "success",
                    title: data?.status,
                    text: data?.message,
                    timer: 1200,
                    showConfirmButton: false,
                })
                action.resetForm();
            }
            navigate(`/dashboards/master/marketExecutiveTeams/nominee/${metId}`)
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: error?.response?.data?.status,
                text: error?.response?.data?.message,
                timer: 1200,
                showConfirmButton: false,
            })
        }
    }

    return (
        <div>
            {
                nomineeId && !state?.nomineeData ? <Navigate to={'/dashboards/master/marketExecutiveTeams'} /> :
                    <Formik
                        initialValues={!nomineeId && !state?.nomineeData ? { ...nominee } : { ...state?.nomineeData, nominee_dob: new Date(state?.nomineeData?.nominee_dob).toISOString().split('T')[0] }}
                        validationSchema={yup.object().shape({
                            nominee_name: yup.string().required(),
                            nominee_dob: yup.date().max(new Date(), 'Date of birth must be in the past').required(),
                            nominee_age: yup.number()
                                .typeError("Age must be a number")
                                .positive("Age must be a positive number")
                                .integer("Age must be an integer")
                                .required("Age is required"),
                            address: yup.object().shape({
                                address: yup.string().required("Address is required"),
                                location: yup.string().required("Address is required"),
                                area: yup.string().required("Address is required"),
                                district: yup.string().required("District is required"),
                                taluka: yup.string().required("Taluka is required"),
                                state: yup.string().required("State is required"),
                                city: yup.string().required("City is required"),
                                pincode: yup.string().matches(/^[0-9]{6}$/, "Invalid pincode").required("Pincode is required"),
                            }),
                            kyc: yup.object().shape({
                                pan: yup.object().shape({
                                    pan_no: yup.string()
                                        .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN number')
                                        .required('PAN number is required'),
                                    pan_image: yup.mixed(),
                                }),
                                // gst: yup.object().when('company_details.companyName', {
                                //     is: (companyName) => companyName, // This condition should check if companyName exists
                                //     then: yup.object().shape({
                                //         gst_no: yup.string()
                                //             .matches(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([1-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}?$/, 'Invalid GST number')
                                //             .required('GST number is required'),
                                //         gst_image: yup.mixed(),
                                //     }),
                                //     otherwise: yup.object().shape({
                                //         gst_no: yup.string(),
                                //         gst_image: yup.mixed(),
                                //     })
                                // }),
                                aadhar: yup.object().shape({
                                    aadhar_no: yup.string()
                                        .matches(/^\d{4}\s\d{4}\s\d{4}$/, 'Invalid Aadhar number')
                                        .required('Aadhar number is required'),
                                    aadhar_image: yup.mixed(),
                                })
                            })
                        })}
                        onSubmit={handleAddEditNominee}
                    >
                        {({ values, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched }) => {
                            return (
                                <Form>
                                    <Box sx={{ mt: -4 }}>
                                        <Typography as="div">
                                            <Heading Name={`${!nomineeId ? "Add" : "Edit"} Nominee`} />
                                        </Typography>
                                        <Box id="NomineeDetails" sx={{ mt: 4 }}>
                                            <Grid2 container spacing={3}>
                                                {
                                                    Object.keys(nomineeDetails).map((nomineeKey, index) => {
                                                        return (
                                                            <Grid2 xs={4} key={index}>
                                                                <Field name={nomineeKey}>
                                                                    {({ field, form, meta }) => {
                                                                        return (
                                                                            <TextField
                                                                                required
                                                                                {...field}
                                                                                type={nomineeKey.toLowerCase().endsWith("dob") ? "date" : "text"}
                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                size='small'
                                                                                label={nomineeKey.split("_").join(" ")}
                                                                                error={meta?.error && meta?.touched}
                                                                                helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                                            />
                                                                        )
                                                                    }}
                                                                </Field>
                                                            </Grid2>
                                                        )
                                                    })
                                                }
                                            </Grid2>
                                        </Box>
                                        <Box id="kycDetails">
                                            <Typography variant='h3' my={3}>KYC Detail</Typography>
                                            {
                                                Object.entries({ ...kyc }).map(([kycField, kycValues], i) => {
                                                    return (
                                                        <Box key={i}>
                                                            <Typography variant='h5' sx={{ textTransform: "capitalize" }} my={2}>{kycField}</Typography>
                                                            <Grid2 container spacing={3}>
                                                                {
                                                                    Object.keys({ ...kycValues }).map((kyc, index) => {
                                                                        return (
                                                                            <Grid2 xs={6} key={index}>
                                                                                {kyc?.endsWith("image") ?
                                                                                    <Div sx={{ display: "flex" }}>
                                                                                        <FastField name={`kyc.${kycField}.${kyc}`}>
                                                                                            {({ field }) => {
                                                                                                const { value, ...rest } = field;
                                                                                                return <TextField
                                                                                                    {...rest}
                                                                                                    type='file'
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        const { name, files } = e.target
                                                                                                        setFieldValue(name, files[0])
                                                                                                    }}
                                                                                                    sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                                    size='small'
                                                                                                    label={kyc.split("_").join(" ")}
                                                                                                />
                                                                                            }}
                                                                                        </FastField>
                                                                                        {values?.kyc?.[kycField]?.[kyc] && <Button sx={{ "&:hover": { bgcolor: "transparent" } }} onClick={() => handleImagesTab(values?.kyc?.[kycField]?.[kyc])}>
                                                                                            <PermMediaIcon sx={{ height: "100%" }} />
                                                                                        </Button>}
                                                                                    </Div> :
                                                                                    <FastField name={`kyc.${kycField}.${kyc}`}>
                                                                                        {({ field, meta }) => {
                                                                                            return <TextField
                                                                                                required
                                                                                                {...field}
                                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                                size='small'
                                                                                                label={kyc.split("_").join(" ")}
                                                                                                error={meta?.error && meta?.touched}
                                                                                                helperText={meta?.error && meta?.touched ? meta?.error : ""}
                                                                                            />
                                                                                        }}
                                                                                    </FastField>}
                                                                            </Grid2>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid2>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                        <Box id="Address">
                                            <Typography variant='h3' my={3}>Address</Typography>
                                            <Grid2 container spacing={3}>
                                                <Grid2 xs={4}>
                                                    <FastField name={`address.address`}>
                                                        {({ field, meta }) => {
                                                            return <TextField
                                                                {...field}
                                                                required
                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                size='small'
                                                                label={"address"}
                                                                error={meta?.error && meta?.touched}
                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                            />
                                                        }}
                                                    </FastField>
                                                </Grid2>
                                                {
                                                    ["location", "district", "area", "taluka"].map((e, i) => {
                                                        return (
                                                            <Grid2 xs={4} key={i}>
                                                                <FastField name={`address.${e}`} key={JSON.stringify(addressDropdown?.[e])}>
                                                                    {({ field, meta }) => {
                                                                        return (
                                                                            <AddressTypeDialog
                                                                                type={e}
                                                                                {...field}
                                                                                setFieldValue={setFieldValue}
                                                                                name={`address.${e}`}
                                                                                list={addressDropdown?.[e] || []}
                                                                                label={e}
                                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                                error={meta?.error && meta?.touched}
                                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                            />
                                                                        )
                                                                    }}
                                                                </FastField>
                                                            </Grid2>
                                                        )
                                                    })
                                                }
                                                <Grid2 xs={4}>
                                                    <FastField name={`address.state`} key={JSON.stringify(stateCityList?.state)}>
                                                        {({ field, meta }) => {
                                                            return <Autocomplete
                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                size="small"
                                                                id="company-autocomplete"
                                                                options={stateCityList?.stateList || []}
                                                                {...field}
                                                                name="address.state"
                                                                value={field.value || ""}
                                                                freeSolo
                                                                getOptionLabel={(option = "") => {
                                                                    if (typeof option === "object") {
                                                                        return option.name
                                                                    }
                                                                    return option
                                                                }}
                                                                isOptionEqualToValue={
                                                                    (option, value) => option?.name === value
                                                                }
                                                                onBlur={field?.onBlur}
                                                                onChange={(e, newValue) => {
                                                                    setFieldValue("address.state", newValue?.name)
                                                                    if (newValue != null) {
                                                                        const cityNames = City.getCitiesOfState(
                                                                            "IN",
                                                                            newValue.isoCode
                                                                        );
                                                                        setStateCityList({ ...stateCityList, cityList: cityNames });
                                                                    } else {
                                                                        setStateCityList({
                                                                            ...stateCityList,
                                                                            cityList: City.getCitiesOfState("IN", "MH"),
                                                                        });
                                                                    }
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <Box
                                                                        component="li"
                                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                        {...props}
                                                                        key={option?.name}
                                                                    >
                                                                        {option?.name}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} variant="outlined" label="State"
                                                                        required
                                                                        name="address.state"
                                                                        error={meta?.error && meta?.touched}
                                                                        helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                    />
                                                                )}
                                                            />
                                                        }}
                                                    </FastField>
                                                </Grid2>
                                                <Grid2 xs={4}>
                                                    <FastField name={`address.city`} key={JSON.stringify(stateCityList?.cityList)}>
                                                        {({ field, meta }) => {
                                                            return <Autocomplete
                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                size="small"
                                                                id="company-autocomplete"
                                                                options={stateCityList?.cityList || []}
                                                                {...field}
                                                                name={`address.city`}
                                                                value={field.value || ""}
                                                                freeSolo
                                                                getOptionLabel={(option = "") => {
                                                                    if (typeof option === "object") {
                                                                        return option.name
                                                                    }
                                                                    return option
                                                                }}
                                                                isOptionEqualToValue={
                                                                    (option, value) => option?.name === value
                                                                }
                                                                onBlur={field?.onBlur}
                                                                onChange={(e, newValue) => {
                                                                    setFieldValue("address.city", newValue?.name)
                                                                }}
                                                                renderOption={(props, option) => (
                                                                    <Box
                                                                        component="li"
                                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                                        {...props}
                                                                        key={option?.name}
                                                                    >
                                                                        {option?.name}
                                                                    </Box>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} variant="outlined" label="City"
                                                                        name={`address.city`}
                                                                        required
                                                                        error={meta?.error && meta?.touched}
                                                                        helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                    />
                                                                )}
                                                            />
                                                        }}
                                                    </FastField>
                                                </Grid2>
                                                <Grid2 xs={4}>
                                                    <FastField name={`address.pincode`}>
                                                        {({ field, meta }) => {
                                                            return <TextField
                                                                required
                                                                {...field}
                                                                sx={{ width: "100%", textTransform: "capitalize" }}
                                                                size='small'
                                                                label={"Pincode"}
                                                                error={meta?.error && meta?.touched}
                                                                helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                            />
                                                        }}
                                                    </FastField>
                                                </Grid2>
                                                {/* {
                                                    ["state", "city", "country", "pincode"].map((e, i) => {
                                                        return (
                                                            <Grid2 xs={4} key={i}>
                                                                <FastField name={`address.${e}`}>
                                                                    {({ field, meta }) => {
                                                                        return <TextField
                                                                            {...field}
                                                                            required
                                                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                                                            size='small'
                                                                            label={`${e}`}
                                                                            error={meta?.error && meta?.touched}
                                                                            helperText={(meta?.error && meta?.touched) ? meta?.error : ""}
                                                                        />
                                                                    }}
                                                                </FastField>
                                                            </Grid2>
                                                        )
                                                    })
                                                } */}
                                            </Grid2>
                                        </Box>
                                        <Div
                                            sx={{
                                                width: "92%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: 3,
                                                mt: 4,
                                            }}
                                        >
                                            <Button
                                                sx={{ width: "100px" }}
                                                variant="outlined"
                                                onClick={() => navigate(`/dashboards/master/marketExecutiveTeams/nominee/${metId}`)}
                                            >
                                                Back
                                            </Button>

                                            <LoadingButton
                                                variant="contained"
                                                type="submit"
                                                sx={{ width: "100px" }}
                                                loading={isSubmitting}
                                            >
                                                {!nomineeId ? "Add" : "Update"}
                                            </LoadingButton>
                                        </Div>
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
            }
        </div>
    )
}

export default React.memo(AddNomineeForm)