import { Box, Typography } from '@mui/material'
import Heading from 'app/components/Heading'
import React, { useEffect, useState } from 'react'
import AddEditMappingMET from './AddEditMappingMET'
import { Navigate, useLocation } from 'react-router-dom'
import ListingMETCommission from './ListingMappedMET'
import { useDispatch, useSelector } from 'react-redux'
import { getMarketExecutiveCommissionList } from 'app/redux/actions/MarketExecutiveCommissionMapping'

const MapMarketExecutive = ({ companyType }) => {
    const { state } = useLocation();

    return (
        <>
            {
                !state?.companyData ? <Navigate to={`/dashboards/master/${companyType}/list/Company`} /> : <Box sx={{ mt: -4 }}>
                    <Typography as="div" sx={{textTransform:"capitalize"}}>
                        <Heading Name={state?.companyData?.companyName} />
                    </Typography>
                    <AddEditMappingMET companyData={state?.companyData}/>
                    <Typography variant='h3'>
                        Market Executives Commissions
                    </Typography>
                    <ListingMETCommission />
                </Box>
            }
        </>
    )
}

export default MapMarketExecutive