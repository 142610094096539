import Div from "@jumbo/shared/Div/Div";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Heading from "app/pages/Component/Heading";
import { Axios } from "index";
import AllApis from "app/Apis/apis";
import Swal from "sweetalert2";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import { State, City } from "country-state-city";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import DispatchTable from "../DispatchTables/DispatchTable";
import { getAllDispatchOrders } from "app/redux/actions/OrderProcessing/Dispatch";
import OutForDeliveryTable from "../DispatchTables/OutForDeliveryTable";
import DeliveredTable from "../DispatchTables/DeliveredTable";
import Loader from "app/components/Loader";

export default function ListDispatchOrders() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [orderType, setOrderType] = React.useState("offlinestores");
  const [deliveryStatus, setDeliveryStatus] = useState("dispatched");
  const { area, district, location, taluka } = useSelector(
    (state) => state?.addressDropdown
  );
  const { loading } = useSelector((state) => state?.dispatchReducer);

  const [emptyVal, setEmptyVal] = useState();
  const [dropdownList, setDropdownList] = useState({
    stateList: State.getStatesOfCountry("IN"),
    cityList: City.getCitiesOfState("IN", "MH"),
    salesNoList: [],
  });
  const [filters, setFilters] = useState({
    from: "",
    to: "",
  });




  const TabChange = () => {
    setFilters({
      from: "",
      to: "",
    });
    setEmptyVal(!emptyVal);
    setPage(1);
    setSort("desc");
    setSortBy("created_at");
    setSearchTerm("");
  };

  const datepickerStyling = {
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aeb7bd ", // or any other color you prefer
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#475259", // Change to the color you want on hover
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7352C7", // Change to the color you want when focused
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#7352C7", // Keep the same color as focused when hovered
      },
    "& .MuiInputLabel-outlined": {
      color: "#98a3aa", // Default label color
      "&.Mui-focused": {
        color: "#7352C7", // Label color on focus
      },
    },
  };

  const filterFun = () => {
    const filter = {};
    for (let i in filters) {
      if (filters[i] != "" && i != "from" && i != "to") {
        filter[`current_data.${i}`] = filters[i];
      }
      if ((i == "from" && i["from"] != "") || (i == "to" && i["to"] != "")) {
        filter[i] = filters[i];
      }
    }
    return filter;
  };

  const handleFilter = () => {
    setPage(1);
    dispatch(
      getAllDispatchOrders(
        searchTerm,
        sort,
        sortBy,
        "",
        orderType,
        deliveryStatus,
        filterFun()
      )
    );
  };
  const handleClear = () => {
    TabChange();
    dispatch(
      getAllDispatchOrders(
        searchTerm,
        sort,
        sortBy,
        page,
        orderType,
        deliveryStatus,
        {}
      )
    );
  };


  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(
      getAllDispatchOrders(
        value,
        sort,
        sortBy,
        page,
        orderType,
        deliveryStatus,
        filterFun()
      )
    );
  };

  const debouncedHandleSearch = debounce(handleSearch, 1000);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Div sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Div>
        )}
      </Div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getAllDispatchOrders(
        searchTerm,
        sort,
        sortBy,
        page,
        orderType,
        deliveryStatus,
        filterFun()
      )
    );
  }, [sort, page, orderType, deliveryStatus]);

  useEffect(() => {
    (async () => {
      try {
        let response = await Axios.get(
          AllApis.dispatch.salesNoFromDispatch(orderType, deliveryStatus)
        );
        setDropdownList((prev) => ({
          ...prev,
          salesNoList: response.data.data,
        }));
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, [orderType, deliveryStatus]);


  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Heading Name={"Dispatch Orders"} />
        {loading && <Loader />}
        <Div className="Filters" sx={{ width: "100%", mt: 4 }}>
          <Typography variant="h1" sx={{ mb: 3 }}>
            Filters
          </Typography>
          <Box
            sx={{ display: "flex", rowGap: 4, columnGap: 4, flexWrap: "wrap" }}
          >
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={dropdownList?.stateList || []}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.state":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                  const cityNames = City.getCitiesOfState(
                    "IN",
                    newValue.isoCode
                  );
                  setDropdownList({ ...dropdownList, cityList: cityNames });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="State" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={dropdownList?.cityList || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.city":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="City" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={taluka || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.taluka":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Taluka" />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <Autocomplete
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={district || []}
                getOptionLabel={(option) => option?.name || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    "customer_details.bill_to.address.district":
                      newValue != null ? newValue?.name?.toLowerCase() : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="District" />
                )}
              />
            </Div>

            <Div sx={{ width: "23%" }}>
              <Autocomplete
                key={emptyVal ? "refresh1" : "refresh2"}
                sx={{ width: "100%", textTransform: "capitalize" }}
                size="small"
                id="company-autocomplete"
                options={dropdownList?.salesNoList || []}
                getOptionLabel={(option) => option?._id || ""}
                // isOptionEqualToValue={
                //   (option, value) => console.log(option?.name, value)
                //   option?.name === value?.name
                // }
                onChange={(e, newValue) => {
                  setFilters({
                    ...filters,
                    sales_order_no: newValue != null ? newValue?._id : "",
                  });
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?._id}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sales Order No"
                  />
                )}
              />
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="from"
                  format="DD-MM-YYYY"
                  name="from"
                  sx={datepickerStyling}
                  label={
                    <>
                      {deliveryStatus === "dispatched" &&
                        "From Dispatch Generated Date"}
                      {deliveryStatus === "outForDelivery" &&
                        "From Dispatched Date"}
                      {deliveryStatus === "delivered" && "From Delivered Date"}
                    </>
                  }
                  defaultValue={
                    filters.from !== "" ? dayjs(filters.from) : null
                  }
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      from: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {filters?.to && !filters?.from && (
                <>
                  {deliveryStatus === "dispatched" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      From Dispatch Generated Date
                    </Typography>
                  )}
                  {deliveryStatus === "outForDelivery" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      From Dispatched Date
                    </Typography>
                  )}
                  {deliveryStatus === "delivered" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      From Delivered Date
                    </Typography>
                  )}
                </>
              )}
            </Div>
            <Div sx={{ width: "23%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={emptyVal ? "refresh1" : "refresh2"}
                  id="to"
                  format="DD-MM-YYYY"
                  name="to"
                  label={
                    <>
                      {deliveryStatus === "dispatched" &&
                        "To Dispatch Generated Date"}
                      {deliveryStatus === "outForDelivery" &&
                        "To Dispatched Date"}
                      {deliveryStatus === "delivered" && "To Delivered Date"}
                    </>
                  }
                  sx={datepickerStyling}
                  defaultValue={filters.to !== "" ? dayjs(filters.to) : null}
                  onChange={(newValue) => {
                    setFilters({
                      ...filters,
                      to: newValue.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    });
                  }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
              {!filters?.to && filters?.from && (
                <>
                  {deliveryStatus === "dispatched" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      To Dispatch Generated Date
                    </Typography>
                  )}
                  {deliveryStatus === "outForDelivery" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      To Dispatched Date
                    </Typography>
                  )}
                  {deliveryStatus === "delivered" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#E73145",
                        fontSize: "0.75rem",
                        ml: 1,
                        mt: 0.5,
                      }}
                    >
                      To Delivered Date
                    </Typography>
                  )}
                </>
              )}
            </Div>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              sx={{ marginRight: 1 }}
              onClick={handleFilter}
            >
              Apply
            </Button>
            {/* <Button variant="outlined" onClick={handleClears}> */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Box>
        </Div>
        {/* <Div
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                    }}
                >
                    <TextField
                        id="search"
                        type="search"
                        label="Search"
                        value={searchTerm}
                        size="small"
                        // onChange={(e) => {
                        //     // setSearchTerm(e.target.value);
                        //     if (e.target.value == "") {
                        //         setsortOrder("desc");
                        //         setsortField("created_at");
                        //         dispatch(getUsers("", "desc", "created_at", 1));
                        //     }
                        // }}
                        sx={{ width: 300, mb: 2, mt: 4 }}
                        InputProps={{
                            endAdornment: (
                                <Div sx={{ cursor: "pointer" }}>
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </Div>
                            ),
                        }}
                    />
                    {Permission?.["user"]?.["add"] && (
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 2,
                                p: 1,
                                pl: 4,
                                pr: 4,
                            }}
                            onClick={() => navigate("/dashboards/users/add")}
                        >
                            Add Users
                        </Button>
                    )}
                </Div> */}
      </Div>

      <Div sx={{ mt: 0 }}>
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ width: 300 }}>
            <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderType}
              label="Order Type"
              onChange={(e) => {
                setOrderType(e.target.value);
                TabChange();
                setDeliveryStatus("dispatched");
                setValue(0);
              }}
            >
              <MenuItem value={"offlinestores"}>Offline Store</MenuItem>
              <MenuItem value={"retailers"}>Retailers</MenuItem>
              <MenuItem value={"websites"}>Websites</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setPage(1);
                setSort("desc");
                setSortBy("created_at");
                dispatch(
                  getAllDispatchOrders(
                    "",
                    "desc",
                    "created_at",
                    1,
                    orderType,
                    deliveryStatus,
                    filterFun()
                  )
                );
              }
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
        </Div>
        <Div>
          <JumboDemoCard
            title={"Supplier Type"}
            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
          >
            <Div sx={{ width: "100%" }}>
              <Div sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={(event, newValue) => setValue(newValue)}
                >
                  <Tab
                    label="Dispatched"
                    {...a11yProps(0)}
                    onClick={() => {
                      TabChange();
                      setDeliveryStatus("dispatched");
                    }}
                  />
                  <Tab
                    label="Out For Delivery"
                    {...a11yProps(1)}
                    onClick={() => {
                      TabChange();
                      setDeliveryStatus("outForDelivery");
                    }}
                  />
                  <Tab
                    label="Delivered"
                    {...a11yProps(2)}
                    onClick={() => {
                      TabChange();
                      setDeliveryStatus("delivered");
                    }}
                  />
                </Tabs>
              </Div>

              <TabPanel value={value} index={0}>
                <DispatchTable
                  filter={filterFun}
                  page={page}
                  setPage={setPage}
                  orderType={orderType}
                  deliveryStatus={deliveryStatus}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <OutForDeliveryTable
                  filter={filterFun}
                  page={page}
                  setPage={setPage}
                  orderType={orderType}
                  deliveryStatus={deliveryStatus}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <DeliveredTable
                  filter={filterFun}
                  page={page}
                  setPage={setPage}
                  orderType={orderType}
                  deliveryStatus={deliveryStatus}
                  search={searchTerm}
                  sort={sort}
                  setSort={setSort}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </TabPanel>
            </Div>
          </JumboDemoCard>
        </Div>
      </Div>
    </>
  );
}
