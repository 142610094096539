import React, { useEffect, useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import {
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Avatar,
  Badge,
} from "@mui/material";

import { getAllActiveUsers } from "app/redux/actions/User";
import { useDispatch, useSelector } from "react-redux";
import Heading from "app/pages/Component/Heading";
import Loader from "app/components/Loader";
import { socket } from "index";

const ActiveUsersList = () => {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("loggedIn_at");
  const dispatch = useDispatch();
  const { TotalPage, activeUser, loading } = useSelector(
    (state) => state?.userReducer
  );

  //debouncing for search
  const handleSearch = (value) => {
    setPage(1);
    dispatch(getAllActiveUsers(value, sort, sortBy, 1));
  };

  const debouncedHandleSearch = debounce(handleSearch, 500);

  socket.emit("activeUserList",{})
  socket.on("getActiveUserList",(data)=>{
    console.log(data)
  })

  useEffect(() => {
    if (searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [searchTerm]);

  const handleSort = (property) => {
    setSort(sort == "asc" ? "desc" : "asc");
    setSortBy(property);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getAllActiveUsers("", sort, sortBy, 1));
  }, [sort, page]);

  return (
    <>
      <Div sx={{ mt: -4 }}>
        <Heading Name={"Active Users"} />
        {loading && <Loader />}
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value == "") {
                setSort("desc");
                setSortBy("created_at");
                dispatch(getAllActiveUsers("", "desc", "loggedIn_at", 1));
              }
            }}
            sx={{ width: 300, mb: 5, mt: 4 }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Div>
            {/* <Button
          variant="contained"
          sx={{
            mr: 2,
            p: 1,
            pl: 4,
            pr: 4,
          }}
          onClick={() => setLogModalOpen(true)}
        >
          Logs
        </Button> */}
          </Div>
        </Div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#7352C7", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Profile Image
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    onClick={() => handleSort("user_id.current_data.first_name")}
                    direction={sort}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    User Name
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "150px",
                    verticalAlign: "middle",
                  }}
                >
                  <TableSortLabel
                    onClick={() =>
                      handleSort("user_id.current_data.role_id.current_data.role_name")
                    }
                    direction={sort}
                    sx={{
                      color: "white",
                      "&:hover": { color: "white" },
                      "&.MuiTableSortLabel-root.Mui-active": {
                        color: "white", // Set the color for the active state
                      },
                    }}
                  >
                    Role
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeUser
                ?.map((ele) => ele.user_id.current_data)
                .map((user, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      <Div sx={{ mb: 3 }}>
                        <Badge
                          overlap="circular"
                          variant="dot"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          sx={{
                            ".MuiBadge-badge": {
                              border: "2px solid #FFF",
                              height: "14px",
                              width: "14px",
                              borderRadius: "50%",
                              bgcolor:"success.main",
                            },
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 72,
                              height: 72,
                              textTransform: "capitalize",
                            }}
                            alt={`${user.first_name} ${user.last_lame}`}
                            src={`${process.env.REACT_APP_IMAGE_URL}/${user.profile_pic}`}
                          />
                        </Badge>
                      </Div>
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {`${user.first_name} ${user.last_name}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {user.role_id.current_data.role_name}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              p: 1,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          />
        </TableContainer>
      </Div>
    </>
  );
};

export default ActiveUsersList;
