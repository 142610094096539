import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { roleReducer } from "./Roles";
import { categoryReducer } from "./Masters/Category";
import { unitReducer } from "./Masters/Units";
import { supplierReducer } from "./Masters/Suppliers";
import { userReducer } from "./User";
import { gstReducer } from "./Masters/Gst";
import { hsnReducer } from "./Masters/Hsn Code";
import { ptdReducer } from "./Masters/PaymentTermDays";
import { tdsReducer } from "./Masters/Tds";
import { productReducer } from "./Masters/Products";
import AddressDropdownReducer from "./AddressDropdown";
import { sskCompanyReducer } from "./Masters/SskCompany";
import { retailerReducer } from "./Masters/Retailers";
import { offlineStoreReducer } from "./Masters/OfflineStores";
import { PurchaseOrderReducer } from "./PurchaseOrder";
import { marketExecutiveReducer } from "./Masters/MarketExecutive";
import {
  MECommissionDropdownReducer,
  marketExcutiveCommissionCompanyReducer,
  marketExcutiveCommissionReducer,
} from "./MarketExecutiveCommissionMapping";
import { orderReducer } from "./OrderProcessing/Orders";
import { salesOrderReducer } from "./OrderProcessing/SalesOrders";
import { dispatchReducer } from "./OrderProcessing/Dispatch";
import offlinePaymentReducer from "./PaymentsAndPayouts/Payments/OfflinePayment";
import payoutReducer from "./PaymentsAndPayouts/Payouts";
import { InventoryReducer } from "./Inventory";
import { sampleReducer } from "./SampleTracking";
import transactionReducer from "./PaymentsAndPayouts/Transaction";
import metCompnayOrderCommissionReducer from "./MarketExecutiveCompanyOrders";
import { faqReducer } from "./FAQs";
import { ticketReducer } from "./Tickets";

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    userReducer: userReducer,
    addressDropdown: AddressDropdownReducer,
    roleReducer: roleReducer,
    categoryReducer: categoryReducer,
    unitReducer: unitReducer,
    gstReducer: gstReducer,
    hsnReducer: hsnReducer,
    ptdReducer: ptdReducer,
    tdsReducer: tdsReducer,
    productReducer: productReducer,
    supplierReducer: supplierReducer,
    sskCompanyReducer: sskCompanyReducer,
    retailerReducer: retailerReducer,
    offlinestoreReducer: offlineStoreReducer,
    marketExecutiveReducer: marketExecutiveReducer,
    marketExcutiveCommissionReducer:  marketExcutiveCommissionReducer,
    marketExcutiveCommissionCompanyReducer: 
    marketExcutiveCommissionCompanyReducer,
    MECommissionDropdownReducer:  MECommissionDropdownReducer,
    metCompnayOrderCommissionReducer:metCompnayOrderCommissionReducer,
    purchaseOrderReducer: PurchaseOrderReducer,
    orderReducer: orderReducer,
    salesOrderReducer: salesOrderReducer,
    dispatchReducer: dispatchReducer,
    offlinePaymentReducer:   offlinePaymentReducer,
    payoutReducer:   payoutReducer,
    InventoryReducer: InventoryReducer,
    sampleReducer: sampleReducer,
    transactionReducer:  transactionReducer,
    faqReducer: faqReducer,
    ticketReducer: ticketReducer,
  });
};

export default exportReducers;
