import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
} from "@mui/material";

import Div from "@jumbo/shared/Div";
import { Axios } from "index";
import AllApis from "app/Apis/apis";

const CategoryLogsModal = ({ open, onClose }) => {
  const [logs, setLogs] = useState([]);
  const [TotalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(async () => {
    try {
      const response = await Axios.get(AllApis.category.logs(page));
      if (response?.data?.statusCode) {
        setLogs(response.data.data);
        setTotalPage(response.data.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Logs</DialogTitle>
      </Div>
      <DialogContent>
        <TableContainer
          component={Paper}
          sx={{ position: "relative", height: "615px" }}
        >
          <Table border={2}>
            <TableHead sx={{ position: "sticky", top: 0, left: 0 }}>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell sx={{ color: "white" }}>User</TableCell>
                <TableCell sx={{ color: "white" }}>Type</TableCell>
                <TableCell sx={{ color: "white" }}>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs?.map((log, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {log?.data?.fullDocument?.approver?.updated_by?.name}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {log?.data?.operationType}
                  </TableCell>
                  <TableCell
                    sx={{ textTransform: "capitalize", fontWeight: 500 }}
                  >
                    {log?.data?.operationType === "insert" ? (
                      Object.entries(log?.data?.fullDocument?.current_data).map(
                        (item, index) =>
                          item[0] !== "created_by" &&
                          item[0] !== "_id" && (
                            <div key={index} style={{ display: "flex" }}>
                              <Typography sx={{ fontWeight: 500 }}>
                                {item[0].split("_").join(" ")} :{" "}
                              </Typography>
                              <Typography sx={{ ml: 1 }}>
                                {typeof item[1] === "boolean"
                                  ? item[1] === true
                                    ? "Active"
                                    : "Inactive"
                                  : item[1]}
                              </Typography>
                            </div>
                          )
                      )
                    ) : (
                      <>
                        {
                          <Div sx={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {/* First set of Object.entries */}
                              {Object.entries(
                                log?.data?.fullDocument?.current_data
                              ).map(
                                ([key, value], index) =>
                                  key !== "created_by" &&
                                  key !== "_id" && (
                                    <>
                                      {index == 0 && (
                                        <Typography
                                          variant="h5"
                                          sx={{ textDecoration: "underline" }}
                                        >
                                          current Data
                                        </Typography>
                                      )}
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <Typography sx={{ fontWeight: 500 }}>
                                          {key.split("_").join(" ")} :
                                        </Typography>
                                        <Typography sx={{ ml: 1 }}>
                                          {typeof value === "boolean"
                                            ? value
                                              ? "Active"
                                              : "Inactive"
                                            : value}
                                        </Typography>
                                      </div>
                                    </>
                                  )
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                borderLeft: "1px solid",
                                paddingLeft: "10px",
                              }}
                            >
                              {/* Second set of Object.entries */}
                              {Object.entries(
                                log?.data?.updateDescription?.updatedFields
                              )
                                .filter(([key, value]) =>
                                  key.startsWith("proposed_changes")
                                )
                                .map(([key, value], index) => (
                                  <>
                                    {index == 0 && (
                                      <Typography
                                        variant="h5"
                                        sx={{ textDecoration: "underline" }}
                                      >
                                        Updated Data
                                      </Typography>
                                    )}
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <Typography sx={{ fontWeight: 500 }}>
                                        {key.split(".")[1].split("_").join(" ")}{" "}
                                        :{" "}
                                      </Typography>
                                      <Typography sx={{ ml: 1 }}>
                                        {typeof value === "boolean"
                                          ? value
                                            ? "Active"
                                            : "Inactive"
                                          : value}
                                      </Typography>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </Div>
                        }
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={TotalPage || 1}
            page={page}
            onChange={handleChangePage}
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              p: 1,
              backgroundColor: "white",
              borderWidth: "2px 2px 2px 2px", // top right bottom left
              borderStyle: "solid",
              borderColor: "black",
            }}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            size="small"
            onClick={onClose}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryLogsModal;
