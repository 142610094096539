import { ALL_RETAILER_COMPANY_FAIL, ALL_RETAILER_COMPANY_REQUEST, ALL_RETAILER_COMPANY_SUCCESS, ALL_RETAILER_FAIL, ALL_RETAILER_REQUEST, ALL_RETAILER_SUCCESS } from "app/utils/constants/retailerConstant";


const INIT_STATE = {
  branch: {
    loading: false,
    error: null,
    allBranch: [],
    TotalPage: null,
  },
  company: {
    loading: false,
    error: null,
    allCompany: [],
    TotalPage: null,
  }
};

export const retailerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_RETAILER_REQUEST:
      return { ...state, branch: { ...state.branch, loading: true } };
    case ALL_RETAILER_SUCCESS:
      return {
        ...state,
        branch: {
          ...state.branch,
          loading: false,
          allBranch: action.payload.data,
          TotalPage: action.payload.totalPage,
        }
      };
    case ALL_RETAILER_FAIL:
      return {
        ...state, branch: {
          ...state.branch,
          loading: false,
          allRoles: [],
          error: action.payload
        }
      };
    case ALL_RETAILER_COMPANY_REQUEST:
      return { ...state, company: { ...state.company, loading: true } };
    case ALL_RETAILER_COMPANY_SUCCESS:
      return {
        ...state, company: {
          ...state.company,
          loading: false,
          allCompany: action.payload.data,
          TotalPage: action.payload.totalPage
        }
      };
    case ALL_RETAILER_COMPANY_FAIL:
      return {
        ...state, company: {
          ...state.company,
          loading: false,
          allCompany: [],
          error: action.payload
        }
      }
    default:
      return state;
  }
};

