import { AREA, DISTRICT, LOADING, LOCATION, TALUKA } from "app/utils/constants/AddressDropdownConstant"

const INIT_STATE = {
    loading:false,
    district: [],
    taluka: [],
    area: [],
    location: [],
    error:false,
    errorMessage:""
}

const AddressDropdownReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state,loading:true }
        case DISTRICT:
            return { ...state, district: action.payload,loading:false }
        case AREA:
            return { ...state, area: action.payload,loading:false }
        case LOCATION:
            return { ...state, location: action.payload,loading:false }
        case TALUKA:
            return { ...state, taluka: action.payload,loading:false }
        default:
            return state
    }
}

export default AddressDropdownReducer;