import AllApis from "app/Apis/apis";
import { TRANSACTION_FAILED, TRANSACTION_REQUEST, TRANSACTION_SUCCESS } from "app/utils/constants/TransactionConstant";
import { Axios } from "index";

export const getTransactionHistory = ({ page = 1, sort = "desc", sortBy = "created_at", search_value = "", filters = {},metId }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: TRANSACTION_REQUEST });
            const payload = {
                filters: filters,
                searchFields: {
                    string: [
                        "commission.companyDetails.companyName",
                        "payouts.transactionId",
                    ],
                    numbers: [
                        "commission.salesOrderAmount",
                        "commission.commissionPercentage",
                        "commission.commissionAmount",
                        "payouts.tdsAmount",
                        "payouts.amountPaid",
                    ]
                },
            };
            if (!search_value) {
                search_value = "";
            }

            const urlParams = new URLSearchParams({
                search: search_value.trim(),
                page: page,
                sort: sort,
                sortBy: sortBy,
            });

            const { data } = await Axios.post(`${AllApis.transactionHistroy.listing(metId)}?${urlParams.toString()}`, { ...payload });

            dispatch({
                type: TRANSACTION_SUCCESS, payload: {
                    transactionHistory: data?.data?.Transaction,
                    totalPage: data?.totalPages,
                    totals:data?.totals
                }
            })
        } catch (error) {
            dispatch({ type: TRANSACTION_FAILED, payload: error?.response?.data?.message });
        }
    }
}