import Div from '@jumbo/shared/Div'
import { Autocomplete, Box, TextField } from '@mui/material'
import { BranchFormContext } from 'app/pages/Masters/Suppliers/AddEditSuppliers'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from "yup"

const AddBranch = ({formikRef}) => {
    const {id} = useParams()
    const {branchProps:{companyId,setCompanyId,addBranch,data,setData,companyDropdown,companyType}} = useContext(BranchFormContext);
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    [`${companyType}Id`]: companyId,
                    branch_name: data?.branch_name,
                    branch_onboarding_date: data?.branch_onboarding_date
                }}
                validationSchema={
                    yup.object({
                        [`${companyType}Id`]:yup.object().shape({
                            _id:yup.string().required("Company Name is required")
                        }),
                        branch_name: yup.string().required('Branch Name is required'),
                        branch_onboarding_date: yup.date().required('Date is required'),
                    })
                }
                onSubmit={(values) => {
                    setData((pre) => ({ ...pre, branch_name: values.branch_name, branch_onboarding_date: values.branch_onboarding_date }))
                }}
                innerRef={formikRef}
            >
                {({ values, handleChange, errors, setFieldValue,touched, handleBlur}) => {
                    return (
                        <Div sx={{ marginTop: "2%" }}>
                            <Form>
                                <Div sx={{
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    columnGap: 2,
                                }}>
                                    <Autocomplete
                                        disabled={(companyId?._id && addBranch !== true && !id) ? true : false}
                                        sx={{ width: "30%" }}
                                        size="small"
                                        id="company-autocomplete"
                                        options={companyDropdown || []}
                                        value={companyId}
                                        label="Supplier"
                                        freeSolo
                                        getOptionLabel={(option) => option?.company_name}
                                        isOptionEqualToValue={(option, value) => option?._id === value._id}
                                        onChange={(e, newValue) => {
                                            setCompanyId(newValue)
                                            setFieldValue(`${companyType}Id`,newValue?._id)
                                        }}
                                        onBlur={handleBlur}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{ textTransform:"capitalize" }}
                                                {...props}
                                                key={option?._id}
                                            >
                                                {`${option?.company_name} `}{option?.pan_no && `(${option?.pan_no})`}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label={`${companyType} Name`} 
                                            required name={`${companyType}Id`}
                                             error={errors?.[`${companyType}Id`] && touched?.[`${companyType}Id`]} helperText={errors?.[`${companyType}Id`] && touched?.[`${companyType}Id`] ? errors?.[`${companyType}Id`]?._id : ""}
                                            />
                                        )}
                                    />
                                    <TextField name="branch_name" required value={values.branch_name} label="Branch Name" size="small" sx={{ width: "30%" }} onChange={handleChange} onBlur={handleBlur} error={errors?.branch_name && touched?.branch_name} helperText={errors?.branch_name && touched?.branch_name ? errors?.branch_name : ""} />

                                    <TextField name="branch_onboarding_date" type="date" required value={values.branch_onboarding_date} label="OnBoarding Date" size="small" sx={{ width: "30%" }} onChange={handleChange} onBlur={handleBlur} error={errors?.branch_onboarding_date && touched?.branch_onboarding_date} helperText={errors?.branch_onboarding_date && touched?.branch_onboarding_date ? errors?.branch_onboarding_date : ""} />
                                </Div>
                            </Form>
                        </Div>
                    )
                }}
            </Formik>
        </>
    )
}

export default React.memo(AddBranch)