import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { TextField, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { getAllFaq } from "app/redux/actions/FAQs";
import { useDispatch } from "react-redux";
import { Axios } from "index";
import AllApis from "app/Apis/apis";

const AddFaqModal = ({ open, onClose, selectedRow }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const type = selectedRow?.action;
  const state = selectedRow?.data;
  console.log(type, state, "dadad");
  const dispatch = useDispatch();
  const initialValues = {
    question: state ? state?.question : "",
    answer: state ? state?.answer : "",
  };

  const validationSchema = yup.object({
    question: yup.string().required("Question is required"),
    answer: yup.string().required("Answer is required"),
  });

  const onUserSave = async (values) => {
    let response;
    try {
      setSubmitting(true);
      if (type == "add") {
        response = await Axios.post(AllApis.faq.add, values);
      } else if (type == "edit") {
        // Assuming you have a valid 'state' object
        response = await Axios.patch(AllApis.faq.edit(state._id), values);
      } else if (type == "delete") {
        response = await Axios.delete(AllApis.faq.delete(state._id), values);
      }
      if (response?.data?.statusCode) {
        onClose(true);
        Swal.fire({
          title:
            (type === "add" && "FAQ Added") ||
            (type === "edit" && "FAQ Updated") ||
            (type === "delete" && "FAQ Deleted"),
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(getAllFaq("", "desc", "created_at", 1));
      }
    } catch (error) {
      onClose(true);
      Swal.fire({
        title: error?.response?.data.message,
        icon: "error",
        timer: 1000,
        showConfirmButton: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700", textTransform:"capitalize"}}>
          {`${["add", "edit", "delete"].filter((ele) => ele == type)} FAQs`}
        </DialogTitle>
      </Div>
      <DialogContent>
        <Div>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onUserSave}
          >
            {({ setFieldValue, values, errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Div>
                  <Div
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexWrap: "wrap",
                      columnGap: 5,
                      rowGap: 1,
                    }}
                  >
                    <Div sx={{ width: "100%" }}>
                      <Typography variant="h5">Question</Typography>
                      <TextField
                        name="question"
                        size="small"
                        error={!!(touched.question && errors.question)}
                        value={values?.question}
                        fullWidth
                        onChange={(e) =>
                          setFieldValue("question", e.target.value)
                        }
                      />
                      <ErrorMessage
                        name="question"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Div>
                    <Div sx={{ width: "100%", mt: 2 }}>
                      <Typography variant="h5">Answer</Typography>
                      <TextField
                        name="answer"
                        size="small"
                        error={!!(touched.answer && errors.answer)}
                        value={values?.answer}
                        fullWidth
                        multiline
                        rows={4}
                        onChange={(e) =>
                          setFieldValue("answer", e.target.value)
                        }
                      />
                      <ErrorMessage
                        name="answer"
                        component={"div"}
                        style={{ color: "red" }}
                      />
                    </Div>
                    <Div
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 3,
                        mt: 3,
                      }}
                    >
                      <Button
                        sx={{ width: "100px" }}
                        variant="outlined"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        sx={{ width: "100px" }}
                        loading={isSubmitting}
                      >
                        {["add", "edit", "delete"].filter((ele) => ele == type)}
                      </LoadingButton>
                    </Div>
                  </Div>
                </Div>
              </form>
            )}
          </Formik>
        </Div>
      </DialogContent>
    </Dialog>
  );
};

export default AddFaqModal;
